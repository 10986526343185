import { useSelector } from "react-redux";
import { WebAppRoutes } from "../app/routes";
import { selectAuthOIDC } from "../features/auth/selectors";
import { Navigate, useLocation } from "react-router-dom";

const NeedAuth = ({ children }) => {
  const authState = useSelector(selectAuthOIDC);
  const location = useLocation();
  const oidc = localStorage?.oidc ? JSON.parse(localStorage?.oidc) : {};

  if (oidc?.idToken && authState?.shared) {
    if (authState?.course_id) {
      return <Navigate to={WebAppRoutes.COURSE + "/" + authState.course_id} replace={true} />;
    } else {
      return <Navigate to={WebAppRoutes.AUTH} state={{ from: location }} replace={true} />;
    }
  }

  if (!oidc?.idToken) {
    return <Navigate to={WebAppRoutes.AUTH} state={{ from: location }} replace={true} />;
  }

  return children;
};

export default NeedAuth;
