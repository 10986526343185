import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { H2 } from "components/Typography/Titles";
import { generateUid } from "../../../../app/utils/generateUid.util";
import { LandingCompetenciesListLineSvg } from "./LandingCompetenciesListLineSvg";
import { CompetenciesCard } from "components/CompetenciesCard";
import { selectCompetence } from "pages/Course/redux/courseSelectors";
import { getCoursesCompetences } from "pages/Courses/redux/coursesListSlice";
import styles from "./LandingCompetenciesList.module.scss";

export const LandingCompetenciesList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const competences = useAppSelector(selectCompetence);

  useEffect(() => {
    dispatch(getCoursesCompetences());
  }, [dispatch]);

  return (
    <>
      {competences?.length > 0 && (
        <div className={styles["container"]}>
          <H2 className={styles["title"]}>Поможет развить компетенции</H2>
          {competences.map((competence) => (
            <CompetenciesCard
              competence={competence}
              className={styles["competence"]}
              key={generateUid()}
            />
          ))}
          {process.env.REACT_APP_THEME !== "rostechnadzor" && (
            <LandingCompetenciesListLineSvg className={styles["line"]} />
          )}
        </div>
      )}
    </>
  );
};
