import { normalizeISODate } from "../../../../../app/utils";
import { CircleChart } from "components/Charts";

interface TaskAttemptsInfoProps {
  mark: string;
  maxmark: string;
  date: string;
  first: boolean;
}

const TaskAttemptsInfo = ({ mark, maxmark, date, first }: TaskAttemptsInfoProps) => (
  <div className="course-test-achieve__item">
    <div className="course-test-achieve__chart">
      <div className="course-test-achieve__chart__diagram">
        {first ? (
          <CircleChart progress={maxmark ? +mark : 100} color="rgb(var(--success-color))" />
        ) : (
          <CircleChart progress={maxmark ? +mark : 100} color="rgb(var(--base-dark-color))" />
        )}
      </div>
      <span>{mark !== null ? mark + "%" : "-"}</span>
    </div>
    <div className="course-test-achieve-info">
      <span className="course-test-achieve-info__score">
        {mark !== null ? mark : "0"} {+maxmark > 0 && ` из ${maxmark}`}
      </span>
      <small className="course-test-achieve-info__date">{normalizeISODate(date)}</small>
    </div>
  </div>
);

export default TaskAttemptsInfo;
