import TestAlertRight from "../../../../../../../../components/Test/TestAlertRight";
import TestAlertReject from "../../../../../../../../components/Test/TestAlertReject";

export const QuestResultAlert = ({
  right,
  wrong,
  isSelect = false,
}: {
  right?: boolean;
  wrong?: boolean;
  isSelect?: boolean;
}) => {
  const texts = isSelect
    ? ["Выбран верный ответ", "Выбран неверный ответ", "Ответ не был выбран"]
    : ["Верный ответ", "Hеверный ответ", "Ответ не был дан"];
  if (right) {
    return <TestAlertRight text={texts[0]} />;
  }
  if (wrong) {
    return <TestAlertReject text={texts[1]} />;
  }

  return <TestAlertReject text={texts[2]} />;
};
