import { generateUid } from "app/utils";
import { Checkbox } from "components/Checkbox";
import { Scrollbar } from "components/Scrollbar";
import { Option } from "components/Select/types";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./Options.module.scss";

interface OptionItemProps {
  option: Option;
  checkboxValue: boolean | null;
  level?: number;
  isSelected?: boolean;
  icon?: JSX.Element;
  withChild?: boolean;
}

interface OptionsProps {
  selectedOptions?: Option | Option[];
  selectedUpperLevelOptions?: Option[] | Option;
  options: Option[];
  onClickOption: (option: Option) => void;

  onSelectAll?: (isSelectAll: boolean) => void;
  isMulti?: boolean;
  isSorting?: boolean;
  hideSelectAllBySerching?: boolean;
  showUpButton?: boolean;
}

export const Options = ({
  options,
  selectedOptions = [],
  selectedUpperLevelOptions = [],
  onClickOption,
  onSelectAll = () => {},
  isMulti = true,
  isSorting = false,
  hideSelectAllBySerching = false,
  showUpButton = true,
}: OptionsProps) => {
  const [isSelectedAll, setIsSelectedAll] = useState<boolean | null>(false);
  useEffect(() => {
    if (isMulti) {
      if ((selectedOptions as Option[])?.length === options.length) {
        setIsSelectedAll(true);
        return;
      }

      if (!selectedOptions || (selectedOptions as Option[])?.length === 0) {
        setIsSelectedAll(false);
        return;
      }

      setIsSelectedAll(null);
    }
  }, [isMulti, options.length, selectedOptions]);

  const handleSelectAll = () => {
    onSelectAll(!isSelectedAll);
    setIsSelectedAll(!isSelectedAll);
  };

  // true - check, null - minus, false - empty
  const getCheckboxValue = (option: Option): boolean | null => {
    if (!options.some((obj) => obj.parent_id === option.value)) return false;

    const optionsLength = options?.filter((obj) => obj.parent_id === option.value).length;
    const selectedLength = (selectedOptions as Option[])?.filter(
      (obj) => obj.parent_id === option.value
    ).length;

    if (selectedLength === optionsLength) return true;
    if (selectedLength > 0) return null;

    const children = options?.filter((obj) => obj.parent_id === option.value);
    for (const el of children) {
      const temp = getCheckboxValue(el);
      if (temp || temp === null) return null;
    }

    return false;
  };

  const OptionItem = ({
    icon,
    option,
    checkboxValue,
    level = 1,
    isSelected = false,
    withChild,
  }: OptionItemProps) => {
    return (
      <li
        key={generateUid()}
        className={classNames(styles["option"], {
          [styles["selected-option"]]: isSelected,
          [styles["parent"]]: level <= 1,
          [styles["parent-with-child"]]: withChild,
        })}
        onClick={() => onClickOption(option)}
        // todo useMediaQuery
        style={{ paddingLeft: `${level * 36}px` }}
      >
        {icon}
        {isMulti && <Checkbox value={checkboxValue} style={{ flexShrink: 0 }} />}
        {option.label}
      </li>
    );
  };

  const Option = ({ option, level = 1 }: { option: Option; level?: number }) => {
    if (!(options as Option[]).some((obj) => obj.parent_id === option.value)) {
      // Элемент для листа дерева (вершина, которая находится на конце дерева)
      return (
        <OptionItem
          option={option}
          level={level}
          isSelected={option.value === (selectedUpperLevelOptions as Option)?.value}
          checkboxValue={
            isMulti
              ? selectedOptions
                ? (selectedOptions as Option[])?.some(({ value }) => value === option.value)
                : false
              : false
          }
        />
      );
    }

    const children = (options as Option[]).filter((obj) => obj.parent_id === option.value);

    if (isSorting) {
      children.sort((a, b) => a.label.trim().localeCompare(b.label.trim()));
    }

    return (
      <>
        <OptionItem
          option={option}
          level={level}
          checkboxValue={getCheckboxValue(option)}
          withChild={!!children?.length}
        />
        {children.map((obj) => (
          <Option key={generateUid()} option={obj} level={level + 1} />
        ))}
      </>
    );
  };

  const parents = options.filter((obj) => !obj.parent_id);

  if (isSorting) {
    parents.sort((a, b) => a.label.trim().localeCompare(b.label.trim()));
  }

  return (
    <Scrollbar showUpButton={showUpButton}>
      <ul className={styles["options"]}>
        {isMulti && !hideSelectAllBySerching && (
          <div className={styles["options"]}>
            <li
              key={generateUid()}
              className={classNames(styles["option"], styles["option-select-all"])}
              style={{ border: "unset" }}
              onClick={handleSelectAll}
            >
              {isMulti && <Checkbox value={isSelectedAll} />}
              Выбрать все
            </li>
          </div>
        )}

        {parents.map((option) => {
          return <Option key={generateUid()} option={option} />;
        })}
      </ul>
    </Scrollbar>
  );
};
