import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getPollUrl,
  setCourseFeedback,
  showAsideNav,
  showBottomNav,
} from "pages/Course/redux/courseSlice";
import Container from "../../../../components/Container/Container";
import { H1 } from "../../../../components/Typography/Titles";
import { Button } from "../../../../components/Buttons";
import { TextAreaField } from "../../../../components/TextAreaField";
import { StarRating } from "../../../../components/StarRating";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useModal } from "../../../../components/ModalsContainer";
import { CourseFeedbackModal } from "../../../../components/Modals/CourseFeedbackModal";
import { WebAppRoutes } from "../../../../app/routes";
import { selectPollUrl } from "../../redux/courseSelectors";
import styles from "./CourseFeedback.module.scss";

export const CourseFeedback = ({ courseId }: { courseId?: string }) => {
  const [score, setScore] = useState<number>(0);
  const [text, setText] = useState<string>("");
  const [anketologId, setAnketologId] = useState<string | undefined>();

  const COURSE_RESULT_LINK = `${WebAppRoutes.COURSE}/${courseId}/result`;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const pollUrl = useAppSelector(selectPollUrl);
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    dispatch(showAsideNav());
  }, [dispatch]);

  useEffect(() => {
    if (!courseId) return;
    dispatch(getPollUrl(courseId));
  }, [courseId, dispatch]);

  useEffect(() => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
  }, [dispatch]);

  useEffect(() => {
    if (!pollUrl) return;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = pollUrl;
    document.body.appendChild(script);

    setAnketologId(pollUrl.match(/js\/(\d+)/)?.[1]);
  }, [pollUrl]);

  const openModalCourseFeedback = () => {
    openModal({
      content: (
        <CourseFeedbackModal
          hasText={!!text}
          onConfirm={() => {
            navigate(COURSE_RESULT_LINK);
            closeModal();
          }}
        />
      ),
    });
  };

  const handleClick = () => {
    if (!courseId) return;
    dispatch(setCourseFeedback({ course_id: courseId, score, text }))
      .unwrap()
      .then(openModalCourseFeedback);
  };

  return (
    <main>
      <Container>
        <div className={styles["course-feedback"]}>
          <H1 className={styles["course-feedback__title"]}>
            Поздравляем с успешным окончанием курса!
          </H1>

          <p className={styles["course-feedback__description"]}>
            Оставьте свою оценку и впечатление. Они помогут нам усовершенствовать курс, а другим
            пользователям - понять, насколько он им подойдет.
          </p>

          <div className={styles["body"]}>
            <div className={styles["body__title"]}>Оценить курс</div>

            <div className={styles["body__block"]}>
              <StarRating score={score} onClick={setScore} />

              <TextAreaField
                className={styles["textarea"]}
                label="Ваш комментарий о курсе"
                maxLength={300}
                value={text}
                onChange={setText}
              />
            </div>

            {/* Анкета обратной связи */}
            {pollUrl && anketologId && (
              <div id={`anketolog-frame-${anketologId}`} className={styles["anketolog"]} />
            )}

            <Button color="primary" disabled={!score} onClick={handleClick}>
              Отправить
            </Button>
          </div>
        </div>
      </Container>
    </main>
  );
};
