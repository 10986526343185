import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api/api";
import { EndPoints } from "../../../../../../app/endpoints";

export const postMeetingViewed = createAsyncThunk(
  "course-meeting/postMeetingViewed",
  async (meetingId: number) => {
    await api.post(EndPoints.COURSE_MEETING_VIEWED(meetingId));
    return meetingId;
  }
);
