import { Skeleton } from "../../../../../components/Skeleton";

const QuizeLoadSkeleton = () => {
  return (
    <>
      <div style={{ width: "100%", marginTop: "31px" }}>
        <Skeleton width="100%" />
        <Skeleton sx={{ marginTop: "8px" }} width="calc(100% - 50px)" />
        <Skeleton sx={{ marginTop: "8px" }} width="calc(100% - 25px)" />
        <Skeleton sx={{ marginTop: "8px" }} width="100%" />
        <Skeleton sx={{ marginTop: "8px" }} width="min(830px, 100%)" />
      </div>
      <div style={{ width: "100%", marginTop: "31px" }}>
        <Skeleton width="calc(100% - 50px)" />
        <Skeleton sx={{ marginTop: "8px" }} width="100%" />
        <Skeleton sx={{ marginTop: "8px" }} width="min(1010px, 100%)" />
      </div>
      <div style={{ width: "100%", marginTop: "31px" }}>
        <Skeleton width="calc(100% - 25px)" />
        <Skeleton sx={{ marginTop: "8px" }} width="100%" />
        <Skeleton sx={{ marginTop: "8px" }} width="min(545px, 100%)" />
      </div>
    </>
  );
};

export default QuizeLoadSkeleton;
