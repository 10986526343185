import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNotificationSettings } from "../api";

export const getNotificationSettingsThunkAction = createAsyncThunk<any, void>(
  "settings/getNotificationSettings",
  async () => {
    try {
      const response = await getNotificationSettings();
      return response.data.data;
    } catch (e) {
      console.log(e);
    }
  }
);
