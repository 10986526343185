import styles from "./HeaderV2.module.scss";
import classNames from "classnames";

interface HeaderV2Props {
  className?: string;
  children?: (JSX.Element | string)[] | JSX.Element | string | false;
}

export const HeaderV2 = ({ className, children }: HeaderV2Props) => (
  <header className={classNames(styles["header"], className)}>{children}</header>
);
