import { mediaQuerys } from "../../../../app/utils";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { Avatar12 } from "../../../../components/Svg/Avatars";
import { useAppDispatch } from "hooks/redux";
import { clear } from "features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { WebAppRoutes } from "app/routes";
import { LogoutIcon } from "components/Icons";
import { IconButton } from "components/Buttons/IconButton/IconButton";

const UserWidgetShared = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lgMin = useMediaQuery(`(min-width:${mediaQuerys.lgMin}px)`);

  const handleLogout = () => {
    dispatch(clear());
    navigate(WebAppRoutes.HOME, { replace: true });
  };

  return (
    <>
      <div className="profile-widget">
        {lgMin && (
          <div className="profile-widget__name">
            Гостевая
            <br />
            учетная запись
          </div>
        )}
        <div className="profile-widget__avatar">
          <Avatar12 />
        </div>
        <div className="profile-widget__logout-button">
          <IconButton onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default UserWidgetShared;
