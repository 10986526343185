import { EntityId } from "@reduxjs/toolkit/src/entities/models";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  selectCourseIsLoading,
  selectMulticourseContent,
} from "pages/Course/redux/courseSelectors";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { useCallback, useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import { checkFullnessProfileUtil } from "../../../../app/utils";
import { useNotification } from "../../../../components/Notification";
import {
  selectProfileDetails,
  selectProfileFiles,
  selectProfileLoadWithFiles,
} from "../../../Profile/redux/profile-slice/selectors/profile.selectors";
import {
  clearCourseData,
  coursesSelectors,
  getCourseInfo,
  lessonsSelectors,
} from "../../redux/courseSlice";
import CourseLoadSkeleton from "../CourseLoadSkeleton";

const CourseContentWrap = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { course_id, lesson_id, content_id } = useParams();
  const currentCourse = useAppSelector((state) =>
    coursesSelectors.selectById(state, course_id as EntityId)
  );
  const allLesson = useAppSelector(lessonsSelectors.selectAll);
  const profile = useAppSelector(selectProfileDetails);
  const educationDocs = useAppSelector(selectProfileFiles);
  const profileIsload = useAppSelector(selectProfileLoadWithFiles);
  const { showNotification } = useNotification();
  const courseDispatchRef = useRef(null);
  const courseIsLoading = useAppSelector(selectCourseIsLoading);
  const multicourseContent = useAppSelector(selectMulticourseContent);

  const COURSE_RESULT_LINK = `${WebAppRoutes.COURSE}/${course_id}/result`;
  const COURSE_FEEDBACK_LINK = `${WebAppRoutes.COURSE}/${course_id}/feedback`;

  const redirectToProfile = useCallback(
    (profileValid: boolean, educationDocsValid: boolean) => {
      navigate("/profile/edit");
      const message = (
        <>
          <p style={{ marginBottom: "5px" }}>
            <b>Для перехода к курсу необходимо: </b>
          </p>
          <ul style={{ listStyle: "none" }}>
            {!profileValid && <li>- Заполнить данные о себе</li>}
            {!educationDocsValid && <li> - Добавить хотя бы один документ об образовании</li>}
          </ul>
        </>
      );
      showNotification({
        message,
        type: "error",
        showTime: 10,
      });
    },
    [showNotification, navigate]
  );

  useEffect(() => {
    if (!currentCourse && profileIsload) {
      // @ts-ignore
      courseDispatchRef.current = dispatch(getCourseInfo(course_id));
      // @ts-ignore
      courseDispatchRef.current.then(({ payload }) => {
        // @ts-ignore
        if (payload?.status === 404) {
          navigate("/404", { replace: true });
          return;
        }
        // @ts-ignore
        if (payload?.status === 403) {
          navigate("/403", { replace: true });
          return;
        }
      });
    }
  }, [course_id, currentCourse, dispatch, navigate, profileIsload]);

  useEffect(() => {
    return () => {
      dispatch(clearCourseData());
      if (courseDispatchRef.current) {
        // @ts-ignore
        courseDispatchRef.current.abort();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    // Берем 1 незавершенный курс, либо 1 по списку^
    const courseId =
      multicourseContent &&
      multicourseContent.length > 0 &&
      (multicourseContent?.find((obj) => obj.status !== EnrolledStatus.completed)?.id ||
        multicourseContent[0].id);

    const redirectUrl =
      currentCourse &&
      currentCourse.format === CourseFormat.multicourse &&
      multicourseContent &&
      `${WebAppRoutes.COURSE}/${course_id}${WebAppRoutes.CONTENT}/${courseId}`;

    redirectUrl && navigate(redirectUrl, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCourse]);

  useEffect(() => {
    if (currentCourse && !courseIsLoading && profileIsload) {
      const course = currentCourse;

      if (course?.personal_data_processing && profile && educationDocs) {
        const { profileValid, educationDocsValid } = checkFullnessProfileUtil(
          profile,
          educationDocs
        );
        if (!profileValid || !educationDocsValid) {
          redirectToProfile(profileValid, educationDocsValid);
          return;
        }
      }

      if (!course?.enrolled_ts) {
        navigate(`${WebAppRoutes.COURSE_PREVIEW}/${course_id}`, { replace: true });
        return;
      }

      if (
        pathname.includes("report") ||
        pathname.includes("result") ||
        pathname.includes("feedback") ||
        lesson_id ||
        content_id
      )
        return;

      // Пользователь еще не оценил завершенный курс => страница оценок
      if (course?.commenting) {
        navigate(COURSE_FEEDBACK_LINK, { replace: true });
        return;
      }

      const requiredLessons = allLesson?.filter((obj) => obj.required);
      // Пользователь завершил все обязательные задания => страница с результатами
      if (
        course.format !== CourseFormat.multicourse &&
        requiredLessons?.length &&
        requiredLessons?.every((obj) => obj.status === "completed")
      ) {
        navigate(COURSE_RESULT_LINK, { replace: true });
        return;
      }

      // Берем 1 незавершенный урок, либо 1 по списку^
      const lessonId =
        allLesson?.find((obj) => obj.status !== "completed")?.id || course.lessons[0];

      const redirectUrl =
        course.format !== CourseFormat.multicourse &&
        `${WebAppRoutes.COURSE}/${course_id}${WebAppRoutes.LESSON}/${lessonId}`;

      redirectUrl && navigate(redirectUrl, { replace: true });
    }
  }, [
    COURSE_FEEDBACK_LINK,
    COURSE_RESULT_LINK,
    allLesson,
    courseIsLoading,
    course_id,
    content_id,
    currentCourse,
    educationDocs,
    lesson_id,
    navigate,
    pathname,
    profile,
    profileIsload,
    redirectToProfile,
    multicourseContent,
  ]);

  return !courseIsLoading ? <Outlet /> : <CourseLoadSkeleton />;
};
export default CourseContentWrap;
