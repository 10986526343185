import classNames from "classnames";
import { Skeleton } from "components/Skeleton";
import cardStyles from "./CoursesCard/CoursesCard.module.scss";
import listStyles from "./CoursesList/CoursesList.module.scss";

const CoursesListSkeletonCustom = () => {
  return (
    <div className={listStyles["container"]}>
      <div className={cardStyles["course-card"]}>
        <div className={classNames(cardStyles["course-card_skeleton"])} />
        <div className={cardStyles["course-card__body"]}>
          <Skeleton variant="rounded" height={"24px"} />
          <Skeleton variant="text" height={"24px"} width="85%" />
          <Skeleton variant="text" height={"24px"} width="70%" />
          <Skeleton variant="text" height={"24px"} width="75%" />
        </div>
      </div>
      <div className={cardStyles["course-card"]}>
        <div className={classNames(cardStyles["course-card_skeleton"])} />
        <div className={cardStyles["course-card__body"]}>
          <Skeleton variant="rounded" height={"24px"} />
          <Skeleton variant="text" height={"24px"} width="85%" />
          <Skeleton variant="text" height={"24px"} width="70%" />
          <Skeleton variant="text" height={"24px"} width="75%" />
        </div>
      </div>
      <div className={cardStyles["course-card"]}>
        <div className={classNames(cardStyles["course-card_skeleton"])} />
        <div className={cardStyles["course-card__body"]}>
          <Skeleton variant="rounded" height={"24px"} />
          <Skeleton variant="text" height={"24px"} width="85%" />
          <Skeleton variant="text" height={"24px"} width="70%" />
          <Skeleton variant="text" height={"24px"} width="75%" />
        </div>
      </div>
      <div className={cardStyles["course-card"]}>
        <div className={classNames(cardStyles["course-card_skeleton"])} />
        <div className={cardStyles["course-card__body"]}>
          <Skeleton variant="rounded" height={"24px"} />
          <Skeleton variant="text" height={"24px"} width="85%" />
          <Skeleton variant="text" height={"24px"} width="70%" />
          <Skeleton variant="text" height={"24px"} width="75%" />
        </div>
      </div>
    </div>
  );
};

export default CoursesListSkeletonCustom;
