import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUnixTime } from "date-fns";
import {
  ConversationGetResponse,
  ConversationPostResponse,
  ConversationPutResponse,
  ConversationState,
  IMessage,
} from "./interfaces";
import { createMessage, deleteMessage, getMessages, updateMessage } from "./thunk-actions";

const name = "conversation";

const initialState: ConversationState = {
  isLoading: false,
  manager: false,
  messages: [],
};

const findObjectInTree = (tree: IMessage[], id: number): IMessage | null => {
  let curNode;
  for (const node of tree) {
    curNode = node;
    if (node.id === id || (node.children && (curNode = findObjectInTree(node.children, id)))) {
      return curNode;
    }
  }
  return null;
};

const conversationSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearConversation: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMessages.fulfilled, (state, action: PayloadAction<ConversationGetResponse>) => {
        state.isLoading = false;
        state.messages = action.payload?.data?.messages || [];
        state.manager = action.payload?.data?.manager;
      })
      .addCase(getMessages.rejected, (state) => {
        state.isLoading = false;
        state.messages = [];
      });

    builder.addCase(
      createMessage.fulfilled,
      (state, action: PayloadAction<ConversationPostResponse>) => {
        // Добавляем новое сообщение
        const { parent, ...message } = action.payload.data;

        // Заполняем children у нового сообщения
        const newMessage: IMessage = { ...message, children: message.children ?? [] };

        // Если это дочернее сообщение
        if (parent) {
          const parentMessage = findObjectInTree(state.messages, parent);

          if (parentMessage?.children) {
            parentMessage.children = [newMessage, ...parentMessage.children];
          }
        } else {
          state.messages = [newMessage, ...state.messages];
        }
      }
    );

    builder.addCase(
      updateMessage.fulfilled,
      (state, action: PayloadAction<ConversationPutResponse>) => {
        // Обновляем сообщение
        const message = action.payload?.data;
        if (!message) return;

        const curMessage = findObjectInTree(state.messages, message.id);
        if (!curMessage) return;

        curMessage.text = message.text;
        curMessage.updated_ts = message.updated_ts;
        curMessage.user = { ...curMessage.user, ...message.user };
      }
    );

    builder.addCase(deleteMessage.fulfilled, (state, action: PayloadAction<number>) => {
      // Удаляем сообщение
      const curMessage = findObjectInTree(state.messages, action.payload);
      if (!curMessage) return;

      curMessage.deleted = true;
      curMessage.updated_ts = getUnixTime(new Date());
    });
  },
});

export const { clearConversation } = conversationSlice.actions;

export const conversationReducer = conversationSlice.reducer;
