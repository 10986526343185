import { IMessage } from "../../redux/interfaces";
import { Message } from "./Message";
import styles from "./Message.module.scss";

interface MessageListProps {
  courseId: string;
  manager: boolean;
  messages: IMessage[];
  level?: number;
}

export const MessageList = ({ courseId, messages, manager, level = 0 }: MessageListProps) => {
  return (
    <div className={styles["messages-block"]} data-level={level}>
      {messages?.map((message) => (
        <Message
          key={message.id}
          message={message}
          manager={manager}
          level={level}
          courseId={courseId}
        />
      ))}
    </div>
  );
};
