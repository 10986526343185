import { SVGProps } from "react";

export const CalendarIcon = ({
  iconColor = "#FFC422",
  rectColor = "#191229",
  ...otherProps
}: { iconColor?: string; rectColor?: string } & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={93}
      height={96}
      viewBox="0 0 93 96"
      fill="none"
      {...otherProps}
    >
      <path
        fill={rectColor}
        d="M57.188 78.846c-10.816 10.403-26.823 3.033-35.476-7.803-13.188-16.516-12.98-35.977 3.893-47.68 16.873-11.704 39.802-3.035 49.753 9.969 9.95 13.004-7.354 35.11-18.17 45.514Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M69 20.523H31.667a5.333 5.333 0 0 0-5.334 5.334V63.19a5.333 5.333 0 0 0 5.334 5.333H69a5.333 5.333 0 0 0 5.333-5.333V25.857A5.333 5.333 0 0 0 69 20.523ZM61 15.188v10.666M39.667 15.188v10.666M26.333 36.523h48"
      />
    </svg>
  );
};
