import { SVGProps } from "react";

export const MeetingRostechnadzorIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={574}
    height={520}
    viewBox="0 0 574 520"
    fill="none"
    {...props}
  >
    <rect
      width={424.305}
      height={424.305}
      x={94.818}
      y={-15}
      fill="#ECF2F7"
      rx={60}
      transform="rotate(15 94.818 -15)"
    />
    <path
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M478.063 451.909c-77.318 32.801-113.633 55.059-135.892 63.259-22.258 8.201-44.516 0-77.317-14.057-32.802-14.058-161.98-94.248-188.22-110.281-21.088-12.887-20.999-29.07 0-41.002 51.542-29.287 149.249-57.799 155.418-66.612 8.201-11.715 16.4-206.18 32.802-210.867 16.402-4.686 258.898 93.719 270.612 113.634 11.715 19.915-19.914 221.41-38.658 227.267-2.422.757-4.569 1.142-6.461 1.237 4.017 12.394 4.274 30.22-12.284 37.422Z"
    />
    <path
      fill="#fff"
      d="M245.332 308.817c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.909c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M245.332 308.817c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.909c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792ZM467.908 433.002c-15.23 10.543-123.006 59.745-135.892 63.26-12.886 3.514-28.115 0-49.202-8.201"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M444.478 384.971c-23.429-8.2-176.249-93.123-190.307-98.98 0-4.686 18.165-186.96 25.193-186.96 7.029 0 230.717 90.303 231.889 103.19 1.171 12.886-19.915 130.034-26.944 154.635s-16.401 36.316-39.831 28.115Z"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M455 318c-19.591-6.85-171.245-78.608-183-83.501 0-3.913 10-112.5 16-117s206.52 83.237 207.5 94.001c.98 10.763-8.123 72.452-14 93-5.877 20.547-6.909 20.349-26.5 13.5Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m268.389 255.797 118.737 56.227M267 269.029l118.737 56.227"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M83.863 278.989C66.57 271.808 65 252.5 117 252.5h2.001c60.999 3 46.717 20.091 31.499 26.489-25 10.511-54.922 4.865-66.637 0Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M119 214.732v50"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M119.5 124.605c-18.656.223-23.474 16.415-23.362 32.102-.118 6.263-.52 34.925 2.516 44.342 4.674 14.492 30.735 14.695 36.58 2.573 4.691-9.73 4.691-28.214 4.691-46.914v-.001c0-10.425-1.77-32.324-20.425-32.102Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M80 177.359c.524 9.325 0 30.567 13.645 40.928 13.163 9.996 37.56 7.068 46.709 0C154 207.746 154 186.166 154 173.732M98 153.732h15M97 162.732h16M97 170.732h15M293.106 175.756c59.543 28.914 180.661 87.492 188.788 90.49"
    />
    <circle
      cx={387}
      cy={218}
      r={39}
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
    <circle
      cx={314.526}
      cy={187.525}
      r={20}
      fill="#ECF2F7"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      transform="rotate(-7.784 314.526 187.525)"
    />
    <circle
      cx={460.579}
      cy={256.577}
      r={20}
      fill="#ECF2F7"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      transform="rotate(-9.612 460.579 256.577)"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M382.131 195.03c-6.585 3.056-22.742 39.763-6.95 38.563 8.614-.655 32.976-1.912 34.055-9.394 1.071-7.434-20.52-32.226-27.105-29.169ZM461.702 247.97c-2.902 1.284-10.252 17.2-3.34 16.8 3.769-.219 14.427-.577 14.957-3.838.527-3.241-8.715-14.246-11.617-12.962ZM319.424 180.2c1.29 2.9-3.946 19.631-8.8 14.695-2.648-2.693-10.307-10.112-8.511-12.885 1.785-2.756 16.022-4.709 17.311-1.81Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m454.138 245.499-6.043 15.222M326.691 183.447l-5.739 15.34"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M464.557 135.116c32.145-22.961 67.891-2.795 74.996 32.87 7.106 35.664-26.754 55.101-40.346 55.702-60.925 2.694-66.796-65.612-34.65-88.572Z"
    />
    <path
      fill="#ECF2F7"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M481.969 151.719c13.432-9.594 28.369-1.167 31.338 13.736 2.97 14.903-11.179 23.025-16.859 23.276-25.459 1.126-27.912-27.417-14.479-37.012Z"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M487.094 159.162c7.492-5.351 15.822-.651 17.478 7.66 1.656 8.312-6.235 12.842-9.403 12.982-14.198.628-15.567-15.291-8.075-20.642Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M471.49 131.819c-7.881 11.512-12.213 39.674-10.946 45.751 4.539 21.786 41.456 34.706 57.258 13.921 6.426-8.452 9.969-42.805 10.672-46.692"
    />
  </svg>
);
