import { SVGProps } from "react";

export const MicroIcon = ({
  iconColor = "#FFC422",
  rectColor = "#191229",
  ...otherProps
}: { iconColor?: string; rectColor?: string } & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={92}
      height={96}
      viewBox="0 0 92 96"
      fill="none"
      {...otherProps}
    >
      <path
        fill={rectColor}
        d="M78.015 46.05c7.51 12.993-3.48 26.77-16.073 32.575-19.193 8.849-38.038 3.985-45.36-15.2-7.321-19.184 6.587-39.37 21.596-45.916 15.008-6.546 32.327 15.55 39.837 28.542Z"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M42.929 16.116A10 10 0 0 1 60 23.188v18.666a10 10 0 0 1-20 0V23.188a10 10 0 0 1 2.929-7.072ZM50 17.188a6 6 0 0 0-6 6v18.666a6 6 0 1 0 12 0V23.188a6 6 0 0 0-6-6ZM31.333 34.52a2 2 0 0 1 2 2v5.333a16.667 16.667 0 0 0 33.334 0v-5.333a2 2 0 0 1 4 0v5.333A20.666 20.666 0 0 1 52 62.424v6.097a2 2 0 1 1-4 0v-6.097a20.667 20.667 0 0 1-18.667-20.57v-5.333a2 2 0 0 1 2-2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
