import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../../../services/api/api";
import { EndPoints } from "../../../../../../app/endpoints";
import {
  ConversationDeleteRequest,
  ConversationPostRequest,
  ConversationPutRequest,
} from "../interfaces";

const name = "conversation";

export const getMessages = createAsyncThunk(
  `${name}/getMessages`,
  async (courseId: string, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.CONVERSATION(courseId));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

export const createMessage = createAsyncThunk(
  `${name}/createMessage`,
  async ({ course_id, ...data }: ConversationPostRequest, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.CONVERSATION(course_id), data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

export const updateMessage = createAsyncThunk(
  `${name}/updateMessage`,
  async ({ course_id, message_id, ...data }: ConversationPutRequest, thunkAPI) => {
    try {
      const response = await api.put(EndPoints.CONVERSATION_MESSAGE(course_id, message_id), data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

export const deleteMessage = createAsyncThunk(
  `${name}/deleteMessage`,
  async ({ course_id, message_id }: ConversationDeleteRequest, thunkAPI) => {
    try {
      await api.delete(EndPoints.CONVERSATION_MESSAGE(course_id, message_id));
      return message_id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);
