import { useClickAway, useEventListener } from "ahooks";
import classNames from "classnames";
import { CloseIcon } from "components/Icons";
import { PointerEvent, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./Modal.module.scss";

interface ModalProps {
  children?: ReactNode;
  onClose?: () => void;
  isActiveModal: boolean;
  clickOutsideClose?: boolean;

  closeClassName?: string;
}

export const Modal = ({
  children,
  onClose = () => {},
  isActiveModal = false,
  clickOutsideClose = true,
  closeClassName,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isRender, setIsRender] = useState<boolean>();

  const handleCloseModal = (event: PointerEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClose();
  };

  useClickAway(() => {
    if (isRender && isActiveModal && clickOutsideClose) {
      onClose();
    }
  }, [modalRef]);

  useEventListener("keydown", (event) => {
    if (event.key.toLocaleLowerCase() === "escape") {
      onClose();
    }
  });

  useEffect(() => {
    setIsRender(true);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.content} ref={modalRef}>
          {children}
          <button
            className={classNames(styles["close-button"], closeClassName)}
            onClick={handleCloseModal}
          >
            <CloseIcon className={styles["close-icon"]} />
          </button>
        </div>
      </div>
    </div>
  );
};
