import PaperBox from "../Paper/PaperBox";
import { H3 } from "../Typography/Titles";
import { Button } from "../Buttons";

interface StudyPlugProps {
  title: string;
  descr: string;
  pic: JSX.Element;
  buttonName: string;
  onClick: () => void;
}

const StudyPlug = ({ title, descr, pic, buttonName, onClick }: StudyPlugProps) => {
  return (
    <PaperBox className="plug-study">
      {pic && <div className="plug-study__pic">{pic}</div>}
      <div className="plug-study-text">
        <H3 className="plug-study-text__title">{title}</H3>
        <p className="plug-study-text__descr">{descr}</p>
        {buttonName && (
          <Button color="primary" onClick={onClick}>
            {buttonName}
          </Button>
        )}
      </div>
    </PaperBox>
  );
};

export default StudyPlug;
