import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Select } from "../../../../../components/Select";
import { Option } from "../../../../../components/Select/types";
import { coursesStatusesSelectors } from "../../../redux/coursesListSlice";
import styles from "../CoursesSearch.module.scss";

interface StatusSearchProps {
  selectedStatuses: Option[] | undefined;
  setSelectedStatuses: Dispatch<SetStateAction<Option[] | undefined>>;
}

export const StatusSearch = ({ selectedStatuses, setSelectedStatuses }: StatusSearchProps) => {
  const courseStatuses = useSelector(coursesStatusesSelectors.selectAll);

  const handleSetSelectedStatuses = (selectedOptions: Option[]) => {
    setSelectedStatuses(selectedOptions);
  };

  const isSelectedAll = selectedStatuses?.length === courseStatuses.length;

  return (
    <div className={styles["secondary-control"]}>
      <Select
        isMulti={true}
        showCleaner={!isSelectedAll && !!selectedStatuses}
        label="Статус"
        placeholder="Статус"
        options={courseStatuses?.map(({ code, name }) => ({
          label: name ?? "",
          value: `${code}`,
        }))}
        onChange={(selectedOptions) => {
          handleSetSelectedStatuses(selectedOptions as Option[]);
        }}
        value={selectedStatuses}
      />
    </div>
  );
};
