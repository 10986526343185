import { SVGProps } from "react";

export const QuestionaryRostechnadzorIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={701}
    height={520}
    fill="none"
    viewBox="0 0 701 520"
    {...props}
  >
    <rect
      width={424.305}
      height={424.305}
      x={94.818}
      y={-15}
      fill="#ECF2F7"
      rx={60}
      transform="rotate(15 94.818 -15)"
    />
    <path
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M478.063 451.909c-77.318 32.801-113.633 55.059-135.892 63.259-22.258 8.201-44.516 0-77.317-14.057-32.802-14.058-161.98-94.248-188.22-110.281-21.088-12.887-20.999-29.07 0-41.002 51.542-29.287 149.249-57.799 155.418-66.612 8.201-11.715 16.4-206.18 32.802-210.867 16.402-4.686 258.898 93.719 270.612 113.634 11.715 19.915-19.914 221.41-38.658 227.267-2.422.757-4.569 1.142-6.461 1.237 4.017 12.394 4.274 30.22-12.284 37.422Z"
    />
    <path
      fill="#fff"
      d="M245.331 308.817c-16.4-2.343-55.057 16.1-69.117 24.609-23.43 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.859-96.07ZM219.553 452.909c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.544-3.514-41.664 11.861-32.801 22.259 8.986 10.543 37.486 25.763 51.544 32.792Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M245.331 308.817c-16.4-2.343-55.057 16.1-69.117 24.609-23.43 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.859-96.07ZM219.553 452.909c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.544-3.514-41.664 11.861-32.801 22.259 8.986 10.543 37.486 25.763 51.544 32.792ZM467.908 433.002c-15.229 10.543-123.005 59.745-135.891 63.26-12.887 3.514-28.116 0-49.203-8.201"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M444.478 384.971c-23.43-8.2-176.249-93.123-190.307-98.98 0-4.686 18.164-186.96 25.193-186.96s230.717 90.303 231.888 103.19c1.172 12.886-19.915 130.034-26.944 154.635s-16.4 36.316-39.83 28.115Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m348.486 158.789 54.267 24.392M339.521 212.744l54.223 24.489M330.482 266.465l52.535 27.926M345 172.855l54.266 24.392M336.009 226.805l54.222 24.488M327 280.465l52.535 27.926"
    />
    <ellipse
      cx={318.87}
      cy={158.786}
      fill="#ECF2F7"
      rx={18.806}
      ry={19.573}
      transform="rotate(25.659 318.87 158.786)"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M310.243 149.653c-.431 7.096 1.604 13.534 6.184 12.311 4.58-1.222 18.347-8.104 19.203-9.087"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M329.405 146.426c-9.922-8.74-25.156-10.439-29.908 4.086-4.752 14.525 16.064 31.771 30.553 14.91M282.052 254.511c4.752-14.525 19.943-14.936 29.903-5.511 9.96 9.425 3.813 21.451.002 25-13.96 13.001-34.658-4.964-29.905-19.489Z"
    />
    <path
      fill="#fff"
      d="M376.28 262.559c9.547 2.944 16.76 6.391 16.442 16.225l-2.753 25.131-3.041 27.756c-1.849 16.878-19.853 14.906-25.479 14.289-5.627-.616-142.613-14.865-155.283-17.013-12.671-2.147-18.431-8.472-17.568-16.349l5.589-51.01c.645-5.891 6.669-6.621 11.947-5.284-1.187-5.742.521-12.33 9.237-11.375 13.503 1.48 151.362 14.686 160.909 17.63Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeWidth={3}
      d="m389.969 303.915-3.041 27.756c-1.849 16.878-19.853 14.906-25.479 14.289-5.627-.616-142.613-14.865-155.283-17.013-12.671-2.147-18.431-8.472-17.568-16.349l5.589-51.01c1.315-12.003 24.955-2.58 19.129 2.095-5.826 4.676-14.449-20.562 2.055-18.754 13.503 1.48 151.362 14.686 160.909 17.63 9.547 2.944 15.539 6.107 15.221 15.941v8.5"
    />
    <path
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M233.782 266.736a.5.5 0 0 1 .903.099l4.623 13.869a3.502 3.502 0 0 0 2.537 2.305l14.248 3.274a.5.5 0 0 1 .185.889l-11.762 8.683a3.502 3.502 0 0 0-1.408 3.125l1.29 14.563a.5.5 0 0 1-.789.451l-11.893-8.504a3.501 3.501 0 0 0-3.406-.373l-13.452 5.727a.5.5 0 0 1-.673-.611l4.412-13.939a3.5 3.5 0 0 0-.697-3.355l-9.604-11.024a.5.5 0 0 1 .374-.828l14.619-.111a3.5 3.5 0 0 0 2.976-1.701l7.517-12.539ZM293.044 273.23a.5.5 0 0 1 .903.099l4.624 13.87a3.5 3.5 0 0 0 2.536 2.304l14.249 3.274a.5.5 0 0 1 .185.889l-11.762 8.683a3.502 3.502 0 0 0-1.408 3.125l1.29 14.563a.5.5 0 0 1-.789.451l-11.893-8.503a3.5 3.5 0 0 0-3.407-.374l-13.452 5.727a.5.5 0 0 1-.672-.611l4.412-13.938a3.502 3.502 0 0 0-.698-3.356l-9.603-11.024a.5.5 0 0 1 .373-.828l14.62-.111a3.5 3.5 0 0 0 2.975-1.7l7.517-12.54ZM352.305 279.722a.5.5 0 0 1 .903.099l4.623 13.87a3.5 3.5 0 0 0 2.537 2.304l14.249 3.274a.5.5 0 0 1 .185.89l-11.762 8.683a3.497 3.497 0 0 0-1.408 3.124l1.289 14.563a.5.5 0 0 1-.788.451l-11.893-8.503a3.5 3.5 0 0 0-3.407-.374l-13.452 5.727a.5.5 0 0 1-.672-.611l4.412-13.938a3.502 3.502 0 0 0-.698-3.356l-9.603-11.023a.5.5 0 0 1 .373-.829l14.62-.111a3.498 3.498 0 0 0 2.975-1.7l7.517-12.54Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M291.457 203.067c4.753-14.525 19.944-14.935 29.904-5.51 9.96 9.424 3.812 21.451.001 25-13.96 13-34.657-4.965-29.905-19.49Z"
    />
    <path
      fill="#fff"
      d="M100.5 264c-9.5-5.5-12.5-43-17-57.5-1.395-5.5 25.449-9.5 35.499-9.5 18.5 0 30.001 1.5 31.501 6s-6 61-14.5 61-26 5.5-35.5 0Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M137.002 208.406c-11.885 5.415-40.65 6.617-53.002-5.494 22.076-11.887 53.847-5.079 62.729-3.958 8.882 1.121 4.896 21.27 0 42.028-5.551 23.535-7.772 28.018-27.201 28.018-19.429 0-21.766-6.215-28.311-28.018C85.665 222.489 84 216.003 84 216.003"
    />
    <path
      fill="#fff"
      d="M99.826 201.442c-8.697-7.159-30.973-39.164-16.529-48.434 2.902-1.863 6.778-1.467 10.35.059-2.046-4.906-3.532-10.938-3.29-17.293.726-19.182 12.649-39.742 23.975-35.375 11.472 4.423 17.774 29.098 14.39 44.226 4.83-3.897 10.72-6.46 15.224-2.979 6.053 4.677 2.71 18.228-3.783 28.132a72.488 72.488 0 0 1 2.733-.426c19.748-2.696-.703 22.313-10.988 30.745-7.924 1.749-24.69 1.626-32.082 1.345Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M99.826 201.442c-8.697-7.159-30.973-39.164-16.529-48.434 7.182-4.61 20.325 4.612 20.099 10.579-.226 5.968-13.73-9.589-13.04-27.813.727-19.182 12.65-39.742 23.976-35.375 14.732 5.68 21.554 37.734 9.668 47.101-11.886 9.367 7.278-16.977 18.896-8 10.354 8-2.761 34.837-14.896 39.5-12.135 4.663-9.574-6.307 14.896-9.648 19.748-2.696 5.104 24.648-10.988 30.745M91.673 258c-17.85 2.209-25.104 13.808-7.81 20.99 11.714 4.864 45.184 12.704 68.057.552 12.436-6.607 19.528-18.78-7.252-21.542"
    />
    <path
      fill="#fff"
      d="M629.3 250.06c2.678 28.639-38.511 50.268-53.845 53.694-51.388 1.811-69.105-19.562-75.991-17.651-19.874 18.794-75.679 18.611-72.743 12.715 2.935-5.896 21.82-25.38 20.24-36.19-1.58-10.811-6.919-11.592-21.655-39.816-14.735-28.223 7.226-61.546 27.486-74.927 29.819-19.694 70.65-17.642 109.468 1.783 38.818 19.426 64.362 71.754 67.04 100.392Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeWidth={3}
      d="M514.14 292.235c79.083 32.531 128.594-12.345 111.335-64.187-24.453-73.452-115.707-90.089-158.596-78.664-42.889 11.426-62.714 51.769-29.159 95.39 20.722 26.937-2.323 42.741-10.562 52.682-8.239 9.942 41.617 9.529 69.105-8.147"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M471.059 210.725c-2.106-10.003 6.556-15.323 15.478-13.196 8.922 2.127 9.384 11.15 8.364 14.48-3.736 12.201-21.735 8.719-23.842-1.284ZM514.523 221.491c-2.106-10.003 6.555-15.323 15.477-13.196 8.922 2.126 9.385 11.149 8.365 14.48-3.736 12.2-21.735 8.719-23.842-1.284ZM555.363 234.024c-2.107-10.003 6.555-15.323 15.477-13.196 8.922 2.127 9.385 11.149 8.365 14.48-3.736 12.2-21.735 8.719-23.842-1.284Z"
    />
  </svg>
);
