import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EndPoints } from "app/endpoints";
import { IShopProductFull } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import { getShopProduct } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import api from "../../../services/api/api";
import { IOrder, IOrdersRequest, OrdersSlice } from "./interfaces/orders.interface";
import { Capacitor } from "@capacitor/core";

const initialState: OrdersSlice = {
  orders: [],
  productDetailsLoading: false,
  ordersIsLoading: true,
};

const getUserOrders = createAsyncThunk(
  "shop/getUserOrders",
  async (arg: IOrdersRequest | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(EndPoints.SHOP_ORDERS, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
        params: arg,
      });
      return response.data?.items;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const updateOrderStatus = createAsyncThunk(
  "shop/updateOrderStatus",
  async (orderId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.put(EndPoints.SHOP_ORDERS + `/${orderId}/cancel`, undefined, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error?.response?.status,
        code: error?.response?.data?.code,
        details: error?.response?.data?.details,
      });
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getUserOrders.fulfilled,
      (state, action: PayloadAction<IOrder[] | undefined>) => {
        state.orders = action.payload || [];
        state.ordersIsLoading = false;
      }
    );
    builder.addCase(getUserOrders.rejected, (state) => {
      state.ordersIsLoading = false;
    });
    builder.addCase(getShopProduct.pending, (state) => {
      state.productDetailsLoading = true;
    });
    builder.addCase(getShopProduct.rejected, (state) => {
      state.productDetailsLoading = false;
    });
    builder.addCase(getShopProduct.fulfilled, (state, action: PayloadAction<IShopProductFull>) => {
      state.productDetailsLoading = false;
      state.currentProduct = action.payload;
    });
  },
});

//export const {} = ordersSlice.actions;

export { getUserOrders, updateOrderStatus };
export default ordersSlice.reducer;
