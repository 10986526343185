import { TextMessages } from "app/messages";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { AuthTypes } from "pages/Authorization/authRoutes.congig";
import { AuthData, UseAuthFormFieldNames } from "pages/Authorization/data/data.types";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import { triggerWinResize } from "../../../../app/utils";
import UrlContext from "../../../../contexts/urlcontext";
import { clear, getRegistrationIsAllowed } from "../../../../features/auth/authSlice";
import {
  selectAuthLastError,
  selectAuthOIDC,
  selectAuthShowcaseExpanded,
  selectAuthType,
  selectAuthUserExist,
  selectRecoveryError,
  selectRegRequirements,
  selectRegistrationIsAllowed,
} from "../../../../features/auth/selectors";
import {
  authAgreement,
  bannedDomen,
  passwordRecovery,
  registeredAcc,
  registrationCodeCheckData,
  registrationData,
  registrationEmailBlocked,
  secondStepData,
  signInData,
} from "../../data";
import { isHasInternetSelector } from "app/store";
import { lmsSettings } from "../../../Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";

const TIMER_SECONDS = 60;

export const useAuthorizationForm = () => {
  const [showError, setShowError] = useState(false);
  const [timerInterval, setTimerInterval] = useState(TIMER_SECONDS);
  const [showTimer, setShowTimer] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authOIDC = useSelector(selectAuthOIDC);
  const authLastError = useSelector(selectAuthLastError);
  const authUserExist = useSelector(selectAuthUserExist);
  const authContext = useContext(UrlContext);
  const authType = useSelector(selectAuthType);
  const recoveryError = useSelector(selectRecoveryError);
  const showcaseExpanded = useSelector(selectAuthShowcaseExpanded);
  const regRequirements: any = useSelector(selectRegRequirements);
  const registrationIsAllowed = useSelector(selectRegistrationIsAllowed);
  const isHasInternet = useAppSelector(isHasInternetSelector);
  const selectedLmsSettings = useAppSelector(lmsSettings);

  const {
    handleSubmit,
    control,
    formState,
    setError,
    watch,
    resetField,
    setValue,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      code: "",
      mail: "",
      login: "",
      name: "",
      password: "",
      secondname: "",
      position: "",
    },
  });

  const mail = watch(UseAuthFormFieldNames.mail);
  const login = watch(UseAuthFormFieldNames.login);
  const name = watch(UseAuthFormFieldNames.name);
  const secondname = watch(UseAuthFormFieldNames.secondname);
  const position = watch(UseAuthFormFieldNames.position);
  const password = watch(UseAuthFormFieldNames.password);

  const hideTimerTimeout = () => {
    setShowTimer(false);
    setTimerInterval(TIMER_SECONDS);
  };

  const showTimerTimeout = () => {
    setShowTimer(true);
    setTimerInterval(TIMER_SECONDS);
  };

  const handleData = (context: AuthTypes): AuthData | null => {
    const nav = (endpoint: any, state?: any) => {
      navigate(endpoint, { replace: true, state });
      clearErrors();
      reset();

      if ([WebAppRoutes.AUTH].indexOf(endpoint) !== -1) {
        dispatch(clear());
      }

      if ([WebAppRoutes.REGISTRATION_CODE_CHECK, WebAppRoutes.ACCOUNT_EXISTS].includes(endpoint)) {
        showTimerTimeout();
      }
    };

    const dep = {
      nav,
      navigate,
      dispatch,
      control,
      setError,
      resetField,
      reset,
      setValue,
      authOIDC,
      authLastError,
      authUserExist,
      showTimerTimeout,
      authType,
      recoveryError,
      setShowError,
      showError,
      firstLoginCheck: selectedLmsSettings.firstLoginCheck,
      isHasInternet,
    };

    switch (context) {
      // Вход
      case AuthTypes.AUTH:
        return signInData(AuthTypes.AUTH, { ...dep, registrationIsAllowed, isHasInternet });

      case AuthTypes.AUTH_EXIST:
        return signInData(
          AuthTypes.AUTH_EXIST,
          dep,
          process.env.REACT_APP_THEME === "myAcademy"
            ? "Мы нашли созданный аккаунт с указанным email. Если у вас есть аккаунт на mylanit.ru, используйте корпоративную электронную почту и пароль от нее."
            : "Мы нашли созданный аккаунт с указанным email. Если email указан верно, введите пароль."
        );

      case AuthTypes.AUTH_AGREEMENT:
        return authAgreement(dep);

      // Восстановление пароля
      case AuthTypes.PASSWORD_RECOVERY:
        return passwordRecovery(dep);

      // Вход по временному коду
      // case AuthTypes.ACCESS_RECOVERY:
      //   return enterWithTemporaryCode(dep);
      // case AuthTypes.ACCESS_RECOVERY_CODE_CHECK:
      //   return accessRecoveryCodeCheckData(dep);

      // Регистрация
      case AuthTypes.REGISTRATION:
        return registrationData(dep);
      case AuthTypes.REGISTRATION_CODE_CHECK:
        return registrationCodeCheckData(dep);
      case AuthTypes.REGISTRATION_CODE_CHECK_ERROR:
        return registrationCodeCheckData(dep);
      case AuthTypes.REGISTRATION_EMAIL_BLOCKED:
        return registrationEmailBlocked(nav);
      case AuthTypes.ACCOUNT_EXISTS:
        return registrationCodeCheckData(dep, {
          togglesTypeIsReg: true,
          title: TextMessages.ACCOUNT_TO_CREATE,
          info: TextMessages.CODE_SEND_MESSAGE,
        });
      case AuthTypes.REGISTRATION_SECOND_STEP:
        return secondStepData(dep, regRequirements?.password);
      case AuthTypes.REGISTERED:
        return registeredAcc(nav);

      // Домен забанен
      case AuthTypes.BANNED_DOMEN:
        return bannedDomen(dep);

      default:
        return null;
    }
  };

  const authObj: AuthData | null = handleData(authContext);
  const formSubmit = (formData: any) => {
    if (typeof authObj?.form.submit === "function") authObj?.form.submit(formData);
  };

  // hack for https://jira.lanit.ru/browse/SDOLAN-2459
  useEffect(() => {
    const handleTabClose = () => {
      dispatch(clear());
    };
    if (authContext === AuthTypes.AUTH_AGREEMENT) {
      window.addEventListener("beforeunload", handleTabClose);
    }
    return () => {
      if (authContext === AuthTypes.AUTH_AGREEMENT) {
        window.removeEventListener("beforeunload", handleTabClose);
      }
    };
  }, [authContext, dispatch]);

  useEffect(() => {
    triggerWinResize(100);
  }, [authContext]);

  useEffect(() => {
    dispatch(getRegistrationIsAllowed());
  }, [dispatch]);

  useEffect(() => {
    // контроль длинны заполнения полей
    // @ts-ignore
    setValue(UseAuthFormFieldNames.name, name?.slice(0, 25));
    // @ts-ignore
    setValue(UseAuthFormFieldNames.secondname, secondname?.slice(0, 50));
    // @ts-ignore
    setValue(UseAuthFormFieldNames.position, position?.slice(0, 100));
    // @ts-ignore
    setValue(UseAuthFormFieldNames.password, password?.slice(0, 25));
  }, [name, secondname, position, password, setValue]);

  useEffect(() => {
    authObj &&
      authObj.type === AuthTypes.AUTH_EXIST &&
      authObj.form.fields?.forEach((field) => {
        field.name &&
          [UseAuthFormFieldNames.login, UseAuthFormFieldNames.mail].includes(field.name) &&
          field.value &&
          !mail &&
          !login &&
          // @ts-ignore
          setValue(field.name, field.value);
      });

    // https://jira.lanit.ru/browse/SDOLAN-5563
    authObj &&
      authObj.type === AuthTypes.AUTH_EXIST &&
      process.env.REACT_APP_THEME === "myAcademy" &&
      setShowError(true);
  }, [authObj, setValue, mail, login]);

  const handleOnChangeForm = () => {
    clearErrors();
    if (authObj) authObj.form.errorMsg = "";
    setShowError(false);
  };

  return {
    authContext,
    showcaseExpanded,
    form: authObj?.form,
    formState,
    handleOnChangeForm,
    handleSubmit,
    authObj,
    toggles: authObj?.toggles,
    titles: authObj?.titles,
    formSubmit,
    showError,
    control,
    showTimer,
    timerInterval,
    hideTimerTimeout,
    showTimerTimeout,
    regRequirements,
    dispatch,
    policyCheckReg: authOIDC.policyCheck && authContext === AuthTypes.REGISTRATION_CODE_CHECK,
    registrationIsAllowed,
  };
};
