import { forwardRef } from "react";
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import { KeyboardArrowDown } from "../Svg/Icons";

const Select = forwardRef(
  (
    { label, className, variant, id, name, onChange, value, data, index = -1, labelClassName = "" },
    ref
  ) => {
    return (
      <>
        <FormControl
          className={`select textfield ${className}`}
          data-empty={!value}
          variant={variant}
        >
          <InputLabel id={`${id}-label`} className={labelClassName}>
            {label}
          </InputLabel>
          <MuiSelect
            ref={ref}
            labelId={`${id}-label`}
            id={`${id}-select`}
            value={value}
            name={name}
            onChange={(event) => {
              onChange(event, index, name);
            }}
            label={label}
            IconComponent={KeyboardArrowDown}
            data-empty={!value}
          >
            <MenuItem value="">Не выбрано</MenuItem>
            {data.map((item, index) => (
              <MenuItem key={index} value={item?.value ? item?.value : item?.id}>
                {" "}
                {item?.text ? item?.text : item?.name}{" "}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
      </>
    );
  }
);

export default Select;
