import { Scrollbar } from "components/Scrollbar/Scrollbar";
import { useMergeRefs } from "hooks";
import { OverlayScrollbarsComponentRef } from "overlayscrollbars-react";
import { ComponentProps, ReactNode, Ref, forwardRef, useCallback, useRef, useState } from "react";
import { ScrollbarContainerContext } from "./context";

export const ScrollbarContainer = forwardRef(
  (
    {
      children,
      events,
      upButtonClassName,
      ...scrollbarProps
    }: { children: ReactNode; upButtonClassName?: string } & ComponentProps<typeof Scrollbar>,
    ref: Ref<OverlayScrollbarsComponentRef>
  ) => {
    const [scrollInfo, setScrollData] = useState({
      scroll: 0,
      scrollProgress: 0,
    });

    const osRef = useRef<OverlayScrollbarsComponentRef>(null);
    const mergedRefs = useMergeRefs([ref, osRef]);

    const scrollTo = useCallback((newScroll: number, behavior?: ScrollBehavior) => {
      const scrollbarElements = osRef?.current?.osInstance()?.elements();

      if (scrollbarElements) {
        scrollbarElements.viewport.scrollTo({ top: newScroll, behavior });
      }
    }, []);

    return (
      <ScrollbarContainerContext.Provider
        value={{
          scrollInfo: scrollInfo,
          scrollTo: scrollTo,
        }}
      >
        <Scrollbar
          {...scrollbarProps}
          upButtonClassName={upButtonClassName}
          ref={mergedRefs}
          events={{
            ...events,
            scroll: (_instance, event) => {
              // @ts-ignore
              const scrollTop = event?.target?.scrollTop ?? 0;

              const maxScroll =
                // @ts-ignore
                (event.target?.scrollHeight ?? 0) - (event.target?.clientHeight ?? 0);

              setScrollData({
                scroll: scrollTop,
                scrollProgress: (scrollTop / (maxScroll || 1)) * 100,
              });
              // @ts-ignore
              events?.scroll?.(_instance, event);
            },
          }}
        >
          {children}
        </Scrollbar>
      </ScrollbarContainerContext.Provider>
    );
  }
);
