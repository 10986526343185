import { BalanceRow } from "components/BalanceRow";
import { Skeleton } from "components/Skeleton";
import { getProfileCoins } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import {
  selectProfileCoins,
  selectShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDrawerOpened } from "../../features/mainMenu/selectors";
import { changeDrawerOpened } from "../../features/mainMenu/slice";
import { CircleIconButton } from "../Buttons/CircleIconButton";
import { CloseSvgIcon, HamburgerSvgIcon } from "../Svg/Icons";
import styles from "./Hamburger.module.scss";

const Hamburger = () => {
  const drawerOpened = useSelector(selectDrawerOpened);
  const shopSettings = useSelector(selectShopSettings);
  const coins = useSelector(selectProfileCoins);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileCoins());
  }, [dispatch]);

  const handleCLick = () => {
    dispatch(changeDrawerOpened(!drawerOpened));
  };

  return (
    <div className={styles["hamburger"]}>
      {shopSettings?.enable &&
        (coins ? (
          <BalanceRow type="profile" coins={coins.shop_coins} />
        ) : (
          <>
            <Skeleton variant="text" width={"100px"} height={"25px"} />
          </>
        ))}
      <CircleIconButton
        className="hamburger"
        size="hamburger"
        color="transparent"
        onClick={handleCLick}
      >
        {drawerOpened ? <CloseSvgIcon /> : <HamburgerSvgIcon />}
      </CircleIconButton>
    </div>
  );
};

export default Hamburger;
