import { TextMessages } from "app/messages";
import { AuthTypes, UseAuthForm } from "../authRoutes.congig";
import { AuthData, UseAuthFormFieldNames } from "./data.types";
import { WebAppRoutes } from "app/routes";
import { codeSend, isExist, mailCheck, policyCheck, setTokens } from "features/auth/authSlice";
import { emailValidationPattern } from "app/constants";
import { setAuthError } from "./setAuthError";
import { messagesWithLink } from "app/messagesWithLink";

const sendCode = ({ dispatch, formData, nav }: any) => {
  dispatch(
    codeSend({
      email: formData.mail!,
    })
  ).then((result: any) => {
    dispatch(policyCheck({ email: formData.mail! }));
    if (result.payload.status === 200) {
      nav(WebAppRoutes.REGISTRATION_CODE_CHECK, { is_allowed: true });
    } else {
      setAuthError(result, nav);
    }
  });
};

export const registrationData = (dep: any): AuthData => {
  const { nav, authOIDC, authLastError, dispatch, setError, setShowError } = dep;

  const toAuthExist = (mail: string) => {
    dispatch(setTokens({ mail: mail }));
    nav(WebAppRoutes.AUTH_EXIST, { is_allowed: true });
  };

  return {
    type: AuthTypes.REGISTRATION,
    toggles: {
      top: {
        title: TextMessages.ACCOUNT_ENTER,
        action: () => {
          nav(WebAppRoutes.AUTH);
        },
      },
    },
    titles: {
      main: TextMessages.ACCOUNT_CREATE,
    },
    form: {
      info: messagesWithLink.EMAIL_ENTER(nav),
      errorMsg: (function () {
        if (authLastError === 403) {
          if (authOIDC.detail === "Timeout") {
            nav(WebAppRoutes.REGISTRATION_CODE_CHECK, { is_allowed: true });
          } else if (authOIDC.detail === "Blocked") {
            return TextMessages.TIME_BLOCKED;
          }
        } else if (authLastError === 409) {
          return TextMessages.EMAIL_EXISTS;
        } else if (authLastError === 500 || authLastError === "unknown_error") {
          return TextMessages.UNKNOWN_ERROR;
        } else if (authLastError) {
          return "";
        } else {
          return "";
        }
      })(),
      valid: false,
      submit: (formData: UseAuthForm) => {
        formData.mail &&
          dispatch(
            mailCheck({
              email: formData.mail,
            })
          ).then((res: any) => {
            if (!res.payload.is_allowed) {
              setShowError(true);

              res.payload?.is_allowed === false
                ? nav(WebAppRoutes.BANNED_DOMEN)
                : setError("mail", {
                    type: "manual",
                    message: TextMessages.UNKNOWN_ERROR,
                  });
            } else {
              dispatch(isExist({ email: formData.mail! })).then((res: any) => {
                res.payload.status === 200
                  ? sendCode({ dispatch, formData, nav })
                  : res.payload?.code === "USER_EXISTS"
                  ? toAuthExist(formData.mail!)
                  : setError("mail", {
                      type: "manual",
                      message: TextMessages.UNKNOWN_ERROR,
                    });
              });
            }
          });
      },
      fields: [
        {
          label: TextMessages.EMAIL,
          type: "email",
          value: "",
          name: UseAuthFormFieldNames.mail,
          rules: {
            required: TextMessages.ERROR_FIELD_IS_EMPTY,
            pattern: {
              value: emailValidationPattern,
              message: TextMessages.ERROR_EMAIL_IS_NOT_VALID,
            },
          },
        },
      ],
      actions: {
        buttons: {
          primary: {
            title: TextMessages.CONTINUE_BUTTON,
          },
        },
      },
    },
  };
};
