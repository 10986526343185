import { useEffect } from "react";
import { generateUid, numWord } from "../../../../app/utils";
import { Achievement } from "../../../../components/Achievement";
import { AchievmentsTooltip } from "../../../../components/AchievmentsTooltip";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { getBadges } from "../../../Course/redux/courseSlice";
import { selectAchievements } from "../../../Course/redux/courseSelectors";
import styles from "./LandingAchievements.module.scss";
import { SquiggleIcon1 } from "./SquiggleIcon1";
import { SquiggleIcon2 } from "./SquiggleIcon2";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";

export const LandingAchievements = ({ courseId }: { courseId?: string }) => {
  const dispatch = useAppDispatch();
  const achievements = useAppSelector(selectAchievements);

  useEffect(() => {
    dispatch(getBadges({ course_id: courseId }));
  }, [courseId, dispatch]);

  if (!achievements || achievements.length < 1) return null;

  return (
    <div
      className={classNames(styles["landing-achievements"], {
        [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
        [styles["my-academy-theme"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
    >
      <div
        className={styles["title-wrapper"]}
        style={{
          color: process.env.REACT_APP_THEME === "myAcademy" ? "#191229" : undefined,
        }}
      >
        <div className={styles["title"]}>Доступные бейджи</div>

        <p className={styles["description"]}>
          Во время изучения курса вы сможете получить {achievements?.length}{" "}
          {numWord(achievements?.length, ["бейдж", "бейджа", "бейджей"])},{" "}
          {numWord(achievements?.length, ["который", "которые", "которые"])}{" "}
          {numWord(achievements?.length, ["появится", "появятся", "появятся"])} в вашем личном
          кабинете.
        </p>
      </div>

      <div className={styles["list"]}>
        {achievements?.map((item) => (
          <>
            {Capacitor.isNativePlatform() ? (
              <Achievement
                title={item.name}
                image={item.image}
                disabled={item.criteria?.every((value) => !value.received_dt)}
              />
            ) : (
              <AchievmentsTooltip key={generateUid()} achievment={item}>
                <Achievement
                  title={item.name}
                  image={item.image}
                  disabled={item.criteria?.every((value) => !value.received_dt)}
                />
              </AchievmentsTooltip>
            )}
          </>
        ))}
      </div>

      {process.env.REACT_APP_THEME !== "rostechnadzor" && (
        <SquiggleIcon1
          color={process.env.REACT_APP_THEME === "myAcademy" ? "#191229" : "#ffffff"}
        />
      )}
      {process.env.REACT_APP_THEME !== "rostechnadzor" && (
        <SquiggleIcon2
          color={process.env.REACT_APP_THEME === "myAcademy" ? "#191229" : "#ffffff"}
        />
      )}
    </div>
  );
};
