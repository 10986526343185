import { H3 } from "../../../../../../../components/Typography/Titles";

import { useAppSelector } from "hooks/redux";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../../../../components/Forms/Select";
import { setQuestAnswer } from "../../../redux/Quze/courseQuizeQuestionsSlice";
import {
  answersByPageSelector,
  selectCurrentOpenPageInQuiz,
} from "../../../redux/Quze/courseQuizeSelectors";
import { IQuizeQuestionProp } from "../../../redux/Quze/interface/quiz.interface";
import { QuestDescription } from "./QuestDescription/QuestDescription";

export const QuestCorrelate = ({
  title,
  questions = [],
  questionsId,
  description,
  handleChange = () => {},
}: IQuizeQuestionProp) => {
  const dispatch = useDispatch();

  const answers: any = useSelector(answersByPageSelector);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);

  const defaultAnswers = answers[`${currentOpenPageInQuiz}`]?.find(
    (answer: any) => answer.id === questionsId
  );

  const column1 = questions.filter((item) => item.column === 1);
  const selectOptions = questions
    .filter((item) => item.column === 2)
    .map((item) => {
      return { text: item.text, value: item.code };
    });

  const { control, getValues } = useForm({
    mode: "onBlur",
  });

  const handleOnChange = () => {
    const values = getValues();
    if (Object.keys(values).length > 0) {
      dispatch(
        setQuestAnswer({
          page: currentOpenPageInQuiz,
          answer: { id: questionsId, answer: values },
        })
      );
      handleChange({ id: questionsId, answer: values });
    }
  };

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />
      <form>
        <div
          className="course-questions-block-wrapper"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ maxWidth: "40%", marginRight: "20px" }}>
            {column1.map((item) => {
              return (
                <div key={item.code} className="course-questions-select-item">
                  <span className="course-questions-block-list__item" style={{ maxWidth: "100%" }}>
                    <span
                      className={
                        /*@ts-ignore*/
                        defaultAnswers?.answer?.[item.code]
                          ? "course-questions-bubble__item course-questions-bubble__item_fluide course-questions-bubble__item--active"
                          : "course-questions-bubble__item course-questions-bubble__item_fluide"
                      }
                    >
                      {item.text}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
          <div
            className="course-questions-select-item"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "70%",
            }}
          >
            {column1.map((item) => (
              <Controller
                name={item.code}
                control={control}
                /*@ts-ignore*/
                defaultValue={defaultAnswers?.answer[item.code] || ""}
                render={({ field: { onChange, ...otherFields } }) => (
                  <Select
                    {...otherFields}
                    /*@ts-ignore*/
                    className="course-questions__select"
                    variant="outlined"
                    /*@ts-ignore*/
                    onChange={(event) => {
                      onChange.call(this, event);
                      handleOnChange();
                    }}
                    data={selectOptions}
                  />
                )}
              />
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};
