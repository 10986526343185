import { CloseSvgIcon } from "components/Svg/Icons";
import { TextCard } from "components/Typography/Texts";
import styles from "../CoursesSearch.module.scss";

interface CoursesSearchChipProps {
  title: string;

  onDeleteClick?: () => void;
}

export const CoursesSearchChip = ({ title, onDeleteClick }: CoursesSearchChipProps) => {
  return (
    <div className={styles["chip"]}>
      <TextCard>{title}</TextCard>
      <div className={styles["chip-close"]}>
        <CloseSvgIcon onClick={onDeleteClick} />
      </div>
    </div>
  );
};
