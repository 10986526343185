import { fromUnixTime, isAfter } from "date-fns";
import { IQuizResult, ScoringAttemptType } from "../../../redux/Quze/interface";

export const getQuizResultsForRendering = (
  results: IQuizResult[],
  scoringAttempt: ScoringAttemptType
) => {
  switch (scoringAttempt) {
    case "highter": {
      const copyResults = [...results];
      const sortingResultsByEndTs = copyResults.sort((a, b) => {
        const dateCompletedAResults = a?.end_ts ? fromUnixTime(a?.end_ts) : null;
        const dateCompletedBResults = b?.end_ts ? fromUnixTime(b?.end_ts) : null;
        if (dateCompletedAResults && dateCompletedBResults) {
          return isAfter(dateCompletedAResults, dateCompletedBResults) ? -1 : 1;
        }
        return -1;
      });

      let maxElementIndex = 0;

      for (let i = 0; i < sortingResultsByEndTs.length; i++) {
        if (
          sortingResultsByEndTs[maxElementIndex].percentage_correct <
          sortingResultsByEndTs[i].percentage_correct
        ) {
          maxElementIndex = i;
        }
      }

      const findElementIndexInResults = results.findIndex(
        (result) => result.end_ts === sortingResultsByEndTs[maxElementIndex].end_ts
      );

      results.splice(findElementIndexInResults, 1);

      return [sortingResultsByEndTs[maxElementIndex], ...results];
    }
    case "average": {
      return results.sort((a, b) => b?.percentage_correct - a?.percentage_correct);
    }
    case "first": {
      return results.sort((a, b) => {
        const dateCompletedAResults = a?.end_ts ? fromUnixTime(a?.end_ts) : null;
        const dateCompletedBResults = b?.end_ts ? fromUnixTime(b?.end_ts) : null;
        if (dateCompletedAResults && dateCompletedBResults) {
          return isAfter(dateCompletedAResults, dateCompletedBResults) ? 1 : -1;
        }
        return -1;
      });
    }
    case "last": {
      return results.sort((a, b) => {
        const dateCompletedAResults = a?.end_ts ? fromUnixTime(a?.end_ts) : null;
        const dateCompletedBResults = b?.end_ts ? fromUnixTime(b?.end_ts) : null;
        if (dateCompletedAResults && dateCompletedBResults) {
          return isAfter(dateCompletedAResults, dateCompletedBResults) ? -1 : 1;
        }
        return -1;
      });
    }
    default:
      return results.sort((a, b) => b?.percentage_correct - a?.percentage_correct);
  }
};
