import { WebAppRoutes } from "app/routes";
import classNames from "classnames";
import { CourseStatus } from "components/CourseStatus";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { useNavigate } from "react-router-dom";
import { mediaQuerys, normalizeISODate, secondsToHms } from "../../../../app/utils";
import { dateTsToFormat } from "../../../../app/utils/date.util";
import cardHeadMock from "../../../../assets/img/course-card-head.svg";
import cardHeadMockMyAcademy from "../../../../assets/img/myacademy_course-card-head.svg";
import cardHeadMockRostechnadzor from "../../../../assets/img/rostechnadzor_course-card-head.svg";
import { CourseCardBadge } from "../../../../components/CourseCardBadge";
import { CourseTypeTile } from "../../../../components/CourseTypeTile";
import tileStyle from "../../../../components/CourseTypeTile/CourseTypeTile.module.scss";
import { ClockSvgIcon } from "../../../../components/Svg/Icons";
import { Subtitle } from "../../../../components/Typography/Titles";
import { useAppDispatch } from "../../../../hooks/redux";
import { setPageBeforeCourse } from "../../../Course/redux/courseSlice";
import { CourseFormat } from "../../../Course/redux/interfaces/course.interface";
import { CourseList } from "../../redux/interface";
import { Capacitor } from "@capacitor/core";
import { useMediaQuery } from "hooks";
import styles from "./CoursesCard.module.scss";

export const CoursesCard = ({ course }: { course: CourseList }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const courseDuration = (duration: string | null) => {
    return (
      <>
        <ClockSvgIcon />
        <p>{secondsToHms(duration)}</p>
      </>
    );
  };

  const openLinkInNewTab = (path: string) =>
    window.open(`${origin}${process.env.REACT_APP_BASE_PATH}${path}`, `tab_${Date.now()}`);

  const handlerClickCourseCard = (id: number) => {
    dispatch(setPageBeforeCourse(WebAppRoutes.COURSES));

    if (course.enrolled_status === EnrolledStatus.not_started) {
      if (Capacitor.isNativePlatform() || isMobile) {
        navigate(WebAppRoutes.COURSE_PREVIEW + "/" + id);
      }
      openLinkInNewTab(`${WebAppRoutes.COURSE_PREVIEW}/${id}`);
      return;
    } else {
      if (Capacitor.isNativePlatform() || isMobile) {
        navigate(WebAppRoutes.COURSE + "/" + id);
        return;
      }
      openLinkInNewTab(`${WebAppRoutes.COURSE}/${id}`);
    }
  };

  const getDefaultImage = () => {
    if (process.env.REACT_APP_THEME === "rostechnadzor") {
      return cardHeadMockRostechnadzor;
    }
    if (process.env.REACT_APP_THEME === "myAcademy") {
      return cardHeadMockMyAcademy;
    }
    if (process.env.REACT_APP_THEME === "base") {
      return cardHeadMock;
    }
  };

  return (
    <div
      className={classNames(
        styles["course-card"],
        styles[`enrolled-status_${course.enrolled_status}`],
        {
          [styles["myacademy_theme"]]: process.env.REACT_APP_THEME === "myAcademy",
          [styles["rostechnadzor_theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
          [styles["course-card-desktop"]]: !Capacitor.isNativePlatform(),
        }
      )}
      onClick={() => handlerClickCourseCard(course.id)}
    >
      <div
        className={styles["course-card__head"]}
        style={{
          backgroundImage: course?.preview?.src ? `url(${course.preview.src}` : undefined,
        }}
      >
        <div className={styles["course-card__head_blur"]}></div>
        <img
          className={styles["course-card__head_image"]}
          src={course?.preview?.src ? course.preview.src : getDefaultImage()}
          alt="Course preview"
        />

        {(course.finished_ts || (course.start_dt && course.start_dt !== "1970-01-01")) && (
          <div className={styles["head-block"]}>
            <div>
              {course.finished_ts ? (
                <div className={styles["course-card__startDate"]}>
                  <p>Завершен</p>
                  <time dateTime={dateTsToFormat(course.finished_ts)}>
                    {dateTsToFormat(course.finished_ts)}
                  </time>
                </div>
              ) : (
                <div className={styles["course-card__startDate"]}>
                  <p>{course.format === CourseFormat.meeting ? "Пройдет" : "Старт"}</p>
                  <time dateTime={normalizeISODate(course.start_dt)}>
                    {normalizeISODate(course.start_dt)}
                  </time>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles["course-card__body"]}>
        <div>
          <div className={styles["course-card__body_tiles"]}>
            <CourseTypeTile type={course.format} className={styles["course-type-tail"]} />

            {course?.duration && (
              <div className={classNames(tileStyle["tile"], styles["course-type-tail"])}>
                {courseDuration(course.duration)}
              </div>
            )}
          </div>

          <div className={styles["course-card__description"]}>
            <Subtitle className={styles["course-card__title"]} title={course.name}>
              {course.name}
            </Subtitle>

            <p className={styles["course-card__text"]} title={course.description ?? undefined}>
              {course.description}
            </p>
          </div>
        </div>

        <div className={styles["course-card__info"]}>
          <div className={styles["course-card__info-left"]}>
            {(!!course.rating || !!course.comments_count) && (
              <div>
                {!!course.rating && (
                  <CourseCardBadge
                    type="rating"
                    text={course.rating}
                    className={styles["cource-card-badge"]}
                  />
                )}

                {!!course.comments_count && (
                  <CourseCardBadge
                    type="comments"
                    text={course.comments_count}
                    className={styles["cource-card-badge"]}
                  />
                )}
              </div>
            )}

            {course?.passed_count !== 0 && (
              <span className={styles["course-card__passed"]}>
                {`Пройден: ${course.passed_count}`}
              </span>
            )}
          </div>
          {course.enrolled_status !== EnrolledStatus.not_started && (
            <CourseStatus
              className={styles["course-card__status"]}
              status={course.enrolled_status}
            />
          )}
          {/* todo Для чего оно?
          <div className={styles["course-card__spacer"]}></div> */}
        </div>
      </div>
    </div>
  );
};
