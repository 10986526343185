import { TextCard } from "../../Typography/Texts";
import { CourseGradeIcon } from "./components";
import { ScoreType } from "./types";
import sanitizeHtml from "sanitize-html";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { formatNumber, mediaQuerys } from "app/utils";
import classNames from "classnames";
interface CourseGradeCardProps {
  title: string;
  description: string;
  type: ScoreType;
  score: number;
}

export const CourseGradeCard = ({ type, title, description, score }: CourseGradeCardProps) => {
  const smMin = useMediaQuery(`(min-width:${mediaQuerys.smMin}px)`);

  return (
    <div className="course-grade-card">
      <div className="course-grade-card-info">
        {smMin && (
          <div className="course-grade-card-info__icon">
            <CourseGradeIcon scoreType={type} />
          </div>
        )}
        <div className="course-grade-card-info-text">
          <TextCard className="course-grade-card-info-text__title" hidden={false}>
            {title}
          </TextCard>
          {description && (
            <p
              className={`text-card course-grade-card-info-text__descr`}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(description, {
                  exclusiveFilter: (frame) => {
                    return frame.tag === "br";
                  },
                  textFilter: (text) => {
                    if (!text) return "";
                    return text.replace(/\.s{0,}/g, ". ").replace(/\s{1,}/g, " ");
                  },
                }),
              }}
            ></p>
          )}
        </div>
      </div>
      <div
        className={classNames("course-grade-card__raiting", {
          "course-grade-card__raiting--empty": !score || score < 0,
        })}
      >
        {formatNumber(score) || "-"}
      </div>
    </div>
  );
};
