import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import { useState } from "react";
import styles from "./RoundedButton.module.scss";

interface RoundedButtonPops {
  children: JSX.Element;
  onClick: () => void;
}

export const RoundedButton = ({ children, onClick }: RoundedButtonPops) => {
  const [clickedStyle, setClickedStyle] = useState<boolean>(false);

  const handleOnClick = () => {
    setClickedStyle(true);
    setTimeout(() => setClickedStyle(false), 100);
    onClick();
  };

  return (
    <div
      className={classNames(styles["button"], {
        [styles["hovered-button"]]: !Capacitor.isNativePlatform(),
        [styles["clicked"]]: clickedStyle,
      })}
      onClick={handleOnClick}
    >
      {children}
    </div>
  );
};
