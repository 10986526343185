import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { Ref, forwardRef, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMergeRefs } from "../../hooks";
import { ScrollUpButton } from "../Buttons/ScrollUpButton";
import "./Scrollbar.scss";
import classNames from "classnames";

export const Scrollbar = forwardRef(
  (
    {
      children,
      options,
      events,
      showUpButton = true,
      upButtonClassName,
      hideScrollBar = false,
      ...otherProps
    }: OverlayScrollbarsComponentProps & {
      showUpButton?: boolean;
      upButtonClassName?: string;
      hideScrollBar?: boolean;
    },
    ref: Ref<OverlayScrollbarsComponentRef>
  ) => {
    const osRef = useRef<OverlayScrollbarsComponentRef>(null);
    const mergedRefs = useMergeRefs([ref, osRef]);
    const [visibleUpButton, setVisibleUpButton] = useState<boolean>(false);
    const { course_id = "" } = useParams();

    const scrollToTop = () => {
      const scrollbarElements = osRef?.current?.osInstance()?.elements();
      // @ts-ignore
      const refScrollbarElements = ref?.current?.osInstance()?.elements();

      if (scrollbarElements) scrollbarElements.viewport.scrollTo({ top: 0, behavior: "smooth" });

      if (refScrollbarElements)
        refScrollbarElements.viewport.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
      if (course_id) osRef?.current?.osInstance()?.elements().viewport.scrollTo({ top: 0 });
    }, [course_id]);

    return (
      <OverlayScrollbarsComponent
        defer
        ref={mergedRefs}
        events={{
          ...events,
          scroll: (_instance, event) => {
            // @ts-ignore
            const scrollTop = event?.target?.scrollTop ?? 0;
            if (scrollTop > 300) setVisibleUpButton(true);
            else setVisibleUpButton(false);

            // @ts-ignore
            events?.scroll?.(_instance, event);
          },
        }}
        options={{
          scrollbars: {
            theme: classNames("os-theme-dark custom-scrollbar", {
              "custom-scrollbar_hide": hideScrollBar,
              "custom-scrollbar_myacademy": process.env.REACT_APP_THEME === "myAcademy",
              "custom-scrollbar_rostechnadzor": process.env.REACT_APP_THEME === "rostechnadzor",
            }),
          },
        }}
        {...otherProps}
      >
        {children}

        {showUpButton && visibleUpButton && (
          <ScrollUpButton className={upButtonClassName} onClick={scrollToTop} />
        )}
      </OverlayScrollbarsComponent>
    );
  }
);
