import { ClockSvgImage } from "components/svg-images";
import { formatNumber, normalizeTimestampDate } from "../../../../../app/utils";
import { CircleChart } from "components/Charts";

const QuizeAttemptsInfo = ({
  currentPoints,
  totalPoints,
  totalQuestions,
  rightQuestions,
  percent = 0,
  endTs = 0,
  first = false,
  isCalculatedResult = false,
}: {
  currentPoints: number;
  totalPoints: number;
  rightQuestions: number;
  totalQuestions: number;
  percent: number;
  endTs: number | null;
  first: boolean;
  isCalculatedResult: boolean;
}) => {
  return (
    <div className="course-test-achieve__item">
      {!isCalculatedResult && (
        <div className="course-test-achieve__chart">
          <div className="course-test-achieve__chart__diagram">
            {first ? (
              <CircleChart progress={percent} color="rgb(var(--success-color))" />
            ) : (
              <CircleChart progress={percent} color="rgb(var(--base-dark-color))" />
            )}
          </div>
          <span>{percent.toFixed()}%</span>
        </div>
      )}
      {isCalculatedResult && (
        <div className="course-test-achieve__clock-svg-image-container">
          <ClockSvgImage />
        </div>
      )}
      <div className="course-test-achieve-info">
        {isCalculatedResult && (
          <p className="course-test-achieve-info__calculate-text">Подсчитываем результаты...</p>
        )}
        {!isCalculatedResult && (
          <span className="course-test-achieve-info__score">
            {currentPoints >= 0
              ? // Полученные баллы за попытку / Всего балов за тест
                `${formatNumber(currentPoints)}/${totalPoints}`
              : rightQuestions >= 0 &&
                // Количество верных ответов на вопросы из Общее количество вопросов теста
                `${formatNumber(rightQuestions)} из ${totalQuestions}`}
          </span>
        )}

        <small className="course-test-achieve-info__date">{normalizeTimestampDate(endTs)}</small>
      </div>
    </div>
  );
};

export default QuizeAttemptsInfo;
