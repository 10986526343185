import { DefaultModal } from "components/Modals/DefaultModal";
import { useModal } from "components/ModalsContainer";
import styles from "../../Orders.module.scss";

export interface ErrorModalProps {
  title: string;
  description: string;
}

export const ErrorModal = ({ title, description }: ErrorModalProps) => {
  const { closeModal } = useModal();

  return (
    <DefaultModal
      buttonsClassName={styles["modal-buttons"]}
      title={title}
      description={description}
      confirmBtnText="ОК"
      onConfirm={closeModal}
    />
  );
};
