import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const WomanWithHeadphoneAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();
  return process.env.REACT_APP_THEME === "myAcademy" ? (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width="234" height="234" rx="117" fill="#00AEFF" />
        <path
          d="M102.361 73.8211C100.604 69.9359 95.6256 70.9914 93.5171 73.6994C93.2408 73.999 93.0528 74.3234 92.7765 74.623C89.7132 71.9738 83.8643 73.0682 84.1616 78.0527C86.6547 111.834 51.4094 118.264 30.138 98.4482C18.5148 87.651 9.55802 74.8677 -3.20915 65.0695C-25.9094 47.5868 -58.5728 33.9101 -87.8009 40.3293C-109.379 45.0279 -129.183 64.2671 -125.798 87.5536C-122.739 108.298 -103.405 119.842 -85.3967 127.999C-104.925 149.954 -97.8243 184.65 -72.267 200.482C-43.5358 218.241 -10.384 208.774 14.1253 189.44C15.7998 201.313 24.1161 211.655 36.8348 215.596C55.073 221.272 102.524 216.566 114.682 203.384C145.041 170.231 119.926 113.334 102.361 73.8211Z"
          fill="#F1472E"
        />
        <path
          d="M235.376 440.272C235.328 418.843 233.567 397.463 234.981 376.193C235.013 374.497 235.158 372.741 235.19 371.045C234.24 348.206 234.837 323.656 251.103 307.652C252.406 292.792 253.82 277.871 254.863 262.932C246.669 237.799 230.728 215.242 209.779 199.693C205.158 204.449 199.653 208.369 193.791 210.949C174.884 219.329 153.749 218.694 133.992 213.74C118.812 209.892 102.988 202.815 91.104 192.007C53.0411 210.871 25.1866 248.089 25.2699 294.164C25.3423 332.656 13.091 434.21 10.3405 446.641C85.0215 457.272 160.263 460.304 235.631 461.954C235.466 454.891 235.377 447.569 235.376 440.272Z"
          fill="#FF6B00"
        />
        <path
          d="M63.2625 314.227C65.0626 301.861 74.1576 285.159 82.4546 268.304C84.8682 246.459 86.4837 224.461 89.8919 202.734C85.793 196.385 81.385 190.801 81.0529 190.985C56.8845 198.498 32.9588 212.119 17.4681 222.332C-29.336 253.155 -66.386 298.118 -87.819 351.003C-108.869 402.859 -114.407 460.689 -105.323 516.366C-94.1463 512.183 -83.1534 507.656 -72.0135 503.271C-52.1312 495.381 -31.4807 489.742 -11.5868 482.143C-11.562 482.055 -11.5984 481.852 -11.5736 481.764C-15.0036 390.41 -0.550642 382.555 19.361 353.324C31.5045 335.751 46.298 324.364 63.2625 314.227Z"
          fill="#FF6B00"
        />
        <path
          d="M155.022 43.9669C153.701 40.0583 151.647 36.7963 149.147 34.1637C148.369 34.9852 147.655 35.9176 146.965 36.7624C149.095 39.0156 150.825 41.7232 151.891 44.8151C152.375 46.9092 155.704 46.0203 155.022 43.9669Z"
          fill="#191229"
        />
        <path
          d="M151.41 222.787C176.257 224.526 196.648 215.247 210.65 200.166C195.572 187.241 166.525 178.91 141.4 180.405C121.685 181.586 102.539 187.127 85.7082 196.177C99.0024 214.05 131.679 221.487 151.41 222.787Z"
          fill="#F2A98A"
        />
        <path
          d="M176.657 185.728C175.535 175.978 174.499 166.251 173.377 156.5C184.064 146.669 188.979 131.107 184.668 116.047L170.69 66.6143C168.44 58.728 164.089 52.1567 158.396 47.2022C149.263 58.213 139.201 68.4982 128.453 77.8403C118.305 88.1018 106.949 97.2781 94.9749 105.247C98.3228 114.283 100.686 123.9 103.091 131.545C109.478 152.272 114.231 173.214 115.194 194.82C119.588 208.109 130.254 218.293 145.413 218.183C161.139 218.039 178.596 203.163 176.657 185.728Z"
          fill="#F2A98A"
        />
        <path
          d="M98.4707 103.315C98.3719 102.26 138.018 86.7382 157.84 49.0941C148.464 39.3915 133.657 35.723 119.758 39.6857L106.5 43.3517C85.52 49.2606 73.4101 71.0972 79.4551 92.0704L81.3863 98.665C87.5164 99.2711 93.2188 100.792 98.4707 103.315Z"
          fill="#F1472E"
        />
        <path
          d="M103.398 104.216C74.8718 96.6237 68.8949 100.636 68.218 111.773C67.541 122.911 72.9069 127.642 94.8155 131.661C116.636 135.657 103.398 104.216 103.398 104.216Z"
          fill="#F2A98A"
        />
        <path
          d="M101.824 118.827C96.27 113.447 88.8589 110.456 81.0237 110.201C78.7627 110.11 78.9433 113.451 81.1148 113.521C88.0993 113.752 94.5252 116.504 99.4746 121.279C101.087 122.77 103.437 120.318 101.824 118.827Z"
          fill="#191229"
        />
        <path
          d="M164.299 84.6671C162.586 83.3567 160.287 85.7446 161.978 87.1429C166.638 90.7567 177.089 100.83 167.617 105.697C165.683 106.678 167.445 109.601 169.379 108.619C173.269 106.568 174.954 102.227 174.323 97.8231C173.488 92.0468 168.634 88.0985 164.299 84.6671Z"
          fill="#191229"
        />
        <path
          d="M172.985 154.641C163.649 157.699 154.118 160.06 144.524 161.943C142.343 162.308 143.385 165.533 145.477 165.145C155.159 163.285 164.601 160.901 173.937 157.843C176.01 157.173 175.057 153.971 172.985 154.641Z"
          fill="#191229"
        />
        <path
          d="M139.855 81.8202C137.93 83.0842 135.894 84.4122 133.969 85.6762C132.154 86.8762 133.925 89.7839 135.739 88.584C137.664 87.32 139.7 85.9919 141.625 84.7279C143.44 83.528 141.669 80.6203 139.855 81.8202Z"
          fill="#191229"
        />
        <path
          d="M171.337 67.0138L166.433 68.4264C164.303 68.9846 165.246 72.2047 167.376 71.6464L172.279 70.2338C174.321 69.6525 173.378 66.4324 171.337 67.0138Z"
          fill="#191229"
        />
        <path
          d="M166.694 124.143C166.23 129.114 160.89 133.198 155.817 131.982C150.744 130.765 147.91 124.665 149.791 120.059C150.607 118.048 147.624 116.446 146.808 118.457C144.133 124.987 147.658 133.487 155.057 135.211C162.457 136.935 169.476 131.155 170.201 124.122C170.397 121.951 166.955 122.081 166.694 124.143Z"
          fill="#191229"
        />
        <path
          d="M151.372 90.3672C150.157 88.5511 147.275 90.2139 148.49 92.03C149.51 93.5144 149.265 95.8768 147.537 96.7256C145.897 97.5976 143.662 96.4443 143.173 94.7275C142.49 92.6788 139.264 93.5036 139.925 95.6395C141.055 99.2071 145.35 101.467 148.893 99.7928C152.325 98.1826 153.564 93.4698 151.372 90.3672Z"
          fill="#191229"
        />
        <path
          d="M177.75 78.1C176.535 76.2837 173.653 77.9466 174.868 79.7628C175.888 81.2473 175.643 83.6099 173.915 84.4586C172.276 85.3307 170.041 84.1773 169.552 82.4604C168.868 80.4116 165.643 81.2363 166.304 83.3724C167.434 86.9402 171.729 89.2003 175.271 87.5261C178.703 85.9159 179.832 81.2668 177.75 78.1Z"
          fill="#191229"
        />
        <path
          d="M149.808 42.6939C150.904 42.4262 152.023 42.0706 153.142 41.715C143.671 23.5406 116.066 22.7482 99.8967 32.1977C74.9274 46.7897 89.0139 79.1915 90.5502 101.332C90.7236 103.542 94.0908 103.513 93.9174 101.303C92.6525 82.4332 81.6596 59.3327 93.8665 42.1337C105.405 26.0706 139.465 24.2835 149.808 42.6939Z"
          fill="#191229"
        />
        <path
          d="M111.224 122.539C109.596 128.744 105.099 133.717 99.5114 135.687C98.3855 136.042 97.1719 136.373 96.0041 136.529C94.0655 136.761 92.1308 136.619 90.1123 136.082C80.6343 133.556 73.6439 122.624 76.8225 113.373C80.5934 102.223 89.0399 98.3022 98.5179 100.828C107.996 103.353 113.701 113.101 111.224 122.539Z"
          fill="#191229"
        />
        <path
          d="M166.489 221.708C161.547 222.414 156.243 223.688 150.656 223.226C129.246 221.792 90.9022 212.516 81.2049 190.973C104.091 183.14 126.933 180.862 144.03 190.771C153.782 197.123 161.536 207.109 165.507 219.555C165.884 220.657 166.29 220.65 166.489 221.708Z"
          fill="#F2A98A"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width={234} height={234} fill="#FFC422" rx={117} />
        <path
          fill="#F1472E"
          d="M102.361 73.821c-1.757-3.885-6.735-2.83-8.844-.122-.276.3-.464.624-.74.924-3.064-2.65-8.913-1.555-8.615 3.43 2.493 33.781-32.753 40.211-54.024 20.395C18.515 87.651 9.558 74.868-3.209 65.07c-22.7-17.483-55.364-31.16-84.592-24.74-21.578 4.698-41.382 23.937-37.997 47.224 3.059 20.744 22.393 32.288 40.401 40.445-19.528 21.955-12.427 56.651 13.13 72.483 28.731 17.759 61.883 8.292 86.392-11.042 1.675 11.873 9.991 22.215 22.71 26.156 18.238 5.676 65.689.97 77.847-12.212 30.359-33.153 5.244-90.05-12.321-129.563Z"
        />
        <path
          fill="#A650DB"
          d="M235.376 440.272c-.048-21.429-1.809-42.809-.395-64.079.032-1.696.177-3.452.209-5.148-.95-22.839-.353-47.389 15.913-63.393 1.303-14.86 2.717-29.781 3.76-44.72-8.194-25.133-24.135-47.69-45.084-63.239-4.621 4.756-10.126 8.676-15.988 11.256-18.907 8.38-40.042 7.745-59.799 2.791-15.18-3.848-31.004-10.925-42.888-21.733-38.063 18.864-65.917 56.082-65.834 102.157.072 38.492-12.179 140.046-14.93 152.477 74.681 10.631 149.923 13.663 225.291 15.313a939.903 939.903 0 0 1-.255-21.682Z"
        />
        <path
          fill="#A650DB"
          d="M63.263 314.227c1.8-12.366 10.895-29.068 19.192-45.923 2.413-21.845 4.029-43.843 7.437-65.57-4.099-6.349-8.507-11.933-8.84-11.749-24.168 7.513-48.093 21.134-63.584 31.347-46.804 30.823-83.854 75.786-105.287 128.671-21.05 51.856-26.588 109.686-17.504 165.363 11.177-4.183 22.17-8.71 33.31-13.095 19.882-7.89 40.532-13.529 60.426-21.128.025-.088-.011-.291.013-.379-3.43-91.354 11.023-99.209 30.935-128.44 12.143-17.573 26.937-28.96 43.901-39.097Z"
        />
        <path
          fill="#191229"
          d="M155.022 43.967c-1.321-3.909-3.375-7.17-5.875-9.803-.778.821-1.492 1.754-2.182 2.598 2.13 2.254 3.86 4.961 4.926 8.053.484 2.094 3.813 1.205 3.131-.848Z"
        />
        <path
          fill="#F2A98A"
          d="M151.41 222.787c24.847 1.739 45.238-7.54 59.24-22.621-15.078-12.925-44.125-21.256-69.25-19.761-19.715 1.181-38.861 6.722-55.692 15.772 13.294 17.873 45.971 25.31 65.702 26.61Z"
        />
        <path
          fill="#F2A98A"
          d="M176.657 185.728c-1.122-9.75-2.158-19.477-3.28-29.228 10.687-9.831 15.602-25.393 11.291-40.453L170.69 66.614c-2.25-7.886-6.601-14.457-12.294-19.412a263.972 263.972 0 0 1-29.943 30.638c-10.148 10.262-21.504 19.438-33.478 27.407 3.348 9.036 5.711 18.653 8.116 26.298 6.387 20.727 11.14 41.669 12.103 63.275 4.394 13.289 15.06 23.473 30.219 23.363 15.726-.144 33.183-15.02 31.244-32.455Z"
        />
        <path
          fill="#F1472E"
          d="M98.47 103.315c-.098-1.055 39.548-16.577 59.37-54.22-9.376-9.703-24.183-13.372-38.082-9.41L106.5 43.353C85.52 49.26 73.41 71.097 79.455 92.07l1.931 6.595c6.13.606 11.833 2.127 17.085 4.65Z"
        />
        <path
          fill="#F2A98A"
          d="M103.398 104.216c-28.526-7.592-34.503-3.58-35.18 7.557-.677 11.138 4.689 15.869 26.597 19.888 21.821 3.996 8.583-27.445 8.583-27.445Z"
        />
        <path
          fill="#191229"
          d="M101.824 118.827c-5.554-5.38-12.965-8.371-20.8-8.626-2.261-.091-2.08 3.25.09 3.32 6.985.231 13.411 2.983 18.36 7.758 1.613 1.491 3.963-.961 2.35-2.452ZM164.299 84.667c-1.713-1.31-4.012 1.078-2.321 2.476 4.66 3.614 15.111 13.687 5.639 18.554-1.934.981-.172 3.904 1.762 2.922 3.89-2.051 5.575-6.392 4.944-10.796-.835-5.776-5.689-9.724-10.024-13.156ZM172.985 154.641c-9.336 3.058-18.867 5.419-28.461 7.302-2.181.365-1.139 3.59.953 3.202 9.682-1.86 19.124-4.244 28.46-7.302 2.073-.67 1.12-3.872-.952-3.202ZM139.855 81.82c-1.925 1.264-3.961 2.592-5.886 3.856-1.815 1.2-.044 4.108 1.77 2.908 1.925-1.264 3.961-2.592 5.886-3.856 1.815-1.2.044-4.108-1.77-2.908ZM171.337 67.014l-4.904 1.412c-2.13.559-1.187 3.779.943 3.22l4.903-1.412c2.042-.581 1.099-3.802-.942-3.22ZM166.694 124.143c-.464 4.971-5.804 9.055-10.877 7.839-5.073-1.217-7.907-7.317-6.026-11.923.816-2.011-2.167-3.613-2.983-1.602-2.675 6.53.85 15.03 8.249 16.754 7.4 1.724 14.419-4.056 15.144-11.089.196-2.171-3.246-2.041-3.507.021ZM151.372 90.367c-1.215-1.816-4.097-.153-2.882 1.663 1.02 1.484.775 3.847-.953 4.696-1.64.872-3.875-.282-4.364-1.998-.683-2.05-3.909-1.224-3.248.912 1.13 3.567 5.425 5.827 8.968 4.153 3.432-1.61 4.671-6.323 2.479-9.426ZM177.75 78.1c-1.215-1.816-4.097-.153-2.882 1.663 1.02 1.484.775 3.847-.953 4.696-1.639.872-3.874-.282-4.363-1.999-.684-2.048-3.909-1.224-3.248.912 1.13 3.568 5.425 5.828 8.967 4.154 3.432-1.61 4.561-6.26 2.479-9.426ZM149.808 42.694c1.096-.268 2.215-.623 3.334-.979-9.471-18.174-37.076-18.967-53.245-9.517-24.97 14.592-10.883 46.994-9.347 69.134.174 2.21 3.54 2.181 3.367-.029-1.264-18.87-12.257-41.97-.05-59.17 11.538-16.062 45.598-17.85 55.941.56Z"
        />
        <path
          fill="#191229"
          d="M111.224 122.539c-1.628 6.205-6.125 11.178-11.713 13.148-1.126.355-2.34.686-3.507.842-1.939.232-3.873.09-5.892-.447-9.478-2.526-16.468-13.458-13.29-22.709 3.771-11.15 12.218-15.07 21.696-12.545 9.478 2.525 15.183 12.273 12.706 21.711Z"
        />
        <path
          fill="#F2A98A"
          d="M166.489 221.708c-4.942.706-10.246 1.98-15.833 1.518-21.41-1.434-59.754-10.71-69.451-32.253 22.886-7.833 45.728-10.111 62.825-.202 9.752 6.352 17.506 16.338 21.477 28.784.377 1.102.783 1.095.982 2.153Z"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
