import { useEffect, useState, useContext, useMemo } from "react";
import UrlContext from "../../../contexts/urlcontext";
import { numWord, useInterval } from "../../../app/utils";
import { WithAnimation } from "components/utils/utils";
import { AuthTypes } from "pages/Authorization/authRoutes.congig";
import styles from "./CodeRecieveCounter.module.scss";

interface CodeRecieveCounterProps {
  starts: boolean;
  intervals: number;
  onChange: () => void;
}

export const CodeRecieveCounter = ({ starts, intervals, onChange }: CodeRecieveCounterProps) => {
  const [count, setCount] = useState(30);
  const [secondsMessage, setSecondsMessage] = useState("секунд");
  const [isRunning, setIsRunning] = useState(true);
  const authContext = useContext(UrlContext);

  const actualContexts = useMemo(() => {
    return [
      AuthTypes.ACCESS_RECOVERY_CODE_CHECK,
      AuthTypes.REGISTRATION_CODE_CHECK_ERROR,
      AuthTypes.ACCOUNT_EXISTS,
      AuthTypes.REGISTRATION_CODE_CHECK,
    ];
  }, []);

  useEffect(() => {
    setCount(intervals);
    setIsRunning(starts);
  }, [intervals, starts]);

  useInterval(
    () => {
      if (count > 0) {
        setCount(count - 1);
        setSecondsMessage(numWord(count - 1, ["секунда", "секунды", "секунд"]));
      } else {
        setIsRunning(false);
        if (typeof onChange === "function") {
          onChange();
        }
      }
    },
    isRunning ? 1000 : null
  );

  return (
    <WithAnimation inCondition={actualContexts.indexOf(authContext) !== -1 && count > 0}>
      <p
        className={styles["form-countdown-couner"]}
      >{`Осталось ${count} ${secondsMessage} до повторной отправки кода`}</p>
    </WithAnimation>
  );
};
