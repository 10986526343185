import { WithSwitchAnimation } from "components/utils/utils";
import { useMediaQuery } from "hooks";
import { AuthTypes } from "pages/Authorization/authRoutes.congig";
import { useContext } from "react";
import {
  CapybaraBook,
  CapybaraLaptop,
  CapybaraLike,
  CapybaraPcPassword,
  CatWomanSvg,
  DoorLock,
  HappyCat2Pic,
  HappyCatAuthPick,
  LockPick2,
  SadCatPic2,
  SadCatWomanSvg,
  SleepCatPic,
  LaptopWithPassword,
} from "../../../../components/Svg/Pics";
import UrlContext from "../../../../contexts/urlcontext";

export const AuthorizationIllustration = () => {
  const authContext = useContext(UrlContext);

  const isMiddle = useMediaQuery(`(max-width:1025px)`);

  const isMyAcademyTheme = process.env.REACT_APP_THEME === "myAcademy";

  const isRostechnadzorTheme = process.env.REACT_APP_THEME === "rostechnadzor";

  const config = {
    catWomanContextArr: [AuthTypes.AUTH, AuthTypes.ACCOUNT_EXISTS, AuthTypes.AUTH_AGREEMENT],
    woman1ContextArr: [
      AuthTypes.ACCESS_RECOVERY,
      AuthTypes.ACCESS_RECOVERY_CODE_CHECK,
      AuthTypes.REGISTRATION_CODE_CHECK_ERROR,
      AuthTypes.PASSWORD_RECOVERY,
    ],
    accIsRegistered: [AuthTypes.REGISTERED],
    lock: !isMyAcademyTheme
      ? [AuthTypes.REGISTRATION_EMAIL_BLOCKED]
      : [AuthTypes.REGISTRATION_EMAIL_BLOCKED],
    sadCat: [AuthTypes.BANNED_DOMEN],
    registration: [
      AuthTypes.AUTH_EXIST,
      AuthTypes.REGISTRATION,
      AuthTypes.REGISTRATION_CODE_CHECK,
      AuthTypes.REGISTRATION_SECOND_STEP,
    ],
  };

  const getCatWomanContextImage = () => {
    if (isRostechnadzorTheme) {
      return <LaptopWithPassword />;
    }

    if (isMyAcademyTheme) {
      return <LaptopWithPassword width={354} height={229} />;
    }

    if (isMiddle) {
      return <CapybaraBook />;
    }

    return <CatWomanSvg />;
  };

  const getWoman1ContextImage = () => {
    if (isMyAcademyTheme) {
      return <CapybaraPcPassword />;
    }

    return <SadCatWomanSvg />;
  };

  const getAccIsRegisteredImage = () => {
    if (isMyAcademyTheme) {
      return <HappyCatAuthPick />;
    }

    return <CapybaraLike />;
  };

  const getLockImage = () => {
    if (isMyAcademyTheme) {
      return <DoorLock />;
    }

    return <SleepCatPic />;
  };

  const getSadCatImage = () => {
    if (isMyAcademyTheme) {
      return <LockPick2 />;
    }

    return <SadCatPic2 />;
  };

  const getRegistrationImage = () => {
    if (isMyAcademyTheme) {
      return <CapybaraLaptop />;
    }

    return <HappyCat2Pic />;
  };

  return (
    <WithSwitchAnimation animationName="slide-right-to-left" keyword={authContext}>
      {config.catWomanContextArr.includes(authContext)
        ? getCatWomanContextImage()
        : config.woman1ContextArr.includes(authContext)
        ? getWoman1ContextImage()
        : config.accIsRegistered.includes(authContext)
        ? getAccIsRegisteredImage()
        : config.lock.includes(authContext)
        ? getLockImage()
        : config.sadCat.includes(authContext)
        ? getSadCatImage()
        : config.registration.includes(authContext)
        ? getRegistrationImage()
        : null}
    </WithSwitchAnimation>
  );
};
