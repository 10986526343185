import { useDebounceEffect } from "ahooks";
import { EventEmitter } from "ahooks/lib/useEventEmitter";
import { Button } from "components/Buttons";
import { FilterIcon } from "components/Icons";
import { WithAnimation } from "components/utils/utils";
import { selectCourseCatalogFilterPopupOpened } from "features/courseCatalog/selectors";
import { changeCourseCatalogFilterPopupOpened } from "features/courseCatalog/slice";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  selectCoursesSearch,
  selectDurations,
} from "pages/Courses/redux/coursesListSliceSelectors";
import { SearchParams } from "pages/Courses/redux/interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUpperLevelOptions, mediaQuerys } from "../../../../app/utils";
import { Option } from "../../../../components/Select/types";
import { useMediaQuery } from "../../../../hooks";
import {
  coursesCompetenceSelectors,
  getSearchCourses,
  setCoursesIsLoaded,
  setMissGetCourses,
  setSearchCategory,
  setSearchCompetence,
  setSearchDuration,
  setSearchFormats,
  setSearchInput,
  setSearchKeywords,
  setSearchStatuses,
} from "../../redux/coursesListSlice";
import { CategorySearch } from "./components/CategorySearch";
import { CompetenceSearch } from "./components/CompetenceSearch";
import { CoursesSearchChips } from "./components/CoursesSearchChips";
import { DurationSearch } from "./components/DurationSearch";
import { FormatSearch } from "./components/FormatSearch";
import { GeneralSearch } from "./components/GeneralSearch";
import { StatusSearch } from "./components/StatusSearch";
import { KeywordsSearch } from "./components/KeywordsSearch";
import { keywordsCoursesList } from "pages/Course/redux/courseSelectors";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { CourseSearchPopup } from "components/Course/CourseSearchPopup";
import classNames from "classnames";
import styles from "./CoursesSearch.module.scss";

interface CoursesSearchProps {
  clearFilters$?: EventEmitter<void>;
}

export const CoursesSearch = ({ clearFilters$ }: CoursesSearchProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [selectedCourseCategories, setSelectedCourseCategories] = useState<Option[] | undefined>();
  const [selectedFormats, setSelectedFormats] = useState<Option[] | undefined>();
  const [selectedCompetencies, setSelectedCompetencies] = useState<Option[] | undefined>();
  const [selectedStatuses, setSelectedStatuses] = useState<Option[] | undefined>();
  const [selectedDurations, setSelectedDurations] = useState<Option[] | undefined>();
  const [selectedKeywords, setSelectedKeywords] = useState<Option[] | undefined>();

  const {
    categories: searchCategories,
    competences: searchCompetences,
    duration: searchDurations,
    format: searchFormats,
    enrolled_status: searchStatuses,
    input: searchInputValue,
    keywords: searchKeywords,
    missGetCourses,
  } = useAppSelector(selectCoursesSearch);

  const smMax = useMediaQuery(`(max-width:${mediaQuerys.smMax}px)`);
  const popupOpened = useSelector(selectCourseCatalogFilterPopupOpened);
  const durations = useAppSelector(selectDurations);
  const courseCompetences = useSelector(coursesCompetenceSelectors.selectAll);
  const selectKeywordsCoursesList = useAppSelector(keywordsCoursesList);
  const selectedLmsSettings = useAppSelector(lmsSettings);

  clearFilters$?.useSubscription(() => {
    dispatch(setSearchInput(""));
    dispatch(setSearchCategory(undefined));
    setSelectedCourseCategories(undefined);
    setSelectedCompetencies(undefined);
    setSelectedDurations(undefined);
    setSelectedFormats(undefined);
    setSelectedStatuses(undefined);
    setSelectedKeywords(undefined);
    onSearchCourses();
  });

  useEffect(() => {
    dispatch(setCoursesIsLoaded(false));
  }, [
    dispatch,
    searchCategories,
    searchCompetences,
    searchDurations,
    searchFormats,
    searchStatuses,
    searchInputValue,
    searchKeywords,
    missGetCourses,
  ]);

  useDebounceEffect(
    () => {
      if (missGetCourses) {
        dispatch(setMissGetCourses(false));
        return;
      }

      const searchParams: SearchParams = {
        categories: searchCategories?.length > 0 ? searchCategories?.join() : undefined,
        duration: searchDurations?.length > 0 ? searchDurations?.join() : undefined,
        format: searchFormats?.length > 0 ? searchFormats?.join() : undefined,
        enrolled_status: searchStatuses?.length > 0 ? searchStatuses?.join() : undefined,
        competences: searchCompetences?.length > 0 ? searchCompetences?.join() : undefined,
        query: searchInputValue,
        keywords: searchKeywords?.length > 0 ? searchKeywords?.join() : undefined,
      };

      onSearchCourses(searchParams);
    },
    [
      searchInputValue,
      searchCategories,
      searchCompetences,
      searchDurations,
      searchFormats,
      searchStatuses,
      searchKeywords,
    ],
    {
      wait: 500,
    }
  );

  useEffect(() => {
    // Если "Выбрать все", то берем все курсы
    const durationValue = selectedDurations?.length === durations.length ? [] : selectedDurations;

    dispatch(setSearchDuration(durationValue?.map(({ value }) => value)));
  }, [dispatch, selectedDurations, durations.length]);

  useEffect(() => {
    dispatch(
      setSearchFormats(getUpperLevelOptions(selectedFormats as Option[])?.map(({ value }) => value))
    );
  }, [dispatch, selectedFormats]);

  useEffect(() => {
    dispatch(
      setSearchStatuses(
        getUpperLevelOptions(selectedStatuses as Option[])?.map(({ value }) => value)
      )
    );
  }, [dispatch, selectedStatuses]);

  useEffect(() => {
    dispatch(
      setSearchKeywords(
        getUpperLevelOptions(selectedKeywords as Option[])?.map(({ value }) => value)
      )
    );
  }, [dispatch, selectedKeywords]);

  useEffect(() => {
    // Если "Выбрать все", то берем все курсы
    const competenceValue =
      selectedCompetencies?.length === courseCompetences.length
        ? []
        : (selectedCompetencies as Option[]);

    dispatch(setSearchCompetence(getUpperLevelOptions(competenceValue)?.map(({ value }) => value)));
  }, [dispatch, selectedCompetencies, courseCompetences.length]);

  useEffect(() => {
    dispatch(setSearchCategory(selectedCourseCategories?.map(({ value }) => value)) as any);
  }, [dispatch, selectedCourseCategories]);

  useEffect(() => {
    const selectCompetence = courseCompetences.find(
      (item) => item.id === location.state?.filter?.competences
    );
    selectCompetence &&
      setSelectedCompetencies([
        {
          value: selectCompetence.id.toString(),
          parent_id: selectCompetence?.parent_id?.toString(),
          label: selectCompetence.name,
        },
      ]);
  }, [location, courseCompetences]);

  const onSearchCourses = (searchParams?: SearchParams) => {
    dispatch(setCoursesIsLoaded(false));
    dispatch(getSearchCourses(searchParams));
  };

  const openPopup = () => {
    dispatch(changeCourseCatalogFilterPopupOpened(true));
  };

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["row"]}>
          <GeneralSearch searchInputValue={searchInputValue} />

          {!smMax && (
            <>
              {selectedLmsSettings.keywords && (
                <KeywordsSearch
                  selectedKeywords={selectedKeywords}
                  setSelectedKeywords={setSelectedKeywords}
                  keywords={selectKeywordsCoursesList}
                />
              )}
            </>
          )}

          {smMax && (
            <>
              <Button
                onClick={openPopup}
                size="small"
                className={classNames(styles["filter-button"], {
                  [styles["rostechnadzor-filter-button-theme"]]:
                    process.env.REACT_APP_THEME === "rostechnadzor",
                })}
              >
                <FilterIcon />
              </Button>
              <WithAnimation inCondition={popupOpened && smMax}>
                <div>
                  <CourseSearchPopup />
                </div>
              </WithAnimation>
            </>
          )}
        </div>

        {!smMax && (
          <div className={styles["row"]}>
            {selectedLmsSettings.competences && (
              <CompetenceSearch
                selectedCompetencies={selectedCompetencies}
                setSelectedCompetencies={setSelectedCompetencies}
                courseCompetences={courseCompetences}
              />
            )}
            <CategorySearch
              selectedCourseCategories={selectedCourseCategories}
              setSelectedCourseCategories={setSelectedCourseCategories}
              isCompetenceEnabled={selectedLmsSettings.competences}
            />
            <FormatSearch
              selectedFormats={selectedFormats}
              setSelectedFormats={setSelectedFormats}
            />
            <StatusSearch
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
            />
            <DurationSearch
              selectedDurations={selectedDurations}
              setSelectedDurations={setSelectedDurations}
              durations={durations}
            />
          </div>
        )}
      </div>

      <CoursesSearchChips
        filters={(selectedCourseCategories ?? [])
          .concat(selectedCompetencies ?? [])
          .concat(selectedDurations ?? [])
          .concat(selectedFormats ?? [])
          .concat(selectedStatuses ?? [])
          .concat(selectedKeywords ?? [])}
        onDeleteClick={(option) => {
          const removeOption = (options?: Option[]) => {
            const result = options?.filter((x) => x !== option);
            if (!result?.length || result.length < 1) {
              return undefined;
            } else {
              return result;
            }
          };

          setSelectedCourseCategories(removeOption);
          setSelectedCompetencies(removeOption);
          setSelectedDurations(removeOption);
          setSelectedFormats(removeOption);
          setSelectedStatuses(removeOption);
          setSelectedKeywords(removeOption);
        }}
      />
    </>
  );
};
