import { QuizAccess as QuizAccessInterface } from "../../redux/Quze/interface/quiz.interface";
import styles from "./QuizAccesses.module.scss";
import { QuizAccess } from "./components";

interface QuizAccessesProps {
  quizAccesses: QuizAccessInterface[];
}

export const QuizAccesses = ({ quizAccesses = [] }: QuizAccessesProps) => (
  <>
    <p className={styles.text}>Перед выполнение данного теста необходимо:</p>
    <ul className={styles.list}>
      {quizAccesses.map((quizAccess) => (
        <li key={quizAccess.id} className={styles["list-item"]}>
          <QuizAccess id={quizAccess.id} title={quizAccess.title} type={quizAccess.type} />
        </li>
      ))}
    </ul>
  </>
);
