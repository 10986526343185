import { useAppSelector } from "hooks/redux";
import { questionsSelectors } from "../../redux/Quze/courseQuizeQuestionsSlice";
import {
  selectCoursePosition,
  selectCurrentOpenPageInQuiz,
} from "../../redux/Quze/courseQuizeSelectors";
import { Question } from "../Question";
import { getQuestionsByPosition } from "./functions";
import { useEffect, useState } from "react";
import { useDebounceFn } from "ahooks";
import { IQuizQuestion } from "../../redux/Quze/interface";

interface QuestionsProps {
  lessonId: string;
  onChange: (id: string | number, flush: () => unknown) => void;
}

export const Questions = ({ lessonId, onChange }: QuestionsProps) => {
  const [questionsForRendering, setQuestionsForRendering] = useState<IQuizQuestion[]>([]);

  const questions = useAppSelector(questionsSelectors.selectAll);
  const position = useAppSelector(selectCoursePosition);
  const currentOpenPage = useAppSelector(selectCurrentOpenPageInQuiz);

  const { run: handleOnChange } = useDebounceFn(
    () => {
      setQuestionsForRendering(
        getQuestionsByPosition({
          questions,
          position,
          currentOpenPage,
        })
      );
    },
    {
      wait: 300,
    }
  );

  useEffect(() => {
    handleOnChange();
  }, [questions, position, currentOpenPage, handleOnChange]);

  return (
    <>
      {questionsForRendering?.map((question) => (
        <Question
          key={question.id}
          quest={question}
          questIndex={question.id}
          lessonId={lessonId}
          onChange={onChange}
        />
      ))}
    </>
  );
};
