import { ArrowDownIcon, CheckIcon } from "components/Icons";
import { Subtitle } from "components/Typography/Titles";
import { coursesCategorySelectors } from "pages/Courses/redux/coursesListSlice";
import { useState } from "react";
import { useSelector } from "react-redux";

interface CourseSearchPopupBlockPops {
  activeCategories: string[];
  setActiveCategories: (categories: string[]) => void;
  isHiddenList?: boolean;
}

export const CourseSearchPopupCategories = ({
  activeCategories,
  setActiveCategories,
  isHiddenList = true,
}: CourseSearchPopupBlockPops): JSX.Element => {
  const courseCategories = useSelector(coursesCategorySelectors.selectAll);

  const [isOpenOptions, setIsOpenOptions] = useState(false);

  const onClickHandler = (id: string) => () => {
    setActiveCategories(
      activeCategories?.includes(id)
        ? activeCategories?.filter((item) => item !== id)
        : [...(activeCategories ?? []), id]
    );
  };

  const handleToggleOptions = () => isHiddenList && setIsOpenOptions((prev) => !prev);

  return (
    <div>
      <Subtitle onClick={handleToggleOptions} className="course-search-popup__subtitle">
        <span>Категория</span>
        {isHiddenList && (
          <div
            className="course-search-popup__subtitle-button"
            style={{ transform: `scale(${isOpenOptions ? "-1, 1" : "1, -1"})` }}
          >
            <ArrowDownIcon />
          </div>
        )}
      </Subtitle>
      {(isOpenOptions || !isHiddenList) && (
        <>
          {(courseCategories || [])
            .sort((a, b) => a?.name!.localeCompare(b.name!))
            .map(({ id, name }) => {
              // @ts-ignore
              const isActive = (activeCategories ?? []).includes(id);
              return (
                <li
                  key={id}
                  className={
                    isActive
                      ? "course-search-popup-list__item course-search-popup-list__item--active"
                      : "course-search-popup-list__item"
                  }
                  // @ts-ignore
                  onClick={onClickHandler(id)}
                >
                  {isActive && <CheckIcon className="course-search-popup-list__icon" />}
                  <span>{name}</span>
                </li>
              );
            })}
        </>
      )}
    </div>
  );
};
