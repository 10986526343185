import { ScoringAttemptType } from "../../redux/Quze/interface";
import styles from "./ScoringAttempt.module.scss";

interface ScoringAttemptProps {
  scoringAttempt: ScoringAttemptType;
}

export const ScoringAttempt = ({ scoringAttempt }: ScoringAttemptProps) => {
  switch (scoringAttempt) {
    case "highter":
      return (
        <p className={styles.text}>
          После того, как вы пройдете тест, будет учтена попытка с наивысшим результатом.
        </p>
      );
    case "average":
      return (
        <p className={styles.text}>
          После того, как вы пройдете тест, будут учтены результаты всех попыток для вычисления
          средней оценки.
        </p>
      );
    case "first":
      return (
        <p className={styles.text}>
          После того, как вы пройдете тест, в результате будет учтена первая попытка.
        </p>
      );
    case "last":
      return (
        <p className={styles.text}>
          После того, как вы пройдете тест, в результате будет учтена последняя попытка.
        </p>
      );
    default:
      return null;
  }
};
