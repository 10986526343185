const TextButton = (props) => {
  return props?.href ? (
    <a
      {...props}
      href={props?.href}
      className={`text-button ${props.className ?? ""}`}
      data-invert={props?.invert ?? null}
    >
      {props.children}
    </a>
  ) : (
    <div
      {...props}
      className={`text-button ${props.className ?? ""}`}
      data-invert={props?.invert ?? null}
    >
      {props.children}
    </div>
  );
};

export default TextButton;
