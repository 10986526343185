export const LessonDescription = ({ stringHTML }: { stringHTML: string | undefined }) => {
  return stringHTML ? (
    <div
      className="text-main course-test__descr editable"
      // https://jira.lanit.ru/browse/SDOLAN-5489
      // dangerouslySetInnerHTML={{ __html: sanitizeHtml(stringHTML) }}
      dangerouslySetInnerHTML={{ __html: stringHTML }}
    ></div>
  ) : null;
};
