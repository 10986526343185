import {
  TestResultLeftDraw,
  TestResultLeftLeafPlugUnfilled,
  TestResultRightDraw,
  TestResultRightLeafPlugUnfilled,
} from "components/Svg/Pics";
import { TextMain } from "../../../../../components/Typography/Texts";
import { H1 } from "../../../../../components/Typography/Titles";
import { useMediaQuery } from "hooks";

export const ResultBlockProcessing = () => {
  const isMobile = useMediaQuery("(max-width: 550px)");
  const isXlMobile = useMediaQuery("(max-width: 650px)");

  return (
    <div className="test-result-card">
      <div className="test-result-header test-result-header-plug">
        <H1 className="test-result-card__title">Итоговая оценка по курсу пока не сформирована*</H1>
        <p
          className="test-result-card-info-alert-text"
          style={{ ...(isMobile ? { marginLeft: 0 } : {}) }}
        >
          Результат обучения будет доступен после завершения всех обязательных заданий курса.
        </p>
      </div>
      <div className="test-result-card-block">
        <div className="test-result-raiting test-result-raiting-unfinished test-result-raiting-plug">
          <div className="test-result-raiting-block test-result-raiting-block-plug">
            <div
              className="test-result-raiting__pretitle-container"
              style={{
                ...(isMobile ? { marginTop: 0 } : {}),
                marginBottom: isXlMobile ? "20px" : 0,
              }}
            >
              <div className="test-result-raiting__pretitle-container-score">
                <TestResultLeftDraw className="test-result-raiting-draw test-result-rating-draw-left test-result-raiting-draw__unscore" />
                <TestResultLeftLeafPlugUnfilled className="test-result-raiting-leaf test-result-raiting-leaf-left test-result-raiting-leaf__unscore" />

                <p className="test-result-raiting__pretitle test-result-raiting__pretitle__unscore">
                  Итоговая оценка <br /> не сформирована
                </p>

                <TestResultRightLeafPlugUnfilled className="test-result-raiting-leaf test-result-raiting-leaf-right test-result-raiting-leaf__unscore" />
                <TestResultRightDraw className="test-result-raiting-draw test-result-rating-draw-right test-result-raiting-draw__unscore" />
              </div>
            </div>
          </div>
        </div>
        <div className="test-result-card-info-conditional">
          <TextMain>
            Если вы уже завершили все задания, но результата еще нет, пожалуйста, подождите пару
            минут. Мы выполняем расчет результата
          </TextMain>
        </div>
      </div>
    </div>
  );
};
