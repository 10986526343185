import { numWord } from "app/utils";
import classNames from "classnames";
import { Badge } from "components/Badge";
import Container from "components/Container/Container";
import { Link } from "components/Link";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import { normalizeISODate, normalizeTimestampDate } from "../../../../app/utils";
import cardHeadMock from "../../../../assets/img/course-card-head.svg";
import cardHeadMockMyAcademy from "../../../../assets/img/myacademy_course-card-head.svg";
import cardHeadMockRostechnadzor from "../../../../assets/img/rostechnadzor_course-card-head.svg";
import Alert from "../../../../components/Alert/Alert";
import { Button } from "../../../../components/Buttons";
import { CourseCoinList } from "../../../../components/CourseCoinList";
import { CourseTypeTile } from "../../../../components/CourseTypeTile";
import { StarIcon } from "../../../../components/Icons";
import PaperBox from "../../../../components/Paper/PaperBox";
import { Skeleton } from "../../../../components/Skeleton";
import { H1 } from "../../../../components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { selectCourseCoins } from "../../../Course/redux/courseSelectors";
import { setCourseEnrolled } from "../../../Course/redux/courseSlice";
import { ICourse } from "../../../Course/redux/interfaces";
import { CourseFormat } from "../../../Course/redux/interfaces/course.interface";
import { selectShopSettings } from "../../../Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { getBackgroundImage } from "./backgroundImage/getBackgroundImage.util";
import styles from "./LandingMain.module.scss";

interface LandingMainProps {
  course?: ICourse;
  loaded: boolean;
  isMulticourse: boolean;
}

export const LandingMain = ({ course, loaded, isMulticourse }: LandingMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shopSettings = useAppSelector(selectShopSettings);
  const coins = useAppSelector(selectCourseCoins);

  const handleCourseEnroll = (id: number) => {
    if (id) {
      // @ts-ignore
      dispatch(setCourseEnrolled(id)).then(() => {
        navigate(WebAppRoutes.COURSE + "/" + id, { replace: true });
      });
    }
  };
  const handleCourseEnter = (id: number) => {
    if (id) {
      navigate(WebAppRoutes.COURSE + "/" + id);
    }
  };

  const enrollBtn = (course: ICourse) => {
    if (!course?.enrolled_ts && course?.self_enrollment) {
      return (
        <Button
          className={styles["btn"]}
          color="primary"
          size="big"
          onClick={() => {
            handleCourseEnroll(course?.id);
          }}
        >
          Записаться
        </Button>
      );
    }

    if (
      !!course?.enrolled_ts &&
      (course.time_limit_actual === null ||
        (!!course.time_limit_actual && course.time_limit_actual > 0))
    ) {
      return (
        <Button
          className={styles["btn"]}
          size="big"
          color="primary"
          onClick={() => {
            handleCourseEnter(course?.id);
          }}
        >
          Перейти
        </Button>
      );
    }
  };

  const courseStart = course?.start_dt;
  const courseEnd = course?.end_dt;

  const getAlertText = () => {
    if (courseStart && parseISO(courseStart) > new Date()) {
      return `Данный курс будет доступен для обучения ${normalizeISODate(courseStart)}`;
    }
    if (courseEnd && parseISO(courseEnd) < new Date()) {
      return `Данный курс не доступен для обучения после ${normalizeISODate(courseEnd)}`;
    }
    return "Чтобы записаться на курс обратитесь к менеджеру организации";
  };

  const courseDurationHours = course && Math.floor(course.duration / 3600);
  const courseDurationMinutes = course && Math.floor((course.duration % 3600) / 60);

  const description = course?.description;

  const getDefaultImage = () => {
    if (process.env.REACT_APP_THEME === "rostechnadzor") {
      return cardHeadMockRostechnadzor;
    }
    if (process.env.REACT_APP_THEME === "myAcademy") {
      return cardHeadMockMyAcademy;
    }
    if (process.env.REACT_APP_THEME === "base") {
      return cardHeadMock;
    }
  };

  return (
    <div
      className={classNames(styles["main-wrapper"], {
        [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
        [styles["myacademy-theme"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
      style={{
        backgroundImage: `url(${getBackgroundImage(course?.format)})`,
      }}
    >
      <Container>
        {loaded ? (
          <PaperBox className="lan-main">
            <div className="lan-main-content">
              {/* Рейтинг */}

              {course?.rating && (
                <Badge
                  color="accent"
                  className={classNames(styles["badge"], {
                    [styles["badge-myacademy"]]: process.env.REACT_APP_THEME === "myAcademy",
                  })}
                >
                  <StarIcon />
                  <p className={styles["badge-label"]}>{course.rating}</p>
                </Badge>
              )}

              <H1 className="lan-main-content__title">{course?.name}</H1>

              <p
                className={classNames("lan-main-content__descr", {
                  // eslint-disable-next-line
                  ["lan-main-content__descr-clip"]: description && description.length > 450,
                })}
              >
                {description}
              </p>
              {description && description.length > 450 && (
                <Link className="lan-main-content__more" href="#more">
                  Подробнее
                </Link>
              )}

              {!course?.enrolled_ts && !course?.self_enrollment ? (
                <div className="lan-main-content-info">
                  <Alert>{getAlertText()}</Alert>
                </div>
              ) : (
                <div className="lan-main-content-info">
                  {enrollBtn(course)}

                  {!!course?.self_enrollment &&
                    (course?.time_limit_actual === null ||
                      (!!course?.time_limit_actual && course?.time_limit_actual > 0)) && (
                      <CourseTypeTile type={course.format} className={styles["tile"]} />
                    )}
                </div>
              )}

              <div className={styles["info"]}>
                {course?.finished_ts ? (
                  <div className={styles["info-block"]}>
                    <p className={styles["info-block-title"]}>Дата завершения:</p>
                    <span className={styles["info-block-value"]}>
                      {normalizeTimestampDate(course?.finished_ts)}
                    </span>
                  </div>
                ) : (
                  course?.start_dt && (
                    <div className={styles["info-block"]}>
                      <p className={styles["info-block-title"]}>
                        {course?.format === CourseFormat.meeting
                          ? "Дата проведения:"
                          : "Старт обучения:"}
                      </p>

                      <span className={styles["info-block-value"]}>
                        {normalizeISODate(course?.start_dt)}
                      </span>
                    </div>
                  )
                )}

                {(course?.time_limit_total || course?.time_limit_actual) && (
                  <div className={styles["info-block"]}>
                    <p className={styles["info-block-title"]}>Курс необходимо пройти за: </p>
                    <span className={styles["info-block-value"]}>
                      {!!course?.time_limit_total &&
                        course?.time_limit_total +
                          " " +
                          numWord(course?.time_limit_total, ["день", "дня", "дней"])}{" "}
                      {!!course?.time_limit_actual &&
                        `(${numWord(course?.time_limit_total, [
                          "остался",
                          "осталось",
                          "осталось",
                        ])} ${course?.time_limit_actual})`}
                    </span>
                  </div>
                )}

                {course?.duration && course.duration > 0 && (
                  <div className={styles["info-block"]}>
                    <p className={styles["info-block-title"]}>Длительность: </p>
                    <span className={styles["info-block-value"]}>
                      {!!courseDurationHours &&
                        `${courseDurationHours} ${numWord(courseDurationHours, [
                          "час",
                          "часа",
                          "часов",
                        ])} `}
                      {!!courseDurationMinutes &&
                        `${courseDurationMinutes} ${numWord(courseDurationMinutes, [
                          "минута",
                          "минуты",
                          "минут",
                        ])} `}
                    </span>
                  </div>
                )}

                {shopSettings?.enable &&
                  (!!coins?.course?.amount ||
                    !!coins?.lessons?.amount ||
                    !!coins?.linked_courses?.amount) && (
                    <div className={styles["info-block"]}>
                      <p className={styles["info-block-title"]}>Баллы за курс:</p>
                      <CourseCoinList coins={coins} isMulticourse={isMulticourse} />
                    </div>
                  )}
              </div>
            </div>

            <div
              className={classNames("lan-main-bg", {
                "lan-main-bg_rostechnadzor-theme": process.env.REACT_APP_THEME === "rostechnadzor",
              })}
              style={{
                backgroundImage: course?.preview?.src ? `url(${course?.preview?.src})` : undefined,
              }}
            >
              <div className={"lan-main-bg__blur"}></div>
              <img
                className={"lan-main-bg__image"}
                src={course?.preview?.src ? `${course.preview.src}` : getDefaultImage()}
                alt="course preview"
              />
            </div>
          </PaperBox>
        ) : (
          <PaperBox className="lan-main">
            <div className="lan-main-content">
              <Skeleton variant="text" width={"100%"} height={95} />
              <br />
              <Skeleton variant="text" width={"100%"} height={24} />
              <Skeleton variant="text" width={"100%"} height={24} />
              <Skeleton variant="text" width={"100%"} height={24} />
            </div>
          </PaperBox>
        )}
      </Container>
    </div>
  );
};
