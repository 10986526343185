import classNames from "classnames";
import { CloseIcon } from "components/Icons";
import { Ref } from "react";
import stylesErrorMessage from "./ErrorMessage.module.scss";
import stylesLabel from "./Label.module.scss";
import styles from "./TextFieldWrap.module.scss";

interface TextFieldWrapProps {
  children: JSX.Element;
  className?: string;
  endAbortment?: JSX.Element;
  startAbortment?: JSX.Element;
  errorMessage?: string;
  isDisabled?: boolean;
  isFilled?: boolean;
  isFocused?: boolean;
  isInvalid?: boolean;
  label?: string;
  icon?: JSX.Element;
  textFieldWrapRef?: Ref<HTMLDivElement>;
  showCleaner?: boolean;
  onClear?: () => void;
}

export const TextFieldWrap = ({
  children,
  className,
  errorMessage,
  isDisabled = false,
  isFilled = false,
  isFocused = false,
  isInvalid = false,
  icon,
  label,
  textFieldWrapRef,
  showCleaner = false,
  startAbortment,
  onClear = () => {},
}: TextFieldWrapProps) => {
  const calcBorderForInput = () => {
    if (showCleaner && isFilled && icon) {
      return { "--border-right-width": "3.5rem" };
    }

    if ((showCleaner && isFilled) || icon) {
      return { "--border-right-width": "1.75rem" };
    }

    return;
  };

  return (
    <div className={classNames(styles["text-field"], className)}>
      <div
        className={classNames(styles["wrapper"], {
          [styles["_focused"]]: isFocused,
          [styles["_filled"]]: isFilled,
          [styles["_disabled"]]: isDisabled,
          [styles["_invalid"]]: isInvalid,
        })}
        style={calcBorderForInput() as any}
        ref={textFieldWrapRef}
      >
        {children}
        <div className={classNames(styles["content"])}>
          {!isFocused && !isFilled && startAbortment && (
            <div className={styles["cleaner"]}> {startAbortment} </div>
          )}

          <div className={stylesLabel["container"]}>
            {label && (
              <label
                className={classNames(stylesLabel["label"], {
                  [stylesLabel["raised"]]: isFocused || isFilled,
                  [stylesLabel["label_disabled"]]: isDisabled,
                })}
              >
                {label}
              </label>
            )}
          </div>
          {showCleaner && isFilled && (
            <div className={styles["cleaner"]} role="button" onClick={onClear}>
              <CloseIcon />
            </div>
          )}
          {icon && <div className={styles["icon"]}>{icon}</div>}
        </div>
      </div>
      {isInvalid && <p className={stylesErrorMessage["error-message"]}>{errorMessage}</p>}
    </div>
  );
};
