import { CapybaraSearchPic, CatSearchPic } from "components/Svg/Pics";
import { TextMain } from "components/Typography/Texts";
import { H3 } from "components/Typography/Titles";

export const RewardsEmpty = () => {
  return (
    <section className="course-empty">
      <div className="course-empty-img">
        {process.env.REACT_APP_THEME !== "myAcademy" ? <CatSearchPic /> : <CapybaraSearchPic />}
      </div>
      <div className="course-empty-text">
        <H3 className="course-empty-text__title">Мы не нашли ничего похожего</H3>
        <TextMain className="course-empty-text__descr">
          Давайте попробуем найти с другими параметрами
        </TextMain>
      </div>
    </section>
  );
};
