import { useNavigate, useParams } from "react-router-dom";
import Container from "../../../components/Container/Container";
import { NextArrowIcon, PrevArrowIcon } from "../../../components/Svg/Icons";
import {
  selectCourseContentPagination,
  selectCourseLessonPagination,
} from "../../../pages/Course/redux/courseSelectors";

import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "hooks/redux";
import { coursesSelectors } from "pages/Course/redux/courseSlice";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import { useMemo } from "react";
import { matchPath, useLocation } from "react-router";
import { WebAppRoutes } from "../../../app/routes";

type PaginationItem = {
  title: string;
  path: string;
};

const CourseNavBottom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { course_id } = useParams();
  const mainPath = `${WebAppRoutes.COURSE}/${course_id}`;

  const currentCourse = useAppSelector((state) =>
    coursesSelectors.selectById(state, course_id as EntityId)
  );

  const lessonPagination = useAppSelector(selectCourseLessonPagination);
  const contentPagination = useAppSelector(selectCourseContentPagination);

  const parentNav: PaginationItem[] = useMemo(() => {
    const nav: PaginationItem[] = [];
    const isMulticourse = currentCourse?.format === CourseFormat.multicourse;

    if (!isMulticourse) {
      nav.push({ title: "Оценки", path: "/report" });
    }

    if (!isMulticourse && currentCourse?.commenting) {
      nav.push({ title: "Оценить курс", path: "/feedback" });
    }

    nav.push({ title: "Мой результат", path: "/result" });
    return nav;
  }, [currentCourse]);

  const paginationNextPage = (): PaginationItem | undefined => {
    const index = parentNav.findIndex((x) => matchPath(mainPath + x.path, location.pathname));
    if (index >= 0) return parentNav[index + 1];

    if (lessonPagination.next)
      return {
        title: lessonPagination.next.title,
        path: WebAppRoutes.LESSON + "/" + lessonPagination.next.id,
      };

    if (contentPagination.next)
      return {
        title: contentPagination.next.name,
        path: WebAppRoutes.CONTENT + "/" + contentPagination.next.id,
      };

    return parentNav[0];
  };

  const paginationPrevPage = (): PaginationItem | undefined => {
    const index = parentNav.findIndex((x) => matchPath(mainPath + x.path, location.pathname));

    if (index < 0 && lessonPagination.previous)
      return {
        title: lessonPagination.previous.title,
        path: WebAppRoutes.LESSON + "/" + lessonPagination.previous.id,
      };

    if (index === 0 && lessonPagination.last)
      return {
        title: lessonPagination.last.title,
        path: WebAppRoutes.LESSON + "/" + lessonPagination.last.id,
      };

    if (index < 0 && contentPagination.previous)
      return {
        title: contentPagination.previous.name,
        path: WebAppRoutes.CONTENT + "/" + contentPagination.previous.id,
      };

    if (index === 0 && contentPagination.last)
      return {
        title: contentPagination.last.name,
        path: WebAppRoutes.CONTENT + "/" + contentPagination.last.id,
      };

    return parentNav[index - 1];
  };

  const handleNavClick = (path: string | undefined) => () => {
    navigate(mainPath + path);
  };

  const paginationPrevInfo = paginationPrevPage();
  const paginationNextInfo = paginationNextPage();

  return (
    <footer className="course-test-footer">
      <Container>
        <div className="course-test-footer-block">
          <div className="course-test-footer-prev">
            {paginationPrevInfo && (
              <div
                className={"course-test-footer-info course-test-footer-info__left"}
                onClick={handleNavClick(paginationPrevInfo?.path)}
              >
                <div>
                  <PrevArrowIcon className="svg" />
                </div>
                <div className="course-test-footer__link-container">
                  <span className="course-test-footer__link">{paginationPrevInfo?.title}</span>
                </div>
              </div>
            )}
          </div>
          <div className="course-test-footer-next">
            {paginationNextInfo && (
              <div
                className={"course-test-footer-info course-test-footer-info__right"}
                onClick={handleNavClick(paginationNextInfo?.path)}
              >
                <div className="course-test-footer__link-container">
                  <span className="course-test-footer__link">{paginationNextInfo?.title}</span>
                </div>
                <span>
                  <NextArrowIcon className="svg" />
                </span>
              </div>
            )}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default CourseNavBottom;
