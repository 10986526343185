import { forwardRef } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import TextField from "../Forms/TextField";
import { mediaQuerys } from "../../app/utils";
import { SearchSvgIcon, CloseIcon } from "../Svg/Icons";
import { WithAnimation } from "components/utils/utils";

const SearchField = forwardRef((props, ref) => {
  const smMax = useMediaQuery(`(max-width:${mediaQuerys.smMax}px)`);
  return (
    <TextField
      ref={ref}
      {...props}
      data-empty={!props.value}
      value={props.value}
      onChange={(event) => {
        props.onChange && props.onChange(event?.target?.value);
      }}
      label={!smMax && props.label ? props.label : null}
      variant={`${smMax ? "filled" : "outlined"}`}
      InputProps={{
        startAdornment: smMax ? (
          <InputAdornment position="start">
            <SearchSvgIcon />
          </InputAdornment>
        ) : null,
        endAdornment: (
          <WithAnimation inCondition={!!props.value}>
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  props.onChange("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          </WithAnimation>
        ),
      }}
    />
  );
});

export default SearchField;
