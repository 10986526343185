import { getAdminEmail } from "app/constants";
import { Link } from "components/Link";
import styles from "../AuthorizationLeftContent/AuthorizationLeftContent.module.scss";
import { Capacitor } from "@capacitor/core";

export const SupportRow = (): JSX.Element => {
  return (
    <div className={styles["support"]}>
      Техподдержка:{" "}
      <Link
        style={{ display: Capacitor.isNativePlatform() ? "block" : "initial" }}
        onClick={(event) => {
          event.preventDefault();
          // @ts-ignore
          window.location = `mailto:${getAdminEmail()}`;
        }}
      >
        {getAdminEmail()}
      </Link>
    </div>
  );
};
