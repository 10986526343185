import { useEffect, useRef, useState } from "react";
import api from "../../services/api/api";

export const mediaQuerys = {
  xlgMin: 1920,
  lgMax: 1919,
  lgMin: 1280,
  mdMax: 1279,
  mdMin: 1024,
  smMax: 1023,
  smMin: 768,
  xsMax: 767,
  xxsMin: 551,
  xxsMax: 550,
  xsSm: 320,
};

export const triggerWinResize = (timeout = 50) => {
  setTimeout(() => {
    // For a full list of event types: https://developer.mozilla.org/en-US/docs/Web/API/document.createEvent
    let el = window; // This can be your element on which to trigger the event
    let event = document.createEvent("HTMLEvents");

    event.initEvent("resize", true, false);
    el.dispatchEvent(event);
    el = null;
    event = null;
  }, timeout);
};

const disableWheel = (e) => {
  e.preventDefault();
};

export const disableScroll = (targets = ".page .ps:not(.custom-breadcrumbs__popup)") => {
  const arr = document.querySelectorAll(targets);

  Array.from(arr).forEach((target) => {
    const { scrollTop } = target;
    const { scrollLeft } = target;

    document.body.setAttribute("data-scroll", "disable");

    // if any scroll is attempted,
    // set this to the previous value
    target.addEventListener("wheel", disableWheel, { passive: false });

    target.onscroll = () => {
      target.scrollTop = scrollTop;
      target.scrollLeft = scrollLeft;
    };
  });
};

export const enableScroll = (targets = ".page .ps:not(.custom-breadcrumbs__popup)") => {
  const arr = document.querySelectorAll(targets);

  Array.from(arr).forEach((target) => {
    document.body.removeAttribute("data-scroll");
    target.onscroll = () => {};

    target.removeEventListener("wheel", disableWheel, { passive: false });
  });
};

export const stopWheelPropagation = (targets) => {
  Array.from(document.querySelectorAll(targets)).forEach((item) => {
    item.addEventListener(
      "wheel",
      (e) => {
        e.stopPropagation();
      },
      { passive: false }
    );
  });
};

export const watchBodyWidth = () => {
  const setBodyWidth = () => {
    const width = window.innerWidth;
    document.body.style.width = `${Math.round(width)}px`;
  };
  setBodyWidth();
  window.addEventListener("resize", () => {
    setBodyWidth();
  });
};

export const isIE = () => {
  const { userAgent } = navigator;
  return userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1;
};

export const bodyDomObserver = () => {
  const observer = new MutationObserver((mutationRecords) => {
    const addedNodes = [];

    Array.from(mutationRecords).map((item) => addedNodes.push(item.addedNodes));
  });

  observer.observe(document.body, {
    childList: true, // наблюдать за непосредственными детьми
  });
};

export const isResultSuccess = (payload) => {
  if (payload.status === 200) return true;
  else return false;
};

export const isResultConflict = (payload) => {
  if (payload.status === 409) return true;
  else return false;
};

export const isResultBlocked = (payload) => {
  if (payload.status === 403) return true;
  else return false;
};

export const isResultBadRequest = (payload) => {
  if (payload.status === 400) return true;
  else return false;
};

/**
 * Склоняет слово в контексте числительного
 * @param value
 * @param words
 * @returns {*}
 */
export const numWord = (value, words) => {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const normalizeSnils = (value) => {
  if (value) {
    return (
      value
        .toString()
        .replace(/\s/g, "")
        .match(/.{1,3}/g)
        ?.join("-")
        .substr(0, 19) || ""
    );
  }
  return null;
};
export const normalizePhoneHref = (phone) => {
  let number = phone.toString();
  number = number.replace(/[^\d+]+/g, "");
  number = number.replace(/^00/, "+");
  if (number.match(/^7/)) number = "+" + number;
  if (!number.match(/^\+/)) number = "+7" + number;
  return number;
};

export const normalizePhone = (phone) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    var intlCode = match[1] ? "+7 " : "+7 ";
    return [intlCode, "(", match[2], ") ", match[3], " ", match[4], " ", match[5]].join("");
  }
  return null;
};

export const normalizeTimestampDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  const a = new Date(timestamp * 1000);
  const year = a.getFullYear();
  const month = a.getMonth() + 1;
  const date = a.getDate();
  const time =
    date.toString().padStart(2, "0") + "." + month.toString().padStart(2, "0") + "." + year;
  return time;
};
export const normalizeISODate = (newDate) => {
  const date = new Date(newDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return dt + "." + month + "." + year;
};

export const useRefBookItemName = (book, id) => {
  if (book && id) {
    const item = book.filter((item) => item.id === parseInt(id));
    return item.length ? item[0].name : "";
  }
  return null;
};

export const refBookItemName = (book, id) => {
  if (book && id) {
    const item = book.filter((item) => item.id === parseInt(id));
    return item.length ? item[0].name : "";
  }
  return null;
};

export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const hDisplay = h > 0 ? h + numWord(h, [" час ", " часа ", " часов "]) : "";
  const mDisplay = m > 0 ? m + numWord(m, [" минута ", " минуты ", " минут "]) : "";
  return hDisplay + mDisplay;
};

export const secondsToHmsFormat = (d) => {
  d = Number(d);
  const m = Math.floor(d / 60);
  const s = d % 60;
  const mDisplay = m < 10 ? "0" + m : m;
  const sDisplay = s < 10 ? "0" + s : s;
  return mDisplay + ":" + sDisplay;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const normalizeFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
    : ["Кб", "Мб", "Гб", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};

export const validateSnils = (snils, error) => {
  var result = false;
  if (typeof snils === "number") {
    snils = snils.toString();
  } else if (typeof snils !== "string") {
    snils = "";
  }
  if (snils.length) {
    var sum = 0;
    for (var i = 0; i < 9; i++) {
      sum += parseInt(snils[i]) * (9 - i);
    }
    var checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit === parseInt(snils.slice(-2))) {
      result = true;
    }
  }
  return result;
};

export const declensionNum = (value, words) => {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

export const useCoursePicAnimation = (initShow = false) => {
  const [show, setshow] = useState(initShow);

  const [animStyles] = useState({
    transitionDelay: "0.3s",
    transitionDuration: "0.3s",
  });

  useEffect(() => {
    setshow();
  }, []);

  return { show, animStyles };
};

export const validateRule = (password, ruleId, checkAll, requirenments) => {
  if (checkAll) {
    let checkAllResult = true;
    ["length", "specialChars", "upperCase", "digits"].forEach((id) => {
      checkAllResult = checkAllResult && validateRule(password, id, false, requirenments);
    });
    return checkAllResult;
  }

  let result = true;

  switch (ruleId) {
    case "length": {
      const MIN =
        requirenments?.minLength?.length && requirenments.minLength.length !== 0
          ? requirenments.minLength.length
          : 8;
      const MAX = requirenments?.maxLength?.length ? requirenments.maxLength.length : 25;
      result = result && password.length >= MIN && password.length <= MAX ? true : false;
      break;
    }
    case "specialChars":
      if (requirenments?.specialChars?.length) {
        const LENGTH = requirenments?.specialChars?.length;
        const matchSpecial = password.match(/[!@#$%^&*.]/gm) || [];
        result = result && matchSpecial.length >= LENGTH ? true : false;
      }
      break;
    case "upperCase":
      if (requirenments?.upperCase?.length) {
        const LENGTH = requirenments?.upperCase?.length;
        const matchUpperCase = password.match(/[A-ZА-Я]/g) || [];
        result = result && matchUpperCase.length >= LENGTH ? true : false;
      }
      break;
    case "digits":
      if (requirenments?.digits?.length) {
        const LENGTH = requirenments?.digits?.length;
        const matchDigits = password.match(/\d/g) || [];
        result = result && matchDigits.length >= LENGTH ? true : false;
      }
      break;
    // case "onlyLatin": {
    //   const matchLatin = password.match(/[a-zA-Z\d!@#$%^&*.]/gm) || [];
    //   result = result && matchLatin.length === password.length ? true : false;
    //   break;
    // }
    default:
      break;
  }

  return result;
};

// export const generateUid = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const downloadBlob = async (path) => {
  try {
    return api
      .get(path, {
        responseType: "blob",
      })
      .then((response) => response.data);
  } catch (error) {
    console.log(error);
  }
};

export const downloadFile = (path, name) => {
  try {
    downloadBlob(path)
      .then((response) => {
        const blobUrl = URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        document.body.removeChild(link);
      })
      .catch((e) => {});
  } catch (error) {
    console.log(error);
  }
};

export const getFileNameFromSource = (src) => {
  if (!src) return;

  const lastIndex = src?.lastIndexOf("/");
  if (lastIndex === -1) return src;

  if (lastIndex + 1 >= src.length) return src;
  return src.substring(src.lastIndexOf("/") + 1);
};

export const formatNumber = (number) =>
  number >= 0 ? (Number.isInteger(number) ? number?.toString() : number?.toFixed(1)) : "";
