import { CalendarIcon } from "./CalendarIcon";
import { ClockIcon } from "./ClockIcon";
import { HelpIcon } from "./HelpIcon";
import { MediaIcon } from "./MediaIcon";
import { MessageIcon } from "./MessageIcon";
import { MicroIcon } from "./MicroIcon";

export const RulesIcon = ({ type }: { type: string }): JSX.Element | null => {
  switch (type) {
    case "time_limit":
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return <HelpIcon iconColor="#0055A6" rectColor="#E4F1FD" />;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return <HelpIcon iconColor="#191229" rectColor="#FFE796" />;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return <HelpIcon />;
      }

      return null;
    case "record":
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return <MediaIcon iconColor="#0055A6" rectColor="#E4F1FD" />;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return <MediaIcon iconColor="#191229" rectColor="#FFE796" />;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return <MediaIcon />;
      }

      return null;
    case "finish_ts":
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return <ClockIcon iconColor="#0055A6" rectColor="#E4F1FD" />;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return <ClockIcon iconColor="#191229" rectColor="#FFE796" />;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return <ClockIcon />;
      }

      return null;
    case "zoom":
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return <MicroIcon iconColor="#0055A6" rectColor="#E4F1FD" />;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return <MicroIcon iconColor="#191229" rectColor="#FFE796" />;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return <MicroIcon />;
      }

      return null;
    case "questions":
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return <CalendarIcon iconColor="#0055A6" rectColor="#E4F1FD" />;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return <CalendarIcon iconColor="#191229" rectColor="#FFE796" />;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return <CalendarIcon />;
      }

      return null;
    case "commenting":
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return <MessageIcon iconColor="#0055A6" rectColor="#E4F1FD" />;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return <MessageIcon iconColor="#191229" rectColor="#FFE796" />;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return <MessageIcon />;
      }

      return null;

    default:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return <CalendarIcon iconColor="#0055A6" rectColor="#E4F1FD" />;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return <CalendarIcon iconColor="#191229" rectColor="#FFE796" />;
      }

      return <CalendarIcon />;
  }
};
