import _ from "lodash";
import { useEffect } from "react";
import { numWord } from "../../../../app/utils";
import { Button } from "../../../../components/Buttons/Button";
import Container from "../../../../components/Container/Container";
import { TextMain } from "../../../../components/Typography/Texts";
import { H1, H2 } from "../../../../components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { hideBottomNav, setCourseLessonFinish, showBottomNav } from "../../redux/courseSlice";
import { CourseLessonQuestionary, CourseLessonQuestionaryQuestion } from "../../redux/interfaces";
import CourseTestContentHeader from "../CourseTestContentHeader";
import { LessonDescription } from "../LessonDescription";
import QuestGrade from "./Questions/QuestGrade";
import QuestNumb from "./Questions/QuestNumb";
import QuestRadio from "./Questions/QuestRadio";
import QuestText from "./Questions/QuestText";
import { selectCourseQuestionnaire } from "./redux/Questionary/courseQuestionarySelectors";
import {
  postCourseQuestionnaireAnswers,
  setQuestionaryComplite,
  setQuestionnaireQuestions,
} from "./redux/Questionary/courseQuestionarySlice";

const hasInvalidAnswer = (questions: CourseLessonQuestionaryQuestion[]) => {
  for (const question of questions) {
    if (!isValidQuestion(question)) return true;
  }

  return false;
};

const isValidQuestion = (question: CourseLessonQuestionaryQuestion) => {
  return !question.required ||
    //@ts-ignore
    (question?.answer !== undefined && question?.answer !== "" && question?.answer?.length !== 0)
    ? true
    : false;
};

const CourseSheetTest = ({ lesson }: { lesson: CourseLessonQuestionary }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(hideBottomNav());
    return () => {
      dispatch(showBottomNav());
    };
  }, [dispatch]);

  const questionnaire = useAppSelector(selectCourseQuestionnaire);
  const { questions = [], description = "" } = questionnaire;

  const filteredData = _.cloneDeep(questions).filter((question) => {
    if (question.type === "explanation" && !question.title) {
      return false;
    }

    return true;
  });

  const sortedData = filteredData.sort((a, b) => {
    if (!a.order || !b.order) return 0;
    return a.order - b.order;
  });

  const clickDoneHandler = () => {
    if (!hasInvalidAnswer(questions)) {
      const answers = questions
        .filter((question) => question.type !== "explanation")
        .map((question) => ({
          id: question.id,
          value: Array.isArray(question.answer) ? question.answer : [question.answer ?? ""],
        }));
      const questionnaireId = lesson.id;
      dispatch(postCourseQuestionnaireAnswers({ questionnaireId, answers })).then(() => {
        dispatch(setCourseLessonFinish(lesson.id));
        dispatch(setQuestionaryComplite());
      });
    } else {
      dispatch(
        setQuestionnaireQuestions({
          questions: questions.map((question) => {
            return {
              ...question,
              valid: isValidQuestion(question),
            };
          }),
        })
      );
    }
  };

  return (
    <>
      <Container>
        <div className="course-test-block ">
          <div className="course-test-content" style={{ maxWidth: "100%" }}>
            <CourseTestContentHeader>
              <H1 className="course-test-top__title">
                {lesson.title}
                <span className="course-test-top__title-addition">
                  <span className="course-test-top-time">
                    <span className="course-test-top__time--default">
                      {lesson.questions_length}{" "}
                      {numWord(lesson.questions_length, ["вопрос", "вопроса", "вопросов"])}
                    </span>
                  </span>
                </span>
              </H1>
            </CourseTestContentHeader>

            <TextMain className="course-sheet-test__descr">
              <LessonDescription stringHTML={description} />
            </TextMain>
            {sortedData.map((question) => {
              let content = <QuestText key={question.id} question={question} />;
              question.answer = question?.answer ? question.answer : [];
              if (question.type === "select" || question.type === "select_with_explanation")
                content = (
                  <QuestRadio key={question.id} multy={question.multiselect} question={question} />
                );
              if (question.type === "number")
                content = <QuestNumb key={question.id} question={question} />;
              if (question.type === "score_select")
                content = <QuestGrade key={question.id} question={question} />;
              if (question.type === "explanation")
                content = (
                  <H2 key={question.id} className="course-sheet-test__title">
                    {question.title}
                  </H2>
                );

              return question.type === "explanation" ? (
                <>{content}</>
              ) : (
                <div
                  className={
                    !question.required || question.valid
                      ? "course-sheet-test-block"
                      : "course-sheet-test-block course-sheet-test-block--error"
                  }
                >
                  {question.required && !question.valid && (
                    <span className="course-sheet-error">
                      Этот вопрос обязателен для завершения опроса. Пожалуйста, ответьте на него.{" "}
                    </span>
                  )}
                  {content}
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      <footer className="course-test-footer">
        <Container>
          <div className="course-test-footer-block">
            <div className="course-test-footer-prev"></div>
            <div className="course-test-footer-next">
              <Button
                color="primary"
                className="course-test-footer__button"
                onClick={() => clickDoneHandler()}
              >
                Завершить
              </Button>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default CourseSheetTest;
