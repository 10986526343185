import { QuizAccessType } from "pages/Course/components/Quiz/redux/Quze/interface/quiz.interface";
import styles from "./QuizAccess.module.scss";

interface QuizAccessProps {
  title: string;
  type: QuizAccessType;
  id: string;
}

export const QuizAccess = ({ title, type, id }: QuizAccessProps) => {
  switch (type) {
    case "done":
      return (
        <p className={styles.text}>
          Пройти <a href="/">{title}</a>
        </p>
      );
    case "don't_done":
      return (
        <p className={styles.text}>
          Пройти <a href="/">{title}</a>
        </p>
      );
    case "above_passing_score":
      return (
        <p className={styles.text}>
          Должна быть получена оценка выше проходного балла в <a href="/">{title}</a>
        </p>
      );
    case "below_passing_score":
      return (
        <p className={styles.text}>
          Должна быть получена оценка ниже проходного балла в <a href="/">{title}</a>
        </p>
      );
    default:
      return null;
  }
};
