import { useAppSelector } from "hooks/redux";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import styles from "./PriceParagraph.module.scss";
import { Capacitor } from "@capacitor/core";

interface PriceParagraphProps {
  price: number;
}

export const PriceParagraph = ({ price }: PriceParagraphProps): JSX.Element => {
  const shopSettings = useAppSelector(selectShopSettings);

  return (
    <div className={styles["paragraph"]}>
      <p className={styles["paragraph-price"]}>{price}</p>
      {!!shopSettings?.coin?.icon?.path && (
        <img
          src={
            (Capacitor.isNativePlatform()
              ? process.env.REACT_APP_BACKEND_API_MOBILE || ""
              : "/api") + (shopSettings?.coin?.icon?.path || "")
          }
          alt={""}
          className={styles["paragraph-icon"]}
        />
      )}
    </div>
  );
};
