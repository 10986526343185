import { TextMessages } from "app/messages";
import { AuthTypes } from "../authRoutes.congig";
import { AuthData } from "./data.types";
import { PointerEvent } from "react";
import { getAdminEmail } from "app/constants";
import { WebAppRoutes } from "app/routes";

export const bannedDomen = ({ nav }: any): AuthData => ({
  type: AuthTypes.BANNED_DOMEN,
  toggles: {
    top: {
      title: TextMessages.AUTHORIZE_TO_ANOTHER,
      action: () => nav(WebAppRoutes.AUTH),
    },
  },
  titles: {
    main: TextMessages.BANNED_DOMEN,
    sub: TextMessages.BANNED_DOMEN_SUB,
  },
  form: {
    actions: {
      buttons: {
        primary: {
          title: TextMessages.SEND_TO_ADMIN,
          action: (event: PointerEvent<HTMLButtonElement>) => {
            event.preventDefault();
            // @ts-ignore
            window.location = `mailto:${getAdminEmail()}`;
          },
        },
      },
    },
  },
});
