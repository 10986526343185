import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { EndPoints } from "../../app/endpoints";
import api from "../../services/api/api";

const getUser = createAsyncThunk("user/get", async (arg, thunkAPI) => {
  const state = thunkAPI.getState();

  const response = await api.get(EndPoints.PROFILE, {
    headers: {
      "Access-Token": state?.auth?.oidc?.accessToken,
    },
  });

  return response.data;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    displayName: "",
    id: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.displayName = action.payload.data.displayName;
        state.id = action.payload.data.id;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.displayName = "";
        state.id = "";
      });
  },
});

export default userSlice.reducer;
export { getUser };
