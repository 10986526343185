import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "hooks/redux";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { mobile_version } from "app/constants";
import { useSelector } from "react-redux";
import { selectAuthOIDC } from "features/auth/selectors";
import { getMobileSettings } from "./redux/mobile.slice";
import { useAppSelector } from "../../hooks/redux/redux.hook";
import { mobileSettings, timeLastUpdateMobile } from "./redux/mobile.select";

export const useUpdateMobileModal = () => {
  const dispatch = useAppDispatch();

  const settings = useAppSelector(mobileSettings);
  const timeLastUpdate = useAppSelector(timeLastUpdateMobile);

  const authState = useSelector(selectAuthOIDC);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const onConfirm = () => {
    const platform = Capacitor.getPlatform();

    window.open(
      platform === "android"
        ? settings?.android_link
        : "itms-services://?action=download-manifest&url=" + settings?.ios_link,
      "_blank"
    );
  };

  const onCancel = () => setIsOpen(false);

  const compareVersions = (version1: string, version2: string) => {
    const splitVersion1 = version1.split(".").map(Number);
    const splitVersion2 = version2.split(".").map(Number);

    const maxLength = Math.max(splitVersion1.length, splitVersion2.length);

    for (let i = 0; i < maxLength; i++) {
      const num1 = splitVersion1[i] || 0;
      const num2 = splitVersion2[i] || 0;

      if (num2 > num1) {
        return true;
      } else if (num2 < num1) {
        return false;
      }
    }

    return false;
  };

  const hanleGetMobileSettings = () => {
    authState.idToken && dispatch(getMobileSettings());
  };

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.target === containerRef.current && setIsOpen(false);
  };

  useEffect(() => {
    hanleGetMobileSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    settings?.mobile_version &&
      setIsOpen(compareVersions(mobile_version, settings?.mobile_version));
  }, [settings]);

  useEffect(() => {
    const onResume = () => {
      const date1 = new Date().getTime();
      const date2 = (timeLastUpdate || new Date(0)).getTime();

      const diffInMilliseconds = Math.abs(date1 - date2);

      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      diffInHours > 1 && hanleGetMobileSettings();
    };

    const onResumeCallback = App.addListener("resume", onResume);

    return () => {
      onResumeCallback.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isOpen, handleContainerClick, containerRef, onConfirm, onCancel };
};
