import classNames from "classnames";
import { Button } from "../../Buttons";
import { Scrollbar } from "../../Scrollbar";
import { TextMain } from "../../Typography/Texts";
import { H2 } from "../../Typography/Titles";

interface DefaultModalProps {
  title?: string;
  description?: string | JSX.Element;
  icon?: JSX.Element;
  confirmBtnText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  buttonsClassName?: string;
}

export const DefaultModal = ({
  title,
  description,
  icon,
  confirmBtnText = "Ок",
  onConfirm,
  onCancel,
  buttonsClassName,
}: DefaultModalProps) => {
  return (
    <Scrollbar>
      <div className="modal-container">
        <div className="modal-content">
          {icon && <div className="modal-img">{icon}</div>}

          <div className="modal-text">
            <H2 className="modal-text__title">{title}</H2>

            <TextMain className="modal-text__descr">{description}</TextMain>
          </div>
        </div>

        <div className={classNames("modal-buttons", buttonsClassName)}>
          {onCancel && (
            <Button size="big" className="modal__button" onClick={onCancel}>
              Отмена
            </Button>
          )}

          {onConfirm && (
            <Button size="big" className="modal__button" color="primary" onClick={onConfirm}>
              {confirmBtnText}
            </Button>
          )}
        </div>
      </div>
    </Scrollbar>
  );
};
