import { WebAppRoutes } from "app/routes";
import { BalanceRow } from "components/BalanceRow";
import { Button } from "components/Buttons";
import { Link } from "components/Link";
import PaperBox from "components/Paper/PaperBox";
import { Subtitle } from "components/Typography/Titles";
import { useAppSelector } from "hooks/redux";
import { selectProfileCoins } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useNavigate } from "react-router";
import styles from "./WalletWidget.module.scss";

export const WalletWidget = () => {
  const navigate = useNavigate();
  const toShop = () => navigate(WebAppRoutes.REWARDS_SHOP);
  const toCatalog = () => navigate(WebAppRoutes.COURSES);
  const coins = useAppSelector(selectProfileCoins);

  return (
    <PaperBox className={styles["wallet-widget"]}>
      <Subtitle className={styles["wallet-widget-title"]}>В вашем кошельке</Subtitle>
      <div className={styles["wallet-widget-balance-block"]}>
        <div>
          <p className={styles["wallet-widget-mini-title"]}>Можно потратить:</p>
          <BalanceRow className={styles["wallet-widget-balance"]} coins={coins.shop_coins} />
        </div>
        {coins.shop_coins !== coins.total_coins && (
          <div>
            <p className={styles["wallet-widget-mini-title"]}>Всего:</p>
            <BalanceRow coins={coins.total_coins} isGray={true} />
          </div>
        )}
      </div>
      <p className={styles["wallet-widget-info"]}>
        Записывайтесь на курсы,
        <br></br>
        проходите обучение,
        <br></br>
        зарабатывайте и покупайте
        <br></br>в нашем <Link onClick={toShop}>Магазине наград</Link>
      </p>
      <Button
        className={styles["wallet-widget-button"]}
        size="big"
        color="primary"
        onClick={toCatalog}
      >
        Каталог курсов
      </Button>
    </PaperBox>
  );
};
