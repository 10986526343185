import { EndPoints } from "app/endpoints";
import { generateUid } from "app/utils";
import { ProductPicture } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import Slider, { Settings } from "react-slick";

interface ProductSliderProps {
  settings: Settings;
  pictures: ProductPicture[];
  className?: string;
}

export const ProductSlider = ({ settings, pictures, className }: ProductSliderProps) => {
  return (
    <Slider {...settings} className={className}>
      {pictures.map((x) => (
        <img key={generateUid()} alt={"img"} src={`${EndPoints.SHOP_FILES}/${x.id}`} />
      ))}
    </Slider>
  );
};
