import { DefaultModal } from "../DefaultModal";

interface CourseFeedbackModalProps {
  hasText?: boolean;
  onConfirm?: () => void;
}

export const CourseFeedbackModal = ({ hasText = false, onConfirm }: CourseFeedbackModalProps) => {
  return (
    <DefaultModal
      title="Благодарим за обратную связь!"
      description={
        hasText
          ? "Ваш комментарий будет опубликован после проверки модератором"
          : "Ваша оценка будет учтена в общей оценке курса"
      }
      onConfirm={onConfirm}
    />
  );
};
