import CalendarWrapper from "../Calendar/CalendarWrapper";
import CalendarContent from "../Calendar/CalendarContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Skeleton } from "../Skeleton/Skeleton";

const StudyCalendarSkeleton = () => (
  <CalendarWrapper>
    <p className="text-card calendar-eventday">
      <Skeleton variant="rounded" width={"245px"} />
    </p>
    <CalendarContent>
      <div className="calendar-event-block" style={{ padding: 0 }}>
        <Skeleton variant="rounded" height={"95px"} />
      </div>
      <div className="calendar-dates">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <div key={item} style={{ width: 98, paddingRight: 12 }}>
            <Skeleton variant="rounded" height={"95px"} />
          </div>
        ))}
      </div>
    </CalendarContent>
  </CalendarWrapper>
);

export default StudyCalendarSkeleton;
