export interface IProfileBase {
  // ID пользователя
  id: number;
  // Username пользователя
  username: string;
  // ФИО пользователя
  fullname: string;
  // Код аватарки из КК
  avatar_code: string;
  // Флаг, что данный пользователь является текущим авторизованным
  current: boolean;
  // Количество баллов
  points: number;
  // Позиция в выбранном рейтинге
  position: number;
}

export interface IProfile extends IProfileBase {
  // Название подразделения, к которому пользователь относится
  workplace: string;
  // Название уровня, который пользователь достиг в рейтинге
  level_name: string;
}

// Входные данные GET /rating/top
export interface IRatingTopRequest {
  limit?: number;
}

// Выходные данные GET /rating/top
export interface IRatingTopResponse {
  // Список рейтингов по интервалам
  data: {
    // Список объектов пользователя с его данными рейтинга за день
    daily: IProfileBase[];
    // ---- за неделю
    weekly: IProfileBase[];
    // ---- за месяц
    monthly: IProfileBase[];
    // ---- за год
    yearly: IProfileBase[];
    // ---- за все время
    all: IProfileBase[];
  };
}

export interface IActiveRating {
  isLoading: boolean;
  title: string;
  profileList: IProfileBase[];
}

export interface RatingState {
  isLoading: boolean;
  paging: IPaging & { hasMore: boolean };
  myPosition?: number;
  profileList: IProfile[];
  activeRating: IActiveRating;
}

export enum PeriodHeaders {
  daily = "Самые активные сегодня",
  weekly = "Самые активные на этой неделе",
  monthly = "Самые активные в этом месяце",
  yearly = "Самые активные в этом году",
  all = "Самые активные пользователи",
}

export enum Period {
  // Текущая неделя,
  week = "week",
  // Текущий месяц,
  month = "month",
  // Текущий год
  year = "year",
  // Все время.
  all = "all",
}

// Входные данные GET /rating
export interface IRatingRequest extends IProfileRatingRequest {
  limit?: number;
  offset?: number;
}

// Выходные данные GET /rating
export interface IRatingResponse {
  paging: IPaging;
  // Список пользователей с их информацией о текущем положении в рейтинге
  data: IProfile[];
}

interface IPaging {
  total: number;
  limit: number;
  offset: number;
}

// Входные данные GET /profile/rating
export interface IProfileRatingRequest {
  // Рейтинг моего подразделения (флаг о необходимости отобразить рейтинг среди сотрудников своего департамента)
  my_workplace?: boolean;
  // Код периода, за который необходимо посчитать рейтинг
  period?: Period;
}

// Выходные данные GET /profile/rating
export interface IProfileRatingResponse {
  data: {
    // Позиция в рейтинге (порядковый номер в списке)
    position: number;
    // Количество баллов пользователя
    points: number;
    // Величина изменения позиции пользователя в рейтинге. Может быть отрицательной
    change: number;
  };
}

// Выходные данные GET /rating/levels
export interface IRatingLevelsResponse {
  // Информация о текущем рейтинге
  data: IProfileLevel;
}

export interface ILevel {
  // Название уровня из мудла
  name: string;
  // Количество баллов, которые необходимо получить
  thresold: number;
  // Величина уровня
  level: number;
}

export interface IProfileLevel {
  // Описание/вводный текст о текущем рейтинге из мудла
  description: string;
  // Список уровней рейтинга
  levels: ILevel[];
}
