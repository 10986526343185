import { RootState } from "app/store";

export const selectProfileIsEdit = (state: RootState) => state.profile.isEdit;
export const selectProfileName = (state: RootState) => {
  let name = "";
  if (state.profile.details.firstname.length) {
    name += state.profile.details.firstname;
  }
  if (state.profile.details.middlename.length) {
    name += " " + state.profile.details.middlename;
  }
  if (state.profile.details.lastname.length) {
    name += " " + state.profile.details.lastname;
  }
  return name;
};
export const selectProfileFiles = (state: RootState) => state.profile.files;
export const selectProfileDetails = (state: RootState) => state.profile.details;
export const selectProfilePersonalAgreement = (state: RootState) => state.profile.requests;
export const selectProfileLoad = (state: RootState) => state.profile.load;
export const selectProfileLoadWithFiles = (state: RootState) =>
  state.profile.load && state.profile.filesLoad ? true : false;
export const selectProfileAvatar = (state: RootState) => state.profile.avatar;
export const selectProfileFileErrors = (state: RootState) => state.profile.filesErrors;
export const selectProfileCoursesHistory = (state: RootState) => state.profile.coursesHistory;
export const selectProfileCoursesLoading = (state: RootState) =>
  state.profile.coursesHistoryLoading;
export const selectAchievementData = (state: RootState) => state.profile.achievementData;
export const selectCompetenceData = (state: RootState) => state.profile.competenceData;
export const selectMyLevel = (state: RootState) => state.profile.myLevel;
export const selectIsloadingPutProfile = (state: RootState) => state.profile.isLoadingPutProfile;
export const selectIsLoadingMyLevels = (state: RootState) => state.profile.isLoadingMyLevels;
