import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeCourseCatalogFilterPopupOpened } from "../../features/courseCatalog/slice";
import SearchField from "../Forms/SearchField";
import { FilterSvgIcon } from "../Svg/Icons";
import { selectCourseCatalogFilterPopupOpened } from "../../features/courseCatalog/selectors";
import { useSelector } from "react-redux";
import { setSearchInputPopup } from "pages/Courses/redux/coursesListSlice";
import { useEffect } from "react";
import {
  selectCoursesListInputVal,
  selectCoursesListInputValPopup,
} from "pages/Courses/redux/coursesListSliceSelectors";

/**
 * Адаптив. Фильтр (попап). Поле query поиска.
 */
const CourseSearch = () => {
  const dispatch = useDispatch();
  const popupOpened = useSelector(selectCourseCatalogFilterPopupOpened);
  const storeSearchVal = useSelector(selectCoursesListInputVal);
  const storeSearchValPopup = useSelector(selectCoursesListInputValPopup);

  useEffect(() => {
    dispatch(setSearchInputPopup(storeSearchVal));
  }, [dispatch, storeSearchVal]);

  const openPopup = () => {
    dispatch(changeCourseCatalogFilterPopupOpened(true));
  };

  const onChangeHandler = (value) => {
    dispatch(setSearchInputPopup(value));
  };

  return (
    <section className={`course-search ${popupOpened ? "course-search--active" : ""}`}>
      <SearchField label="Поиск" onChange={onChangeHandler} value={storeSearchValPopup} />
      <IconButton onClick={openPopup} className="course-filter__icon">
        <FilterSvgIcon />
      </IconButton>
    </section>
  );
};

export default CourseSearch;
