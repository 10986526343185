import { generateUid } from "app/utils";
import { Option } from "../../../../../components/Select/types";
import { CoursesSearchChip } from "./CourseSearchChip";
import styles from "../CoursesSearch.module.scss";

interface CoursesSearchChipsProps {
  filters: Option[];
  onDeleteClick?: (option: Option) => void;
}

export const CoursesSearchChips = ({ filters, onDeleteClick }: CoursesSearchChipsProps) => (
  <div className={styles["chip-list"]}>
    {filters.map((chip) => (
      <div key={generateUid()}>
        <CoursesSearchChip title={chip.label} onDeleteClick={() => onDeleteClick?.(chip)} />
      </div>
    ))}
  </div>
);
