import { RootState } from "app/store";
import { CourseLessonQuestionary } from "../../../../redux/interfaces";

export const selectCourseQuestionnaire = (state: RootState) => {
  return state.courseQuestionary.questionnaire as CourseLessonQuestionary;
};

export const selectCourseQuestionnaireState = (state: RootState) => {
  return state.courseQuestionary.questionnaire_state;
};
