import { useMediaQuery } from "@mui/material";
import { mediaQuerys } from "app/utils";
import { CloseIcon, SearchIcon } from "../../../../../components/Icons";
import { TextField } from "../../../../../components/TextField";
import { useAppDispatch } from "../../../../../hooks/redux";
import { setSearchInput } from "../../../redux/coursesListSlice";
import styles from "../CoursesSearch.module.scss";
import { Capacitor } from "@capacitor/core";

interface GeneralSearchProps {
  searchInputValue: string;
}

export const GeneralSearch = ({ searchInputValue }: GeneralSearchProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const handleSetSearchInput = (value: string) => dispatch(setSearchInput(value?.trim()));

  return (
    <div className={styles["main-control"]}>
      <TextField
        showCleaner={true}
        value={searchInputValue}
        onChange={(value) => handleSetSearchInput(value)}
        label={!isMobile && !Capacitor.isNativePlatform() ? "Поиск" : undefined}
        startAbortment={isMobile || Capacitor.isNativePlatform() ? <SearchIcon /> : undefined}
        endAbortment={
          <>{searchInputValue && <CloseIcon onClick={() => handleSetSearchInput("")} />}</>
        }
      />
    </div>
  );
};
