import { CourseFormat } from "../../../../Course/redux/interfaces/course.interface";
import {
  courseBgES,
  multicourseBgES,
  taskBgES,
  atbBgES,
  meetingBgES,
  questionnaireBgES,
  pageBgES,
  quizBgES,
  trainerBgES,
} from "./education-studio-background";
import {
  courseBgMA,
  multicourseBgMA,
  taskBgMA,
  atbBgMA,
  meetingBgMA,
  questionnaireBgMA,
  pageBgMA,
  quizBgMA,
  trainerBgMA,
} from "./my-academy-background";
import {
  atbBgRTN,
  courseBgRTN,
  meetingBgRTN,
  multicourseBgRTN,
  pageBgRTN,
  questionnaireBgRTN,
  quizBgRTN,
  taskBgRTN,
  trainerBgRTN,
} from "./rostechnadzor-background";

export const getBackgroundImage = (type?: CourseFormat) => {
  switch (type) {
    case CourseFormat.course:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return courseBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return courseBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return courseBgES;
      }

      return;
    case CourseFormat.multicourse:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return multicourseBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return multicourseBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return multicourseBgES;
      }

      return;
    case CourseFormat.task:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return taskBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return taskBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return taskBgES;
      }

      return;
    case CourseFormat.atb:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return atbBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return atbBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return atbBgES;
      }

      return;
    case CourseFormat.meeting:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return meetingBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return meetingBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return meetingBgES;
      }

      return;
    case CourseFormat.questionnaire:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return questionnaireBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return questionnaireBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return questionnaireBgES;
      }

      return;
    case CourseFormat.page:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return pageBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return pageBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return pageBgES;
      }

      return;
    case CourseFormat.quiz:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return quizBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return quizBgMA;
      }

      if (process.env.REACT_APP_THEME === "base") {
        return quizBgES;
      }

      return;
    case CourseFormat.trainer:
      if (process.env.REACT_APP_THEME === "rostechnadzor") {
        return trainerBgRTN;
      }

      if (process.env.REACT_APP_THEME === "myAcademy") {
        return trainerBgMA;
      }
      if (process.env.REACT_APP_THEME === "base") {
        return trainerBgES;
      }
      return;
    default:
      return "";
  }
};
