import { EventEmitter } from "ahooks/lib/useEventEmitter";
import { generateUid } from "app/utils";
import { CourseList } from "../../redux/interface";
import { CoursesCard } from "../CoursesCard";
import CoursesEmpty from "../CoursesEmpty";
import CoursesListSkeleton from "../CoursesListSkeleton";
import CoursesSearchEmpty from "../CoursesSearchEmpty";
import styles from "./CoursesList.module.scss";

interface CoursesListProps {
  courses: CourseList[];
  clearFilters$: EventEmitter<void>;
  isSearch?: boolean;
  isLoading?: boolean;
}

export const CoursesList = ({
  courses = [],
  clearFilters$,
  isSearch,
  isLoading,
}: CoursesListProps) => {
  const onClear = (): void => {
    clearFilters$.emit();
  };

  return (
    <>
      {courses?.length ? (
        <div className={styles["container"]}>
          {courses.map((item) => (
            <CoursesCard key={generateUid()} course={item} />
          ))}
        </div>
      ) : (
        <>
          {!isLoading && isSearch && <CoursesSearchEmpty onClear={onClear} />}
          {!isLoading && !isSearch && <CoursesEmpty />}
        </>
      )}
      {isLoading && <CoursesListSkeleton />}
    </>
  );
};
