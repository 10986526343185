import { Dispatch, SetStateAction } from "react";
import { Select } from "../../../../../components/Select";
import { Option } from "../../../../../components/Select/types";
import { CourseCompetence } from "../../../redux/interface";
import styles from "../CoursesSearch.module.scss";

interface CompetenceSearchProps {
  courseCompetences: CourseCompetence[];
  selectedCompetencies: Option[] | undefined;
  setSelectedCompetencies: Dispatch<SetStateAction<Option[] | undefined>>;
}

export const CompetenceSearch = ({
  courseCompetences,
  selectedCompetencies,
  setSelectedCompetencies,
}: CompetenceSearchProps) => {
  const handleSetSelectedCompetencies = (selectedOptions: Option[]) =>
    setSelectedCompetencies(selectedOptions);

  const isSelectedAll = selectedCompetencies?.length === courseCompetences.length;

  return (
    <div className={styles["secondary-control"]}>
      <Select
        isMulti={true}
        isSorting={true}
        showCleaner={!isSelectedAll && !!selectedCompetencies}
        label="Компетенции"
        placeholder="Компетенции"
        options={courseCompetences.map((obj) => ({
          label: obj.name,
          value: obj.id?.toString(),
          parent_id: obj.parent_id?.toString(),
        }))}
        onChange={(selectedOptions) => handleSetSelectedCompetencies(selectedOptions as Option[])}
        value={selectedCompetencies}
      />
    </div>
  );
};
