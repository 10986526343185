import { FC } from "react";
import { Achievement } from "components/Achievement";
import { generateUid, mediaQuerys, numWord } from "../../../../app/utils";
import { useAppSelector } from "../../../../hooks/redux";
import { selectAchievements } from "../../redux/courseSelectors";
import { AchievmentsTooltip } from "components/AchievmentsTooltip";
import { Capacitor } from "@capacitor/core";
import { useMediaQuery } from "hooks";
import styles from "./AchievementsListLesson.module.scss";

/**
 * Список наград на страницах всех типов уроков
 */
export const AchievementsListLesson: FC = () => {
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const achievements = useAppSelector(selectAchievements);

  if (!achievements || achievements.length < 1) return null;

  return (
    <div className={styles["achievements"]}>
      <div className={styles["achievements__title"]}>
        В этом уроке {numWord(achievements?.length, ["доступен", "доступны", "доступны"])}{" "}
        {achievements?.length} {numWord(achievements?.length, ["бейдж", "бейджа", "бейджей"])}
      </div>

      <div className={styles["achievements__list"]} style={{ width: "100%" }}>
        {achievements?.map((item, index) => (
          <>
            {Capacitor.isNativePlatform() || isMobile ? (
              <Achievement
                title={item.name}
                image={item.image}
                disabled={item.criteria?.every((value) => !value.received_dt)}
                criteria={item.criteria}
                index={index}
                description={item.description}
                showDescription
              />
            ) : (
              <AchievmentsTooltip key={generateUid()} achievment={item}>
                <Achievement
                  title={item.name}
                  image={item.image}
                  disabled={item.criteria?.every((value) => !value.received_dt)}
                />
              </AchievmentsTooltip>
            )}
          </>
        ))}
      </div>
    </div>
  );
};
