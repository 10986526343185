import { FormControl, FormHelperText } from "@mui/material";
import { policyLink } from "app/constants";
import { Checkbox } from "components/Checkbox";
import { Link } from "components/Link";
import { useState } from "react";
import { Controller } from "react-hook-form";
import styles from "./AgreementInfoCheckboxInAuth.module.scss";

export const AgreementInfoCheckboxInAuth = (props: any) => {
  const [isError, setIsError] = useState(!!props.error?.message);
  const handleChange = (value: boolean) => {
    setIsError(value);
  };

  return (
    <div>
      <div className={styles["text"]}>
        <div className={styles["checkbox"]}>
          <Controller
            name="policy_agreed"
            control={props?.control}
            rules={{ required: "Обязательно для заполнения" }}
            render={({ field: { onChange, ...otherFields } }) => (
              <Checkbox
                onChange={(value: boolean | null) => {
                  handleChange(value || false);
                  onChange(value);
                }}
                {...otherFields}
              />
            )}
          />
        </div>
        <div className={styles["agreement-text"]}>
          Соглашаюсь с условиями сервиса и{" "}
          <Link href={policyLink} target={"_blank"} download>
            политикой обработки персональных данных
          </Link>
        </div>
      </div>
      {!isError ? (
        <FormControl error>
          <FormHelperText>{props.error?.message ? props.error?.message : null}</FormHelperText>
        </FormControl>
      ) : null}
    </div>
  );
};
