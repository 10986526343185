import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const ManWithSmileAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();

  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={234}
        height={234}
        viewBox="0 0 200 200"
        fill="none"
        {...props}
      >
        <g clipPath="url(#a)">
          <rect width={200} height={200} fill="#CFE2F4" rx={100} />
          <path
            fill="#176BD9"
            d="m207.601 208.723-.599 3.046-1.775 8.612-230.553 15.924c8.969-21.91 24.942-41.006 43.476-56.218 27.491-22.431 65.126-28.704 99.769-25.342 32.278 3.127 66.807 24.603 86.205 49.245a95.13 95.13 0 0 1 3.477 4.733Z"
          />
          <path
            fill="#0F1623"
            d="M138.382 95.401c-2.514-1.638-6.99-1.242-8.47 1.801a1569.061 1569.061 0 0 1-8.095 16.732c-1.404 2.945-1.223 6.488 1.782 8.509 2.514 1.639 6.99 1.242 8.47-1.801a1566.434 1566.434 0 0 1 8.096-16.732c1.392-3.032 1.212-6.575-1.783-8.509Z"
          />
          <path
            fill="#F2A98A"
            d="M127.745 56.831c-.025-2.322-.137-4.633-.521-6.998-.044-.352-.186-.781-.23-1.133-.141-.429-.381-.935-.479-1.011-.011-.088-.164-.605-.404-1.11-.217-.33-.424-.572-.642-.902-.109-.164-.544-.824-.969-1.395l-.294-.23c-.685-.537-2.829-2.854-3.784-2.73 2.087 1.16 2.392 1.48 1.152.745-.282-.142-.467-.208-.75-.35-.837-.339-1.685-.766-2.62-1.182-.924-.327-1.761-.666-2.685-.994-.283-.142-.652-.273-.848-.427-1.283-.37-2.793-.444-3.869-.573a84.586 84.586 0 0 0-11.627-1.179c-.89-.063-1.77-.039-2.748-.091-2.434.314-2.184.193-1.315.08-1.5.015-3.01-.058-4.422-.054-6.594.227-13.243.73-19.815 1.132-3.893 4.886-6.6 10.782-7.536 17.431l-6.647 45.758c-2.044 14.037 4.212 27.359 15.033 34.814.22 8.826.352 17.663.572 26.489.294 15.792 17.868 27.114 32.033 25.282 10.775-1.394 18.24-8.084 22.084-16.899 1.57-3.691 2.205-7.798 1.971-11.792-1.178-17.914.567-35.759 3.626-53.685 1.755-10.69 3.147-25.002 7.801-36.515-5.868-1.03-12.133-5.944-12.067-12.481Z"
          />
          <path
            fill="#0F1623"
            d="m117.054 27.282-12.364-1.801c-12.726-1.844-24.838 3.24-32.598 12.383.198.152.495.38.703.62 12.37 13.773 32.979.428 47.165 10.245 9.297 6.448 4.332 20.025 6.387 29.165 3.284 1.268 5.239 1.907 5.261 2.082 4.365-2.86 9.368-4.913 14.746-6.128l.913-6.146c2.871-19.52-10.669-37.578-30.213-40.42Z"
          />
          <path
            fill="#F2A98A"
            d="M127.642 80.548c24.479-10.288 30.291-7.39 32.281 2.644 1.99 10.034-5.324 16.371-24.341 22.596-19.018 6.225-7.94-25.24-7.94-25.24Z"
          />
          <path
            fill="#0F1623"
            d="M130.962 93.62c4.217-5.55 10.287-9.216 17.207-10.433 1.928-.33 2.305 2.62.387 3.035-6.126 1.119-11.436 4.426-15.191 9.389-1.156 1.466-3.548-.438-2.403-1.99ZM71.035 71.74c1.333-1.336 3.766.497 2.357 1.932-3.76 3.798-11.83 14.062-2.873 17.287 1.845.656.612 3.5-1.233 2.844-3.789-1.388-5.747-5.072-5.72-9.103.134-5.298 4.034-9.383 7.469-12.96ZM72.572 134.851c8.742 1.639 17.584 2.655 26.45 3.144 1.989.106 1.466 3.053-.435 2.936-8.865-.489-17.718-1.591-26.45-3.144-1.933-.375-1.5-3.311.435-2.936ZM92.145 65.947c1.901.911 3.89 1.811 5.791 2.723 1.803.835.554 3.652-1.248 2.817-1.9-.911-3.89-1.812-5.79-2.723-1.726-.934-.566-3.74 1.247-2.817ZM62.212 57.042l4.56.664c1.917.289 1.49 3.253-.427 2.964l-4.56-.664c-1.993-.19-1.49-3.253.427-2.964ZM74.035 106.964c1.094 4.379 6.305 7.44 10.643 5.74 4.338-1.701 6.099-7.505 3.896-11.478-.925-1.655 1.512-3.558 2.448-1.816 3.215 5.617 1.193 13.58-5.187 16.071-6.38 2.49-13.373-1.94-14.887-8.215-.408-1.809 2.667-2.198 3.087-.302ZM61.205 67.555c-3.818.755-2.703 6.75 1.115 5.996 3.918-.679 2.704-6.75-1.115-5.996ZM89.744 75.168c-3.819.755-2.704 6.75 1.115 5.995 3.917-.678 2.715-6.662-1.115-5.995ZM121.208 51.323c.501 9.087.28 18-.641 26.914-.814 7.643 8.797 8.905 9.525 1.272.932-8.825 1.142-17.826.641-26.913-.425-7.752-9.961-9.113-9.525-1.273Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect width={200} height={200} fill="#fff" rx={100} />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "myAcademy") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width="234" height="234" rx="117" fill="#3DC100" />
          <path
            d="M242.895 244.206L242.194 247.77L240.118 257.846L-29.6299 276.477C-19.1362 250.842 -0.447512 228.501 21.2373 210.702C53.4021 184.458 97.4346 177.118 137.967 181.052C175.733 184.71 216.132 209.837 238.827 238.668C240.209 240.443 241.604 242.318 242.895 244.206Z"
            fill="#00AEFF"
          />
          <path
            d="M161.908 111.619C158.966 109.702 153.729 110.166 151.998 113.727C148.879 120.282 145.747 126.735 142.526 133.304C140.884 136.748 141.095 140.894 144.612 143.259C147.553 145.176 152.79 144.712 154.522 141.151C157.641 134.596 160.772 128.143 163.994 121.575C165.623 118.027 165.412 113.882 161.908 111.619Z"
            fill="#191229"
          />
          <path
            d="M149.463 66.4919C149.434 63.775 149.303 61.0713 148.854 58.3041C148.802 57.8921 148.636 57.3904 148.585 56.9785C148.419 56.4767 148.138 55.8852 148.024 55.7954C148.011 55.6924 147.832 55.0876 147.552 54.4961C147.297 54.1104 147.055 53.8278 146.8 53.4422C146.673 53.2494 146.163 52.4783 145.666 51.81C145.552 51.7202 145.437 51.6303 145.323 51.5405C144.521 50.9117 142.013 48.2015 140.894 48.3462C143.337 49.7045 143.694 50.077 142.243 49.2182C141.912 49.0517 141.696 48.975 141.365 48.8085C140.386 48.412 139.394 47.9124 138.3 47.426C137.219 47.0426 136.24 46.6461 135.158 46.2627C134.828 46.0962 134.395 45.9429 134.166 45.7633C132.666 45.3295 130.899 45.2442 129.64 45.0931C125.101 44.3199 120.6 43.8556 116.037 43.7134C114.994 43.639 113.964 43.6675 112.82 43.6062C109.974 43.9745 110.266 43.8321 111.282 43.7005C109.528 43.7182 107.761 43.6327 106.109 43.6372C98.3932 43.9027 90.6148 44.4903 82.925 44.9618C78.3699 50.6784 75.2022 57.576 74.1077 65.3564L66.3307 118.892C63.9396 135.317 71.2589 150.903 83.9197 159.625C84.1764 169.951 84.3315 180.291 84.5882 190.617C84.9331 209.094 105.494 222.34 122.067 220.197C134.675 218.566 143.408 210.739 147.906 200.426C149.743 196.107 150.486 191.302 150.212 186.628C148.834 165.669 150.875 144.791 154.454 123.818C156.508 111.309 158.136 94.5652 163.582 81.0944C156.716 79.8897 149.386 74.1408 149.463 66.4919Z"
            fill="#F2A98A"
          />
          <path
            d="M136.954 31.9198L122.488 29.8122C107.599 27.6545 93.427 33.6027 84.3483 44.3002C84.5798 44.4784 84.9269 44.7457 85.1713 45.026C99.6437 61.1402 123.756 45.5263 140.354 57.0131C151.231 64.5565 145.422 80.4423 147.826 91.1357C151.669 92.6197 153.956 93.3671 153.982 93.5713C159.089 90.2256 164.943 87.8229 171.235 86.4022L172.303 79.211C175.662 56.373 159.821 35.2443 136.954 31.9198Z"
            fill="#191229"
          />
          <path
            d="M149.341 94.2393C177.982 82.203 184.781 85.5942 187.11 97.3338C189.438 109.073 180.881 116.487 158.631 123.771C136.38 131.054 149.341 94.2393 149.341 94.2393Z"
            fill="#F2A98A"
          />
          <path
            d="M153.226 109.537C158.16 103.043 165.261 98.7537 173.358 97.3296C175.614 96.9445 176.054 100.395 173.811 100.881C166.643 102.19 160.431 106.059 156.038 111.866C154.685 113.581 151.886 111.354 153.226 109.537Z"
            fill="#191229"
          />
          <path
            d="M83.1121 83.9348C84.6715 82.3716 87.5181 84.5159 85.8701 86.1953C81.471 90.6393 72.0297 102.647 82.5083 106.421C84.6675 107.188 83.2249 110.516 81.0657 109.749C76.6329 108.124 74.3418 103.814 74.3734 99.0977C74.5299 92.8996 79.0936 88.1201 83.1121 83.9348Z"
            fill="#191229"
          />
          <path
            d="M84.9091 157.776C95.1374 159.694 105.483 160.883 115.855 161.455C118.183 161.579 117.571 165.027 115.347 164.89C104.975 164.318 94.6167 163.029 84.4013 161.211C82.1384 160.772 82.6462 157.337 84.9091 157.776Z"
            fill="#191229"
          />
          <path
            d="M107.811 77.1564C110.036 78.2228 112.363 79.2761 114.587 80.3424C116.696 81.3197 115.236 84.616 113.127 83.6387C110.903 82.5723 108.576 81.519 106.351 80.4527C104.332 79.3603 105.69 76.0771 107.811 77.1564Z"
            fill="#191229"
          />
          <path
            d="M72.7896 66.7391L78.1249 67.5154C80.3672 67.8536 79.8675 71.3217 77.6251 70.9835L72.2899 70.2071C69.9574 69.9834 70.5473 66.4008 72.7896 66.7391Z"
            fill="#191229"
          />
          <path
            d="M86.621 125.148C87.9003 130.272 93.9979 133.853 99.0729 131.863C104.148 129.874 106.209 123.083 103.632 118.435C102.549 116.499 105.4 114.272 106.495 116.31C110.258 122.882 107.892 132.199 100.427 135.113C92.9622 138.026 84.78 132.843 83.0093 125.501C82.5309 123.385 86.1296 122.93 86.621 125.148Z"
            fill="#191229"
          />
          <path
            d="M71.6112 79.0404C67.1433 79.9236 68.448 86.9385 72.9159 86.0552C77.4991 85.2613 76.079 78.1571 71.6112 79.0404Z"
            fill="#191229"
          />
          <path
            d="M105.001 87.9453C100.533 88.8286 101.838 95.8433 106.306 94.9601C110.889 94.1661 109.482 87.1645 105.001 87.9453Z"
            fill="#191229"
          />
          <path
            d="M141.813 60.0488C142.399 70.6813 142.141 81.1089 141.063 91.5381C140.11 100.481 151.355 101.957 152.207 93.0271C153.297 82.7012 153.543 72.1705 152.957 61.538C152.46 52.4687 141.303 50.8762 141.813 60.0488Z"
            fill="#191229"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "base") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width={234} height={234} fill="#56D6A9" rx={117} />
          <path
            fill="#FFC422"
            d="m242.895 244.206-.701 3.564-2.076 10.076-269.748 18.631c10.494-25.635 29.182-47.976 50.867-65.775 32.165-26.244 76.198-33.584 116.73-29.65 37.766 3.658 78.165 28.785 100.86 57.616a112.259 112.259 0 0 1 4.068 5.538Z"
          />
          <path
            fill="#191229"
            d="M161.908 111.619c-2.942-1.917-8.179-1.453-9.91 2.108a1839.241 1839.241 0 0 1-9.472 19.577c-1.642 3.444-1.431 7.59 2.086 9.955 2.941 1.917 8.178 1.453 9.91-2.108 3.119-6.555 6.25-13.008 9.472-19.576 1.629-3.548 1.418-7.693-2.086-9.956Z"
          />
          <path
            fill="#F2A98A"
            d="M149.463 66.492c-.029-2.717-.16-5.42-.609-8.188-.052-.412-.218-.914-.269-1.326-.166-.501-.447-1.093-.561-1.183-.013-.103-.192-.707-.472-1.299-.255-.386-.497-.668-.752-1.054a38.37 38.37 0 0 0-1.134-1.632l-.343-.27c-.802-.628-3.31-3.338-4.429-3.194 2.443 1.359 2.8 1.731 1.349.872-.331-.166-.547-.243-.878-.41-.979-.396-1.971-.896-3.065-1.382-1.081-.383-2.06-.78-3.142-1.163-.33-.167-.763-.32-.992-.5-1.5-.433-3.267-.519-4.526-.67a98.999 98.999 0 0 0-13.603-1.38c-1.043-.074-2.073-.046-3.217-.107-2.846.368-2.554.226-1.538.094-1.754.018-3.521-.067-5.173-.063-7.716.266-15.494.853-23.184 1.325-4.555 5.716-7.723 12.614-8.817 20.394l-7.777 53.536c-2.391 16.425 4.928 32.011 17.589 40.733.256 10.326.412 20.666.668 30.992.345 18.477 20.906 31.723 37.479 29.58 12.608-1.631 21.341-9.458 25.839-19.771 1.837-4.319 2.58-9.124 2.306-13.798-1.378-20.959.663-41.837 4.242-62.81 2.054-12.509 3.682-29.253 9.128-42.724-6.866-1.204-14.196-6.953-14.119-14.602Z"
          />
          <path
            fill="#191229"
            d="m136.954 31.92-14.466-2.108c-14.889-2.158-29.061 3.79-38.14 14.488.232.178.579.446.823.726 14.473 16.114 38.585.5 55.183 11.987 10.877 7.544 5.068 23.43 7.472 34.123 3.843 1.484 6.13 2.231 6.156 2.435 5.107-3.345 10.961-5.748 17.253-7.169l1.068-7.191c3.359-22.838-12.482-43.967-35.349-47.291Z"
          />
          <path
            fill="#F2A98A"
            d="M149.341 94.24c28.641-12.037 35.44-8.646 37.769 3.094 2.328 11.739-6.229 19.153-28.479 26.437-22.251 7.283-9.29-29.532-9.29-29.532Z"
          />
          <path
            fill="#191229"
            d="M153.226 109.537c4.934-6.494 12.035-10.783 20.132-12.207 2.256-.385 2.696 3.065.453 3.551-7.168 1.309-13.38 5.178-17.773 10.985-1.353 1.715-4.152-.512-2.812-2.329ZM83.112 83.935c1.56-1.563 4.406.58 2.758 2.26-4.399 4.444-13.84 16.452-3.362 20.226 2.16.767.717 4.095-1.442 3.328-4.433-1.625-6.724-5.935-6.693-10.651.157-6.198 4.72-10.978 8.74-15.163ZM84.91 157.776c10.227 1.918 20.573 3.107 30.945 3.679 2.328.124 1.716 3.572-.508 3.435-10.372-.572-20.73-1.861-30.946-3.679-2.263-.439-1.755-3.874.508-3.435ZM107.811 77.156c2.225 1.067 4.552 2.12 6.776 3.186 2.109.978.649 4.274-1.46 3.297-2.224-1.067-4.551-2.12-6.776-3.186-2.019-1.093-.661-4.376 1.46-3.297ZM72.79 66.74l5.335.775c2.242.339 1.743 3.807-.5 3.469l-5.335-.777c-2.333-.224-1.743-3.806.5-3.468ZM86.621 125.148c1.28 5.124 7.377 8.705 12.452 6.715 5.075-1.989 7.136-8.78 4.559-13.428-1.083-1.936 1.768-4.163 2.863-2.125 3.763 6.572 1.397 15.889-6.068 18.803-7.465 2.913-15.647-2.27-17.418-9.612-.478-2.116 3.12-2.571 3.612-.353ZM71.611 79.04c-4.468.884-3.163 7.898 1.305 7.015 4.583-.794 3.163-7.898-1.305-7.015ZM105.001 87.945c-4.468.884-3.163 7.898 1.305 7.015 4.583-.794 3.176-7.795-1.305-7.015ZM141.813 60.049c.586 10.632.328 21.06-.75 31.49-.953 8.942 10.292 10.418 11.144 1.488 1.09-10.326 1.336-20.856.75-31.489-.497-9.07-11.654-10.662-11.144-1.49Z"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return null;
};
