import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField as MuiTextField } from "@mui/material";
import { WithSwitchAnimation } from "components/utils/utils";
import { useState, forwardRef, useCallback, useEffect } from "react";
import InputMask from "react-input-mask";

const TextField = forwardRef(
  (
    {
      value,
      className,
      helperText = null,
      placeholder = null,
      error = false,
      name,
      type = "text",
      index,
      InputProps,
      onChange,
      mask,
      maskChar,
      beforeMaskedStateChange,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isAutofilled, setIsAutofilled] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword((prevState) => !prevState);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const setType = (type, show) => {
      if (type === "password" && !show) {
        return "password";
      }
      if (type === "search") {
        return "text";
      }
      return "text";
    };

    const handleAutofill = useCallback((event) => {
      if (event.animationName === "mui-auto-fill") {
        setIsAutofilled(true);
      }
    }, []);

    useEffect(() => {
      setIsAutofilled(false);
    }, [value]);

    const getInputProps = () => {
      return {
        ...InputProps,
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <WithSwitchAnimation
                animationName="fade-password-btn"
                keyword={`${setType(name, showPassword)} ${showPassword}`}
              >
                <div>
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleMouseDownPassword}
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </div>
              </WithSwitchAnimation>
            </InputAdornment>
          ) : (
            InputProps?.endAdornment ?? null
          ),
      };
    };

    const onChangeFromFormValidate = onChange;
    if (mask && maskChar) {
      return (
        <>
          <InputMask
            mask={mask}
            value={value}
            maskChar={null}
            alwaysShowMask={false}
            onBlur={rest.onBlur}
            ref={ref}
            onChange={(event) => {
              if (typeof onChangeFromFormValidate === "function") {
                onChangeFromFormValidate.call(this, event);
              }
            }}
          >
            {() => (
              <MuiTextField
                {...rest}
                type={setType(name, showPassword)}
                name={name}
                className={`field textfield ${className ?? ""}`}
                data-empty={!value && !isAutofilled}
                error={!!error}
                placeholder={placeholder}
                helperText={error ? error?.message : helperText}
                InputProps={getInputProps()}
                onAnimationStart={handleAutofill}
              />
            )}
          </InputMask>
        </>
      );
    } else {
      return (
        <>
          <MuiTextField
            id="test"
            {...rest}
            value={value}
            type={setType(name, showPassword)}
            name={name}
            ref={ref}
            className={`field textfield ${window.chrome ? "chrome" : ""} ${className ?? ""}`}
            data-empty={!value && !isAutofilled}
            error={!!error}
            helperText={error ? error?.message : helperText}
            placeholder={placeholder}
            onChange={(event) => {
              if (typeof onChangeFromFormValidate === "function") {
                onChangeFromFormValidate.call(this, event);
              }
            }}
            onAnimationStart={handleAutofill}
            InputProps={getInputProps()}
          />
        </>
      );
    }
  }
);

export default TextField;
