import { Avatar } from "components/Avatar";
import { useSelector } from "react-redux";
import { selectProfileAvatar } from "../../pages/Profile/redux/profile-slice/selectors/profile.selectors";

const UserAvatar = () => {
  const avatar = useSelector(selectProfileAvatar);
  return <Avatar src={avatar.src} width={52} height={52} />;
};

export default UserAvatar;
