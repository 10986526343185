import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const WomanAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();

  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={234}
        height={234}
        viewBox="0 0 200 200"
        fill="none"
        {...props}
      >
        <g clipPath="url(#a)">
          <rect width={200} height={200} fill="#CFE2F4" rx={100} />
          <path
            fill="#176BD9"
            d="M-33.262 270.354c.125-5.018.188-9.974.313-14.929.69-37.95 19.132-70.568 44.286-91.582 15.807-1.004 31.677-1.568 47.547-1.443 26.158.188 53.068.376 79.1 4.767.376.377.815.753 1.192 1.192 21.076 22.206 33.872 54.887 37.322 90.83.753 7.903 1.13 15.995.941 24.15-1.442 76.402-2.948 152.804-4.391 229.269l-18.818-.565-154.435-4.579c-1.066-1.568-2.133-3.074-3.136-4.579-4.266-7.402-9.221-14.428-12.922-20.763-5.645-9.723-11.04-19.759-16.811-29.419-1.066-2.823-2.196-5.583-3.513-8.217l.377-17.627c.94-52.189 1.944-104.378 2.948-156.505Z"
          />
          <path
            fill="#0F1623"
            d="M24.008 108.704c1.881-2.571 4.453-4.579 6.774-6.711 7.527-6.712 8.594-10.79 10.413-20.763 3.324-18.505 10.726-22.394 29.42-27.35 16.747-4.453 36.067-8.154 51.185-.815 2.697-5.018 12.231-4.83 13.611 2.447 1.82 9.534 23.272 9.597 30.11 15.87 5.457 4.955 7.966 11.604 6.147 18.88-2.509 9.849-8.092 14.679-3.826 25.719 2.822 7.276 5.143 14.553 2.007 22.268-7.214 17.815-28.541 16.184-44.098 15.556-23.71-.878-44.912 3.45-68.749.753-17.062-1.944-34.25-5.143-37.762-22.895-1.505-8.029-.188-16.309 4.768-22.959Z"
          />
          <path
            fill="#F2A98A"
            d="M122.301 77.216c20.7-13.55 26.471-11.793 29.983-2.949 3.513 8.845.502 14.051-15.87 22.959-16.372 8.907-14.113-20.01-14.113-20.01Z"
          />
          <path
            fill="#F2A98A"
            d="M66.222 65.925c.502-.188 1.003-.313 1.505-.502 6.461-2.007 12.86-4.014 19.07-6.649 12.231-5.144 24.087-11.103 35.691-17.438 2.823 1.694 5.269 4.203 7.653 7.84 3.638 5.584 9.283 8.344 9.033 15.557l-1.506 43.157c-.439 13.235-8.531 24.338-19.822 29.419-1.631 8.092-3.324 16.121-4.955 24.213-2.948 14.427-21.14 21.829-34.061 17.814-12.42-3.889-18.442-14.803-18.567-26.533 4.892-17.501 6.46-35.441 6.649-53.758.188-9.66 1.191-22.143-.69-33.12Z"
          />
          <path
            fill="#0F1623"
            d="M106.745 30.045h.062c18.505.627 32.995 16.184 32.368 34.688l-.188 4.893c-7.716-17.187-24.213-23.774-32.242-39.581Z"
          />
          <path
            fill="#0F1623"
            d="M67.728 73.139c-2.321-.69-4.83-1.13-7.528-2.321l.251-8.908c.627-18.504 16.184-32.994 34.688-32.367l11.668.376c11.354.377 21.076 6.399 26.847 15.243-19.822 13.675-42.278 23.962-65.926 27.977Z"
          />
          <path
            fill="#F2A98A"
            d="M77.825 78.155c-20.7-13.55-26.47-11.793-29.984-2.948-3.512 8.844-.501 14.05 15.87 22.958 16.372 8.907 14.114-20.01 14.114-20.01Z"
          />
          <path
            fill="#0F1623"
            d="M72.56 89.7c-2.886-5.896-7.841-10.225-13.926-12.546-1.693-.627-2.571 2.07-.878 2.698 5.458 2.07 9.723 6.022 12.295 11.165.752 1.694 3.324.314 2.509-1.317ZM113.896 83.99c-1.003-1.506-3.575-.19-2.509 1.38 2.823 4.076 8.594 14.803-.251 16.308-1.819.314-1.129 3.074.69 2.76 3.701-.627 6.21-3.701 6.838-7.339.752-4.893-2.133-9.284-4.768-13.11ZM117.846 137.622c-8.217.439-16.434.314-24.652-.313-1.819-.126-1.756 2.697.063 2.885 8.217.627 16.435.753 24.652.314 1.819-.126 1.756-2.948-.063-2.886ZM91.939 70.003c-1.945.377-3.89.753-5.897 1.13-1.82.376-1.38 3.198.439 2.822 1.945-.376 3.89-.753 5.897-1.129 1.819-.376 1.38-3.2-.44-2.823ZM130.27 69.751l-4.266-.502c-1.819-.188-2.133 2.635-.313 2.823l4.265.502c1.819.25 2.133-2.572.314-2.823ZM128.575 79.41c3.325 1.568.878 6.712-2.446 5.144-3.325-1.568-.879-6.775 2.446-5.144ZM91.817 78.847c3.325 1.568.878 6.712-2.446 5.143-3.325-1.568-.878-6.712 2.446-5.143ZM139.674 80.853c1.129-1.255 2.196-2.51 3.325-3.827 1.192-1.38 3.199.628 2.007 2.008-1.129 1.254-2.195 2.509-3.324 3.826-1.192 1.38-3.2-.627-2.008-2.007ZM62.397 18.88c4.83-6.713 13.8-6.838 20.072-2.07 6.524 4.955 10.1 13.235 12.671 20.825 2.196 6.398-4.704 10.099-9.66 8.154-7.464-.125-15.243-1.066-20.825-6.523-5.52-5.395-6.838-13.989-2.258-20.387ZM91.122 108.265c.879 4.58 4.203 8.155 8.657 9.284 1.756.439 1.003 3.199-.753 2.76-5.395-1.38-9.597-5.833-10.664-11.291-.313-1.819 2.447-2.572 2.76-.753Z"
          />
          <path
            fill="#176BD9"
            d="M118.224 179.648c6.336-11.291 43.847-23.021 46.67-22.018 96.851 34.877 174.068 110.652 155.94 189.061-2.007 8.845-5.143 17.187-9.095 24.966-23.46-14.114-45.29-30.674-68.499-45.164.063-1.004.188-2.007.188-3.137.251-19.947-24.902-44.536-54.698-64.295-24.777-16.372-52.691-29.357-71.76-33.371 3.199-12.734-5.269-34.375 1.254-46.042ZM13.031 156.439c.753-.251 22.833 12.358 23.585 12.107 17 21.515 8.343 33.12 19.822 57.96a66.015 66.015 0 0 1-4.14 1.066c-22.519 4.579-57.772 21.955-85.372 42.78-22.582 16.999-40.02 36.257-41.4 52.629-24.463 12.922-49.241 25.216-74.144 37.26-1.317-3.764-2.509-7.59-3.387-11.605-18.755-80.918 63.92-158.951 165.036-192.197Z"
          />
          <path
            fill="#F2A98A"
            d="M149.776 152.614a305.332 305.332 0 0 1 15.118 5.081c-4.956 3.952-10.162 7.527-15.62 10.664-16.873 9.785-36.005 15.431-55.513 16.183-6.148.251-12.42 0-18.568-.752-.376-.063-.815-.063-1.192-.126-20.323-2.76-39.58-10.852-55.764-23.083a108.358 108.358 0 0 1-5.206-4.14c71.132-17.878 128.842-6.336 136.745-3.827Z"
          />
          <path
            fill="#0F1623"
            d="M109.444 164.343c1.129-4.265 4.579-21.515 5.645-25.781.439-1.756 3.199-1.003 2.76.753-1.129 4.265-4.579 21.515-5.645 25.781-.439 1.819-3.199 1.066-2.76-.753Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect width={200} height={200} fill="#fff" rx={100} />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "myAcademy") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width="234" height="234" rx="117" fill="#FFC422" />
          <path
            d="M-38.9188 316.313C-38.772 310.441 -38.6986 304.644 -38.5518 298.846C-37.7445 254.444 -16.1674 216.281 13.2624 191.695C31.757 190.52 50.3248 189.86 68.8928 190.007C99.4969 190.227 130.982 190.447 161.439 195.584C161.879 196.025 162.393 196.465 162.833 196.979C187.493 222.959 202.465 261.196 206.501 303.249C207.382 312.496 207.822 321.964 207.602 331.505C205.914 420.895 204.153 510.286 202.465 599.749L180.447 599.089L-0.241669 593.731C-1.48932 591.896 -2.73697 590.135 -3.91122 588.374C-8.90181 579.714 -14.6996 571.494 -19.0297 564.081C-25.6349 552.706 -31.9466 540.963 -38.6985 529.661C-39.9462 526.358 -41.2673 523.129 -42.8085 520.046C-42.6617 513.148 -42.5149 506.322 -42.3681 499.424C-41.2672 438.362 -40.093 377.301 -38.9188 316.313Z"
            fill="#FF6B00"
          />
          <path
            d="M28.0877 127.184C30.2895 124.175 33.2985 121.827 36.014 119.331C44.821 111.479 46.0685 106.708 48.1968 95.0389C52.0866 73.3885 60.7468 68.8383 82.6174 63.0404C102.213 57.8296 124.817 53.4996 142.504 62.0863C145.66 56.215 156.816 56.4352 158.43 64.9485C160.559 76.104 185.658 76.1774 193.658 83.5165C200.043 89.3144 202.979 97.0939 200.85 105.607C197.915 117.13 191.383 122.781 196.374 135.698C199.676 144.211 202.392 152.724 198.722 161.751C190.282 182.595 165.329 180.686 147.128 179.952C119.386 178.925 94.58 183.989 66.6914 180.833C46.729 178.558 26.6198 174.815 22.5099 154.045C20.7486 144.651 22.2898 134.964 28.0877 127.184Z"
            fill="#191229"
          />
          <path
            d="M143.091 90.3421C167.31 74.4896 174.062 76.5445 178.172 86.8927C182.281 97.2408 178.759 103.332 159.604 113.754C140.448 124.175 143.091 90.3421 143.091 90.3421Z"
            fill="#F2A98A"
          />
          <path
            d="M77.4786 77.1326C78.0657 76.9124 78.6528 76.7657 79.2399 76.5455C86.7992 74.197 94.285 71.8484 101.551 68.766C115.862 62.7479 129.733 55.7758 143.31 48.3633C146.613 50.3448 149.475 53.2805 152.264 57.5372C156.521 64.069 163.126 67.2982 162.832 75.7382L161.071 126.231C160.557 141.717 151.09 154.707 137.879 160.652C135.971 170.119 133.99 179.513 132.082 188.981C128.632 205.861 107.349 214.521 92.2301 209.824C77.6987 205.274 70.6532 192.503 70.5064 178.779C76.2309 158.303 78.0656 137.313 78.2858 115.883C78.506 104.581 79.6803 89.9761 77.4786 77.1326Z"
            fill="#F2A98A"
          />
          <path
            d="M124.889 35.1523H124.963C146.613 35.8863 163.566 54.0873 162.833 75.7376L162.612 81.4622C153.585 61.353 134.283 53.6469 124.889 35.1523Z"
            fill="#191229"
          />
          <path
            d="M79.2408 85.5728C76.5254 84.7655 73.5897 84.2517 70.4339 82.8573L70.7274 72.4357C71.4613 50.7854 89.6624 33.832 111.313 34.5659L124.964 35.0063C138.247 35.4466 149.623 42.4921 156.375 52.8403C133.183 68.8396 106.909 80.8757 79.2408 85.5728Z"
            fill="#191229"
          />
          <path
            d="M91.0542 91.4417C66.8351 75.5892 60.0831 77.6441 55.9732 87.9923C51.8633 98.3404 55.3861 104.432 74.5412 114.853C93.6963 125.275 91.0542 91.4417 91.0542 91.4417Z"
            fill="#F2A98A"
          />
          <path
            d="M84.8934 104.948C81.5174 98.0495 75.7196 92.9855 68.6006 90.27C66.6191 89.5361 65.5916 92.692 67.5732 93.4259C73.9582 95.8478 78.9487 100.471 81.9578 106.489C82.8385 108.471 85.8475 106.856 84.8934 104.948Z"
            fill="#191229"
          />
          <path
            d="M133.257 98.2678C132.083 96.5064 129.073 98.0476 130.321 99.8824C133.624 104.653 140.376 117.203 130.027 118.964C127.899 119.331 128.706 122.56 130.835 122.193C135.165 121.459 138.1 117.863 138.834 113.607C139.715 107.882 136.339 102.745 133.257 98.2678Z"
            fill="#191229"
          />
          <path
            d="M137.878 161.018C128.264 161.532 118.65 161.385 109.036 160.651C106.907 160.504 106.981 163.807 109.109 164.027C118.723 164.761 128.337 164.908 137.952 164.394C140.08 164.247 140.007 160.945 137.878 161.018Z"
            fill="#191229"
          />
          <path
            d="M107.567 81.9036C105.292 82.3439 103.017 82.7843 100.668 83.2246C98.54 83.665 99.0537 86.9676 101.182 86.5272C103.457 86.0869 105.732 85.6466 108.081 85.2062C110.209 84.7659 109.696 81.4632 107.567 81.9036Z"
            fill="#191229"
          />
          <path
            d="M152.413 81.6094L147.423 81.0223C145.294 80.8021 144.927 84.1047 147.056 84.3249L152.046 84.912C154.175 85.2055 154.542 81.9029 152.413 81.6094Z"
            fill="#191229"
          />
          <path
            d="M150.432 92.9106C154.322 94.7454 151.46 100.763 147.57 98.9287C143.68 97.0939 146.542 91.0024 150.432 92.9106Z"
            fill="#191229"
          />
          <path
            d="M107.424 92.2501C111.314 94.0849 108.452 100.103 104.562 98.2682C100.672 96.4334 103.534 90.4153 107.424 92.2501Z"
            fill="#191229"
          />
          <path
            d="M163.419 94.5977C164.74 93.1298 165.987 91.662 167.308 90.1208C168.703 88.5062 171.051 90.8547 169.657 92.4693C168.336 93.9371 167.088 95.405 165.767 96.9462C164.373 98.5608 162.024 96.2123 163.419 94.5977Z"
            fill="#191229"
          />
          <path
            d="M73.0039 22.0886C78.655 14.2358 89.1499 14.089 96.489 19.6667C104.122 25.4646 108.305 35.1522 111.314 44.0326C113.883 51.5185 105.81 55.8485 100.012 53.5734C91.2783 53.4266 82.1778 52.3258 75.646 45.9407C69.1876 39.6291 67.6463 29.5745 73.0039 22.0886Z"
            fill="#191229"
          />
          <path
            d="M106.613 126.672C107.64 132.029 111.53 136.212 116.741 137.534C118.796 138.047 117.915 141.276 115.86 140.763C109.548 139.148 104.631 133.937 103.383 127.552C103.016 125.424 106.246 124.543 106.613 126.672Z"
            fill="#191229"
          />
          <path
            d="M138.322 210.188C145.734 196.978 189.622 183.254 192.925 184.428C306.241 225.233 396.585 313.89 375.375 405.629C373.027 415.977 369.357 425.738 364.733 434.838C337.285 418.325 311.745 398.95 284.59 381.997C284.664 380.822 284.81 379.648 284.81 378.327C285.104 354.989 255.674 326.219 220.813 303.101C191.824 283.946 159.165 268.754 136.854 264.057C140.597 249.159 130.689 223.839 138.322 210.188Z"
            fill="#FF6B00"
          />
          <path
            d="M15.2446 183.034C16.1253 182.74 41.9589 197.492 42.8396 197.198C62.7286 222.371 52.6007 235.949 66.0312 265.012C64.49 265.452 62.8754 265.892 61.1874 266.259C34.8399 271.617 -6.40578 291.946 -38.6979 316.312C-65.1187 336.201 -85.5214 358.732 -87.136 377.887C-115.759 393.006 -144.748 407.39 -173.884 421.481C-175.426 417.078 -176.82 412.601 -177.848 407.904C-199.792 313.23 -103.062 221.931 15.2446 183.034Z"
            fill="#FF6B00"
          />
          <path
            d="M175.237 178.556C181.181 180.391 187.126 182.373 192.924 184.501C187.126 189.125 181.035 193.308 174.65 196.978C154.907 208.427 132.523 215.032 109.698 215.913C102.506 216.206 95.1668 215.913 87.9745 215.032C87.5341 214.958 87.0205 214.958 86.5801 214.885C62.8014 211.656 40.2704 202.188 21.3355 187.877C19.2805 186.336 17.2255 184.721 15.244 183.033C98.4695 162.117 165.989 175.621 175.237 178.556Z"
            fill="#F2A98A"
          />
          <path
            d="M128.048 192.281C129.369 187.29 133.406 167.107 134.653 162.117C135.167 160.062 138.396 160.943 137.882 162.998C136.561 167.988 132.525 188.171 131.277 193.161C130.763 195.29 127.534 194.409 128.048 192.281Z"
            fill="#191229"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "base") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width={234} height={234} fill="#FFC422" rx={117} />
          <path
            fill="#A650DB"
            d="M-38.919 316.313c.147-5.872.22-11.669.367-17.467.807-44.402 22.385-82.565 51.814-107.151 18.495-1.175 37.063-1.835 55.63-1.688 30.605.22 62.09.44 92.547 5.577.44.441.954.881 1.394 1.395 24.66 25.98 39.632 64.217 43.668 106.27.881 9.247 1.321 18.715 1.101 28.256-1.688 89.39-3.449 178.781-5.137 268.244l-22.018-.66-180.689-5.358c-1.247-1.835-2.495-3.596-3.67-5.357-4.99-8.66-10.788-16.88-15.118-24.293-6.605-11.375-12.917-23.118-19.669-34.42-1.247-3.303-2.568-6.532-4.11-9.615.147-6.898.294-13.724.44-20.622 1.102-61.062 2.276-122.123 3.45-183.111Z"
          />
          <path
            fill="#191229"
            d="M28.088 127.184c2.201-3.009 5.21-5.357 7.926-7.853 8.807-7.852 10.054-12.623 12.183-24.292 3.89-21.65 12.55-26.2 34.42-31.999 19.596-5.21 42.2-9.54 59.887-.954 3.156-5.871 14.312-5.65 15.926 2.862 2.129 11.156 27.228 11.23 35.228 18.569 6.385 5.797 9.321 13.577 7.192 22.09-2.935 11.523-9.467 17.174-4.476 30.091 3.302 8.513 6.018 17.026 2.348 26.053-8.44 20.844-33.393 18.935-51.594 18.201-27.742-1.027-52.548 4.037-80.437.881-19.962-2.275-40.071-6.018-44.181-26.788-1.761-9.394-.22-19.081 5.578-26.861Z"
          />
          <path
            fill="#F2A98A"
            d="M143.091 90.342c24.219-15.852 30.971-13.797 35.081-3.45 4.109 10.349.587 16.44-18.568 26.862-19.156 10.421-16.513-23.412-16.513-23.412Z"
          />
          <path
            fill="#F2A98A"
            d="M77.479 77.133c.587-.22 1.174-.367 1.76-.587 7.56-2.349 15.046-4.698 22.312-7.78 14.311-6.018 28.182-12.99 41.759-20.403 3.303 1.982 6.165 4.918 8.954 9.174 4.257 6.532 10.862 9.761 10.568 18.201l-1.761 50.493c-.514 15.486-9.981 28.476-23.192 34.421-1.908 9.467-3.889 18.861-5.797 28.329-3.45 16.88-24.733 25.54-39.852 20.843-14.531-4.55-21.577-17.321-21.724-31.045 5.725-20.476 7.56-41.466 7.78-62.896.22-11.302 1.394-25.907-.807-38.75Z"
          />
          <path
            fill="#191229"
            d="M124.889 35.152h.074c21.65.734 38.603 18.935 37.87 40.586l-.221 5.724c-9.027-20.109-28.329-27.815-37.723-46.31Z"
          />
          <path
            fill="#191229"
            d="M79.24 85.573c-2.715-.807-5.65-1.321-8.806-2.716l.293-10.421c.734-21.65 18.935-38.604 40.586-37.87l13.651.44c13.283.44 24.659 7.486 31.411 17.834-23.192 16-49.466 28.036-77.134 32.733Z"
          />
          <path
            fill="#F2A98A"
            d="M91.054 91.442c-24.219-15.853-30.97-13.798-35.08-3.45-4.11 10.348-.588 16.44 18.567 26.861 19.155 10.422 16.513-23.411 16.513-23.411Z"
          />
          <path
            fill="#191229"
            d="M84.893 104.948C81.517 98.05 75.72 92.986 68.601 90.27c-1.982-.734-3.01 2.422-1.028 3.156 6.385 2.422 11.376 7.045 14.385 13.063.88 1.982 3.89.367 2.935-1.541ZM133.257 98.268c-1.174-1.762-4.184-.22-2.936 1.614 3.303 4.771 10.055 17.321-.294 19.082-2.128.367-1.321 3.596.808 3.229 4.33-.734 7.265-4.33 7.999-8.586.881-5.725-2.495-10.862-5.577-15.34ZM137.878 161.018c-9.614.514-19.228.367-28.842-.367-2.129-.147-2.055 3.156.073 3.376 9.614.734 19.228.881 28.843.367 2.128-.147 2.055-3.449-.074-3.376ZM107.567 81.904c-2.275.44-4.55.88-6.899 1.32-2.128.441-1.614 3.744.514 3.303 2.275-.44 4.55-.88 6.899-1.32 2.128-.441 1.615-3.744-.514-3.303ZM152.413 81.61l-4.99-.588c-2.129-.22-2.496 3.083-.367 3.303l4.99.587c2.129.293 2.496-3.01.367-3.303ZM150.432 92.91c3.89 1.835 1.028 7.853-2.862 6.019-3.89-1.835-1.028-7.927 2.862-6.018ZM107.424 92.25c3.89 1.835 1.028 7.853-2.862 6.018s-1.028-7.853 2.862-6.018ZM163.419 94.598c1.321-1.468 2.568-2.936 3.889-4.477 1.395-1.615 3.743.734 2.349 2.348-1.321 1.468-2.569 2.936-3.89 4.477-1.394 1.615-3.743-.734-2.348-2.348ZM73.004 22.089c5.651-7.853 16.146-8 23.485-2.422 7.633 5.798 11.816 15.485 14.825 24.366 2.569 7.486-5.504 11.816-11.302 9.54-8.734-.146-17.834-1.247-24.366-7.632-6.458-6.312-8-16.367-2.642-23.852ZM106.613 126.672c1.027 5.357 4.917 9.54 10.128 10.862 2.055.513 1.174 3.742-.881 3.229-6.312-1.615-11.229-6.826-12.477-13.211-.367-2.128 2.863-3.009 3.23-.88Z"
          />
          <path
            fill="#A650DB"
            d="M138.322 210.188c7.412-13.21 51.3-26.934 54.603-25.76 113.316 40.805 203.66 129.462 182.45 221.201-2.348 10.348-6.018 20.109-10.642 29.209-27.448-16.513-52.988-35.888-80.143-52.841.074-1.175.22-2.349.22-3.67.294-23.338-29.136-52.108-63.997-75.226-28.989-19.155-61.648-34.347-83.959-39.044 3.743-14.898-6.165-40.218 1.468-53.869ZM15.245 183.034c.88-.294 26.714 14.458 27.595 14.164 19.889 25.173 9.76 38.751 23.191 67.814-1.541.44-3.156.88-4.844 1.247-26.347 5.358-67.593 25.687-99.885 50.053-26.42 19.889-46.823 42.42-48.438 61.575-28.623 15.119-57.612 29.503-86.748 43.594-1.542-4.403-2.936-8.88-3.964-13.577-21.944-94.674 74.786-185.973 193.093-224.87Z"
          />
          <path
            fill="#F2A98A"
            d="M175.237 178.556a357.989 357.989 0 0 1 17.687 5.945c-5.798 4.624-11.889 8.807-18.274 12.477-19.743 11.449-42.127 18.054-64.952 18.935-7.192.293-14.531 0-21.723-.881-.44-.074-.954-.074-1.395-.147-23.779-3.229-46.31-12.697-65.245-27.008a125.588 125.588 0 0 1-6.091-4.844c83.225-20.916 150.745-7.412 159.993-4.477Z"
          />
          <path
            fill="#191229"
            d="M128.048 192.281c1.321-4.991 5.358-25.174 6.605-30.164.514-2.055 3.743-1.174 3.229.881-1.321 4.99-5.357 25.173-6.605 30.163-.514 2.129-3.743 1.248-3.229-.88Z"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return null;
};
