import { normalizeISODate, secondsToHms } from "app/utils";
import classNames from "classnames";
import { Badge } from "components/Badge";
import { CourseTypeTile } from "components/CourseTypeTile";
import { CourseDurationIcon } from "components/Svg/Icons";
import { Subtitle } from "components/Typography/Titles";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import cardHeadMock from "../../../../assets/img/course-card-head.svg";
import cardHeadMockMyAcademy from "../../../../assets/img/myacademy_course-card-head.svg";
import cardHeadMockRostechnadzor from "../../../../assets/img/rostechnadzor_course-card-head.svg";
import styles from "./CourseCard.module.scss";

interface CourseCardProps {
  imageSrc?: string | null;
  title: string | null;
  description: string | null;

  duration: string | null;
  startDate: Date | null;
  format: CourseFormat;
  progress?: number;
  onClick: () => void;
}

const courseDuration = (duration: string | null) => {
  return (
    <>
      <CourseDurationIcon
        className={classNames(styles["duration-icon"], {
          [styles["duration-icon__myacademy_theme"]]: process.env.REACT_APP_THEME === "myAcademy",
          [styles["duration-icon__rostechnadzor_theme"]]:
            process.env.REACT_APP_THEME === "rostechnadzor",
        })}
      />
      <p
        className={classNames(styles["duration-label"], {
          [styles["duration-label__myacademy_theme"]]: process.env.REACT_APP_THEME === "myAcademy",
          [styles["duration-label__rostechnadzor_theme"]]:
            process.env.REACT_APP_THEME === "rostechnadzor",
        })}
      >
        {secondsToHms(duration)}
      </p>
    </>
  );
};

const getDefaultImage = () => {
  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return cardHeadMockRostechnadzor;
  }
  if (process.env.REACT_APP_THEME === "myAcademy") {
    return cardHeadMockMyAcademy;
  }
  if (process.env.REACT_APP_THEME === "base") {
    return cardHeadMock;
  }
};

export const CourseCard = ({
  imageSrc,
  title,
  description,
  startDate,
  duration,
  format,
  progress,
  onClick = () => {},
}: CourseCardProps) => {
  return (
    <div
      className={classNames(styles["container"], styles[`enrolled-status_not_started`], {
        [styles["enrolled-status_not_started__myacademy_theme"]]:
          process.env.REACT_APP_THEME === "myAcademy",
      })}
      onClick={onClick}
    >
      <div
        className={classNames(styles["head"], {
          [styles["head__myacademy_theme"]]: process.env.REACT_APP_THEME === "myAcademy",
          [styles["head__rostechnadzor_theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
        })}
        style={{ backgroundImage: imageSrc ? `url(${imageSrc})` : undefined }}
      >
        {progress && (
          <div className={styles["progress-wrapper"]}>
            <div
              className={classNames(styles["progress"], {
                [styles["progress_rostechnadzor"]]: process.env.REACT_APP_THEME === "rostechnadzor",
              })}
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
        <div className={styles["head_blur"]}></div>
        <img
          className={classNames(styles["head_image"], {
            [styles["head_image_max-height"]]: !!startDate,
          })}
          src={imageSrc ? imageSrc : getDefaultImage()}
          alt="Course preview"
        />
        {startDate && (
          <div className={styles["head-block"]}>
            <div
              className={classNames(styles["startDate"], {
                [styles["startDate_rostechnadzor"]]:
                  process.env.REACT_APP_THEME === "rostechnadzor",
              })}
            >
              <p>{format === CourseFormat.meeting ? "Пройдет" : "Стартует"}</p>
              <time dateTime={normalizeISODate(startDate)}>{normalizeISODate(startDate)}</time>
            </div>
          </div>
        )}
      </div>

      <div className={styles["body"]}>
        <div className={styles["body-info"]}>
          <CourseTypeTile
            type={format}
            className={classNames({
              [styles["course-type-tile_rostechnadzor"]]:
                process.env.REACT_APP_THEME === "rostechnadzor",
            })}
          />

          <Subtitle className={styles["title"]} title={title}>
            {title}
          </Subtitle>
          <p className={styles["description"]} title={description || undefined}>
            {description}
          </p>
        </div>
        {duration && (
          <Badge
            className={classNames(styles["duration"], {
              [styles["duration_my-academy"]]: process.env.REACT_APP_THEME === "myAcademy",
              [styles["duration_rostechnadzor"]]: process.env.REACT_APP_THEME === "rostechnadzor",
            })}
          >
            {courseDuration(duration)}
          </Badge>
        )}
      </div>
    </div>
  );
};
