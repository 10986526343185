import { Dispatch } from "@reduxjs/toolkit";
import { generateUid } from "app/utils";
import { Button } from "components/Buttons/Button";
import { TextMain } from "components/Typography/Texts";
import { H1 } from "components/Typography/Titles";
import { notificationSettingsSelector } from "pages/Settings/redux";
import { NotificationSetting } from "pages/Settings/redux/interfaces";
import {
  getNotificationSettingsThunkAction,
  saveNotificationSettingsThunkAction,
} from "pages/Settings/redux/thunkActions";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NotificationSettingsRus } from "./enums";
import { Checkbox } from "components/Checkbox";
import { useNotification } from "components/Notification";

export const NotificationSettings = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { showNotification } = useNotification();

  const { control, getValues, setValue } = useForm();

  const notificationSettings = useSelector(notificationSettingsSelector);

  const notificationSettingsRus: { [key: string]: string } = NotificationSettingsRus;

  useEffect(() => {
    dispatch(getNotificationSettingsThunkAction());
  }, [dispatch, getValues]);

  useEffect(() => {
    if (notificationSettings.length > 0) {
      notificationSettings.map(({ code, value }) => setValue(code, value));
    }
  }, [notificationSettings, setValue]);

  const handleSaveChangesButton = () => {
    const formValues = getValues();
    const changedNotificationSettings: NotificationSetting[] = [];
    for (const key in formValues) {
      changedNotificationSettings.push({
        code: key,
        value: formValues[key] ?? false,
      });
    }

    dispatch(
      saveNotificationSettingsThunkAction({
        settings: changedNotificationSettings,
        action: showNotification,
      })
    );
  };

  return (
    <>
      <section className="settings-section">
        <H1 className="">Уведомления</H1>
        <TextMain className="settings-subtitle">
          Присылать мне уведомления на электронную почту:
        </TextMain>
        <ul className="settings-list">
          {notificationSettings.map(({ code, value: defaultValue }) => (
            <li className="settings-list-item" key={generateUid()}>
              <div className="settings-list-item__widget">
                <Controller
                  name={code}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox onChange={onChange} value={value || defaultValue} />
                  )}
                />
              </div>
              <TextMain className="settings-list-item__text">
                {notificationSettingsRus[code]}
              </TextMain>
            </li>
          ))}
        </ul>
        <Button color="primary" onClick={handleSaveChangesButton}>
          Сохранить изменения
        </Button>
      </section>
    </>
  );
};
