import { Button } from "components/Buttons";
import { ProfileModalPic } from "components/Svg/Pics";
import { TextMain } from "components/Typography/Texts";
import { H2 } from "components/Typography/Titles";
import styles from "../../Orders.module.scss";

interface ConfirmModalProps {
  orderNumber: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmModal = ({ orderNumber, onConfirm, onCancel }: ConfirmModalProps) => (
  <div className="modal-container">
    <div className="modal-content">
      <div className="modal-img">
        <ProfileModalPic />
      </div>
      <div className="modal-text">
        <H2 className="modal-text__title">Отменить заказ №{orderNumber} ?</H2>
        <TextMain className="modal-text__descr">
          Если вы отмените заказ, то мы вернем Вам стоимость товара
        </TextMain>
      </div>
    </div>
    <div className={styles["modal-buttons"]}>
      <Button size="big" className="modal__button" color="primary" onClick={onCancel}>
        Нет
      </Button>
      <Button size="big" className="modal__button" onClick={onConfirm}>
        Да
      </Button>
    </div>
  </div>
);
