import { ChangeEvent, useState, Ref, useEffect, forwardRef, KeyboardEvent } from "react";
import classNames from "classnames";
import styles from "./TextAreaField.module.scss";
import labelStyles from "./Label.module.scss";
import textAreaStyles from "./TextArea.module.scss";
import errorMessageStyles from "./ErrorMessage.module.scss";

interface TextAreaFieldProps {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  invalid?: boolean;
  label?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => string;
}

export const TextAreaField = forwardRef(
  (
    {
      className = "",
      disabled = false,
      errorMessage = "",
      invalid = false,
      label = "",
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      placeholder = "",
      maxLength,
      onKeyDown,
      value = "",
    }: TextAreaFieldProps,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const [currentValue, setCurrentValue] = useState<string>("");
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleChangeEvent = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const textareaValue = event.currentTarget.value;
      onChange(textareaValue);
      setCurrentValue(textareaValue);
    };

    const handleFocus = () => {
      setIsFocused(true);
      onFocus();
    };

    const handleBlur = () => {
      setIsFocused(false);
      onBlur();
    };

    useEffect(() => setCurrentValue(value), [value]);

    return (
      <>
        <div
          className={classNames(
            styles["container"],
            {
              [styles["focused"]]: isFocused,
              [styles["invalid"]]: invalid,
              [styles["filled"]]: currentValue,
              [styles["disabled"]]: disabled,
              [styles["disabled-filled"]]: currentValue,
            },
            className
          )}
        >
          {label && (
            <label
              className={classNames(labelStyles["label"], {
                [labelStyles["raised-label"]]: isFocused || currentValue,
              })}
            >
              {label}
            </label>
          )}

          <div className={styles["textarea-container"]}>
            <div className={styles["pseudo-content"]}>
              <span>{currentValue}</span>
              &nbsp;
            </div>
            <textarea
              placeholder={isFocused || !label ? placeholder : ""}
              value={currentValue}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={textAreaStyles["textarea"]}
              onChange={handleChangeEvent}
              disabled={disabled}
              maxLength={maxLength}
              ref={ref}
              onKeyDown={(e) => {
                if (!onKeyDown) return;
                const newText = onKeyDown(e);
                onChange(newText);
                setCurrentValue(newText);
              }}
            ></textarea>
          </div>
        </div>
        {invalid && errorMessage && (
          <p className={errorMessageStyles["error-message"]}>{errorMessage}</p>
        )}
      </>
    );
  }
);
