import { useAppSelector } from "hooks/redux";
import { H2 } from "components/Typography/Titles";
import { numWord } from "app/utils";
import { generateUid } from "../../../../app/utils/generateUid.util";
import { selectMulticourseContent } from "pages/Course/redux/courseSelectors";
import { MulticourseContentCard } from "components/MulticourseContentCard";
import styles from "./MulticourseContentList.module.scss";

export const MulticourseContentList = (): JSX.Element => {
  const multicourseContent = useAppSelector(selectMulticourseContent);

  return (
    <>
      {multicourseContent && multicourseContent?.length > 0 && (
        <div className={styles["container"]}>
          <H2 className={styles["title"]}>
            Программа обучения состоит из {multicourseContent.length}{" "}
            {numWord(multicourseContent.length, ["курса", "курсов", "курсов"])}:
          </H2>
          {multicourseContent.map((course) => (
            <MulticourseContentCard
              course={course}
              className={styles["course"]}
              key={generateUid()}
            />
          ))}
        </div>
      )}
    </>
  );
};
