import Container from "../../../../components/Container/Container";
import { H1 } from "../../../../components/Typography/Titles";
import { CourseBookPrestartImg } from "../../../../components/Svg/Pics";
import { Button } from "../../../../components/Buttons";
import CourseTestContentHeader from "../../../Course/components/CourseTestContentHeader";
import Alert from "../../../../components/Alert/Alert";
import { setCourseLessonFinish, setCourseLessonStart } from "../../redux/courseSlice";
import { LessonDescription } from "../LessonDescription";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { CourseLessonBook } from "../../redux/interfaces/course.interface";
import { AchievementsListLesson } from "../AchievementsListLesson";
import { LessonCompetenciesList } from "../LessonCompetenciesList";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { Capacitor } from "@capacitor/core";

const BookStart = ({ lesson }: { lesson: CourseLessonBook }) => {
  const dispatch = useAppDispatch();
  const selectedLmsSettings = useAppSelector(lmsSettings);

  const handlerStartBook = (lesson: CourseLessonBook) => {
    window.open(lesson.url);
    if (!lesson.start_ts) {
      dispatch(setCourseLessonStart(lesson.id)).then(() => {
        if (!lesson.end_ts) {
          dispatch(setCourseLessonFinish(lesson.id));
        }
      });
    }
  };

  return (
    <>
      <Container>
        <div className="course-test-block">
          <div className="course-test-content course-sheet-content">
            <CourseTestContentHeader fixed={true} status={lesson.status} coins={lesson.coins}>
              <H1 className="course-test-top__title">{lesson.title}</H1>
            </CourseTestContentHeader>

            <AchievementsListLesson />
            {selectedLmsSettings.competences && !Capacitor.isNativePlatform() && (
              <LessonCompetenciesList id={lesson.id} />
            )}
            <LessonDescription stringHTML={lesson?.description} />

            <div className="course-test-buttons">
              <Button
                size="big"
                color="primary"
                className="book__button course-test__button"
                onClick={() => {
                  handlerStartBook(lesson);
                }}
              >
                Начать
              </Button>
              <Alert>
                {
                  "Интерактивный учебник будет открыт в соседней вкладке. Убедитесь, что браузер разрешает открывать всплывающие окна. "
                }
              </Alert>
            </div>
          </div>
          <div className="course-test-img course-test-main-img book-img">
            <CourseBookPrestartImg />
          </div>
        </div>
      </Container>
    </>
  );
};

export default BookStart;
