import { EducationStudioLogo, RostechnadzorShortLogo, LanitLogo } from "components/Logos";
import UrlContext from "contexts/urlcontext";
import { useMediaQuery } from "hooks";
import { AuthTypes } from "pages/Authorization/authRoutes.congig";
import { useContext } from "react";
import { AuthorizationIllustration } from "../AuthorizationIllustration";
import { SupportRow } from "../SupportRow/SupportRow";
import classNames from "classnames";
import styles from "./AuthorizationLeftContent.module.scss";

export const AuthorizationLeftContent = (): JSX.Element => {
  const authContext = useContext(UrlContext);

  const isMob = useMediaQuery(`(max-width:840px)`);

  const handleContent = (context: any) => {
    switch (context) {
      case AuthTypes.REGISTRATION_SECOND_STEP:
      default:
        return <AuthorizationIllustration />;
    }
  };

  return (
    <div
      className={classNames(styles["left-content"], {
        [styles["myacademy"]]: process.env.REACT_APP_THEME === "myAcademy",
        [styles["rostechnadzor-left-content"]]: process.env.REACT_APP_THEME === "rostechnadzor",
      })}
    >
      {process.env.REACT_APP_THEME === "rostechnadzor" && (
        <div>
          <RostechnadzorShortLogo className={styles["rostechnadzor-logo"]} />
        </div>
      )}
      {process.env.REACT_APP_THEME === "myAcademy" && (
        <LanitLogo className={styles["my-academy-logo"]} />
      )}
      {process.env.REACT_APP_THEME === "base" && <EducationStudioLogo className={styles["logo"]} />}

      <div
        className={classNames(styles["text"], {
          [styles["text_rostechnadzor"]]: process.env.REACT_APP_THEME === "rostechnadzor",
        })}
      >
        <div className={styles["title"]}>
          {process.env.REACT_APP_THEME === "rostechnadzor" && "Ростехнадзор"}
          {process.env.REACT_APP_THEME === "myAcademy" && "MyAcademy"}
          {process.env.REACT_APP_THEME === "base" && "Education Studio"}
        </div>
        <div className={styles["subtitle"]}>
          {process.env.REACT_APP_THEME !== "myAcademy"
            ? "Заходи скорее и начинай обучение!"
            : "Добро пожаловать в Корпоративный онлайн-университет ЛАНИТ!"}{" "}
        </div>
        <div className={styles["svg-container"]}>{handleContent(authContext)}</div>
      </div>
      {!isMob && <SupportRow />}
    </div>
  );
};
