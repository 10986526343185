import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api/api";
import { EndPoints } from "../../../../../../app/endpoints";
import { ViewedInfoResponse, ViewedInfoRequest } from "../interfaces";

export const getMeetingViewedInfo = createAsyncThunk(
  "course-meeting/getMeetingViewedInfo",
  async (meetingId: number) =>
    (await api.get<ViewedInfoResponse>(EndPoints.COURSE_MEETING_VIEW_INFO(meetingId))).data
);

export const postMeetingViewedInfo = createAsyncThunk(
  "course-meeting/postMeetingViewedInfo",
  async (info: ViewedInfoRequest & { meetingId: number }) => {
    const { meetingId, ...request } = info;
    const response = await api.post(EndPoints.COURSE_MEETING_VIEW_INFO(meetingId), request);
    return response.data;
  }
);
