import { EndPoints } from "app/endpoints";
import { Badge } from "components/Badge";
import { useModal } from "components/ModalsContainer";
import { PriceParagraph } from "components/PriceParagraph";
import { Scrollbar } from "components/Scrollbar";
import { Subtitle } from "components/Typography/Titles";
import { useAppSelector } from "hooks/redux";
import { IShopProductFull } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import { selectShopClub } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useMemo } from "react";
import { BuyAgreementModal } from "../BuyAgreementModal/BuyAgreementModal";
import { BuyButton } from "../BuyButton/BuyButton";
import { ProductSlider } from "../ProductSlider/ProductSlider";
import styles from "./ShopProductModal.module.scss";
import "./ShopProductModal.scss";
interface ShopProductModalProps {
  product: IShopProductFull;
}

export const ShopProductModal = ({ product }: ShopProductModalProps) => {
  const { openModal, closeModal } = useModal();
  const club = useAppSelector(selectShopClub(product.club_id || ""));

  const sortedPictures = useMemo(
    () => [...product.pictures].sort((x, y) => Number(y.is_main) - Number(x.is_main)),
    [product.pictures]
  );

  const sliderSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 0,
    arrows: false,
    customPaging: function (i: number) {
      return (
        <div>
          <img alt={product.name + i} src={`${EndPoints.SHOP_FILES}/${sortedPictures[i].id}`} />
        </div>
      );
    },
    appendDots: (dots: JSX.Element[]) => {
      return <div>{dots}</div>;
    },
  };

  const onBuy = () => {
    openModal({
      content: (
        <BuyAgreementModal
          id={product.id}
          price={product.price}
          name={product.name}
          onBuy={closeModal}
          clubId={product.club_id}
        />
      ),
      clickOutsideClose: false,
    });
  };

  return (
    <Scrollbar style={{ width: "calc(100% + 15px)" }}>
      <div className={styles["modal"]}>
        <div className={styles["modal-img-container"]}>
          <ProductSlider
            className={"shop-product-slider"}
            settings={sliderSettings}
            pictures={sortedPictures}
          />
        </div>
        <div className={styles["modal-info-container"]}>
          <div className={styles["modal-info"]}>
            {club && (
              <Badge type="result" color="accent" className={styles["modal-info-badge"]}>
                {club.name}
              </Badge>
            )}
            <Subtitle className={styles["modal-title"]} title={product.name}>
              {product.name}
            </Subtitle>
            <p className={styles["modal-text"]} title={product.description}>
              {product.description}
            </p>
            <div className={styles["modal-price"]}>
              {product.price && <PriceParagraph price={product.price} />}
              <BuyButton
                buttonClassName={styles["modal-button"]}
                itemPrice={product.price}
                itemClub={product.club_id}
                onClick={onBuy}
              />
            </div>
          </div>
          {product.how_receive && (
            <div className={styles["modal-receive"]}>
              <p className={styles["modal-receive-title"]}>Как получить товар</p>
              <p>{product.how_receive}</p>
            </div>
          )}
        </div>
      </div>
    </Scrollbar>
  );
};
