import { createContext, useState } from "react";
import BookStart from "./BookStart";

export const CManualMenuContext = createContext();

const CourseBook = ({ lesson }) => {
  const [isActiveMenu, setIsActiveMenu] = useState(false);

  const toggleMenu = () => {
    setIsActiveMenu(!isActiveMenu);
  };

  return (
    <CManualMenuContext.Provider value={{ isActiveMenu, toggleMenu }}>
      <BookStart lesson={lesson} />
    </CManualMenuContext.Provider>
  );
};
export default CourseBook;
