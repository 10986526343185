import { FC, useState } from "react";
import {
  CatAvatar,
  DogAvatar,
  ManAvatar,
  ManWithBeardAvatar,
  ManWithSmileAvatar,
  ManWithWinkAvatar,
  ParrotAvatar,
  RobotAvatar,
  WomanAvatar,
  WomanGlassesAvatar,
  WomanWithHeaddressAvatar,
  WomanWithHeadphoneAvatar,
} from "../Avatars";
import { Skeleton } from "../Skeleton";

interface AvatarProps {
  src: string;
  type?: "preset" | "image" | string;
  className?: string;

  onError?: () => void;

  width?: number;

  height?: number;
}

const PresetAvatar = ({
  name,
  className,
  width,
  height,
}: {
  name: string;
  className?: string;
  width?: number;
  height?: number;
}) => {
  switch (parseInt(name)) {
    case 0:
      return <WomanGlassesAvatar className={className} width={width} height={height} />;
    case 1:
      return <ManWithSmileAvatar className={className} width={width} height={height} />;
    case 2:
      return <WomanAvatar className={className} width={width} height={height} />;
    case 3:
      return process.env.REACT_APP_THEME === "rostechnadzor" ? (
        <ManAvatar className={className} width={width} height={height} />
      ) : (
        <CatAvatar className={className} width={width} height={height} />
      );
    case 4:
      return process.env.REACT_APP_THEME === "rostechnadzor" ? (
        <ManWithWinkAvatar className={className} width={width} height={height} />
      ) : (
        <ManWithBeardAvatar className={className} width={width} height={height} />
      );
    case 5:
      return <WomanWithHeaddressAvatar className={className} width={width} height={height} />;
    case 6:
      return <DogAvatar className={className} width={width} height={height} />;
    case 7:
      return <ManAvatar className={className} width={width} height={height} />;
    case 8:
      return <ParrotAvatar className={className} width={width} height={height} />;
    case 9:
      return <WomanWithHeadphoneAvatar className={className} width={width} height={height} />;
    case 10:
      return <ManWithWinkAvatar className={className} width={width} height={height} />;
    case 11:
      return <RobotAvatar className={className} width={width} height={height} />;
    case 12:
      return <CatAvatar className={className} width={width} height={height} />;
    default:
      return <Skeleton variant="circular" width="100%" height="100%" />;
  }
};

export const ImagesAvatar = ({
  src,
  className,
  width,
  height,
}: {
  src: string;
  className?: string;
  width?: number;
  height?: number;
}) => {
  const [error, setError] = useState<boolean>(false);

  return !error ? (
    <img
      src={src}
      alt=""
      onError={() => setError(true)}
      className={className}
      style={{ objectFit: "cover" }}
    />
  ) : (
    <PresetAvatar name="3" width={width} height={height} />
  );
};

export const Avatar: FC<AvatarProps> = ({ src, type = "preset", className, width, height }) => {
  return process.env.REACT_APP_THEME === "myAcademy" ? (
    <PresetAvatar name="3" width={width} height={height} />
  ) : type === "preset" ? (
    <PresetAvatar name={src} className={className} width={width} height={height} />
  ) : (
    <ImagesAvatar src={src} className={className} />
  );
};
