import { H3 } from "../../../../../components/Typography/Titles";
import { TextMain } from "../../../../../components/Typography/Texts";
import { useDispatch } from "react-redux";
import { setQuestionnaireQuestion } from "../redux/Questionary/courseQuestionarySlice";

const QuestGrade = ({ question }) => {
  const dispatch = useDispatch();

  const { title = "", options = [], answer = [] } = question;

  const handleClick = (value) => {
    dispatch(
      setQuestionnaireQuestion({
        question: { ...question, answer: value },
        valid: question.required ? (value ? true : false) : true,
      })
    );
  };

  return (
    <>
      <H3 className="course-sheet-test-block__title">{title}</H3>
      <div className="course-sheet-test-block-content">
        <div className="course-sheet-test-block-content-wrapper">
          <ul className="course-sheet-list">
            <TextMain className="course-sheet-test-block__grade course-sheet-test-block-badgrade course-sheet-list-badgrade"></TextMain>
            <ul className="course-sheet-test-list">
              {options.map((option, index) => (
                <li
                  key={`${index}_${option.value}`}
                  className={
                    answer === option.value
                      ? "course-sheet-test-list__item course-sheet-test-list__item--active"
                      : "course-sheet-test-list__item"
                  }
                  onClick={() => {
                    handleClick(option.value);
                  }}
                >
                  {option.label}
                </li>
              ))}
            </ul>
            <TextMain className="course-sheet-test-block__grade course-sheet-test-block-goodgrade course-sheet-list-goodgrade"></TextMain>
          </ul>
        </div>
      </div>
    </>
  );
};

export default QuestGrade;
