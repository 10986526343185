import { ScrollbarContainerContext } from "components/ScrollbarContainer/context";
import { useVideoView } from "hooks/useVideoView/useVideoView";
import _ from "lodash";
import { ViewedInfoData, ViewedVideoInfo } from "pages/Course/redux/interfaces/course.interface";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/redux";
import {
  getCoursePageContent,
  getCoursePageViewedInfo,
  postCoursePageViewed,
  postCoursePageViewedInfo,
  setCourseLessonStart,
} from "../../redux/courseSlice";
import { CourseLessonText } from "../../redux/interfaces";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import CourseText from "./CourseText";

const CourseTextIndex = ({ lesson }: { lesson: CourseLessonText }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { scrollInfo, scrollTo } = useContext(ScrollbarContainerContext);
  const { course_id } = useParams();

  const [pageContent, setPageContent] = useState<string>("");
  const [isLoadData, setIsLoadData] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timer>();
  const counterRef = useRef<number>(0);

  const viewedInfoRef = useRef<Omit<ViewedInfoData, "videos">>({
    position: 0,
    time: 0,
  });

  const finishedDataRef = useRef<{
    scroll: boolean;
    fullPage: boolean;
  }>({
    scroll: false,
    fullPage: false,
  });

  const videosInfoRef = useRef<{
    finished: Set<string>;
    state: ViewedVideoInfo[];
  }>({
    finished: new Set(),
    state: [],
  });

  const scrollRestoredRef = useRef<{
    value: number;
    restored: boolean;
  }>({
    value: 0,
    restored: false,
  });

  useEffect(() => {
    return () => {
      setIsLoadData(false);
    };
  }, []);

  useEffect(() => {
    let pegeContentResponse: any = null;
    pegeContentResponse = dispatch(getCoursePageContent(lesson.id));

    // @ts-ignore
    pegeContentResponse.then((result) => {
      if (result.payload?.data) {
        setPageContent(result.payload?.data);
      }
    });
    return () => {
      if (pegeContentResponse) {
        pegeContentResponse.abort();
      }
      setIsLoadData(false);
    };
  }, [dispatch, lesson.id, setIsLoadData]);

  useEffect(() => {
    if (lesson.status !== "not_started" || finishedDataRef.current.fullPage) return;

    let lessonStartResponse: any = null;

    if (!lesson.start_ts) {
      lessonStartResponse = dispatch(setCourseLessonStart(lesson.id));
    }

    return () => {
      if (lessonStartResponse) {
        lessonStartResponse.abort();
      }
    };
  }, [dispatch, lesson.status, lesson.start_ts, lesson.id, course_id]);

  const isConditionsRequired = useMemo(() => {
    return lesson.required && !finishedDataRef.current.fullPage && lesson.status !== "completed";
  }, [lesson.status, lesson.required]);

  /**
   * Проверяет все условия просмотра страницы и фиксирует просмотр, если условия выполнены
   */
  const processViewConditions = useCallback(() => {
    const videosAreCompleted =
      videosInfoRef.current.state && videosInfoRef.current.state.length > 0
        ? _.isEmpty(
            _.difference(
              videosInfoRef.current.state.map((x) => x.name),
              Array.from(videosInfoRef.current.finished)
            )
          )
        : true;

    if (finishedDataRef.current.scroll) {
      console.log("[SMART VIEW] Полностью пролистал");
    }
    if (
      viewedInfoRef.current.time &&
      Number(viewedInfoRef.current.time) >= Number(viewedInfoRef.current.required_time) * 60
    ) {
      console.log("[SMART VIEW] Потратил указанное время");
    }

    if (videosAreCompleted) {
      console.log("[SMART VIEW] Посмотрел все видео");
    }

    if (!viewedInfoRef.current.required_time) {
      if (finishedDataRef.current.scroll && videosAreCompleted) {
        finishedDataRef.current.fullPage = true;

        dispatch(postCoursePageViewed(lesson.id)).catch(() => {
          finishedDataRef.current.fullPage = false;
        });

        console.log(
          "[SMART VIEW] Отслеживание времени прочтения отключено, страница проскроллена до конца, видео просмотрены. итого: Страница просмотрена!"
        );
      }
      return;
    }

    if (
      viewedInfoRef.current.time &&
      Number(viewedInfoRef.current.time) >= Number(viewedInfoRef.current.required_time) * 60 &&
      finishedDataRef.current.scroll &&
      videosAreCompleted
    ) {
      finishedDataRef.current.fullPage = true;

      dispatch(postCoursePageViewed(lesson.id)).catch(() => {
        finishedDataRef.current.fullPage = false;
      });

      console.log(
        "[SMART VIEW] Отслеживание времени прочтения включено, указанное время потрачено, страница проскроллена до конца, видео просмотрены. итого: Страница просмотрена!"
      );
    }
  }, [dispatch, lesson.id]);

  /**
   * Логика видосов
   */
  const { initializeVideos } = useVideoView(
    videosInfoRef,
    isConditionsRequired,
    processViewConditions
  );

  /**
   * Инициализация контента (скролл, видосы)
   */
  const onDataLoaded = useCallback(
    (videos: NodeListOf<HTMLVideoElement> | undefined) => {
      // Восстанавливаем сохранённый скролл, находится здесь т.к нам нужно дождаться полной загрузки контента для скролла
      if (!scrollRestoredRef.current.restored) {
        console.log(scrollRestoredRef.current);
        scrollTo(scrollRestoredRef.current.value);
        scrollRestoredRef.current.restored = true;
      }
      initializeVideos(videos);
    },
    [scrollTo, initializeVideos]
  );

  /**
   * Проскроллили страницу до конца
   */
  const onScrollToEnd = useCallback(() => {
    // Сохраняем полное пролистывание, чтобы каждый раз не дёргать проверки
    if (!finishedDataRef.current.scroll && isConditionsRequired) {
      finishedDataRef.current.scroll = true;
      processViewConditions();
      console.log("[SMART VIEW] проскролена страница");
    }
  }, [processViewConditions, isConditionsRequired]);

  /**
   * Логика подгрузки состояния прохождения
   */
  useEffect(() => {
    const pageViewedInfoResponse = dispatch(getCoursePageViewedInfo(lesson.id));
    pageViewedInfoResponse
      .unwrap()
      .then((info) => {
        viewedInfoRef.current = {
          ...info.data,
        };

        videosInfoRef.current.state = info.data.videos ? JSON.parse(info.data.videos) : [];
        scrollRestoredRef.current = {
          value: Number(info.data.position) || 0,
          restored: false,
        };

        setIsLoadData(true);

        console.log(info);
      })
      .catch((e) => {
        console.log(e);
      });
    return () => {
      pageViewedInfoResponse.abort();
    };
  }, [dispatch, navigate, lesson.id]);

  /**
   * Логика учёта скролла
   */
  useEffect(() => {
    if (!isLoadData) return;

    // Записываем текущую позицию скролла
    viewedInfoRef.current.position = Math.ceil(scrollInfo.scroll);
  }, [isLoadData, scrollInfo]);

  /**
   * Логика учёта времени
   */
  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (!isLoadData) return;

    timerRef.current = setInterval(() => {
      counterRef.current = counterRef.current + 1;

      // Считаем время, если страница не просмотрена, иначе нет смысла
      if (isConditionsRequired) {
        viewedInfoRef.current.time = (Number(viewedInfoRef.current.time) || 0) + 1;
        processViewConditions();
      }

      if (counterRef.current < 15) return;
      counterRef.current = 0;

      dispatch(
        postCoursePageViewedInfo({
          ...viewedInfoRef.current,
          videos:
            videosInfoRef.current.state && videosInfoRef.current.state.length > 0
              ? JSON.stringify(videosInfoRef.current.state)
              : undefined,
          pageId: lesson.id,
        })
      );
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isLoadData, dispatch, lesson.id, isConditionsRequired, processViewConditions]);

  return (
    <>
      {!isLoadData ? (
        <CourseLoadSkeleton />
      ) : (
        <CourseText
          lesson={lesson}
          data={pageContent}
          onDataLoaded={onDataLoaded}
          onEndReached={onScrollToEnd}
        />
      )}
    </>
  );
};
export default CourseTextIndex;
