import { Outlet, Route, Routes, useParams } from "react-router-dom";
import CourseLayout from "../../Layout/CourseLayout/CourseLayout";
import CourseLayoutShared from "../../Layout/CourseLayout/CourseLayoutShared";
import { selectAuthOIDC } from "../../features/auth/selectors";
import { MulticourseContent } from "./components/CourseContent";
import { CourseFeedback } from "./components/CourseFeedback";
import { CourseReport } from "./components/CourseReport";
import { CourseResult } from "./components/CourseResult";
import CourseContentWrap from "./components/Lesson/CourseContentWrap";
import CourseContentWrapShared from "./components/Lesson/CourseContentWrapShared";
import CourseLessonContent from "./components/Lesson/CourseLessonContent";
import CourseLessons from "./components/Lesson/CourseLessons";
import { MulticourseResult } from "./components/MulticourseResult";
import CourseError from "./components/Task/CourseError";
import { coursesSelectors } from "./redux/courseSlice";
import { ICourse } from "./redux/interfaces";
import { CourseFormat } from "./redux/interfaces/course.interface";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "hooks/redux";

// export const MenuContext = createContext();

const Course = () => {
  const { course_id } = useParams();
  const authOIDC = useAppSelector(selectAuthOIDC);
  const currentCourse = useAppSelector((state) =>
    coursesSelectors.selectById(state, course_id as EntityId)
  );

  const isMulticourse = currentCourse?.format === CourseFormat.multicourse;

  const ResultBlock = () =>
    isMulticourse ? <MulticourseResult /> : <CourseResult course={currentCourse as ICourse} />;

  const courseRoutes = () => {
    return (
      <Routes>
        <Route
          path="/"
          element={!authOIDC.shared ? <CourseContentWrap /> : <CourseContentWrapShared />}
        >
          {/* Роуты course */}
          <Route index element={<Outlet />} />
          <Route path="lesson" element={<Outlet />}>
            <Route index element={<CourseLessons />} />
            <Route path=":lesson_id" element={<CourseLessonContent />} />
          </Route>
          <Route path="report" element={<CourseReport courseId={course_id} />} />
          <Route path="feedback" element={<CourseFeedback courseId={course_id} />} />

          {/* Роуты multicourse */}
          <Route path="content" element={<Outlet />}>
            <Route index element={<CourseLessons isMulticourse />} />
            <Route path=":content_id" element={<MulticourseContent />} />
          </Route>

          {/* Общийе роуты */}
          <Route path="result" element={<ResultBlock />} />
        </Route>
        <Route path="*" element={<CourseError />} />
      </Routes>
    );
  };

  return !authOIDC.shared ? (
    <CourseLayout>{courseRoutes()}</CourseLayout>
  ) : (
    <CourseLayoutShared>{courseRoutes()}</CourseLayoutShared>
  );
};

export default Course;
