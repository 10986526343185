import { SVGProps } from "react";

interface CircleChartProps extends SVGProps<SVGSVGElement> {
  progress?: number;
}

export const CircleChart = ({ progress = 0, style, ...otherProps }: CircleChartProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="74"
    fill="none"
    viewBox="0 0 74 74"
    style={{ transform: "rotate(-90deg)", ...style }}
    {...otherProps}
  >
    <circle cx="37" cy="37" r="27" stroke="#E8EAEF" strokeWidth="10"></circle>
    {progress && progress > 0 && (
      <circle
        cx="37"
        cy="37"
        r="27"
        stroke="currentColor"
        strokeWidth="10"
        strokeDashoffset={100 - progress}
        strokeDasharray="100"
        pathLength="100"
        strokeLinecap="round"
      ></circle>
    )}
  </svg>
);
