import { Skeleton } from "components/Skeleton";
import styles from "../../OrderItem.module.scss";
import { SubtitleBlock } from "../SubtitleBlock/SubtitleBlock";
interface DescriptionBlockProps {
  loading?: boolean;
  description?: string;
  takeInfo?: string;
}

export const DescriptionBlock = ({ loading, description, takeInfo }: DescriptionBlockProps) => {
  return (
    <div className={styles["item-additional-info"]}>
      {loading ? (
        <Skeleton width="min(730px, 100%)" height="45px" />
      ) : (
        <>
          {description && <SubtitleBlock title="Описание" description={description} />}
          {takeInfo && <SubtitleBlock title="Как получить товар" description={takeInfo} />}
        </>
      )}
    </div>
  );
};
