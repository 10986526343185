import { generateUid } from "app/utils";
import { IOrder } from "pages/Orders/redux/interfaces/orders.interface";
import { OrderItem } from "../OrderItem/OrderItem";
import styles from "./OrderList.module.scss";

interface OrderListItemProps {
  orders: IOrder[];
}

export const OrderList = ({ orders }: OrderListItemProps) => {
  return (
    <div className={styles["order-list"]}>
      {orders.map((o) => (
        <OrderItem key={generateUid()} order={o} />
      ))}
    </div>
  );
};
