import styles from "./CalendarWrapper.module.scss";
import classNames from "classnames";
import PaperBox from "../Paper/PaperBox";

const CalendarWrapper = ({ children }) => {
  return (
    <PaperBox className={classNames("calendar-wrapper", styles["border-radius-adaptive"])}>
      {children}
    </PaperBox>
  );
};

export default CalendarWrapper;
