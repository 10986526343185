import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const ParrotAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();
  return process.env.REACT_APP_THEME === "myAcademy" ? (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width="234" height="234" rx="117" fill="#3DC100" />
        <path
          d="M78.2016 120.717C62.2218 131.67 58.4879 149.9 67.0001 175.408C79.7683 213.67 107.053 251.816 137.707 261.801C168.362 271.785 195.448 269.148 195.448 245.367C195.448 221.586 176.881 106 143.209 106C120.762 106 99.0923 110.906 78.2016 120.717Z"
          fill="#FF8B00"
        />
        <path
          d="M70.0763 76.5192C79.5748 66.902 95.8827 59.3954 119 53.9995C155.184 45.5537 163.438 21.6782 167.854 27.8091C170.798 31.8963 165.339 40.6265 151.477 53.9995C172.115 43.8789 182.434 41.3488 182.434 46.4091C182.434 51.4693 174.295 58.7544 158.019 68.2641C174.295 64.632 182.434 65.2104 182.434 69.9995C182.434 74.6914 167.737 81.7155 138.344 91.0717C139.242 94.4641 139.879 97.9302 140.304 101.092C155.533 102.819 164.878 108.033 168.337 116.733C192.935 178.589 200.946 246.073 210.818 264.96C222.81 287.906 222.81 298.585 210.818 297C184.589 286.187 163.529 272.947 147.638 257.281C123.802 233.781 116.291 210.453 106.361 186.64C102.002 176.187 105.297 160.239 116.244 138.797C111.978 139.708 106.944 140.238 101 140.241H86.8353C69.6118 140.274 61 134.017 61 121.47C61 104.738 61 83.8783 70.0763 76.5192Z"
          fill="#FFC422"
        />
        <path
          d="M140 149C130 166 123 173 135 193C147 213 156 223 163 232"
          stroke="#191229"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.7936 124.061L103.355 107.019C103.647 111.665 103.34 115.556 102.434 118.693C101.075 123.4 99.4726 123.865 98.0647 125.34C96.0299 127.472 92.6062 127.045 87.7936 124.061Z"
          fill="#191229"
        />
        <path
          d="M80.729 101.795C73.6742 118.627 71.3112 128.862 73.6397 132.502C77.1326 137.963 78.1526 133.544 94.701 123.638C96.8183 122.37 99.7028 116.831 103.355 107.019C99.4739 103.824 95.3412 101.72 90.9565 100.708C86.5719 99.6953 83.1627 100.058 80.729 101.795Z"
          fill="#FF6B00"
        />
        <path
          d="M114 111C111.239 111 109 108.761 109 106C109 103.239 111.239 101 114 101C116.761 101 119 103.239 119 106C119 108.761 116.761 111 114 111ZM67 111C64.2386 111 62 108.761 62 106C62 103.239 64.2386 101 67 101C69.7614 101 72 103.239 72 106C72 108.761 69.7614 111 67 111Z"
          fill="#191229"
        />
        <path
          d="M113 94L123 99"
          stroke="#191229"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73 96L62 98"
          stroke="#191229"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width={234} height={234} fill="#56D6A9" rx={117} />
        <path
          fill="#C861F9"
          d="M78.202 120.717C62.222 131.67 58.488 149.9 67 175.408c12.768 38.262 40.053 76.408 70.707 86.393 30.655 9.984 57.741 7.347 57.741-16.434 0-23.781-18.567-139.367-52.239-139.367-22.447 0-44.117 4.906-65.007 14.717Z"
        />
        <path
          fill="#FFC422"
          d="M70.076 76.52C79.575 66.901 95.883 59.394 119 54c36.184-8.446 44.438-32.322 48.854-26.19 2.944 4.086-2.515 12.817-16.377 26.19 20.638-10.121 30.957-12.651 30.957-7.59 0 5.06-8.139 12.344-24.415 21.854 16.276-3.632 24.415-3.054 24.415 1.736 0 4.691-14.697 11.716-44.09 21.072.898 3.392 1.535 6.858 1.96 10.02 15.229 1.727 24.574 6.941 28.033 15.641 24.598 61.856 32.609 129.34 42.481 148.227 11.992 22.946 11.992 33.625 0 32.04-26.229-10.813-47.289-24.053-63.18-39.719-23.836-23.5-31.347-46.828-41.277-70.641-4.359-10.453-1.064-26.401 9.883-47.843-4.266.911-9.3 1.441-15.244 1.444H86.835C69.612 140.274 61 134.017 61 121.47c0-16.732 0-37.592 9.076-44.95Z"
        />
        <path
          stroke="#191229"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M140 149c-10 17-17 24-5 44s21 30 28 39"
        />
        <path
          fill="#191229"
          d="m87.794 124.061 15.561-17.042c.292 4.646-.015 8.537-.921 11.674-1.359 4.707-2.961 5.172-4.37 6.647-2.034 2.132-5.458 1.705-10.27-1.279Z"
        />
        <path
          fill="#A650DB"
          d="M80.729 101.795c-7.055 16.832-9.418 27.067-7.09 30.707 3.494 5.461 4.514 1.042 21.062-8.864 2.117-1.268 5.002-6.807 8.654-16.619-3.881-3.195-8.014-5.299-12.398-6.311-4.385-1.013-7.794-.65-10.228 1.087Z"
        />
        <path
          fill="#191229"
          d="M114 111a5 5 0 1 1 .001-10.001A5 5 0 0 1 114 111Zm-47 0a5 5 0 1 1 0-10 5 5 0 0 1 0 10Z"
        />
        <path
          stroke="#191229"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="m113 94 10 5M73 96l-11 2"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
