import { WebAppRoutes } from "app/routes";
import { BalanceRow } from "components/BalanceRow";
import { Button } from "components/Buttons";
import { Link } from "components/Link";
import PaperBox from "components/Paper/PaperBox";
import { H3 } from "components/Typography/Titles";
import {
  ProfileCoinsData,
  ShopSettingsData,
} from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import { useNavigate } from "react-router";
import styles from "./OperationHistoryBalanceList.module.scss";

const helpText = (coinName?: string) =>
  `Записывайтесь на курсы,\nпроходите обучение, зарабатывайте и покупайте\nв нашем `;

interface OperationHistoryBalanceListProps {
  shopSettings: ShopSettingsData;
  coins: ProfileCoinsData;
}

export const OperationHistoryBalanceList = ({
  shopSettings,
  coins,
}: OperationHistoryBalanceListProps): JSX.Element => {
  const navigate = useNavigate();

  const toCourses = () => navigate(WebAppRoutes.COURSES);
  const toShop = () => navigate(WebAppRoutes.REWARDS_SHOP);

  return (
    <PaperBox className={styles["balance"]}>
      <H3 className={styles["balance-title"]}>В вашем кошельке</H3>
      <div className={styles["blance-text"]}>Можно потратить:</div>
      <BalanceRow coins={coins.shop_coins} className={styles["blance-row"]} />
      {coins.shop_coins !== coins.total_coins && (
        <>
          <div className={styles["blance-text"]}>Всего:</div>
          <BalanceRow coins={coins.total_coins} className={styles["blance-row"]} isGray />
        </>
      )}
      <div className={styles["help-text"]}>
        {helpText(shopSettings?.coin?.name)}
        {<Link onClick={toShop}>Магазине наград</Link>}
      </div>
      <Button color="primary" onClick={toCourses} className={styles["catalog-btn"]}>
        Каталог курсов
      </Button>
    </PaperBox>
  );
};
