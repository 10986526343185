import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const DogAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();
  return process.env.REACT_APP_THEME === "myAcademy" ? (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width="234" height="234" rx="117" fill="#FF8B00" />
        <path
          d="M112.407 131.298L91.1397 125.937L69.1908 120.453C69.1876 120.436 69.1844 120.419 69.1812 120.402L40.0125 113.05C30.8093 110.73 25.2193 101.4 27.5154 92.191C29.8098 82.9889 39.1294 77.3891 48.3315 79.6834C48.3316 79.6834 48.3317 79.6835 48.3318 79.6835L78.4898 87.2033C88.0993 77.0056 104.435 63.9798 128.85 70.9806C153.128 77.9423 162.157 99.4568 155.936 135.524C158.179 136.951 160.334 138.583 162.376 140.421L243.678 213.588C263.527 231.45 265.137 262.021 247.274 281.869C247.269 281.875 247.263 281.881 247.258 281.887C239.278 290.745 228.769 295.97 217.812 297.453C217.76 297.489 217.708 297.525 217.655 297.562H216.949C213.191 298.002 209.387 298.003 205.621 297.562H137.103C133.778 284.608 140.631 276.9 157.662 274.435C159.524 274.166 162.297 273.864 165.709 273.577L129.936 241.383C127.808 253.545 122.687 272.441 114.573 298.069L114.549 298.062C114.549 298.064 114.549 298.067 114.549 298.069H74C76.7475 289.554 81.3652 285.296 87.8531 285.296C88.7449 285.296 90.053 285.135 91.6187 284.916C91.4594 264.207 90.4032 243.681 88.4499 223.338C87.7095 215.626 87.658 208.15 88.2956 200.911C77.864 183.215 79.5554 160.1 94.0257 144.02C94.0312 144.014 94.0367 144.008 94.0422 144.002C99.2724 138.196 105.589 133.951 112.407 131.298Z"
          fill="#FFC422"
        />
        <path
          d="M96.9673 127.326L140.942 133.192C139.751 146.006 135.938 152.413 129.503 152.413C123.067 152.413 110.944 149.448 93.1328 143.516L96.9673 127.326ZM130.949 241.02C130.73 241.819 129.904 242.289 129.106 242.069C128.307 241.85 127.837 241.025 128.056 240.226C132.052 225.667 133.048 212.727 131.064 201.402C130.921 200.586 131.466 199.809 132.282 199.666C133.098 199.523 133.876 200.068 134.018 200.884C136.085 212.679 135.056 226.058 130.949 241.02ZM166.698 271.457H191.815C182.954 257.172 183.47 242.674 193.357 228.245C193.826 227.561 194.759 227.387 195.443 227.855C196.126 228.324 196.3 229.257 195.832 229.941C186.102 244.139 186.102 258.083 195.827 272.102C196.517 273.097 195.805 274.457 194.595 274.457H166.698C165.87 274.457 165.198 273.786 165.198 272.957C165.198 272.129 165.87 271.457 166.698 271.457Z"
          fill="#00AEFF"
        />
        <path
          d="M258.994 241.373C282.26 239.035 272.094 220.221 264.893 206.683C256.717 191.315 264.172 165.835 269.205 149.624C274.237 133.413 290.547 139.628 297.708 148.013C304.869 156.398 289.976 177.384 288.536 196.844C287.096 216.305 290.963 208.088 293.423 233.734C295.883 259.38 272.081 261.31 265.166 262.369C263.185 262.672 260.842 262.449 258.138 261.698C259.863 255.039 260.14 248.101 258.994 241.373ZM131.582 71.1191C152.349 74.1692 164.798 81.2673 168.929 92.4134C177.341 115.115 175.644 128.952 168.743 151.774C161.843 174.596 141.725 176.83 129.805 162.526C121.858 152.99 122.451 122.521 131.582 71.1191ZM106.564 94.3704C104.516 93.7832 103.332 91.6482 103.919 89.6016C104.506 87.555 106.641 86.3719 108.689 86.9591C110.736 87.5462 111.921 89.6813 111.334 91.7279C110.747 93.7744 108.611 94.9575 106.564 94.3704ZM28.8107 90.0187C24.5557 89.2682 27.0851 83.9726 30.5614 80.3889C34.0376 76.8052 40.6093 75.3549 43.0851 79.0699C45.7071 83.0042 33.0656 90.7692 28.8107 90.0187Z"
          fill="#191229"
        />
        <path
          d="M101.75 84.0005C105.5 82.2505 112.5 81.2505 116.25 87.5005"
          stroke="#191229"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width={234} height={234} fill="#C861F9" rx={117} />
        <path
          fill="#FFC422"
          d="m112.407 131.298-21.267-5.361-21.95-5.484-.009-.051-29.168-7.352c-9.204-2.32-14.794-11.65-12.498-20.859 2.295-9.202 11.614-14.802 20.816-12.508l30.159 7.52c9.61-10.197 25.945-23.223 50.36-16.222 24.278 6.961 33.307 28.476 27.086 64.543a48.693 48.693 0 0 1 6.44 4.897l81.302 73.167c19.849 17.862 21.459 48.433 3.596 68.281l-.016.018a48.21 48.21 0 0 1-29.446 15.566l-.157.109h-.706c-3.758.44-7.562.441-11.328 0h-68.518c-3.325-12.954 3.528-20.662 20.559-23.127 1.862-.269 4.635-.571 8.047-.858l-35.773-32.194c-2.128 12.162-7.249 31.058-15.363 56.686l-.024-.007v.007H74c2.748-8.515 7.365-12.773 13.853-12.773.892 0 2.2-.161 3.766-.38a700.326 700.326 0 0 0-3.17-61.578c-.74-7.712-.791-15.188-.153-22.427-10.432-17.696-8.74-40.811 5.73-56.891l.016-.018a48.184 48.184 0 0 1 18.365-12.704Z"
        />
        <path
          fill="#FFC422"
          d="m96.967 127.326 43.975 5.866c-1.191 12.814-5.004 19.221-11.439 19.221-6.436 0-18.559-2.965-36.37-8.897l3.834-16.19ZM130.95 241.02a1.499 1.499 0 1 1-2.893-.794c3.996-14.559 4.992-27.499 3.008-38.824a1.5 1.5 0 1 1 2.954-.518c2.067 11.795 1.038 25.174-3.069 40.136Zm35.749 30.437h25.117c-8.861-14.285-8.345-28.783 1.542-43.212a1.5 1.5 0 1 1 2.475 1.696c-9.73 14.198-9.73 28.142-.005 42.161.69.995-.022 2.355-1.232 2.355h-27.897a1.5 1.5 0 1 1 0-3Z"
        />
        <path
          fill="#191229"
          d="M258.994 241.373c23.266-2.338 13.1-21.152 5.899-34.69-8.176-15.368-.721-40.848 4.312-57.059 5.032-16.211 21.342-9.996 28.503-1.611 7.161 8.385-7.732 29.371-9.172 48.831-1.44 19.461 2.427 11.244 4.887 36.89 2.46 25.646-21.342 27.576-28.257 28.635-1.981.303-4.324.08-7.028-.671a48.58 48.58 0 0 0 .856-20.325ZM131.582 71.119c20.767 3.05 33.216 10.148 37.347 21.294 8.412 22.702 6.715 36.539-.186 59.361-6.9 22.822-27.018 25.056-38.938 10.752-7.947-9.536-7.354-40.005 1.777-91.407ZM106.564 94.37a3.856 3.856 0 1 1 2.125-7.41 3.856 3.856 0 0 1 2.645 4.768 3.856 3.856 0 0 1-4.77 2.642ZM28.811 90.02c-4.255-.75-1.726-6.046 1.75-9.63 3.477-3.584 10.048-5.034 12.524-1.32 2.622 3.935-10.02 11.7-14.274 10.95Z"
        />
        <path
          stroke="#191229"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M101.75 84c3.75-1.75 10.75-2.75 14.5 3.5"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
