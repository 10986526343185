import { H3 } from "../../../../../components/Typography/Titles";
import TextField from "../../../../../components/Forms/TextField";
import { useDispatch } from "react-redux";
import { setQuestionnaireQuestion } from "../redux/Questionary/courseQuestionarySlice";

const QuestText = ({ question }) => {
  const dispatch = useDispatch();
  const { title = "", answer = {} } = question;

  const onChangeHandler = (event) => {
    dispatch(
      setQuestionnaireQuestion({
        question: {
          ...question,
          answer: event.target.value ? [event.target.value] : [],
          valid: question.required ? (event.target.value ? true : false) : true,
        },
      })
    );
  };

  return (
    <>
      <H3 className="course-sheet-test-block__title">{title}</H3>
      <div className="course-sheet-test-block-content">
        <TextField value={answer} onChange={onChangeHandler} multiline rows={3} />
      </div>
    </>
  );
};

export default QuestText;
