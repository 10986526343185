import { DynamicMessages, TextMessages } from "app/messages";
import { AuthTypes, UseAuthForm } from "../authRoutes.congig";
import { AuthData, UseAuthFormFieldNames } from "./data.types";
import { WebAppRoutes } from "app/routes";
import { putAvatarCodeRegistration, userRegister } from "features/auth/authSlice";
import { nameChars, positionChars } from "app/constants";
import { validateRule } from "app/utils";
import { getInitProfileFields } from "pages/Profile/redux/profile-slice/profile.slice";

export const secondStepData = (dep: any, requirenmentsFromApi: string): AuthData => {
  const { nav, dispatch, authOIDC, setError } = dep;

  return {
    type: AuthTypes.REGISTRATION_SECOND_STEP,
    toggles: {
      top: {
        isReg: true,
        title: TextMessages.ACCOUNT_NEW_TO_CREATE,
        action: () => {
          nav(WebAppRoutes.REGISTRATION, { is_allowed: true });
        },
      },
    },
    titles: {
      main: TextMessages.ACCOUNT_CREATE,
    },
    form: {
      submit: async (formData: UseAuthForm) => {
        const { email } = authOIDC;
        const { password, name, secondname, department, position } = formData;

        const result =
          email &&
          password &&
          name &&
          secondname &&
          department &&
          position &&
          (await dispatch(
            userRegister({
              email,
              password,
              firstName: name,
              secondName: secondname,
              department: department.value,
              position,
              policyAgreed: true,
            })
          ));
        if (result?.payload.status === 200) {
          // @ts-ignore
          dispatch(putAvatarCodeRegistration()).then(() => {
            nav(WebAppRoutes.REGISTERED);
            dispatch(getInitProfileFields());
          });
        } else {
          setError("password", {
            type: "manual",
            message: TextMessages.UNKNOWN_ERROR,
          });
        }
      },
      actions: {
        buttons: {
          primary: {
            title: TextMessages.CONTINUE_BUTTON,
          },
        },
      },
      fields: [
        {
          label: TextMessages.NAME,
          name: UseAuthFormFieldNames.name,
          rules: {
            required: TextMessages.ERROR_FIELD_IS_EMPTY,
            maxLength: {
              value: 25,
              message: DynamicMessages.nameInvalidMaxCount(25),
            },
            pattern: {
              value: nameChars,
              message: TextMessages.NAME_INVALID_CHARS,
            },
            validate: (data: any) => {
              return data?.length < 2 ? TextMessages.NAME_MINLENGTH_VLIDATE : true;
            },
          },
        },
        {
          label: TextMessages.SECONDNAME,
          name: UseAuthFormFieldNames.secondname,
          rules: {
            required: TextMessages.ERROR_FIELD_IS_EMPTY,
            maxLength: {
              value: 50,
              message: DynamicMessages.nameInvalidMaxCount(50),
            },
            pattern: {
              value: nameChars,
              message: TextMessages.NAME_INVALID_CHARS,
            },
            validate: (data: any) => {
              return data?.length < 2 ? TextMessages.NAME_MINLENGTH_VLIDATE : true;
            },
          },
        },
        {
          label: TextMessages.POSITION,
          name: UseAuthFormFieldNames.position,
          rules: {
            required: TextMessages.ERROR_FIELD_IS_EMPTY,
            maxLength: {
              value: 100,
              message: DynamicMessages.nameInvalidMaxCount(100),
            },
            pattern: {
              value: positionChars,
              message: TextMessages.POSITION_INVALID_CHARS,
            },
            validate: (data: any) => {
              return data?.length < 2 ? TextMessages.NAME_MINLENGTH_VLIDATE : true;
            },
          },
        },
        {
          label: TextMessages.DEPARTMENT,
          name: UseAuthFormFieldNames.department,
          rules: {
            required: TextMessages.ERROR_FIELD_IS_EMPTY,
          },
        },
        {
          label: TextMessages.PASSWORD,
          type: "password",
          name: UseAuthFormFieldNames.password,
          strength: true,
          rules: {
            maxLength: {
              value: 25,
              message: DynamicMessages.nameInvalidMaxCount(25),
            },
            required: TextMessages.ERROR_FIELD_IS_EMPTY,
            validate: (val: any) => val && validateRule(val, null, true, requirenmentsFromApi),
          },
        },
      ],
    },
  };
};
