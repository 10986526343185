import { Button } from "../../Buttons";
import TextButton from "../../Buttons/TextButton";
import { Scrollbar } from "../../Scrollbar";
import CourseSearch from "../CourseSearch";
import CourseSearchPopupHeader from "../CourseSearchPopupHeader";
import { useState } from "react";
import {
  selectCoursesListCategory,
  selectCoursesListFormats,
  selectCoursesListInputValPopup,
} from "pages/Courses/redux/coursesListSliceSelectors";
import { changeCourseCatalogFilterPopupOpened } from "features/courseCatalog/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchInput,
  getSearchCourses,
  setSearchCategory,
  setSearchInputPopup,
  setMissGetCourses,
  setSearchFormats,
} from "pages/Courses/redux/coursesListSlice";
import { useAppSelector } from "hooks/redux";
import { CourseSearchPopupFromats } from "./components/CourseSearchPopupFromats";
import { CourseSearchPopupCategories } from "./components/CourseSearchPopupCategories";

/**
 * Адаптив. Фильтр (попап).
 */
export const CourseSearchPopup = () => {
  const dispatch = useDispatch();

  const coursesListCategory = useSelector(selectCoursesListCategory);
  const coursesListFormats = useAppSelector(selectCoursesListFormats);

  const storeSearchValPopup = useSelector(selectCoursesListInputValPopup);

  const [activeCategories, setActiveCategories] = useState(coursesListCategory);
  const [activeFormats, setActiveFormats] = useState(coursesListFormats);

  const isBaseTheme = process.env.REACT_APP_THEME === "base";

  return (
    <section className="course-search-popup">
      <CourseSearchPopupHeader />
      <div className="course-search-popup__search">
        <CourseSearch />
      </div>
      <Scrollbar className="course-search-popup-list">
        <CourseSearchPopupCategories
          activeCategories={activeCategories}
          setActiveCategories={setActiveCategories}
          isHiddenList={isBaseTheme}
        />
        {isBaseTheme && (
          <CourseSearchPopupFromats
            activeFormats={activeFormats}
            setActiveFormats={setActiveFormats}
          />
        )}
      </Scrollbar>
      <div className="course-search-popup-footer">
        <Button
          size="small"
          color="primary"
          style={{ height: 46, width: 196 }}
          onClick={() => {
            const searchParams = {
              categories: activeCategories?.length > 0 ? activeCategories.join() : [],
              format: activeFormats?.length > 0 ? activeFormats.join() : [],
              search: storeSearchValPopup,
            };

            dispatch(setSearchInput(storeSearchValPopup));
            dispatch(setSearchCategory(activeCategories));
            dispatch(setSearchFormats(activeFormats));
            // @ts-ignore
            dispatch(getSearchCourses(searchParams));
            dispatch(setMissGetCourses(false));
            dispatch(changeCourseCatalogFilterPopupOpened(false));
          }}
        >
          Применить
        </Button>
        <TextButton
          size="small"
          className="course-search-popup__textbutton"
          invert="true"
          onClick={() => {
            setActiveCategories([]);
            setActiveFormats([]);
            dispatch(setSearchInputPopup(""));
          }}
        >
          Очистить
        </TextButton>
      </div>
    </section>
  );
};
