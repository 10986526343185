import classNames from "classnames";
import { coursesStatusesSelectors } from "pages/Courses/redux/coursesListSlice";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { useSelector } from "react-redux";
import styles from "./CourseStatus.module.scss";

interface CourseStatusProps {
  status: EnrolledStatus;
  className?: string;
}

export const CourseStatus = ({ status, className }: CourseStatusProps): JSX.Element => {
  const courseStatuses = useSelector(coursesStatusesSelectors.selectAll);

  const courseStatus = courseStatuses.find((item) => status === item.code);

  return (
    <>
      {courseStatus && courseStatus.code !== "not_started" && (
        <div
          className={classNames(
            styles["wrapper"],
            styles[courseStatus.code],
            {
              [styles["myacademy_theme"]]: process.env.REACT_APP_THEME === "myAcademy",
              [styles["rostechnadzor_theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
            },
            className
          )}
        >
          {courseStatus.name}
        </div>
      )}
    </>
  );
};
