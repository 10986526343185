import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeCourseCatalogFilterPopupOpened } from "../../features/courseCatalog/slice";
import { CloseSvgIcon } from "../Svg/Icons";
import { H1 } from "../Typography/Titles";

/**
 * Адаптив. Фильтр (попап). Хидер.
 */
const CourseSearchPopupHeader = () => {
  const dispatch = useDispatch();

  const closePopup = () => {
    dispatch(changeCourseCatalogFilterPopupOpened(false));
  };

  return (
    <header className="header course-search-popup-header">
      <div className="header__item">
        <H1 style={{ marginBottom: 0 }}>Фильтр</H1>
      </div>
      <div className="header__item">
        <IconButton onClick={closePopup} style={{ transform: "translateX(8px)" }}>
          <CloseSvgIcon />
        </IconButton>
      </div>
    </header>
  );
};

export default CourseSearchPopupHeader;
