import { WebAppRoutes } from "app/routes";
import { mediaQuerys } from "app/utils";
import { AwardIcon } from "components/Icons";
import { Link } from "components/Link";
import { H1 } from "components/Typography/Titles";
import { useAppSelector } from "hooks/redux";
import { useMediaQuery } from "hooks/useMediaQuery";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useNavigate } from "react-router";
import styles from "./OperationHistoryHeader.module.scss";
import classNames from "classnames";

export const OperationHistoryHeader = (): JSX.Element => {
  const navigate = useNavigate();

  const toProfile = () => navigate(WebAppRoutes.PROFILE);
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const shopSettings = useAppSelector(selectShopSettings);

  const openPdf = () => {
    isMobile
      ? fetch(window.location.origin + "/api" + (shopSettings?.tos?.path || ""))
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "file.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(() => URL.revokeObjectURL(url), 100);
          })
          .catch((error) => console.error("Error fetching PDF:", error))
      : fetch(window.location.origin + "/api" + (shopSettings?.tos?.path || ""))
          .then((response) => response.blob())
          .then((data) => window.open(URL.createObjectURL(data)));
  };

  return (
    <div className={styles["header"]}>
      <div className={styles["header-link"]} onClick={toProfile}>
        Перейти в профиль
      </div>
      <div className={styles["header-top"]}>
        <H1 className={styles["header-top-text"]}>История операций</H1>
        {!!shopSettings?.tos?.path && (
          <div
            className={classNames(styles["header-regulations"], {
              [styles["header-regulations_rostechnadzor-theme"]]:
                process.env.REACT_APP_THEME === "rostechnadzor",
            })}
          >
            {!isMobile && <AwardIcon className={styles["header-regulations-icon"]} />}
            <Link onClick={openPdf} className={styles.link}>
              Правила начисления и списания
            </Link>
          </div>
        )}
      </div>
      <div className={styles["header-bottom-text"]}>Время указывается по МCК (UTC+3)</div>
    </div>
  );
};
