import { RootState } from "../../app/store";

// @ts-ignore
export const selectRegVerifyError = (state: RootState) => state.auth?.registration?.verifyError;
export const selectRecoveryError = (state: RootState) => state.auth?.recovery?.error;
export const selectRegRequirements = (state: RootState) => state.auth?.registration?.requirements;
// @ts-ignore
export const selectRegPasswordRules = (state: RootState) =>
  // @ts-ignore
  state.auth?.registration?.requirements?.password;
export const selectAuthOIDC = (state: RootState) => state.auth?.oidc;
export const selectAuthLastError = (state: RootState) => state.auth?.authLastError;
export const selectAuthUserExist = (state: RootState) => state.auth?.userExist;
export const selectAuthType = (state: RootState) => state.auth?.authType;

export const selectAuthShowcaseExpanded = (state: RootState) => state.auth.win.showcase.expanded;

export const selectRegistrationIsAllowed = (state: RootState) => state.auth.registrationIsAllowed;

export const selectUserId = (state: RootState) => state.userInfo.id;

export const authRequestIsLoading = (state: RootState) => state.auth.authRequestIsLoading;
