import { RootState } from "app/store";

export const selectShopSettings = (state: RootState) => state.shop.shopSettings;
export const selectProfileCoins = (state: RootState) => state.shop.coins;
export const selectProfileCoinsDetails = (state: RootState) => state.shop.coinsDetails;
export const selectProfileTransactions = (state: RootState) => state.shop.transactions;
export const selectShopProducts = (state: RootState) => state.shop.products;
export const selectShopProductsLoading = (state: RootState) => state.shop.productsIsLoading;
export const selectShopSearch = (state: RootState) => state.shop.search;
export const selectShopProduct = (state: RootState) => state.shop.currentProduct;

export const lmsSettings = (state: RootState) => state.shop.lmsSettings;

export const selectShopClub = (id: string) => (state: RootState) =>
  state.shop.clubs?.find((x) => x.id.toString() === id);
