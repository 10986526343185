import { createContext } from "react";

export const ScrollbarContainerContext = createContext<{
  scrollInfo: {
    scroll: number;
    scrollProgress: number;
  };
  scrollTo: (scroll: number, behavior?: ScrollBehavior) => void;
}>({
  scrollInfo: {
    scroll: 0,
    scrollProgress: 0,
  },
  scrollTo: () => {},
});
