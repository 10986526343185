import { useEffect, useState } from "react";
import { H3 } from "../../../../../../../../components/Typography/Titles";
import { TextMain } from "../../../../../../../../components/Typography/Texts";
import { useSelector } from "react-redux";
import { QuestResultAlert } from "./QuestResultAlert";
import { IQuizeQuestionProp } from "../../../../redux/Quze/interface/quiz.interface";
import { allAnswersSelector } from "pages/Course/components/Quiz/redux/Quze/courseQuizeSelectors";
import { QuestDescription } from "../QuestDescription/QuestDescription";

export const QuestRadioResult = ({
  title,
  description,
  questionsId,
  multy,
  questions = [],
  right,
  wrong,
  misstake,
}: IQuizeQuestionProp) => {
  const allAnswers = useSelector(allAnswersSelector);

  const findedAnwer: any = allAnswers.find((answer: any) => answer.id === questionsId);
  const [answer, setAnswer] = useState([]);

  useEffect(() => {
    setAnswer([]);
    if (findedAnwer?.answer) {
      // @ts-ignore
      setAnswer(findedAnwer?.answer);
    } else {
      setAnswer([]);
    }
    return () => {
      setAnswer([]);
    };
  }, [findedAnwer?.answer, questionsId]);

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />

      <div className="course-questions-block-wrapper">
        {multy ? (
          <TextMain className="course-questions-block-wrapper__title">
            Выберите правильные ответы:
          </TextMain>
        ) : (
          <TextMain className="course-questions-block-wrapper__title">
            Выберите один из ответов:
          </TextMain>
        )}
        <ul className="course-questions-block-list">
          {questions.map((item, index) => {
            const rightClass = right ? " course-questions-bubble__item--right" : "";
            const wrongClass = wrong ? " course-questions-bubble__item--misstake" : "";
            return (
              <li key={item.code} className={"course-questions-block-list__item"}>
                <span
                  className={
                    // @ts-ignore
                    answer.indexOf(item.code) !== -1
                      ? "course-questions-bubble__item course-questions-bubble__item--active" +
                        rightClass +
                        wrongClass
                      : "course-questions-bubble__item"
                  }
                >
                  {item.text}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <QuestResultAlert wrong={wrong} right={right} isSelect={true} />
    </div>
  );
};
