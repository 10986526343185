import { DefaultModal } from "../DefaultModal";

interface DeleteMessageModalProps {
  onConfirm?: () => void;
}

export const DeleteMessageModal = ({ onConfirm }: DeleteMessageModalProps) => {
  return (
    <DefaultModal title="Удалить данное сообщение?" confirmBtnText="Да" onConfirm={onConfirm} />
  );
};
