import { WebAppRoutes } from "app/routes";

export const setAuthError = (result: any, nav: any) => {
  if (result.payload.status === 400 && result.payload.data?.code === "CODE_SENT") {
    nav(WebAppRoutes.REGISTRATION_CODE_CHECK, { is_allowed: true });
  }
  if (result.payload.status === 400 && result.payload.data?.code === "USER_EXISTS") {
    nav(WebAppRoutes.ACCOUNT_EXISTS);
  }
  if (result.payload.status === 400 && result.payload.data?.code === "EMAIL_BLOCKED") {
    nav(WebAppRoutes.REGISTRATION_EMAIL_BLOCKED);
  }
  if (result.payload.status === 400 && result.payload.data?.code === "DOMEN_BANNED") {
    nav(WebAppRoutes.BANNED_DOMEN);
  }
};
