import classNames from "classnames";
import styles from "./SubtitleBlock.module.scss";
interface SubtitleBlockProps {
  title?: string;
  description?: string;

  titleClassName?: string;
}

export const SubtitleBlock = ({ title, description, titleClassName }: SubtitleBlockProps) => {
  return (
    <div className={styles["subtitle-block"]}>
      <p className={classNames(styles["subtitle-block-title"], titleClassName)}>{title}</p>
      <p className={styles["subtitle-block-description"]}>{description}</p>
    </div>
  );
};
