import { useMediaQuery } from "@mui/material";
import { mediaQuerys } from "app/utils";
import { CloseIcon } from "components/Icons";
import styles from "../OrderItem.module.scss";

interface CancelChipProps {
  onCancel: () => void;
}

export const CancelChip = ({ onCancel }: CancelChipProps) => {
  const mdMin = useMediaQuery(`(min-width:${mediaQuerys.mdMin}px)`);

  return (
    <div className={styles["item-chip"]} onClick={onCancel}>
      <CloseIcon />
      {mdMin ? <span>Отменить заказ</span> : <span>Отменить</span>}
    </div>
  );
};
