import { AlertCircleIcon } from "../Svg/Icons";
import { TextMain } from "../Typography/Texts";
import { ReactNode } from "react";

const Alert = ({ children }: { children: ReactNode }) => {
  return (
    <div className="alert">
      <div className="alert-block">
        <div className="alert-icon">
          <AlertCircleIcon />
        </div>
        <div className="alert-text">
          <TextMain className="alert-text__descr">{children}</TextMain>
        </div>
      </div>
    </div>
  );
};

export default Alert;
