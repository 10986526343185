interface VerticalArrowProps {
  position: "down" | "up";
  onClick?: () => void;

  className?: string;
}

export const VerticalArrow = ({ position, onClick, className }: VerticalArrowProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={className}
    transform={position === "up" ? "rotate(180)" : undefined}
  >
    <g clipPath="url(#clip0_23466_17881)">
      <path
        d="M18 9L12 15L6 9"
        stroke="#A4A4AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23466_17881">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
