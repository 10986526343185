import courseFinished from "assets/img/course-finished.png";
import courseNotFinished from "assets/img/course-not-finished.svg";
import myAcademyCourseNotFinished from "assets/img/myacademy-course-not-finished.svg";
import rostechnadzorCourseNotFinished from "assets/img/rostechnadzor-course-not-finished.svg";
import myAcademyCourseFinished from "assets/img/myacademy_course-finished.svg";
import rostechnadzorCourseFinished from "assets/img/rostechnadzor-course-finished.svg";

import classNames from "classnames";
import { Text } from "components/Text";
import styles from "./CourseWithoutReports.module.scss";

interface CourseWithoutReportsProps {
  isFinished?: boolean;
}

const DescriptionText = () => (
  <>
    Для данного курса не предусмотрено оценочных заданий.
    <br />
    Главное - ознакомиться со всеми материалами, после этого курс будет автоматически считаться
    завершенным.
  </>
);

const getFinishedImage = () => {
  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return rostechnadzorCourseFinished;
  }
  if (process.env.REACT_APP_THEME === "myAcademy") {
    return myAcademyCourseFinished;
  }

  if (process.env.REACT_APP_THEME === "base") {
    return courseFinished;
  }
};

const getNotFinishedImage = () => {
  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return rostechnadzorCourseNotFinished;
  }
  if (process.env.REACT_APP_THEME === "myAcademy") {
    return myAcademyCourseNotFinished;
  }

  if (process.env.REACT_APP_THEME === "base") {
    return courseNotFinished;
  }
};

console.log(getFinishedImage(), getNotFinishedImage());

export const CourseWithoutReports = ({ isFinished = false }: CourseWithoutReportsProps) => (
  <>
    <Text className={styles["no-reports__description"]}>
      <DescriptionText />
    </Text>
    <img
      src={isFinished ? getFinishedImage() : getNotFinishedImage()}
      alt="courseNotFinishedImage"
      className={classNames(styles["no-reports__image"], {
        [styles["no-reports__image_my_academy"]]: process.env.REACT_APP_THEME === "myAcademy",
        [styles["no-reports__image_rostechnadzor"]]:
          process.env.REACT_APP_THEME === "rostechnadzor",
      })}
    />
  </>
);
