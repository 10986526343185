import { useEffect, useRef } from "react";
import Container from "../../../../components/Container/Container";
import { H1 } from "../../../../components/Typography/Titles";
import {
  getCourseLesson,
  hideAsideNav,
  lessonsSelectors,
  setLessonQuizCompleted,
} from "../../redux/courseSlice";
import CourseQFooter from "../Quiz/CourseQFooter";
import {
  clearCurrentOpenPageinQuiz,
  getQuizeQuestionAnswers,
  setCurrentOpenPageInQuiz,
} from "./redux/Quze/courseQuizeQuestionsSlice";

import { getQuizeQuestions, questionsSelectors } from "./redux/Quze/courseQuizeQuestionsSlice";

import { EntityId } from "@reduxjs/toolkit/src/entities/models";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import CourseTestContentHeader from "../CourseTestContentHeader";
import { Questions, QuestionsProgress } from "./components";
import QuizeCounter from "./components/QuizeCounter";
import QuizeLoadSkeleton from "./components/QuizeLoadSkeleton";
import {
  selectCoursePosition,
  selectCourseQuizeDetail,
  selectCurrentOpenPageInQuiz,
} from "./redux/Quze/courseQuizeSelectors";
import {
  clearAttemptStatus,
  getQuize,
  getQuizeResult,
  setQuizeComplite,
  setQuizeFinish,
} from "./redux/Quze/courseQuizeSlice";

const CourseTestQuestions = () => {
  const dispatch = useAppDispatch();
  const questions = useAppSelector(questionsSelectors.selectAll);
  const quizeDetail = useAppSelector(selectCourseQuizeDetail);
  const position = useAppSelector(selectCoursePosition);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);
  const countPages = position === 0 ? 0 : Math.ceil(questions.length / position);
  const debouncedQuestionRef = useRef<{ [id: string | number]: () => unknown }>({});

  const { lesson_id } = useParams();
  const lesson = useAppSelector((state) =>
    lessonsSelectors.selectById(state, lesson_id as EntityId)
  );

  const handlePrevQuest = () => {
    if (currentOpenPageInQuiz > 0) {
      dispatch(setCurrentOpenPageInQuiz(currentOpenPageInQuiz - 1));
    }
  };

  const handleNextQuest = () => {
    if (currentOpenPageInQuiz < countPages) {
      dispatch(setCurrentOpenPageInQuiz(currentOpenPageInQuiz + 1));
    }
  };

  const handleDoneQuest = async () => {
    for (const id in debouncedQuestionRef.current) {
      await debouncedQuestionRef.current[id]();
    }

    dispatch(setQuizeFinish(lesson_id))
      .unwrap()
      .then(() => {
        dispatch(clearAttemptStatus());
        dispatch(getQuizeResult(lesson_id));

        dispatch(getQuize(lesson_id));

        dispatch(setQuizeComplite());

        dispatch(getCourseLesson(lesson_id))
          .unwrap()
          .then((x) => {
            if (x.status !== "completed") return;
            dispatch(setLessonQuizCompleted(lesson_id));
          });
      });
  };

  const handleChange = (id: string | number, flush: () => unknown) => {
    debouncedQuestionRef.current[id] = flush;
  };

  useEffect(() => {
    if (lesson?.attempt_id && quizeDetail?.attempt_status === "started") {
      dispatch(
        getQuizeQuestionAnswers({
          quizeId: lesson?.id ?? 0,
          attemptId: lesson.attempt_id ?? 0,
        })
      );
    }
    dispatch(getQuizeQuestions(lesson?.id)).then(() => {
      dispatch(hideAsideNav());
    });
  }, [dispatch, lesson?.attempt_id, lesson?.id, quizeDetail?.attempt_status]);

  useEffect(
    () => () => {
      dispatch(clearCurrentOpenPageinQuiz());
    },
    [dispatch]
  );

  return (
    <>
      <Container>
        <CourseTestContentHeader status={""}>
          <H1 className="course-test-top__title">
            {quizeDetail.title}
            <span className="course-test-top__title-addition">
              {quizeDetail.time_to_complete ? (
                <QuizeCounter
                  timeSpent={quizeDetail.time_spent}
                  totalTime={quizeDetail.time_to_complete}
                  lesson={lesson}
                  onCounterEnd={handleDoneQuest}
                />
              ) : null}
            </span>
          </H1>
        </CourseTestContentHeader>
        {!questions.length ? (
          <QuizeLoadSkeleton />
        ) : (
          <>
            {countPages > 1 && <QuestionsProgress />}
            <Questions lessonId={lesson_id ?? ""} onChange={handleChange} />
          </>
        )}
      </Container>
      <CourseQFooter
        start={0}
        max={countPages}
        current={currentOpenPageInQuiz}
        onNext={handleNextQuest}
        onPrev={handlePrevQuest}
        onDone={handleDoneQuest}
      />
    </>
  );
};

export default CourseTestQuestions;
