import { useResponsive } from "ahooks";
import { WebAppRoutes } from "app/routes";
import classNames from "classnames";
import { getFormatIcon } from "components/CourseTypeTile/CourseTypeTile";
import { CheckCircleIcon } from "components/Icons";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { MulticourseContent } from "pages/Course/redux/interfaces/course.interface";
import { getCoursesFormats } from "pages/Courses/redux/coursesListSlice";
import { selectFormats } from "pages/Courses/redux/coursesListSliceSelectors";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./MulticourseMenuAsideTab.module.scss";

export const MulticourseMenuAsideTab = ({
  isOpen,
  courseId,
  content,
  toogleMenu,
}: {
  isOpen: boolean;
  courseId?: string;
  content: MulticourseContent;
  toogleMenu: () => void;
}) => {
  const { tablet } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formats = useAppSelector(selectFormats);

  useEffect(() => {
    dispatch(getCoursesFormats());
  }, [dispatch]);

  const handlerItemClick = () => {
    navigate(WebAppRoutes.COURSE + "/" + courseId + WebAppRoutes.CONTENT + "/" + content.id);

    if (!tablet) {
      toogleMenu();
    }
  };

  return (
    <div
      onClick={handlerItemClick}
      className={classNames(styles["container"], {
        [styles["active"]]: isOpen,
      })}
    >
      <div className={styles["title"]}>
        <div className={styles["icon"]}>
          {content.status === EnrolledStatus.completed ? (
            <CheckCircleIcon className={styles["complited"]} />
          ) : (
            getFormatIcon(content.format)
          )}
        </div>
        {formats.find((x) => x.value === content.format)?.label || content.format}
      </div>
      <div className={styles["description"]}>{content.name}</div>
    </div>
  );
};
