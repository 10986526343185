import { Capacitor } from "@capacitor/core";
import { CourseAuthor } from "components/CourseAuthor";
import { ScrollbarContainerContext } from "components/ScrollbarContainer/context";
import { useAppSelector } from "hooks/redux";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useContext, useEffect } from "react";
import Container from "../../components/Container/Container";
import { CourseFormat } from "../Course/redux/interfaces/course.interface";
import { DescriptionBlock } from "./components/DescriptionBlock";
import { FeedbackList } from "./components/FeedbackList";
import { LandingAchievements } from "./components/LandingAchievements";
import { LandingCompetenciesList } from "./components/LandingCompetenciesList";
import { LandingConversation } from "./components/LandingConversation";
import { LandingMain } from "./components/LandingMain";
import { LandingRating } from "./components/LandingRating";
import { MultiCoursesList } from "./components/MultiCoursesList";
import { MulticourseContentList } from "./components/MulticourseContentList";
import { RulesList } from "./components/RulesList";
import { StudyProgramm } from "./components/StudyProgramm";
import { useCourseLanding } from "./useCourseLanding";
import { CertificateBlock } from "./components/CertificateBlock";
import styles from "./CourseLanding.module.scss";

export const CourseLanding = () => {
  const {
    currentCourse,
    isLoadData,
    multiCourseSummary,
    course_id,
    multicourses,
    certificateAvailability,
  } = useCourseLanding();

  const { scrollTo } = useContext(ScrollbarContainerContext);

  const isMulticourse = currentCourse?.format === CourseFormat.multicourse;
  const isCourse = currentCourse?.format === CourseFormat.course;
  const selectedLmsSettings = useAppSelector(lmsSettings);
  const description = currentCourse?.description;

  useEffect(() => {
    if (!course_id) return;
    scrollTo(0);
  }, [course_id, scrollTo]);

  return (
    <main className={styles["flex-wrapper"]}>
      <LandingMain course={currentCourse} loaded={isLoadData} isMulticourse={isMulticourse} />

      <Container className={styles["container"]}>
        <div className={styles["content-wrapper"]}>
          {/*
            порядок блоков установлен
              в соответствии с документацией
                https://wiki.lanit.ru/pages/viewpage.action?pageId=360488219
          */}

          {/* Блок Правила */}
          {multiCourseSummary && (
            <RulesList summary={multiCourseSummary} courseFormat={currentCourse?.format} />
          )}

          {/* Блок Рейтинг курса */}
          {currentCourse?.commenting && <LandingRating courseId={course_id} />}

          {/* Блок Сертификат */}
          {certificateAvailability && <CertificateBlock />}

          {/* Описание курса */}
          {description && description.length > 450 && <DescriptionBlock text={description} />}

          {/* Блок Поможет развить компетенции */}
          {selectedLmsSettings.competences && !Capacitor.isNativePlatform() && (
            <LandingCompetenciesList />
          )}

          {/* Блок Программа обучения */}
          {isCourse && <StudyProgramm />}

          {/* Блок Программа обучения состоит из n курсов */}
          {isMulticourse && <MulticourseContentList />}

          {/* Блок Награды */}
          <LandingAchievements courseId={course_id} />

          {/* Блок Автор курса */}
          {currentCourse?.author &&
            (currentCourse.author.name ||
              currentCourse.author.title ||
              currentCourse.author.company ||
              currentCourse.author.intro) && <CourseAuthor author={currentCourse.author} />}

          {/* Входит в траектории обучения */}
          {multicourses.length > 0 && (
            <MultiCoursesList multiCourses={multicourses} currentFormat={currentCourse?.format} />
          )}

          {/* Блок Отзывы участников */}
          <FeedbackList id={course_id} />

          {/* Блок Вопросы и ответы */}
          <LandingConversation courseId={course_id} />
        </div>
      </Container>
    </main>
  );
};
