import { SVGProps } from "react";

export const QuizRostechnadzorIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={647}
    height={520}
    viewBox="0 0 647 520"
    fill="none"
    {...props}
  >
    <rect
      width={424.305}
      height={424.305}
      x={94.818}
      y={-15}
      fill="#ECF2F7"
      rx={60}
      transform="rotate(15 94.818 -15)"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M178.242 107.892c5.406-40.082 45.512-52.614 77.104-32.912 31.591 19.702 22.355 58.592 13.274 69.155-40.703 47.346-95.783 3.838-90.378-36.243Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M221.941 88.628c-9.187 1.722-15.274 11.291-15.015 17.214.131 2.994 3.649 4.913 6.131 3.412 3.723-2.251 1.394-6.944 6.901-11.291 4.07-3.212 9.101-.927 10.53 2.277 1.428 3.204-3.19 9.02-5.031 12.703-4.373 8.745-1.819 13.81.432 17.534 2.297 3.798 8.14 1.688 5.958-3.602-5.293-12.834 5.653-15.037 6.183-25.094.488-9.283-6.902-14.874-16.089-13.153ZM230.905 141.588c1.213 2.84 4.279 2.655 6.118.582 1.839-2.074.389-4.387-.441-5.034-3.04-2.371-6.89 1.611-5.677 4.452Z"
    />
    <path
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M478.063 451.909c-77.318 32.801-113.634 55.059-135.892 63.259-22.258 8.201-44.516 0-77.317-14.057-32.802-14.058-161.981-94.248-188.22-110.281-21.088-12.887-20.999-29.07 0-41.002 51.542-29.287 149.249-57.799 155.418-66.612 8.201-11.715 16.4-206.18 32.802-210.867 16.401-4.686 258.898 93.719 270.612 113.634 11.715 19.915-19.914 221.41-38.658 227.267-2.422.757-4.57 1.142-6.461 1.237 4.017 12.394 4.274 30.22-12.284 37.422Z"
    />
    <path
      fill="#fff"
      d="M245.332 308.817c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.909c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M245.332 308.817c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.909c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792ZM467.907 433.002c-15.229 10.543-123.005 59.745-135.891 63.26-12.886 3.514-28.116 0-49.202-8.201"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M444.478 384.971c-23.429-8.2-176.249-93.123-190.307-98.98 0-4.686 18.164-186.96 25.193-186.96s230.717 90.303 231.889 103.19c1.171 12.886-19.916 130.034-26.944 154.635-7.029 24.601-16.401 36.316-39.831 28.115Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M300.486 134.789 422.5 183.5M297 148.855l123.5 50.144M295 164.121l122 50.378M333.053 226.511c4.752-14.525 19.943-14.936 29.903-5.511 9.96 9.425 3.813 21.451.002 25-13.96 13.001-34.658-4.964-29.905-19.489Z"
    />
    <path
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M291.139 247.213c5.5 1 79.361 38.787 86.861 42.787s1 20.5-1.5 25-6 6.286-12.5 3.5c-18-7.714-71.899-36.306-79.861-40.287-6.001-3-4-15-3-23 .813-6.5 4.5-9 10-8Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M379.053 247.511c4.752-14.525 19.943-14.936 29.903-5.511 9.96 9.425 3.813 21.451.002 25-13.96 13.001-34.658-4.964-29.905-19.489Z"
    />
    <ellipse
      cx={305.427}
      cy={215.786}
      fill="#ECF2F7"
      rx={18.806}
      ry={19.573}
      transform="rotate(25.659 305.427 215.786)"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M296.799 206.653c-.431 7.096 1.604 13.534 6.184 12.311 4.58-1.222 18.347-8.104 19.203-9.087"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M286.052 207.511c4.752-14.525 19.944-14.936 29.904-5.511 9.959 9.425 3.812 21.451.001 25-13.96 13.001-34.657-4.964-29.905-19.489Z"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M472.832 141.585c51.346-36.578 108.351-4.324 119.62 52.606 11.268 56.931-42.806 87.882-64.499 88.814-97.235 4.178-106.468-104.842-55.121-141.42Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M541.321 170.751c-11.751-8.614-29.124-5.336-35.583 1.273-3.264 3.34-1.697 9.386 2.655 10.609 6.529 1.835 9.384-5.817 20.198-4.187 7.992 1.204 10.755 9.362 8.634 14.402-2.121 5.04-13.659 5.988-19.809 7.819-14.608 4.349-17.646 12.653-19.481 19.183-1.871 6.66 6.756 11.057 10.447 2.936 8.954-19.701 23.128-9.599 35.13-19.718 11.078-9.34 9.56-23.704-2.191-32.317ZM490.644 237.398c-1.937 4.408 1.542 7.697 5.86 7.578 4.318-.118 5.403-4.233 5.254-5.867-.544-5.985-9.177-6.118-11.114-1.711Z"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M496.166 256.756c44.121-8.832 71.832 28.818 62.55 69.187-9.283 40.368-53.538 44.74-67.938 39.013-64.549-25.667-38.733-99.367 5.388-108.2Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M532.257 295.748c-5.138-9.041-17.41-11.978-23.547-9.56-3.101 1.222-3.845 5.618-1.368 7.685 3.717 3.101 7.81-1.049 14.378 3.17 4.853 3.118 4.271 9.238 1.418 11.901-2.853 2.663-10.644-.088-15.185-.691-10.783-1.432-15.187 3.089-18.288 6.806-3.163 3.791 1.172 9.174 5.947 4.963 11.584-10.217 17.865.5 28.637-2.586 9.942-2.849 13.147-12.647 8.008-21.688ZM479.793 324.359c-2.549 2.305-1.243 5.463 1.604 6.647 2.847 1.183 4.755-1.18 5.135-2.287 1.393-4.057-4.19-6.665-6.739-4.36Z"
    />
    <path
      fill="#fff"
      d="M100.5 264c-9.5-5.5-12.5-43-17-57.5-1.395-5.5 25.449-9.5 35.499-9.5 18.5 0 30.001 1.5 31.501 6s-6 61-14.5 61-26 5.5-35.5 0Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M137.002 208.406c-11.885 5.415-40.65 6.617-53.002-5.494 22.076-11.887 53.848-5.079 62.73-3.958 8.882 1.121 4.896 21.27 0 42.028-5.552 23.535-7.772 28.018-27.202 28.018-19.429 0-21.766-6.215-28.311-28.018C85.666 222.489 84 216.003 84 216.003"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M55 188.166c4.334-5.833 17.1-16.699 33.5-13.499-4-8-3.5-25.999 2.5-25.999 5.214 0 18.645 8.941 23.491 14.895 4.638-7.052 16.82-18.44 20.009-14.896 4.5 5.001 6.5 12 8.5 21.5 2-4.5 30-12 30-7.5s-4.5 19-13.5 25.499c6.5 0 22 20.501 13.5 20.501h-32c-2.5 4.5-14 20.5-16.5 20.5s-19.5-9.5-25-15c-11 6-34 4.5-31.5 0 1.442-2.595 5.59-13.145 9.97-19.548-7.639-.989-18.095-4.463-22.97-6.453Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M103.5 186.167c-2-2.5-4.499-13.167-2.999-13.667 1.5-.5 11.499 1.5 16.499 7.668 1-4.668 8.813-9.168 12.5-9.168 1.5 0 4.5 10.5 3 15.167"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M98.498 186.668c-5.5 1.5-6.998 12.5-5.5 14 3.2 3.203 18.001-3.5 20.001-6.5 0 3.5 3.841 15.5 11 15.5 3 0 11.5-21.071 5.5-18.5-3.5 1.5 13.5 12.5 25.5 9.5 2-.5-2.5-17-12-17M91.673 258c-17.85 2.209-25.103 13.808-7.81 20.99 11.714 4.864 45.184 12.704 68.057.552 12.436-6.607 19.529-18.78-7.252-21.542"
    />
  </svg>
);
