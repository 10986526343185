import { Dispatch, SetStateAction } from "react";
import { Select } from "../../../../../components/Select";
import { Option } from "../../../../../components/Select/types";
import styles from "../CoursesSearch.module.scss";

interface DurationSearchProps {
  durations: Option[];
  selectedDurations: Option[] | undefined;
  setSelectedDurations: Dispatch<SetStateAction<Option[] | undefined>>;
}

export const DurationSearch = ({
  durations,
  selectedDurations,
  setSelectedDurations,
}: DurationSearchProps) => {
  const handleSetSelectedDuration = (selectedOptions: Option[]) =>
    setSelectedDurations(selectedOptions);

  const isSelectedAll = selectedDurations?.length === durations.length;

  return (
    <div className={styles["primary-control"]}>
      <Select
        isMulti={true}
        showCleaner={!isSelectedAll && !!selectedDurations}
        label="Длительность"
        onChange={(selectedOptions) => handleSetSelectedDuration(selectedOptions as Option[])}
        value={selectedDurations}
        options={durations}
      />
    </div>
  );
};
