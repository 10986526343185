import styles from "./AttemptComment.module.scss";
import { CatSvgImage, CornerSvgImage } from "components/SvgImages";

interface AttemptCommentProps {
  comment: string;
}

export const AttemptComment = ({ comment }: AttemptCommentProps) => (
  <div className={styles.container}>
    <CatSvgImage />
    <div className={styles["message-container"]}>
      <CornerSvgImage className={styles["corner"]} />
      <p className={styles.message}>{comment}</p>
    </div>
  </div>
);
