import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../../../services/api/api";
import { EndPoints } from "../../../../../app/endpoints";

const getTask = createAsyncThunk(
  "courseTask/getTask",
  async (lesson_id: number | undefined, thunkAPI) => {
    const response = await api.get(EndPoints.TASK(lesson_id));
    return response.data;
  }
);

const getTaskResult = createAsyncThunk(
  "courseTask/getTaskResult",
  async (lesson_id: number | undefined, thunkAPI) => {
    const response = await api.get(EndPoints.TASK_RESULTS(lesson_id));
    return response.data;
  }
);

const setTaskTextAnswer = createAsyncThunk(
  "courseTask/setTaskTextAnswer",
  async ({ id, answer }: { id: number | undefined; answer: any | undefined }, thunkAPI) => {
    const response = await api.post(EndPoints.TASK_TEXT_ANSWER(id), {
      answer: answer,
    });
    return response.data;
  }
);

const setTaskFileAnswer = createAsyncThunk(
  "courseTask/setTaskFileAnswer",
  async (
    { id, formData }: { id: number | null | undefined; formData: any | undefined },
    thunkAPI
  ) => {
    try {
      const response = await api.post(EndPoints.TASK_FILE_ANSWER(id), formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const initialState = {
  isLoading: false,
  task_state: "idle",
  detail: {
    id: undefined,
    title: "",
    description: "",
    files: [],
    start_ts: "",
    end_ts: "",
    start_dt: "",
    end_dt: "",
    task_type: "",
    character_count: 0,
    files_count: 2,
    file_types: "",
    file_size: 0,
    total_attempts: 0,
    user_attempts: 0,
    confirmation: 0,
  },
  maxmark: 0,
  results: [],
};

const taskSlice = createSlice({
  name: "courseTask",
  initialState,
  reducers: {
    setTaskIdle(state) {
      state.task_state = "idle";
    },
    setTaskStarted(state) {
      state.task_state = "started";
    },
    setTaskComplite(state) {
      state.task_state = "complete";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTask.pending, (state, action) => {
        state.isLoading = true;
        state.detail = initialState.detail;
      })
      .addCase(getTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detail = action.payload?.data;
      })
      .addCase(getTask.rejected, (state, action) => {
        state.isLoading = true;
        state.detail = initialState.detail;
      })

      .addCase(getTaskResult.pending, (state, action) => {
        state.results = [];
        state.maxmark = 0;
      })
      .addCase(getTaskResult.fulfilled, (state, { payload }) => {
        state.maxmark = payload?.data?.maxmark;
        state.results = payload?.data?.result;
      })
      .addCase(getTaskResult.rejected, (state, action) => {
        state.results = [];
        state.maxmark = 0;
      });
  },
});

export const { setTaskIdle, setTaskStarted, setTaskComplite } = taskSlice.actions;
export { getTask, getTaskResult, setTaskTextAnswer, setTaskFileAnswer };
export default taskSlice.reducer;
