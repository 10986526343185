import { SVGProps } from "react";

export const TaskRostechnadzorIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={693}
    height={520}
    viewBox="0 0 693 520"
    fill="none"
    {...props}
  >
    <rect
      width={424.305}
      height={424.305}
      x={94.818}
      y={-15}
      fill="#ECF2F7"
      rx={60}
      transform="rotate(15 94.818 -15)"
    />
    <path
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M478.063 451.909c-77.318 32.801-113.633 55.059-135.892 63.259-22.258 8.201-44.516 0-77.317-14.057-32.802-14.058-161.98-94.248-188.22-110.281-21.088-12.887-20.999-29.07 0-41.002 51.542-29.287 149.249-57.799 155.418-66.612 8.201-11.715 16.4-206.18 32.802-210.867 16.402-4.686 258.898 93.719 270.612 113.634 11.715 19.915-19.914 221.41-38.658 227.267-2.422.757-4.569 1.142-6.461 1.237 4.017 12.394 4.274 30.22-12.284 37.422Z"
    />
    <path
      fill="#fff"
      d="M245.332 308.816c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.908c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M245.332 308.816c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.908c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792ZM467.908 433.002c-15.23 10.543-123.006 59.745-135.892 63.26-12.886 3.514-28.115 0-49.202-8.201"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M444.478 384.971c-23.429-8.2-176.249-93.123-190.307-98.98 0-4.686 18.165-186.96 25.193-186.96 7.029 0 230.717 90.303 231.889 103.19 1.171 12.886-19.915 130.034-26.944 154.635s-16.401 36.316-39.831 28.115Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M297.5 131c5.5.5 71 27.5 93 38 7 3.341-5 38.182-13 36-5.5-1.5-82.783-30.878-85.5-34.5-3.001-4 0-40 5.5-39.5ZM306.5 146.676l-3 20.324m0 0-5-1.5m5 1.5 4.5 1.5m-5.5-23 8.5 2.5M285 196.399l69.964 27.993M283 211.399l69.964 27.993M281 226.399l69.964 27.993M279 241.399l69.964 27.993"
    />
    <path
      fill="#fff"
      d="M155.404 160.097c-28.706-20.344-75.73-12.109-91.404 3.296 3.98 33.61 5.375 68.417 12 101.54 2.184 10.919 69.71 8.299 69.71-4.177 0-11.256 1.405-28.14 3.43-35.937 11.233 4.221 27.698 2.533 28.821-15.477 1.123-18.01-22.422-25.326-28.821-11.256 0-9.088 6.264-37.989 6.264-37.989Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M145.71 165.447c-13.311 9.204-62.196 11.298-81.71-2.054m0 0c15.674-15.405 62.698-23.64 91.404-3.296 0 0-6.264 28.901-6.264 37.989 6.399-14.07 29.944-6.754 28.821 11.256-1.123 18.01-17.588 19.698-28.821 15.477-2.025 7.797-3.43 24.681-3.43 35.937 0 12.476-67.526 15.096-69.71 4.177-6.625-33.123-8.02-67.93-12-101.54ZM108.262 137.633c-7.495-6.404-2.075-7.302 5.609-11.692 7.149-4.085-40.47-11.736-5.803-15.544 21.672-2.381 17.611-22.735-18.907-19.054-17.365 1.75-14.474 7.598-4.423 8.812 8.172.988 62.125.192 50.551-21.656-2.711-5.117-11.822-7.932-22.28-9.416"
    />
    <path
      fill="#fff"
      d="M513 350.001C486.5 350.78 407.001 300.5 397.501 298c-17-6.58-13-21.999-7-34.999 6-13 50.5-134.001 55.5-141.001s18.483-26.689 33.498-18.5c16.501 9 141.999 37.501 134.5 58.5-6.423 17.983-64.499 145.501-70.499 154.001s-4 33.22-30.5 34Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M441.5 132c-2.111 5.969-20.359 55.472-28.5 78.5m131.301 118.781c3.297 1.166 12.607 6.312 16.979-6.054l53.918-152.509c5.829-16.487-4.305-21.925-21.617-28.045l-112.826-39.889c-8.763-3.097-11.177 15.524-35.784 16.923-19.206 1.091-14.334-14.239 2.88-8.153l131.075 46.341c12.366 4.371 12.216 12.665 9.01 21.733-3.206 9.068-42.752 131.417-52.461 148.387-9.709 16.971-24.065 15.605-34.782 11.816-5.376-1.9-41.137-15.648-63.193-23.331"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M468.801 227.775c-11.021 3.411-50.414 26.712-59.991 31.243-24.501 11.591-7.993 32.828 8.668 25.322 15.307-6.895 66.302-31.12 73.832-35.21 11.262-6.116 18.207-18.53 14.209-31.513-3.998-12.984-18.472-22.363-32.382-21.192-13.91 1.172-76.17 34.388-85.155 39.59-19.591 11.342-24.732 31.78-17.329 49.012 7.404 17.232 17.868 25.804 28.965 29.118 11.098 3.314 31.666-2.811 41.626-8.424 7.967-4.49 45.573-23.063 63.381-31.788M555.241 159.619l-6.12 17.312c-.875 2.473-.733 9.942 8.335 13.148l15.663 5.538"
    />
  </svg>
);
