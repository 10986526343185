import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";

const ErrorPage = ({ pic, title, text }: { pic: JSX.Element; title: string; text: string }) => {
  const navigate = useNavigate();
  return (
    <>
      <article className="error-page">
        <div className="error-page__pic">{pic}</div>
        <h1 className="error-page__title">{title}</h1>
        <div className="error-page__wrap">
          <p className="error-page__text">{text}</p>
        </div>
        <div className="error-page__btn">
          <Button
            size="big"
            color="primary"
            onClick={() => {
              navigate("/courses");
            }}
          >
            Каталог курсов
          </Button>
        </div>
      </article>
    </>
  );
};

export default ErrorPage;
