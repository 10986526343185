import { SVGProps } from "react";

export const CourseGradeWebinarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#929BAA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
    />
    <path
      stroke="#929BAA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12 22v-4 4Zm-5 0h5-5Zm10 0h-5 5Z"
    />
  </svg>
);
