import { CourseAuthorProps } from "pages/Course/redux/interfaces/course.interface";
import { CapybaraAvatarSvg } from "./CapybaraAvatarSvg";
import { CatAvatarSvg } from "./CatAvatarSvg";
import { ImagesAvatar } from "components/Avatar/Avatar";
import styles from "./CourseAuthor.module.scss";
import classNames from "classnames";

export const CourseAuthor = ({ author }: { author: CourseAuthorProps }) => (
  <div
    className={classNames(styles["container"], {
      [styles["my-academy-theme"]]: process.env.REACT_APP_THEME === "myAcademy",
      [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
    })}
  >
    <div className={styles["autor-photo"]}>
      <div className={styles["avatar-container"]}>
        {author.photo ? (
          <ImagesAvatar src={author.photo} />
        ) : process.env.REACT_APP_THEME === "myAcademy" ? (
          <CapybaraAvatarSvg />
        ) : (
          <CatAvatarSvg />
        )}
      </div>
      <div className={styles["author-tile"]}>Преподаватель</div>
    </div>
    <div className={styles["info"]}>
      <div className={styles["name"]}>{author.name}</div>
      <div className={styles["position"]}>
        {author.title} {author.title && author.company ? "в" : ""} {author.company}
      </div>
      <div className={styles["text"]}>{author.intro}</div>
    </div>
  </div>
);
