import Container from "../../components/Container/Container";
import { NotificationSettings } from "./components";

export const Settings = () => (
  <div className="settings-block">
    <Container>
      <div className="settings-blocks">
        <NotificationSettings />
      </div>
    </Container>
  </div>
);
