import { SVGProps } from "react";

export const ClockIcon = ({
  iconColor = "#FFC422",
  rectColor = "#191229",
  ...otherProps
}: { iconColor?: string; rectColor?: string } & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={92}
      height={96}
      viewBox="0 0 92 96"
      fill="none"
      {...otherProps}
    >
      <path
        fill={rectColor}
        d="M48.485 18.195c12.76-7.9 26.862 2.668 33.046 15.08 9.424 18.918 5.132 37.9-13.823 45.799-18.954 7.898-39.551-5.394-46.548-20.198s14.565-32.782 27.325-40.681Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M50 68.52c14.728 0 26.667-11.938 26.667-26.666 0-14.727-11.94-26.666-26.667-26.666-14.728 0-26.667 11.939-26.667 26.666 0 14.728 11.94 26.667 26.667 26.667Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M50 25.855v16h12"
      />
    </svg>
  );
};
