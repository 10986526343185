import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  courseCertificateAvailability,
  selectMulticourseSummary,
} from "pages/Course/redux/courseSelectors";
import {
  clearCourseData,
  coursesSelectors,
  getCertificateAvailability,
  getCourseCoins,
  getCourseInfo,
} from "pages/Course/redux/courseSlice";
import { getCoursesFormats } from "pages/Courses/redux/coursesListSlice";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

export const useCourseLanding = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoadData, setIsLoadData] = useState(false);

  const { course_id = "" } = useParams();
  const currentCourse = useAppSelector((state) => coursesSelectors.selectById(state, course_id));
  const multiCourseSummary = useAppSelector(selectMulticourseSummary);
  const certificateAvailability = useAppSelector(courseCertificateAvailability);

  const multicourses = multiCourseSummary?.multicourses || [];

  useEffect(() => {
    dispatch(getCoursesFormats());
    dispatch(getCourseCoins(course_id));
    dispatch(getCertificateAvailability(course_id));

    return () => {
      dispatch(clearCourseData());
      setIsLoadData(false);
    };
  }, [dispatch, course_id]);

  useEffect(() => {
    // @ts-ignore
    dispatch(getCourseInfo(course_id)).then((response) => {
      // @ts-ignore
      if (response.payload?.status === 404) {
        navigate("/404", { replace: true });
        return response;
      }
      // @ts-ignore
      if (response.payload?.status === 403) {
        navigate("/403", { replace: true });
        return response;
      }
      // @ts-ignore
      if (response.payload?.status === 500) {
        setIsLoadData(false);
        return response;
      }
      setIsLoadData(true);
    });
  }, [dispatch, course_id, navigate]);

  return {
    currentCourse,
    isLoadData,
    multiCourseSummary,
    course_id,
    multicourses,
    certificateAvailability,
  };
};
