import { Option } from "components/Select/types";
import styles from "./MultiValueContainer.module.scss";

interface MultiValueContainerProps {
  selectedUpperLevelOptions?: Option[];
  onClick?: () => void;
  isSelectedAll: boolean;
  placeholder: string;
  label?: string;
  isSearching?: boolean;
  setSearchInputValue: (value: string) => void;
  setIsSearching: (isSearching: boolean) => void;
}

export const MultiValueContainer = ({
  selectedUpperLevelOptions = [],
  onClick = () => {},
  placeholder = "",
  label,
  isSelectedAll = false,
  isSearching = false,
  setSearchInputValue,
  setIsSearching,
}: MultiValueContainerProps) => {
  const onChangeSearchInputValue = (evant: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(evant.currentTarget.value);

  return (
    <div className={styles["wrapper"]} onClick={onClick}>
      {!isSearching && (
        <div className={styles["container"]}>
          {isSelectedAll && <div className={styles["text"]}>Все</div>}

          {selectedUpperLevelOptions?.length > 0 && !isSelectedAll && (
            <div className={styles["text"]}>Выбрано {selectedUpperLevelOptions.length}</div>
          )}
        </div>
      )}
      {isSearching && (
        <input
          type="text"
          onClick={() => {}}
          className={styles["input"]}
          autoFocus
          onFocus={() => setIsSearching(true)}
          onChange={onChangeSearchInputValue}
        />
      )}
    </div>
  );
};
