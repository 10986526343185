import { Button } from "../../../../components/Buttons";
import Container from "../../../../components/Container/Container";
import { H1 } from "../../../../components/Typography/Titles";
import { numWord } from "../../../../app/utils";
import { CourseSheetStartImg } from "../../../../components/Svg/Pics";
import { selectCourseQuestionnaire } from "./redux/Questionary/courseQuestionarySelectors";
import { setCourseLessonStart } from "../../redux/courseSlice";
import CourseTestContentHeader from "../CourseTestContentHeader";
import { setQuestionaryStarted } from "./redux/Questionary/courseQuestionarySlice";
import { getCourseQuestionnaireContent } from "./redux/Questionary/courseQuestionarySlice";
import { LessonDescription } from "../LessonDescription";
import { CourseLessonQuestionary } from "../../redux/interfaces";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { format, fromUnixTime, isAfter } from "date-fns";
import Alert from "components/Alert/Alert";
import { AchievementsListLesson } from "../AchievementsListLesson";
import { LessonCompetenciesList } from "../LessonCompetenciesList";
import {
  QuestionaryMyAcademyIllustration,
  QuestionaryRostechnadzorIllustration,
} from "components/Illustrations";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";

const CourseSheetStart = ({ lesson }: { lesson: CourseLessonQuestionary }) => {
  const dispatch = useAppDispatch();
  const questionnaire = useAppSelector(selectCourseQuestionnaire);
  const selectedLmsSettings = useAppSelector(lmsSettings);
  const { questions = [] } = questionnaire;
  const clickHandler = (id: number) => {
    if (questionnaire && !questionnaire.questions) {
      if (lesson.status === "not_started") {
        dispatch(setCourseLessonStart(id)).then(() => {
          // Получаем контент анкеты только после ее старта.
          dispatch(getCourseQuestionnaireContent(lesson.id)).then(() => {
            // Фиксим idle > started (getCourseQuestionnaireContent.pending выставляет "idle").
            dispatch(setQuestionaryStarted());
          });
        });
      }
      return;
    }

    if (lesson.status === "not_started") {
      dispatch(setCourseLessonStart(id));
    }
    dispatch(setQuestionaryStarted());
  };

  const questionsLength = lesson.questions_length || questions.length;

  const parsedStartDate = lesson?.start_ts ? fromUnixTime(+lesson.start_ts) : null;

  const parsedEndDate = lesson?.end_ts ? fromUnixTime(+lesson.end_ts) : null;

  const isLessonStarted = parsedStartDate ? isAfter(Date.now(), parsedStartDate) : !parsedStartDate;

  const isLessonEnded = parsedEndDate ? isAfter(Date.now(), parsedEndDate) : !!parsedEndDate;

  return (
    <>
      <Container>
        <div className="course-test-block">
          <div className="course-test-content course-sheet-content">
            <CourseTestContentHeader fixed={true} status={lesson?.status} coins={lesson.coins}>
              <H1 className="course-test-top__title">
                {lesson?.title}
                {questionsLength ? (
                  <span className="course-test-top__title-addition">
                    <span className="course-test-top-time">
                      <span className="course-test-top__time--default">
                        {questionsLength}{" "}
                        {numWord(questionsLength, ["вопрос", "вопроса", "вопросов"])}
                      </span>
                    </span>
                  </span>
                ) : null}
              </H1>
            </CourseTestContentHeader>

            <AchievementsListLesson />
            {selectedLmsSettings.competences && !Capacitor.isNativePlatform() && (
              <LessonCompetenciesList id={lesson.id} />
            )}
            <LessonDescription stringHTML={lesson?.description} />

            <div className="course-test-buttons">
              {isLessonStarted && !isLessonEnded && (
                <Button
                  size="big"
                  color="primary"
                  className="course-test__button"
                  onClick={() => {
                    clickHandler(lesson.id);
                  }}
                >
                  Начать
                </Button>
              )}
              {!isLessonStarted && parsedStartDate && (
                <Alert>
                  Дата начала прохождения анкеты:{" "}
                  {parsedStartDate ? format(parsedStartDate, "dd.MM.yyyy") : ""}
                </Alert>
              )}
              {isLessonEnded && parsedEndDate && (
                <Alert>
                  Прохождение анкеты не доступно с{" "}
                  {parsedEndDate ? format(parsedEndDate, "dd.MM.yyyy") : ""}
                </Alert>
              )}
            </div>
          </div>

          <div
            className={classNames("course-test-img course-test-main-img", {
              "course-test-img_rostechnadzor": process.env.REACT_APP_THEME === "rostechnadzor",
            })}
          >
            {process.env.REACT_APP_THEME === "rostechnadzor" && (
              <QuestionaryRostechnadzorIllustration />
            )}
            {process.env.REACT_APP_THEME === "myAcademy" && (
              <div className="course-pic" data-pic="sheet-start">
                <QuestionaryMyAcademyIllustration />
              </div>
            )}
            {process.env.REACT_APP_THEME === "base" && <CourseSheetStartImg />}
          </div>
        </div>
      </Container>
    </>
  );
};

export default CourseSheetStart;
