import { Button } from "../../../../components/Buttons";
import Container from "../../../../components/Container/Container";
import { CourseTestsImg } from "../../../../components/Svg/Pics";
import { TextMain } from "../../../../components/Typography/Texts";
import { H1 } from "../../../../components/Typography/Titles";

import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import { QuizMyAcademyIllustration, QuizRostechnadzorIllustration } from "components/Illustrations";
import { format, fromUnixTime, isAfter } from "date-fns";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { lessonsSelectors } from "pages/Course/redux/courseSlice";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { numWord, secondsToHmsFormat } from "../../../../app/utils";
import Alert from "../../../../components/Alert/Alert";
import { CourseLessonQuiz } from "../../redux/interfaces";
import { AchievementsListLesson } from "../AchievementsListLesson";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import CourseTestContentHeader from "../CourseTestContentHeader";
import { LessonCompetenciesList } from "../LessonCompetenciesList";
import { LessonDescription } from "../LessonDescription";
import styles from "./CourseTestStart.module.scss";
import { QuizAccesses } from "./components/QuizAccesses";
import { QuizResults } from "./components/QuizResults";
import { ScoringAttempt } from "./components/ScoringAttempt";
import { clearAllAnswers } from "./redux/Quze/courseQuizeQuestionsSlice";
import {
  selectCoursePassingScore,
  selectCourseQuizAccess,
  selectCourseQuizeAttemptStatus,
  selectCourseQuizeAttemptsLeft,
  selectCourseQuizeDetail,
  selectCourseQuizeIsLoading,
  selectCourseQuizeResults,
  selectCourseScore,
  selectCourseScoringAttempt,
} from "./redux/Quze/courseQuizeSelectors";
import { getQuizScore, setQuizeStart, setQuizeStarted } from "./redux/Quze/courseQuizeSlice";

const CourseTestStart = ({ lesson }: { lesson: CourseLessonQuiz }) => {
  const dispatch = useAppDispatch();
  const quizeDetail = useAppSelector(selectCourseQuizeDetail);
  const quizScoringAttempt = useAppSelector(selectCourseScoringAttempt);
  const quizeIsLoading = useAppSelector(selectCourseQuizeIsLoading);
  const quizeAttempts = useAppSelector(selectCourseQuizeAttemptsLeft);
  const quizAccess = useAppSelector(selectCourseQuizAccess);
  const quizePassingScore = useAppSelector(selectCoursePassingScore);
  const quizeAttemptStatus = useAppSelector(selectCourseQuizeAttemptStatus);
  const selectedLmsSettings = useAppSelector(lmsSettings);
  const allLesson = useAppSelector((state) => lessonsSelectors.selectAll(state));
  const score = useAppSelector(selectCourseScore);

  const preconditionsIsCompleted = allLesson
    .filter((lesson) => lesson.id && (quizeDetail.preconditions as number[])?.includes(lesson.id))
    .every((lesson) => lesson.status === "completed");

  const quizAccessList = quizAccess?.filter((access) => {
    const lesson = allLesson.find((lesson) => access.id.toString() === lesson.id.toString());

    return lesson && lesson?.status !== "completed";
  });

  const quizeResult = useAppSelector(selectCourseQuizeResults);

  useEffect(() => {
    dispatch(getQuizScore(lesson.id));
  }, [dispatch, lesson.id]);

  const handleStartQuize = () => {
    dispatch(clearAllAnswers());
    dispatch(setQuizeStart(lesson.id)).then((result) => {
      dispatch(setQuizeStarted());
    });
  };

  if (quizeIsLoading) {
    return <CourseLoadSkeleton />;
  }

  const parsedStartDate = lesson?.start_ts ? fromUnixTime(+lesson.start_ts) : null;

  const parsedEndDate = lesson?.end_ts ? fromUnixTime(+lesson.end_ts) : null;

  const isLessonStarted = parsedStartDate ? isAfter(Date.now(), parsedStartDate) : false;

  const isLessonEnded = parsedEndDate ? isAfter(Date.now(), parsedEndDate) : false;

  const alertText =
    !isLessonStarted && parsedStartDate
      ? `Дата начала теста: ${parsedStartDate && format(parsedStartDate, "dd.MM.yyyy")}`
      : isLessonEnded && parsedEndDate
      ? `Прохождение урока не доступно с ${parsedEndDate && format(parsedEndDate, "dd.MM.yyyy")}`
      : !quizeAttemptStatus && quizeDetail.attempt_status !== "started"
      ? "У вас закончились попытки"
      : "";

  return (
    <>
      <Container>
        <div className="course-test-block">
          <div className="course-test-content ">
            <CourseTestContentHeader fixed={true} status={lesson.status} coins={lesson.coins}>
              <H1 className="course-test-top__title">
                {lesson.title}
                {quizeDetail.time_to_complete ? (
                  <span className="course-test-top__title-addition">
                    <span className="course-test-top-time">
                      <span className="course-test-top__time--default">
                        {secondsToHmsFormat(quizeDetail.time_to_complete)}
                      </span>
                    </span>
                  </span>
                ) : null}
              </H1>
            </CourseTestContentHeader>

            <AchievementsListLesson />
            {selectedLmsSettings.competences && !Capacitor.isNativePlatform() && (
              <LessonCompetenciesList id={lesson.id} />
            )}

            <LessonDescription stringHTML={lesson?.description ?? quizeDetail.description} />
            {score?.score !== undefined && score?.score !== null ? (
              <p className={styles["passing-score"]}>
                По данному тесту вы получили{" "}
                <strong>
                  {parseFloat(score.score.toFixed(1))}{" "}
                  {numWord(score.score, ["балл", "балла", "баллов"])}.
                </strong>{" "}
                Этого {score.status ? "" : "не "}
                достаточно для завершения урока
              </p>
            ) : (
              +quizePassingScore > 0 && (
                <p className={styles["passing-score"]}>
                  По данному тесту необходимо набрать проходной балл {quizePassingScore}
                </p>
              )
            )}
            {quizScoringAttempt && (
              <div className={styles["quiz-scoring-attempt-container"]}>
                <ScoringAttempt scoringAttempt={quizScoringAttempt} />
              </div>
            )}
            {quizAccessList && quizAccessList.length > 0 && (
              <QuizAccesses quizAccesses={quizAccessList} />
            )}
            {quizeResult.length > 0 && (
              <>
                <QuizResults results={quizeResult} scoringAttempt={quizScoringAttempt} />
                {quizeAttempts > 0 && !quizeResult.some((x) => x.percentage_correct === 100) && (
                  <TextMain className="course-test-achieve__impetus">
                    Вы можете улучшить свой результат, пройдя тестирование еще раз.
                  </TextMain>
                )}
              </>
            )}
            {alertText ? (
              <div className="course-test-buttons">
                <Alert>{alertText}</Alert>
              </div>
            ) : (
              <>
                {preconditionsIsCompleted && (
                  <div className="course-test-buttons">
                    <Button
                      size="big"
                      color="primary"
                      className="course-test__button"
                      onClick={handleStartQuize}
                    >
                      Начать
                    </Button>
                  </div>
                )}
              </>
            )}

            {quizeAttempts > 0 && (
              <span className="course-test__attempt">
                {numWord(quizeAttempts, ["Осталась", "Осталось", "Осталось"])} {quizeAttempts}{" "}
                {numWord(quizeAttempts, ["попытка", "попытки", "попыток"])}
              </span>
            )}
          </div>

          <div
            className={classNames("course-test-img course-test-main-img", {
              "course-test-main-img_my-academy": process.env.REACT_APP_THEME === "myAcademy",
              "course-test-img_rostechnadzor": process.env.REACT_APP_THEME === "rostechnadzor",
            })}
          >
            {process.env.REACT_APP_THEME === "rostechnadzor" && <QuizRostechnadzorIllustration />}
            {process.env.REACT_APP_THEME === "myAcademy" && (
              <div className="course-pic" data-pic="test">
                <QuizMyAcademyIllustration />
              </div>
            )}
            {process.env.REACT_APP_THEME === "base" && <CourseTestsImg />}
          </div>
        </div>
      </Container>
    </>
  );
};

export default CourseTestStart;
