import { useMergeRefs } from "hooks";
import IMask, { AnyMaskedOptions, Masked } from "imask/esm/imask";
import { ChangeEvent, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { TextFieldWrap } from "../TextFieldWrap";

interface TextFieldProps {
  className?: string;
  disabled?: boolean;
  endAbortment?: JSX.Element;
  startAbortment?: JSX.Element;
  errorMessage?: string;
  isInvalid?: boolean;
  mask?: AnyMaskedOptions;
  label?: string;
  textFieldWrapRef?: Ref<HTMLDivElement>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  icon?: JSX.Element;
  showCleaner?: boolean;
  value?: string;
  maxLength?: number;
}

export const TextField = forwardRef(
  (
    {
      disabled = false,
      value = "",
      mask,
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      placeholder = "",
      maxLength,
      textFieldWrapRef,
      ...textFieldWrapProps
    }: TextFieldProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const textFieldRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");
    const [maskResolver, setMaskResolver] = useState<Masked<any> | null>(
      mask ? IMask.createMask(mask) : null
    );

    const mergedRefs = useMergeRefs([ref, textFieldRef]);

    const handleFocus = () => {
      setIsFocused(true);
      onFocus();
    };

    const handleBlur = () => {
      setIsFocused(false);
      onBlur();
    };

    useEffect(() => {
      if (mask) {
        setMaskResolver(IMask.createMask(mask));
      }
    }, [mask]);

    useEffect(() => {
      setInputValue(mask ? maskResolver?.resolve(value + "") ?? "" : value);
    }, [mask, maskResolver, value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (maskResolver) {
        setInputValue(maskResolver.resolve(event.target.value));
      } else {
        setInputValue(event.target.value);
      }
      onChange(event.target.value);
    };

    return (
      <TextFieldWrap
        {...textFieldWrapProps}
        isFocused={isFocused}
        isFilled={!!inputValue}
        isDisabled={disabled}
        textFieldWrapRef={textFieldWrapRef}
        onClear={() => {
          onChange("");
          if (textFieldRef.current) {
            textFieldRef.current.focus();
          }
        }}
      >
        <input
          value={inputValue}
          type="text"
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          maxLength={maxLength}
          ref={mergedRefs}
        />
      </TextFieldWrap>
    );
  }
);
