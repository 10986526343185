import { EventAttributes, DateArray } from "ics";
import { CalendarWithCheckIcon, DownloadIcon } from "components/Icons";
import styles from "./DownloadNotification.module.scss";
import { Link } from "components/Link";
import { useState } from "react";
import { useAsyncEffect } from "ahooks";
import { createFileWithEvents } from "./functions";
import { add, getDate, getHours, getMinutes, getMonth, getYear } from "date-fns";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { LinkButton } from "components/Buttons";
import { convertBlobToBase64 } from "app/utils";

const getDateForEvent = (date: Date): DateArray => [
  getYear(date),
  getMonth(date) + 1,
  getDate(date),
  getHours(date),
  getMinutes(date),
];

export const DownloadNotification = ({
  startEventDate,
  endEventDate,
  titleEvent,
}: {
  startEventDate?: Date;
  endEventDate?: Date;
  titleEvent?: string;
}) => {
  const [fileWithEvent, setFileWithEvent] = useState<File>();
  const fileName = `${titleEvent}.ics`;

  useAsyncEffect(async () => {
    if (startEventDate && endEventDate && titleEvent) {
      const fileName = `${titleEvent}.ics`;
      const event: EventAttributes = {
        title: `Вебинар "${titleEvent}"`,
        start: getDateForEvent(startEventDate),
        end:
          +endEventDate === 0
            ? getDateForEvent(
                add(startEventDate, {
                  hours: 1,
                })
              )
            : getDateForEvent(endEventDate),
        alarms: [
          {
            action: "audio",
            description: "Через 5 минут начнётся вебинар",
            trigger: { minutes: 5, before: true },
          },
        ],
      };

      const fileWithEvent = await createFileWithEvents({ event, fileName });
      setFileWithEvent(fileWithEvent);
    }
  }, [startEventDate, titleEvent, endEventDate]);

  const handleDownloadFileInMobile =
    ({ fileName, file }: { fileName: string; file: File }) =>
    async () => {
      const fileInBase64 = await convertBlobToBase64(file);
      const downloadDirectory = Directory.ExternalStorage;
      const uri = await Filesystem.getUri({
        directory: downloadDirectory,
        path: "Download",
      });
      const path = `${uri.uri}/${fileName}`;

      await Filesystem.writeFile({
        path,
        data: fileInBase64.replace(/^data:image\/[a-z]+;base64,/, ""),
      });
    };

  return (
    <div className={styles.container}>
      <div className={styles["text-container"]}>
        <CalendarWithCheckIcon className={styles["calendar-icon"]} />
        <p className={styles["text"]}>Напоминания на портале включены</p>
      </div>
      <div className={styles["link-container"]}>
        <DownloadIcon className={styles["download-icon"]} />
        {Capacitor.isNativePlatform() && fileWithEvent && (
          <LinkButton
            className={styles["link"]}
            onClick={handleDownloadFileInMobile({ fileName, file: fileWithEvent })}
          >
            Скачать событие в календарь
          </LinkButton>
        )}
        {!Capacitor.isNativePlatform() && fileWithEvent && (
          <Link
            className={styles["link"]}
            href={URL.createObjectURL(fileWithEvent)}
            download={fileName}
          >
            Скачать событие в календарь
          </Link>
        )}
      </div>
    </div>
  );
};
