import { MutableRefObject, useCallback } from "react";
import { PositionDropdownType } from "../types";

export const usePositionDropdown = (ref: MutableRefObject<HTMLElement | null>) => {
  const getPositionDropdown = useCallback(
    ({ position = "bottom-left" }: { position?: PositionDropdownType; elementWidth: number }) => {
      if (ref) {
        const { width, height } = ref.current?.getBoundingClientRect() ?? {
          bottom: 0,
          left: 0,
          top: 0,
          right: 0,
          height: 0,
        };

        if (width) {
          switch (position) {
            case "bottom-left": {
              return {
                top: height + 3,
                left: 0,
              };
            }
            case "bottom-right": {
              return {
                top: height + 3,
                right: 0,
              };
            }
          }
        }
      }
    },
    [ref]
  );

  return getPositionDropdown;
};
