import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getBadges,
  getCourseLesson,
  lessonsSelectors,
  setActiveLesson,
  showAsideNav,
  showBottomNav,
  updateVisite,
} from "../../redux/courseSlice";

import { EntityId } from "@reduxjs/toolkit/src/entities/models";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { CourseLessonQuiz, CourseLessonText } from "pages/Course/redux/interfaces";
import { selectIsLoadingCourseLesson, selectLessonIsLock } from "../../redux/courseSelectors";
import {
  CourseLesson,
  CourseLessonBook,
  CourseLessonQuestionary,
  CourseLessonTask,
} from "../../redux/interfaces/course.interface";
import CourseBook from "../Book/CourseBook";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import CourseLock from "../CourseLock";
import { CourseMeeting } from "../Meeting";
import CourseSheet from "../Questionary/CourseSheet";
import CourseTestIndex from "../Quiz/CourseTestIndex";
import TaskIndex from "../Task/TaskIndex";
import CourseTextIndex from "../Text/CourseTextIndex";
import CourseTrainingIndex from "../Traning/CourseTrainingIndex";

const CourseLessonContent = () => {
  const dispatch = useAppDispatch();
  const { lesson_id, course_id } = useParams();
  const lesson = useAppSelector((state) =>
    lessonsSelectors.selectById(state, lesson_id as EntityId)
  );
  const isLoadingCourseLesson = useAppSelector(selectIsLoadingCourseLesson);

  const lessonIsLock = useAppSelector((state) => selectLessonIsLock(state, lesson_id as string));

  useEffect(() => {
    dispatch(getCourseLesson(lesson_id)).then((result) => {
      dispatch(showBottomNav());
      dispatch(showAsideNav());
      dispatch(setActiveLesson(lesson_id));
    });
    dispatch(getBadges({ lesson_id }));
  }, [dispatch, lesson_id]);

  useEffect(() => {
    course_id && dispatch(updateVisite(course_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson?.id, course_id]);

  const renderCourseType = (lesson: CourseLesson | undefined) => {
    // https://wiki.lanit.ru/pages/viewpage.action?pageId=355346924
    switch (lesson?.type) {
      // 1 Страница (Лонгрид)
      case "page":
        return <CourseTextIndex key={"lesson_" + lesson.id} lesson={lesson as CourseLessonText} />;
      // 2 Интерактивная книга (АТБ)
      case "book":
        return <CourseBook key={"lesson_" + lesson.id} lesson={lesson as CourseLessonBook} />;
      case "atb":
        return <CourseBook key={"lesson_" + lesson.id} lesson={lesson as CourseLessonBook} />;
      // 3 Вебинар
      case "meeting":
        return <CourseMeeting key={"lesson_" + lesson.id} lessonId={lesson.id} />;
      // 4 Тест
      case "quiz":
        return <CourseTestIndex key={"lesson_" + lesson.id} lesson={lesson as CourseLessonQuiz} />;
      // 5 Анкета (Опрос, обратная связь)
      case "questionnaire":
        return (
          <CourseSheet key={"lesson_" + lesson.id} lesson={lesson as CourseLessonQuestionary} />
        );
      // 6 Тренажер (SCORM)
      case "scorm":
        return <CourseTrainingIndex key={"lesson_" + lesson.id} lessonId={lesson.id} />;
      // 7 Задание
      case "task":
        return <TaskIndex key={"lesson_" + lesson.id} lesson={lesson as CourseLessonTask} />;
      default:
        return <CourseLoadSkeleton />;
    }
  };

  if (isLoadingCourseLesson && !lessonIsLock) {
    return <CourseLoadSkeleton />;
  }

  return (
    <>
      {lessonIsLock ? (
        renderCourseType(lesson)
      ) : (
        <CourseLock
          title={lesson?.title}
          preconditions={lesson!.preconditions}
          type={lesson?.type}
        />
      )}
    </>
  );
};
export default CourseLessonContent;
