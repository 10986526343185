import { SVGProps } from "react";

export const HelpIcon = ({
  iconColor = "#FFC422",
  rectColor = "#191229",
  ...otherProps
}: { iconColor?: string; rectColor?: string } & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={93}
      height={96}
      viewBox="0 0 93 96"
      fill="none"
      {...otherProps}
    >
      <path
        fill={rectColor}
        d="M57.522 78.846c-10.816 10.403-26.824 3.033-35.477-7.803-13.188-16.516-12.98-35.977 3.893-47.68 16.873-11.704 39.803-3.035 49.753 9.969 9.95 13.004-7.354 35.11-18.17 45.514Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M50.667 68.52c14.727 0 26.666-11.938 26.666-26.666 0-14.727-11.939-26.666-26.666-26.666C35.939 15.188 24 27.127 24 41.853c0 14.728 11.94 26.667 26.667 26.667Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M42.907 33.857a8 8 0 0 1 15.546 2.667c0 5.333-8 8-8 8M50.667 55.188h.026"
      />
    </svg>
  );
};
