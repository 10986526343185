import classNames from "classnames";
import { LeftArrowLongIcon, RightArrowLongIcon } from "components/Icons";
import { closeConfirmModal, openConfirmModal } from "components/Modals/redux/modalsSlice";
import { useState } from "react";
import { mediaQuerys, numWord } from "../../../../app/utils";
import { Button } from "../../../../components/Buttons/Button";
import Container from "../../../../components/Container/Container";
import { ConfirmModal } from "../../../../components/Modals/ConfirmModal";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { questionsSelectors } from "./redux/Quze/courseQuizeQuestionsSlice";
import {
  allAnswersSelector,
  selectCourseNavigation,
  selectCoursePosition,
} from "./redux/Quze/courseQuizeSelectors";

const CourseQFooter = (props) => {
  const dispatch = useAppDispatch();
  const [descriptionConfirmModal, setDescriptionConfirmModal] = useState("");
  const allAnswers = useAppSelector(allAnswersSelector);
  const questions = useAppSelector(questionsSelectors.selectAll);
  const xsMax = useMediaQuery(`(max-width:${mediaQuerys.xsMax}px)`);
  const position = useAppSelector(selectCoursePosition);
  const mdMin = useMediaQuery(`(min-width:${mediaQuerys.mdMin}px)`);
  const navigation = useAppSelector(selectCourseNavigation);
  const smMinSmMax = useMediaQuery(
    `(min-width:${mediaQuerys.smMin}px) and (max-width:${mediaQuerys.smMax}px)`
  );

  const checkAnswers = () => {
    const filteredAllAnswers = allAnswers.filter((answer) => {
      if (!answer.answer) {
        return false;
      }

      if (Array.isArray(answer.answer) && answer.answer[0].length === 0) {
        return false;
      }

      return true;
    });
    if (filteredAllAnswers.length < questions.length) {
      setDescriptionConfirmModal(
        `Вы ответили на ${filteredAllAnswers.length} ${numWord(filteredAllAnswers.length, [
          "вопрос",
          "вопроса",
          "вопросов",
        ])} из ${questions.length}. Хотите завершить текущую попытку?`
      );
      dispatch(openConfirmModal());
      return;
    }
    props.onDone();
  };

  const onConfirm = () => {
    dispatch(closeConfirmModal());
    props.onDone();
  };

  const onDone = () => checkAnswers();

  return (
    <footer className="course-question-footer">
      <Container>
        <div className="course-question-footer-block">
          <div className="course-question-footer__prev">
            {props.current > 1 && navigation === "free" ? (
              <>
                {mdMin && position > 0 && (
                  <Button
                    className={classNames(
                      "course-question-footer__button course-question-footer__button_small",
                      {
                        "course-question-footer__button_grayText":
                          process.env.REACT_APP_THEME === "myAcademy",
                        "course-question-footer__button_rostechnadzor":
                          process.env.REACT_APP_THEME === "rostechnadzor",
                      }
                    )}
                    onClick={props.onPrev}
                  >
                    <>
                      <LeftArrowLongIcon />
                      Предыдущий вопрос
                    </>
                  </Button>
                )}
                {smMinSmMax && position > 0 && (
                  <Button
                    className={classNames(
                      "course-question-footer__button course-question-footer__button_small",
                      {
                        "course-question-footer__button_grayText":
                          process.env.REACT_APP_THEME === "myAcademy",
                      }
                    )}
                    onClick={props.onPrev}
                  >
                    <>
                      <LeftArrowLongIcon />
                      Предыдущий
                    </>
                  </Button>
                )}
                {xsMax && position > 0 && (
                  <Button
                    className={classNames("course-question-footer__button", {
                      "course-question-footer__button_grayText":
                        process.env.REACT_APP_THEME === "myAcademy",
                    })}
                    onClick={props.onPrev}
                  >
                    <LeftArrowLongIcon />
                  </Button>
                )}
              </>
            ) : null}
          </div>
          <div className="course-question-footer__next">
            {props.current < props.max && (
              <>
                {/*<ExclamationIcon />*/}
                <Button
                  size="small"
                  className={classNames({
                    "course-question-footer__button_grayText":
                      process.env.REACT_APP_THEME === "myAcademy",
                    "course-question-footer__button_rostechnadzor":
                      process.env.REACT_APP_THEME === "rostechnadzor",
                  })}
                  onClick={onDone}
                  style={{ marginRight: "12px" }}
                >
                  Завершить
                </Button>

                {mdMin && position > 0 && (
                  <Button
                    className="course-question-footer__button course-question-footer__button_small"
                    size="big"
                    color="primary"
                    onClick={props.onNext}
                  >
                    <>
                      Следующий
                      <RightArrowLongIcon />
                    </>
                  </Button>
                )}
                {smMinSmMax && position > 0 && (
                  <Button
                    className="course-question-footer__button"
                    size="big"
                    color="primary"
                    onClick={props.onNext}
                  >
                    <>
                      Следующий
                      <RightArrowLongIcon />
                    </>
                  </Button>
                )}
                {xsMax && position > 0 && (
                  <Button
                    className="course-question-footer__button"
                    size="big"
                    color="primary"
                    onClick={props.onNext}
                  >
                    <RightArrowLongIcon />
                  </Button>
                )}
              </>
            )}
            {props.current >= props.max && (
              <>
                <Button
                  className={classNames("course-question-footer__button", {
                    "course-question-footer__button_grayText":
                      process.env.REACT_APP_THEME === "myAcademy",
                  })}
                  size="big"
                  color="primary"
                  onClick={onDone}
                >
                  Завершить
                </Button>
              </>
            )}
          </div>
        </div>
      </Container>
      <ConfirmModal
        onConfirm={onConfirm}
        title="Завершение теста"
        description={descriptionConfirmModal}
      />
    </footer>
  );
};

export default CourseQFooter;
