import { Link } from "components/Link";
import { policyLink } from "app/constants";
import styles from "../../AuthorizationForm.module.scss";

interface RegistrationPolicyAgreementProps {
  policyCheckReg: boolean;
}

export const RegistrationPolicyAgreement = ({
  policyCheckReg,
}: RegistrationPolicyAgreementProps): JSX.Element => (
  <>
    {policyCheckReg && (
      <div className={styles["registration-agreement"]}>
        Введя код, вы соглашаетесь с условиями сервиса
        <br />и{" "}
        <Link href={policyLink} target={"_blank"} download>
          политикой обработки персональных данных
        </Link>
      </div>
    )}
  </>
);
