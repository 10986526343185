import { DefaultModal } from "components/Modals/DefaultModal";
import { useModal } from "components/ModalsContainer";
import styles from "../../RewardsShop.module.scss";

export const BuyErrorModal = () => {
  const { closeModal } = useModal();

  return (
    <DefaultModal
      buttonsClassName={styles["modal-buttons"]}
      title="Покупка не удалась"
      description="Извините, сейчас товар недоступен. Попробуйте выбрать другой товар в Магазине"
      confirmBtnText="ОК"
      onConfirm={closeModal}
    />
  );
};
