import classNames from "classnames";
import {
  HistoryCourseRatingBg,
  TestResultLeftLeafPlugUnfilled,
  TestResultLeftLeafPlugUnfilledRostechnadzor,
  TestResultRightLeafPlugUnfilled,
  TestResultRightLeafPlugUnfilledRostechnadzor,
  TestResultLeftLeafPlugUnfilledRostechnadzorNotFinished,
  TestResultRightLeafPlugUnfilledRostechnadzorNotFinished,
} from "components/Svg/Pics";
import { LeafIcon, LeafWithoutShadow } from "components/svg-images";
import styles from "../../TrajectoryResultCard.module.scss";

interface ResultBlockProps {
  result?: number;
  isFinished: boolean;
}

export const ResultBlock = ({ result, isFinished }: ResultBlockProps): JSX.Element => {
  const Leaf = isFinished ? LeafIcon : LeafWithoutShadow;

  const text = isFinished
    ? !result
      ? "Оценка в курсе не предусмотрена"
      : "Итоговая оценка"
    : "Оценка появится после завершения";

  const myAcademyTheme = process.env.REACT_APP_THEME === "myAcademy";

  const getLeftLeaf = () => {
    if (process.env.REACT_APP_THEME === "rostechnadzor") {
      return isFinished ? (
        <TestResultLeftLeafPlugUnfilledRostechnadzor />
      ) : (
        <TestResultLeftLeafPlugUnfilledRostechnadzorNotFinished />
      );
    }
    if (process.env.REACT_APP_THEME === "myAcademy") {
      return <TestResultLeftLeafPlugUnfilled className={styles["leaf-white"]} />;
    }

    if (process.env.REACT_APP_THEME === "base") {
      return <Leaf className={classNames(styles["leaf"], styles["left"])} />;
    }

    return null;
  };

  const getRightLeaf = () => {
    if (process.env.REACT_APP_THEME === "rostechnadzor") {
      return isFinished ? (
        <TestResultRightLeafPlugUnfilledRostechnadzor />
      ) : (
        <TestResultRightLeafPlugUnfilledRostechnadzorNotFinished />
      );
    }
    if (process.env.REACT_APP_THEME === "myAcademy") {
      return <TestResultRightLeafPlugUnfilled className={styles["leaf-white"]} />;
    }

    if (process.env.REACT_APP_THEME === "base") {
      return <Leaf className={classNames(styles["leaf"], styles["right"])} />;
    }

    return null;
  };

  return (
    <div
      className={classNames(styles["result-container"], {
        [styles["not-finished"]]: !isFinished,
        [styles["result-container-blue"]]: myAcademyTheme,
      })}
    >
      <div className={styles["result"]}>
        {getLeftLeaf()}
        <span
          className={classNames(styles["text"], {
            [styles["text-result"]]: isFinished && result,
            [styles["text-not-result"]]: isFinished && !result,
          })}
        >
          {text}
        </span>
        {getRightLeaf()}
      </div>
      {isFinished && !!result && (
        <>
          <div className={styles["better"]}>{result}%</div>
        </>
      )}
      {isFinished && <HistoryCourseRatingBg className={styles["svg"]} />}
    </div>
  );
};
