import { Skeleton } from "../../../../components/Skeleton";

export const CourseSkeleton = () => (
  <div className="paper-box study-programm-item">
    <div className="study-programm-item-bg">
      <Skeleton
        className={"study-programm-item-bg__skeleton"}
        variant="rounded"
        width="calc(100% - 20px)"
        height="calc(100% - 20px)"
      />
    </div>
    <div className="study-programm-item-content">
      <h3 className="title study-programm-item__title">
        <Skeleton variant="rounded" height={"24px"} />
      </h3>
      <div className="text-card study-programm-item__descr">
        <Skeleton variant="text" height={"24px"} width="calc(100% - 15px)" />
        <Skeleton variant="text" height={"24px"} width="calc(100% - 35px)" />
        <Skeleton variant="text" height={"24px"} width="calc(100% - 25px)" />
      </div>
    </div>
  </div>
);
