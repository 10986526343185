import { generateUid } from "app/utils";
import { IShopProduct } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import { ShopCard } from "../ShopCard/ShopCard";
import styles from "./RewardsList.module.scss";

interface RewardsListProps {
  items: IShopProduct[];
}

export const RewardsList = ({ items }: RewardsListProps) => {
  return (
    <div className={styles["container"]}>
      {items.map((item) => (
        <ShopCard key={generateUid()} item={item} />
      ))}
    </div>
  );
};
