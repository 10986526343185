import { useMediaQuery } from "hooks";
import { CapybaraEmptyPic } from "../../../components/Svg/Pics";
import { TextMain } from "../../../components/Typography/Texts";
import { H3 } from "../../../components/Typography/Titles";
import { ChairLampIllustration } from "components/Illustrations/ChairLampIllustration";

const CoursesEmpty = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <>
      {process.env.REACT_APP_THEME === "rostechnadzor" && (
        <section className="course-empty-myAcademy">
          <div className="course-empty-myAcademy__img">
            <ChairLampIllustration />
          </div>
          <div style={{ paddingLeft: isMobile ? 0 : 48 }}>
            <H3 className="course-empty-text__title">Нет доступных курсов</H3>
            <TextMain className="course-empty-text__descr">
              Мы уже добавляем новые курсы. Попробуйте зайти на эту страницу позже
            </TextMain>
          </div>
        </section>
      )}
      {process.env.REACT_APP_THEME === "myAcademy" && (
        <section className="course-empty-myAcademy">
          <div className="course-empty-myAcademy__img">
            <CapybaraEmptyPic />
          </div>
          <div style={{ paddingLeft: isMobile ? 0 : 48 }}>
            <H3 className="course-empty-text__title">Нет доступных курсов</H3>
            <TextMain className="course-empty-text__descr">
              Мы уже добавляем новые курсы. Попробуйте зайти на эту страницу позже
            </TextMain>
          </div>
        </section>
      )}
      {process.env.REACT_APP_THEME === "base" && (
        <section className="course-empty">
          <div className="course-empty-img">
            <ChairLampIllustration />{" "}
          </div>
          <div className="course-empty-text">
            <H3 className="course-empty-text__title">Мы не нашли ничего похожего</H3>
            <TextMain className="course-empty-text__descr">
              Нет доступных курсов. Мы уже добавляем новые курсы. Попробуйте зайти на эту страницу
              позже
            </TextMain>
          </div>
        </section>
      )}
    </>
  );
};
export default CoursesEmpty;
