import { AuthorizationForm } from "../AuthorizationForm";
import { AuthorizationLeftContent } from "../AuthorizationLeftContent";
import styles from "./AuthorizationWindow.module.scss";

export const AuthorizationWindow = (props: any) => (
  <section className={styles["container"]} data-form={props.context} data-active={props.showcase}>
    <div className={styles["inner"]}>
      <AuthorizationLeftContent />
      <AuthorizationForm />
    </div>
  </section>
);
