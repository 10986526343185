import { CourseCoinAmount } from "components/CourseCoinAmount";
import { useAppSelector } from "hooks/redux";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import { dateTsToFormat } from "../../../../app/utils/date.util";
import defaultRtnImageSvg from "../../../../assets/img/rostechnadzor_course-card-head.svg";
import defaultEsImageSvg from "../../../../assets/img/course-card-head.svg";
import defaultMaImageSvg from "../../../../assets/img/study-default.my-academy.svg";
import { CourseCardBadge } from "../../../../components/CourseCardBadge";
import { CourseTypeTile } from "../../../../components/CourseTypeTile";
import { CourseFormat, MultiCourse } from "../../../Course/redux/interfaces/course.interface";
import styles from "./MultiCoursesList.module.scss";
import classNames from "classnames";

export const MultiCoursesCard = ({ course }: { course: MultiCourse }) => {
  const navigate = useNavigate();
  const shopSettings = useAppSelector(selectShopSettings);

  const handlerClick = (id: number) => {
    navigate(WebAppRoutes.COURSE_PREVIEW + "/" + id);
  };

  const getDefaultImage = () => {
    if (process.env.REACT_APP_THEME === "rostechnadzor") {
      return defaultRtnImageSvg;
    }
    if (process.env.REACT_APP_THEME === "myAcademy") {
      return defaultMaImageSvg;
    }
    if (process.env.REACT_APP_THEME === "base") {
      return defaultEsImageSvg;
    }
  };

  return (
    <div
      className={classNames(styles["card"], {
        [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
        [styles["myacademy-theme"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
      onClick={() => handlerClick(course.id)}
    >
      <div className={styles["card-image"]}>
        {course.start_ts && (
          <div className={styles["card-start-date-container"]}>
            <p className={styles["card-start-date-label"]}>Старт</p>
            <p className={styles["card-start-date"]}>{dateTsToFormat(course.start_ts)}</p>
          </div>
        )}

        <img src={course.preview || getDefaultImage()} alt="course-logo" />
      </div>

      <div className={styles["card-container"]}>
        <div className={styles["card-header"]}>
          <CourseTypeTile className={styles["course-format"]} type={CourseFormat.multicourse} />

          {shopSettings?.enable && (
            <div className={styles["coins"]}>
              <CourseCoinAmount coin={course?.coins} />
              <CourseCoinAmount coin={course?.linked_courses} allCourses />
            </div>
          )}
        </div>

        <div className={styles["card-title"]}>{course.name}</div>

        <p>{course.description}</p>

        <div className={styles["card-info"]}>
          {(!!course.rating || !!course.comments_count) && (
            <div>
              {!!course.rating && <CourseCardBadge type="rating" text={course.rating.toString()} />}

              {!!course.comments_count && (
                <CourseCardBadge type="comments" text={course.comments_count.toString()} />
              )}
            </div>
          )}

          {!!course.passed_count && <span>Пройден: {course.passed_count}</span>}
        </div>
      </div>
    </div>
  );
};
