import { Capacitor } from "@capacitor/core";
import axios, { AxiosAdapter, AxiosRequestConfig } from "axios";
import { throttleAdapterEnhancer } from "axios-extensions";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { EndPoints } from "../../app/endpoints";
import { RootState, store } from "../../app/store";
import { refreshToken } from "../../features/auth/authSlice";

const api = axios.create({
  baseURL: Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE || "" : "/api",
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
  withCredentials: true,
  adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { threshold: 750 }),
});

let inited = false;
let checkTokenInProcess = false;
let refreshTokenPromise: any = null;

const getExpirationDate = (jwtToken: string) => {
  if (!jwtToken) {
    return null;
  }
  const jwt = jwt_decode<JwtPayload>(jwtToken);
  if (!jwt.exp) {
    console.error("No token exp");
  }
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

const isExpired = (exp: any) => {
  if (!exp) {
    return false;
  }
  return Date.now() + 10000 > exp;
};

async function checkToken(store: RootState) {
  try {
    return new Promise<void>((resolve, reject) => {
      const oidc = localStorage?.oidc ? JSON.parse(localStorage?.oidc) : {};
      if (!checkTokenInProcess) {
        if (oidc?.idToken && isExpired(getExpirationDate(oidc?.idToken))) {
          checkTokenInProcess = true;
          // @ts-ignore
          refreshTokenPromise = store.dispatch(
            // @ts-ignore
            refreshToken({ refreshToken: oidc.refreshToken })
          );
          refreshTokenPromise.then(
            function () {
              resolve();
              checkTokenInProcess = false;
            },
            function () {
              checkTokenInProcess = false;
            }
          );
        } else {
          resolve();
        }
      } else if (refreshTokenPromise) {
        refreshTokenPromise.then(function () {
          resolve();
        });
      }
    });
  } catch (e) {
    console.log(e);
  }
}

const setUpInterceptors = () => {
  if (inited) {
    return;
  }
  inited = true;

  api.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config.url !== EndPoints.AUTH_REFRESH) {
        // @ts-ignore
        await checkToken(store);
      }

      const oidc = localStorage?.oidc ? JSON.parse(localStorage?.oidc) : {};
      const token = oidc?.idToken;
      const accessToken = oidc?.accessToken;
      if (token && config?.headers) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      if (accessToken && config?.headers) {
        config.headers["Access-Token"] = accessToken;
      }
      return config;
    },
    (error) => error
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error?.config;
      if (error?.response?.status === 400 && config.url.includes(EndPoints.AUTH_REFRESH)) {
        localStorage.removeItem("oidc");
        return Promise.reject(error);
      }

      if (
        config.url.includes(EndPoints.AUTH_REFRESH) ||
        config.url.includes(EndPoints.AUTH_LOGIN) ||
        config.url.includes(EndPoints.AUTH_LOGIN_ANONYMOUS)
      ) {
        return Promise.reject(error);
      }

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;
        // @ts-ignore
        await checkToken(store);
        const oidc = localStorage?.oidc ? JSON.parse(localStorage?.oidc) : {};
        const token = oidc?.idToken;

        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }

        return api(config);
      }
      return Promise.reject(error);
    }
  );
};

setUpInterceptors();

export default api;
export { checkToken, setUpInterceptors };
