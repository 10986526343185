import CourseTrainingStart from "./CourseTraningStart";
import { useEffect, useState } from "react";
import { getCourseTraining } from "../../redux/course-trainer.slice";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import { courseTrainersSelector } from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import CourseTraining from "./CourseTraining";
import { selectLessonCoins } from "../../redux/courseSelectors";

const CourseTrainingIndex = ({ lessonId }: { lessonId: number }) => {
  const { trainer, visibleTrainer, results } = useSelector(courseTrainersSelector);
  const coins = useSelector(selectLessonCoins(lessonId));
  const dispatch = useDispatch<any>();

  const [isLoadData, setIsLoadData] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsLoadData(false);
    };
  }, []);

  useEffect(() => {
    dispatch(getCourseTraining(lessonId))
      .unwrap()
      .then(() => {
        setIsLoadData(true);
      });
  }, [dispatch, lessonId, setIsLoadData]);

  if (isLoadData && visibleTrainer) {
    return <CourseTraining trainer={trainer} />;
  }

  return isLoadData ? (
    <CourseTrainingStart lesson={trainer} results={results} coins={coins} />
  ) : (
    <CourseLoadSkeleton />
  );
};

export default CourseTrainingIndex;
