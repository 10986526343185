import { InfoIcon } from "components/Icons";
import { TextMain } from "../Typography/Texts";

const TestAlertRight = ({ text, descr }: { text: string; descr?: string }) => {
  return (
    <div className="test-alert">
      <div className="test-alert-img">
        <InfoIcon width="22px" height="22px" color="rgb(var(--success-color))" />
      </div>
      <div className="test-alert-text">
        <TextMain className="test-alert-text__title">{text}</TextMain>
        {descr && <TextMain className="test-alert-text__descr">{descr}</TextMain>}
      </div>
    </div>
  );
};

export default TestAlertRight;
