import PaperBox from "components/Paper/PaperBox";
import { Button } from "components/Buttons";
import { H2 } from "components/Typography/Titles";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { Skeleton } from "../../../../components/Skeleton";
import { createMessage, getMessages } from "./redux/thunk-actions";
import { selectConversation } from "./redux/selectors";
import { MessageList } from "./components/Message/MessageList";
import { IMessage } from "./redux/interfaces";
import { QuestionForm } from "./components/QuestionForm";
import styles from "./LandingConversation.module.scss";

export const LandingConversation = ({ courseId }: { courseId: string }) => {
  const dispatch = useAppDispatch();
  const { isLoading, messages, manager } = useAppSelector(selectConversation);

  const [showMessages, setShowMessages] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getMessages(courseId));
  }, [courseId, dispatch]);

  const handleClickToggleBtn = () => setShowMessages((prev) => !prev);

  const totalMessages = (messages: IMessage[]) => {
    let count = 0;
    try {
      for (const message of messages) {
        if (!message.deleted) count += 1;
        if (message.children?.length) {
          count += totalMessages(message.children);
        }
      }
    } catch {
      return 0;
    }
    return count;
  };

  const onSubmitNewMessage = (text: string) => {
    dispatch(createMessage({ course_id: courseId, text }));
  };

  if (isLoading) return <Skeleton width={350} height={80} />;

  return (
    <div>
      <H2 className={styles["title"]}>Вопросы и ответы: {totalMessages(messages)}</H2>

      <Button size="small" className={styles["toggle-btn"]} onClick={handleClickToggleBtn}>
        {showMessages ? "Свернуть все" : "Показать все"}
      </Button>

      {showMessages && (
        <PaperBox className={styles["conversation"]}>
          {/* Добавление сообщения */}
          <QuestionForm label="Задайте вопрос" withHeader={false} onSubmit={onSubmitNewMessage} />

          {messages.length > 0 && (
            <MessageList messages={messages} manager={manager} courseId={courseId} />
          )}
        </PaperBox>
      )}
    </div>
  );
};
