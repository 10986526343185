import { SVGProps } from "react";

export const RocketSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={534}
    height={480}
    fill="none"
    viewBox="0 0 534 480"
    {...props}
  >
    <rect
      width={278.423}
      height={288.239}
      x={170.602}
      y={54}
      fill="#fff"
      rx={16}
      transform="rotate(15 170.602 54)"
    />
    <path
      fill="#9CC7FF"
      d="M178.767 278.566c5.602-24.857 21.357-47.264 32.21-51.816 2.696 17.506 9.831 54.127 16.805 60.569-10.153-1.751-20.483-.502-27.658 8.052-9.103 10.853-8.053 32.21-12.954 32.21-4.902 0-14.004-24.157-8.403-49.015ZM350.32 278.216c-5.601-24.857-17.505-45.513-32.209-51.815 0 12.721-9.336 45.446-14.004 60.218 10.153-1.75 19.956 1.4 24.857 8.403 6.403 9.146 8.052 32.209 12.954 32.209 4.901 0 14.004-24.157 8.402-49.015Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M211.328 203.294c-1.051 47.265 14.004 82.275 19.956 89.627 6.841 12.954 64.069 7.002 67.92 4.551 3.851-2.451 19.606-53.916 19.606-94.178S279.948 58 264.894 58c-13.332 0-52.516 98.03-53.566 145.294Z"
    />
    <path
      fill="#9CC7FF"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M272.596 252.658c0 8.519-1.751 56.017-3.151 67.221-.723 5.78-5.252 6.232-5.252-2.101 0-3.151-2.8-59.353-2.8-66.87 0-15.405 11.203-18.556 11.203 1.75Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M234.435 111.566c7.702 3.151 24.507 8.403 45.163 3.151"
    />
    <path
      fill="#9CC7FF"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M286.822 184.618c1.524-14.392-6.855-24.618-21.329-24.618-11.808 0-20.949 5.302-23.234 18.937-2.285 13.634 11.046 24.239 20.949 24.997 9.903.757 22.091-4.924 23.614-19.316Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M294.76 185.57c2.062-19.626-9.282-33.57-28.878-33.57-15.985 0-28.361 7.23-31.455 25.823-3.094 18.593 11.073 32.755 28.361 34.086 13.408 1.033 29.909-6.714 31.972-26.339ZM210.977 226.751c-10.854 4.551-26.608 26.958-32.21 51.815-5.602 24.858 3.501 49.015 8.402 49.015 4.902 0 3.852-21.356 12.954-32.21 7.175-8.554 17.506-9.803 27.659-8.052M318.111 226.401c14.704 6.302 26.608 26.958 32.209 51.815 5.602 24.858-3.501 49.015-8.402 49.015-4.902 0-6.551-23.063-12.954-32.209-4.901-7.003-14.704-10.153-24.857-8.403"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M246.339 300.623c1.751 19.956 7.002 84.726-7.002 95.229s2.451 5.952 2.451-5.602c0-7.492-4.902-18.205-19.606-14.354-20.504 5.37-12.604 28.008-7.002 22.757 4.342-4.071 3.04-37.615-22.407-43.413-27.658-6.302-46.914 37.694-38.161 40.612 6.542 2.181 9.452-5.602 1.05-37.811-11.325-43.413-54.266-34.123-54.266 4.901 0 7.002 10.984 11.203 11.553-2.451.7-16.805-48.803-47.112-69.32-17.855-18.907 26.958-1.052 53.916 12.602 50.065 13.654-3.851-22.757-12.954-54.266 0M287.626 300.623c-1.751 19.956-7.002 84.726 7.002 95.229s-2.451 5.952-2.451-5.602c0-7.492 4.902-18.205 19.606-14.354 20.504 5.37 12.604 28.008 7.002 22.757-4.342-4.071-3.04-37.615 22.407-43.413 27.658-6.302 46.914 37.694 38.161 40.612-6.542 2.181-9.453-5.602-1.05-37.811 11.325-43.413 54.266-34.123 54.266 4.901 0 7.002-10.984 11.203-11.553-2.451-.7-16.805 48.802-47.112 69.321-17.855 18.906 26.958 1.051 53.916-12.603 50.065-13.655-3.851 22.757-12.954 54.266 0"
    />
  </svg>
);
