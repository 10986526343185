import { Link } from "components/Link";
import { WebAppRoutes } from "./routes";

export const messagesWithLink = {
  EMAIL_ENTER: (nav: any) => (
    <>
      {process.env.REACT_APP_THEME === "myAcademy"
        ? "Если у вас есть аккаунт на mylanit.ru, используйте корпоративную электронную почту и пароль от нее"
        : "Если у вас есть аккаунт в корпоративном домене, используйте его логин и пароль"}{" "}
      {<Link onClick={() => nav(WebAppRoutes.AUTH)}>для входа</Link>}.
    </>
  ),
};
