import { H3 } from "../../../../../../../../components/Typography/Titles";
import { TextMain } from "../../../../../../../../components/Typography/Texts";
import { useSelector } from "react-redux";

import { QuestResultAlert } from "./QuestResultAlert";
import { IQuizeQuestionProp } from "../../../../redux/Quze/interface/quiz.interface";
import { allAnswersSelector } from "pages/Course/components/Quiz/redux/Quze/courseQuizeSelectors";
import { QuestDescription } from "../QuestDescription/QuestDescription";

export const QuestCalculateResult = ({
  title,
  right,
  wrong,
  description,
  questionsId,
}: IQuizeQuestionProp) => {
  const allAnswers = useSelector(allAnswersSelector);

  const findedAnwer: any = allAnswers.find((answer: any) => answer.id === questionsId);
  const rightClass = right ? " course-questions-bubble__item--right" : "";
  const wrongClass = wrong ? " course-questions-bubble__item--misstake" : "";

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <TextMain className="course-questions-block__descr"></TextMain>
      <div className="course-questions-block-wrapper">
        <div
          className="course-questions-row-item"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <QuestDescription data={description} />
          <span className={"course-questions-bubble__item" + rightClass + wrongClass}>
            {findedAnwer ? findedAnwer?.answer : null}
          </span>
        </div>
      </div>
      <QuestResultAlert wrong={wrong} right={right} />
    </div>
  );
};
