import { useState } from "react";
import classNames from "classnames";
import { StarBigIcon } from "../Icons/StarBigIcon";
import { generateUid, mediaQuerys } from "../../app/utils";
import { useMediaQuery } from "hooks";
import styles from "./StarRating.module.scss";

interface StarRatingProps {
  score: number;
  onClick: (score: number) => void;
}

export const StarRating = ({ score = 0, onClick }: StarRatingProps) => {
  const [hover, setHover] = useState<number | null>(null);

  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  return (
    <div className={styles["star-rating"]}>
      {[...Array(5)].map((star, index) => {
        const selectedStar = index + 1;
        const isActive = selectedStar <= (hover || score);
        return (
          <div
            key={generateUid()}
            className={classNames(styles["star"], { [styles["star--active"]]: isActive })}
            onClick={() => onClick(selectedStar)}
            onMouseEnter={() => !isMobile && setHover(selectedStar)}
            onMouseLeave={() => !isMobile && setHover(null)}
          >
            <StarBigIcon fill={isActive ? "currentColor" : "none"} />
          </div>
        );
      })}
    </div>
  );
};
