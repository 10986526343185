import classNames from "classnames";
import { MinusIcon, SmallCheckIcon } from "components/Icons";
import { CSSProperties, Ref, useEffect, useState } from "react";
import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  className?: string;
  disabled?: boolean;
  value?: boolean | null;
  onChange?: (value: boolean | null) => void;
  onBlur?: () => void;
  ref?: Ref<HTMLInputElement>;
  style?: CSSProperties;
  id?: string;
}

export const Checkbox = ({
  className,
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  ref = () => {},
  value = false,
  id = undefined,
  style,
}: CheckboxProps) => {
  const [checkboxValue, setCheckboxValue] = useState<boolean | null>(value);
  const checkboxClassNames = classNames(
    styles["checkbox"],
    { [styles["checkbox_checked"]]: checkboxValue === null ? true : checkboxValue },
    { [styles["checkbox_disabled"]]: disabled },
    { [styles["checkbox_myacademy_theme"]]: process.env.REACT_APP_THEME === "myAcademy" },
    { [styles["checkbox_rostechnadzor_theme"]]: process.env.REACT_APP_THEME === "rostechnadzor" },
    className
  );

  useEffect(() => {
    setCheckboxValue(value);
  }, [value]);

  return (
    <>
      <div className={checkboxClassNames} style={style}>
        <input
          type="checkbox"
          checked={checkboxValue ?? false}
          className={styles["checkbox-native"]}
          onChange={() => {
            onChange(!checkboxValue);
            setCheckboxValue(!checkboxValue);
          }}
          onBlur={onBlur}
          ref={ref}
          disabled={disabled}
          id={id}
        />
        {checkboxValue === null ? (
          <MinusIcon className={styles["checkbox-icon"]} />
        ) : (
          <SmallCheckIcon className={styles["checkbox-icon"]} />
        )}
      </div>
    </>
  );
};
