import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseHeader from "./components/CourseHeader";
import CourseMenu from "./components/CourseMenu";
import CourseNavBottom from "./components/CourseNavBottom";

import {
  selectCourseAsideNav,
  selectCourseBottomNav,
} from "../../pages/Course/redux/courseSelectors";
import {
  clearCourseData,
  coursesSelectors,
  setIsOpenCourseMenu,
} from "../../pages/Course/redux/courseSlice";

import { EntityId } from "@reduxjs/toolkit/src/entities/models";
import { ScrollbarContainer } from "components/ScrollbarContainer";
import { RootState } from "../../app/store";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getMyCorsesList } from "../../pages/My/redux/myCoursesSlice";
import { selectProfileLoad } from "../../pages/Profile/redux/profile-slice/selectors/profile.selectors";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";

const CourseLayout = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { course_id } = useParams();
  const [scrollType, setScrollType] = useState<string>("start");

  const profileLoad = useAppSelector(selectProfileLoad);

  useEffect(() => {
    if (!profileLoad) {
      dispatch(getMyCorsesList());
    }
  }, [profileLoad, dispatch]);

  const [menuOpen, setMenuOpen] = useState<boolean>(true);

  const isVisibleBottomNav = useAppSelector(selectCourseBottomNav);
  const isVisibleAsideNav = useAppSelector(selectCourseAsideNav);

  const currentCourse = useAppSelector((state: RootState) =>
    coursesSelectors.selectById(state, course_id as EntityId)
  );

  useEffect(() => {
    return () => {
      dispatch(clearCourseData());
    };
  }, [dispatch]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    dispatch(setIsOpenCourseMenu(!menuOpen));
  };

  return (
    <ScrollbarContainer
      className={"page"}
      upButtonClassName="course-test-upButtonNav"
      events={{
        // @ts-ignore
        scroll: (_instance, event) => {
          // @ts-ignore
          const scrollTop = event?.target?.scrollTop ?? 0;
          if (scrollTop === 0) {
            setScrollType("start");
            return;
          }
          setScrollType("progress");
        },
      }}
    >
      <div className="page__content">
        <div className="course-wrap">
          <CourseHeader
            courseId={course_id}
            courseTitle={currentCourse?.name}
            isOpen={menuOpen}
            toggleMobileMenuCallback={toggleMenu}
          />
          <main
            className={classNames("course-test-main", {
              "course-test-main--active": menuOpen && isVisibleAsideNav,
              "course-test-main-ios-fix":
                Capacitor.getPlatform() === "ios" && Capacitor.isNativePlatform(),
            })}
          >
            {children}
            {isVisibleBottomNav ? <CourseNavBottom /> : null}
            {isVisibleAsideNav ? (
              <CourseMenu
                isOpen={menuOpen}
                toggleCallback={toggleMenu}
                scrollType={scrollType}
                canPutFeedback={currentCourse?.commenting}
              />
            ) : null}
          </main>
        </div>
      </div>
    </ScrollbarContainer>
  );
};

export default CourseLayout;
