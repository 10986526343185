export enum WebAppRoutes {
  HOME = "/",

  // Аутинтефикация
  AUTH = "/auth",
  AUTH_EXIST = "/auth-exist",
  AUTH_AGREEMENT = "/auth-agreement",

  // Восстановление пароля
  PASSWORD_RECOVERY = "/password-recovery",

  // Восстановление доступа
  ACCESS_RECOVERY = "/access-recovery",
  ACCESS_RECOVERY_CODE_CHECK = "/access-recovery-code-check",

  // Регистрация
  REGISTRATION = "/registration",
  REGISTRATION_CODE_CHECK = "/registration-code-check",
  REGISTRATION_CODE_CHECK_ERROR = "/registration-code-check-error",
  REGISTRATION_EMAIL_BLOCKED = "/registration-email-blocked",
  REGISTRATION_SECOND_STEP = "/second-step",
  REGISTERED = "/registered",

  // Домен запрещен
  BANNED_DOMEN = "/banned-domen",

  // Пользователь уже зарегистрирован
  ACCOUNT_EXISTS = "/account-exists",

  PROFILE = "/profile",
  PROFILE_EDIT = "/profile/edit",
  COURSES = "/courses",
  COURSE = "/course",
  LESSON = "/lesson",
  QUESTIONNARE = "/questionnaire",
  COURSE_PREVIEW = "/course-preview",
  MY_COURSES = "/my",
  HISTORY = "/history",
  SETTINGS = "/profile/settings",
  QUESTION = "/question",
  ACHIEVEMENTS = "/achievements",
  RATING = "/rating",
  CONTENT = "/content",

  REWARDS_SHOP = "/rewards-shop",
  ORDERS = "/orders",

  // История операций
  OPERATION_HISTORY = "/operation-history",

  TEST_EMPTY = "/test-empty",
  TEST_ADVICE = "/test-advice",
  COURSE_SHARED = "/shared",

  UI_UI = "/ui",
  UI_UIKIT = "/uikit",
  UI_PROFILE = "/profile",
  UI_PROFILE_AD = "/profile-ad",
  UI_SANDBOX = "/sandbox",
  UI_STUDY = "/study",
  UI_COURSE_CATALOG = "/course-catalog",
  UI_COURSE_TEST = "/course-test",
  UI_COURSE_WEB = "/course-web",
  UI_COURSE_SIMULATE = "/course-simulate",
  UI_COURSE_QUESTIONS = "/course-questions",
  UI_COURSE_RESULT = "/course-result",
  UI_COURSE_DONE = "/course-done",
  UI_COURSE_TERM = "/course-term",
  UI_COURSE_TRAINING = "/course-training",
  UI_COURSE_WEB_START = "/course-web-start",
  UI_COURSE_GRADE = "/course-grade",
  UI_COURSE_GRADE_EMPTY = "/course-grade-empty",
  UI_COURSE_ANSWER_OPTIONS = "/course-answer-options",
  UI_QUESTION = "/question",

  UI_SETTING_1 = "/settings-1",
  UI_SETTING_2 = "/settings-2",
  UI_SETTING_3 = "/settings-3",
  UI_SETTING_4 = "/settings-4",
  UI_SETTING_5 = "/settings-5",
  UI_BOOK_PRESTART = "/book-prestart",
  UI_BOOK_START = "/book-start",
  UI_MANUAL = "/manual",
  UI_MANUAL_START = "/manual-start",
  UI_CERTIFICATE = "/certificate",
  UI_CERTIFICATE_EMPTY = "/certificate-empty",
  UI_LANDING = "/landing",
  UI_COURSE_SHEET_START = "/course-sheet-start",
  UI_COURSE_SHEET_THANK = "/course-sheet-thank",
  UI_COURSE_SHEET_TEST = "/course-sheet-test",
  UI_TEST_RESULT = "/test-result",
  UI_TEST_RESULT_EMPTY = "/test-result-empty",
  UI_TEST_RESULT_PLUG = "/test-result-plug",
  UI_COURSE_WEB_DOWNLOAD = "/course-web-download",
  UI_COURSE_WEB_PROMPT = "/course-web-prompt",
  UI_COURSE_CATALOG_EMPTY = "/course-catalog-empty",
  UI_COURSE_NOT_AVAILABLE = "/course-not-available",
  UI_HISTORY = "/uihistory",
  UI_TASK_START = "/task-start",
  UI_TASK_START_SECOND = "/task-start-second",
  UI_TASK_FILE = "/task-file",
  UI_TASK_FILE_ATTACHED = "/task-file-attached",
  UI_TASK_FILES_ATTACHED = "/task-files-attached",
  UI_TASK_TEXT = "/task-text",
  UI_TASK_TEXT_ENTERED = "/task-text-entered",
  UI_TASK_FILE_TEXT = "/task-file-text",
  UI_TASK_FILE_TEXT_ENTERED = "/task-file-text-entered",
  UI_FORUM_ADMIN = "/forum-admin",
}
