import { numWord } from "app/utils";
import Container from "components/Container/Container";
import { TrajectoryResultCard } from "components/TrajectoryResultCard";
import { H2, H3 } from "components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectMulticourseContent } from "pages/Course/redux/courseSelectors";
import { getMulticourseContent, showAsideNav } from "pages/Course/redux/courseSlice";
import { getCoursesFormats } from "pages/Courses/redux/coursesListSlice";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { useEffect } from "react";
import { useParams } from "react-router";
import { generateUid } from "../../../../app/utils/generateUid.util";
import styles from "./MulticourseResult.module.scss";

export const MulticourseResult = (): JSX.Element => {
  const multicourseContent = useAppSelector(selectMulticourseContent);
  const dispatch = useAppDispatch();
  const { course_id } = useParams();

  useEffect(() => {
    dispatch(showAsideNav());
    dispatch(getCoursesFormats());
    course_id && dispatch(getMulticourseContent({ id: course_id }));
  }, [dispatch, course_id]);

  const multicourseIsComplete =
    multicourseContent &&
    multicourseContent.every((course) => course.status === EnrolledStatus.completed);

  return (
    <Container>
      <H2 className={styles["title"]}>
        {multicourseIsComplete
          ? "Поздравляем с успешным окончанием траектории обучения!"
          : "Мой результат"}
      </H2>
      <div className={styles["container"]}>
        <H3 className={styles["title"]}>
          В вашу траекторию обучения входит {multicourseContent?.length}{" "}
          {numWord(multicourseContent?.length, ["программа", "программы", "программ"])}:
        </H3>
        {multicourseContent?.map((course) => (
          <TrajectoryResultCard
            name={course.name}
            status={
              course.status ||
              (course.enrolled && EnrolledStatus.enrolled) ||
              EnrolledStatus.not_started
            }
            result={course.grade}
            format={course.format}
            className={styles["course"]}
            key={generateUid()}
          />
        ))}
      </div>
    </Container>
  );
};
