import { useModal } from "components/ModalsContainer";
import { DownloadIcon, FullScreenBoldIcon } from "components/Svg/StaticIcons";
import { TextMain } from "components/Typography/Texts";
import { useEffect, useRef, useState } from "react";
import { ImageModal } from "./modals/ImageModal";

function wrapElement(el: any, wrapper: any) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

export const QuestDescription = ({ data }: { data: string }) => {
  const [content, setContent] = useState("");
  const contentBlockRef = useRef<HTMLDivElement>(null);
  const { openModal } = useModal();

  useEffect(() => {
    const parser = new DOMParser();

    try {
      const content = parser.parseFromString(data, "text/html");

      const links = content.querySelectorAll("a");
      const images = content.querySelectorAll("img");
      links.forEach((linkItem) => {
        const linkHref = linkItem.getAttribute("href");

        const wrapper = document.createElement("span");
        wrapper.classList.add("course-questions-block__descr__content-file-container");

        const downloadButton = document.createElement("a");
        downloadButton.classList.add("course-questions-block__descr__content-file-download");
        downloadButton.innerHTML = DownloadIcon;
        if (linkHref) {
          downloadButton.href = linkHref;
        }

        linkItem.setAttribute("target", "_blank");
        downloadButton.setAttribute("target", "_blank");

        wrapper.appendChild(downloadButton);
        wrapElement(linkItem, wrapper);
      });

      images.forEach((img) => {
        const wrapper = document.createElement("div");
        wrapper.classList.add("course-questions-block__descr__content-image-container");
        wrapElement(img, wrapper);
      });

      setContent(content.body.innerHTML);
    } catch (e) {
      setContent(data);
    }
  }, [setContent, data]);

  useEffect(() => {
    if (!content) return;
    const images = contentBlockRef.current?.querySelectorAll("img");

    images?.forEach((img) => {
      img.onload = () => {
        if (img.naturalWidth < 800 && img.naturalHeight < 800) return;

        const fullScreenIcon = document.createElement("span");
        fullScreenIcon.classList.add("course-questions-block__descr__content-image__close");
        fullScreenIcon.innerHTML = FullScreenBoldIcon;

        img.parentElement?.appendChild(fullScreenIcon);
        fullScreenIcon.onclick = () =>
          openModal({
            custom: true,
            content: <ImageModal imageSrc={img?.src} />,
          });
      };
    });
  }, [content, openModal]);

  return (
    <TextMain className="course-questions-block__descr">
      {
        <div
          ref={contentBlockRef}
          className={`editable`}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      }
    </TextMain>
  );
};
