export const FullScreenBoldIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 21H16.2M21 21L15 15L21 21ZM21 21V16.2V21Z"
        stroke="#FFC422"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 21L9 15M3 16.2V21V16.2ZM3 21H7.8H3Z"
        stroke="#FFC422"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 3L15 9M21 7.8V3V7.8ZM21 3H16.2H21Z"
        stroke="#FFC422"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 3L9 9M3 7.8V3V7.8ZM3 3H7.8H3Z"
        stroke="#FFC422"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>`;

export const DownloadIcon = `<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_24107_31888)">
      <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" fill="white" />
      <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke="#E8EAEF" />
      <g clip-path="url(#clip1_24107_31888)">
        <path
          d="M30 24V28C30 28.5304 29.7893 29.0391 29.4142 29.4142C29.0391 29.7893 28.5304 30 28 30H14C13.4696 30 12.9609 29.7893 12.5858 29.4142C12.2107 29.0391 12 28.5304 12 28V24"
          stroke="#A4A4AF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26 19L21 24L16 19"
          stroke="#A4A4AF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 12V24"
          stroke="#A4A4AF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_24107_31888">
        <rect width="42" height="42" fill="white" />
      </clipPath>
      <clipPath id="clip1_24107_31888">
        <rect width="24" height="24" fill="white" transform="translate(9 9)" />
      </clipPath>
    </defs>
  </svg>`;
