import TextField from "../../../../../components/Forms/TextField";
import { H3 } from "../../../../../components/Typography/Titles";
import { useDispatch } from "react-redux";
import { setQuestionnaireQuestion } from "../redux/Questionary/courseQuestionarySlice";

const QuestNumb = ({ question }) => {
  const dispatch = useDispatch();
  const { title = "", answer = [] } = question;

  const onChangeHandler = (event) => {
    const val = event.target.value;
    if (!/^(0|[1-9]\d*)?$/.test(val)) return;
    const minValue = question.min ?? -Infinity;
    const maxValue = question.max ?? +Infinity;

    if (val !== "") {
      if (minValue > +val || maxValue < +val) return;
    }

    dispatch(
      setQuestionnaireQuestion({
        question: {
          ...question,
          answer: event.target.value === "" ? [] : [+event.target.value],
          valid: question.required ? event.target.value !== "" : true,
        },
      })
    );
  };

  return (
    <>
      <H3 className="course-sheet-test-block__title">{title}</H3>
      <div className="course-sheet-test-block-content">
        <TextField
          className="course-sheet-test-shortfield"
          mask="9"
          value={answer}
          onChange={onChangeHandler}
        />
      </div>
    </>
  );
};

export default QuestNumb;
