import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseMenu from "./components/CourseMenu";
import CourseNavBottom from "./components/CourseNavBottom";

import { clearCourseData, coursesSelectors } from "../../pages/Course/redux/courseSlice";
import {
  selectCourseAsideNav,
  selectCourseBottomNav,
  selectСourseIsLoaded,
} from "../../pages/Course/redux/courseSelectors";

import { Scrollbar } from "components/Scrollbar";
import CourseSharedHeader from "./components/CourseSharedHeader";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { EntityId } from "@reduxjs/toolkit/src/entities/models";

const CourseLayoutShared = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { course_id } = useParams();

  const isVisibleBottomNav = useAppSelector(selectCourseBottomNav);
  const isVisibleAsideNav = useAppSelector(selectCourseAsideNav);
  const currentCourse = useAppSelector((state) =>
    coursesSelectors.selectById(state, course_id as EntityId)
  );
  const isCourseLoaded = useAppSelector(selectСourseIsLoaded);

  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [scrollType, setScrollType] = useState("start");

  useEffect(() => {
    return () => {
      dispatch(clearCourseData());
    };
  }, [dispatch]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Scrollbar
      className={"page"}
      /* @ts-ignore */
      onScroll={(event) => {
        /* @ts-ignore */
        const { scrollTop } = event.target;

        if (scrollTop === 0) {
          setScrollType("start");
          return;
        }
        setScrollType("progress");
      }}
    >
      <div className="page__content">
        <div className="course-wrap">
          <CourseSharedHeader
            courseTitle={currentCourse?.name}
            isOpen={menuOpen}
            toggleMobileMenuCallback={toggleMenu}
          />
          <main
            className={menuOpen ? "course-test-main course-test-main--active" : "course-test-main"}
          >
            {children}
            {isVisibleBottomNav && isCourseLoaded ? <CourseNavBottom /> : null}
            {isVisibleAsideNav ? (
              <CourseMenu
                isOpen={menuOpen}
                toggleCallback={toggleMenu}
                scrollType={scrollType}
                canPutFeedback={currentCourse?.commenting}
                shared={true}
              />
            ) : null}
          </main>
        </div>
      </div>
    </Scrollbar>
  );
};

export default CourseLayoutShared;
