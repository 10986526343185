import { CourseSkeleton } from "../CourseSkeleton";

export const MyCoursesSkeleton = () => (
  <>
    <CourseSkeleton />
    <CourseSkeleton />
    <CourseSkeleton />
    <CourseSkeleton />
  </>
);
