import {
  BookIcon,
  LongerIcon,
  BoxesIcon,
  CameraIcon,
  TrainerIcon,
  HelpIcon,
  CommentsIcon,
  TaskIcon,
  MapPinIcon,
} from "components/Icons";
import { useAppSelector } from "hooks/redux";
import { selectFormats } from "pages/Courses/redux/coursesListSliceSelectors";
import classNames from "classnames";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import styles from "./CourseTypeTile.module.scss";

export const getFormatIcon = (type: CourseFormat) => {
  switch (type) {
    case CourseFormat.multicourse:
      return <MapPinIcon />;
    case CourseFormat.course:
      return <BoxesIcon />;
    case CourseFormat.task:
      return <TaskIcon />;
    case CourseFormat.trainer:
      return <TrainerIcon />;
    case CourseFormat.questionnaire:
      return <CommentsIcon width={24} height={24} />;
    case CourseFormat.quiz:
      return <HelpIcon />;
    case CourseFormat.meeting:
      return <CameraIcon />;
    case CourseFormat.atb:
      return <BookIcon />;
    case CourseFormat.page:
      return <LongerIcon />;
    default:
      return <BoxesIcon />;
  }
};

interface CourseTypeTileProps {
  type: CourseFormat;
  className?: string;
}

export const CourseTypeTile = ({ type, className }: CourseTypeTileProps): JSX.Element => {
  const formats = useAppSelector(selectFormats);

  const currentFormat = formats.find((format) => format.value === type);

  return (
    <>
      {currentFormat && (
        <div className={classNames(styles["tile"], className)}>
          {getFormatIcon(currentFormat.value)}
          {currentFormat.label}
        </div>
      )}
    </>
  );
};
