import classNames from "classnames";
import { MouseEvent } from "react";
import { UpArrowLongIcon } from "../../Icons";
import { CircleIconButton } from "../CircleIconButton";
import styles from "./ScrollUpButton.module.scss";

interface ScrollUpButtonProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;

  className?: string;
}

export const ScrollUpButton = ({ onClick, className }: ScrollUpButtonProps) => {
  return (
    <CircleIconButton
      className={classNames(styles["scroll-up-btn"], className)}
      color="primary"
      onClick={onClick}
    >
      <UpArrowLongIcon />
    </CircleIconButton>
  );
};
