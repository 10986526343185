import { CourseCoinAmount } from "../../../components/CourseCoinAmount";
import { CourseCoin } from "../redux/interfaces/course.interface";
import CourseLessonStatus from "./Lesson/CourseLessonStatus";

const CourseTestContentHeader = ({
  status,
  fixed,
  children,
  lessonType,
  coins,
}: {
  status?: string | null;
  fixed?: boolean;
  children: JSX.Element;
  lessonType?: string;
  coins?: CourseCoin;
}) => {
  return (
    <>
      <div className="course-test-top-header">
        <div className={fixed ? "course-test-top course-test-top_fixed " : "course-test-top "}>
          <div className="course-test-top-header__h">{children}</div>
        </div>

        <div className="course-test-top-info">
          {status && (
            <div className="course-test-top-header__chunk">
              <div className="course-test-chunk-wrapper">
                <CourseLessonStatus status={status} lessonType={lessonType} />
              </div>
            </div>
          )}

          <CourseCoinAmount coin={coins} />
        </div>
      </div>
    </>
  );
};

export default CourseTestContentHeader;
