import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api/api";
import { EndPoints } from "../../app/endpoints";
import { Capacitor } from "@capacitor/core";

const getToken = createAsyncThunk(
  "auth/getToken",
  async (payload: { login: string; password: string }, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.AUTH_LOGIN, {
        login: payload.login.trim(),
        password: payload.password,
        mobile_application: Capacitor.isNativePlatform(),
      });

      return {
        accessToken: response.headers["access-token"],
        idToken: response.headers["authorization"],
        refreshToken: response.headers["refresh"],
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const emailCheckup = createAsyncThunk(
  "auth/emailCheckup",
  async (payload: { email: string }, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.CODE_EMAIL_CHECKUP, {
        email: payload.email,
      });

      return {
        email: payload.email,
        status: 200,
        data: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        email: payload.email,
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const getTokenAnonymous = createAsyncThunk(
  "auth/getTokenAnonymous",
  async (payload: { course_share_id: string }, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.AUTH_LOGIN_ANONYMOUS, {
        course_share_id: payload,
      });

      return {
        status: response.status,
        accessToken: response.data.data["Access-Token"],
        idToken: response.data.data["Authorization"],
        refreshToken: response.data.data["Refresh"],
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const getSharedCourseId = createAsyncThunk(
  "auth/getSharedCourseId",
  async (payload: string, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.COURSE_SHARED(payload));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const registerVerify = createAsyncThunk(
  "auth/registerVerify",
  async (payload: { email: string; code: string }, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.AUTH_REGISTER_VERIFY, {
        email: payload.email,
        code: payload.code,
      });

      return {
        status: response.status,
        data: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const getCode = createAsyncThunk("auth/getCode", async (payload: { email: string }, thunkAPI) => {
  try {
    const response = await api.post(EndPoints.CODE_SEND, {
      email: payload.email,
    });

    return {
      email: payload.email,
      status: 200,
      data: response.data,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      email: payload.email,
      status: error.response.status,
      data: error.response.data,
    });
  }
});

const mailCheck = createAsyncThunk(
  "auth/mailCheck",
  async (payload: { email: string }, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.MAIL_CHECK, {
        params: {
          email: payload.email,
        },
      });

      return {
        email: payload.email,
        status: 200,
        is_allowed: response?.data?.data?.is_allowed,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        email: payload.email,
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const getRegistrationIsAllowed = createAsyncThunk(
  "auth/registrationIsAllowed",
  async (_arg, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.REGISTRATION_IS_ALLOWED);

      return {
        status: response.status,
        allowed: response.data?.data?.allow,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const isExist = createAsyncThunk("auth/isExist", async (payload: { email: string }, thunkAPI) => {
  try {
    const response = await api.post(EndPoints.AUTH_REGISTER_EXISTS, {
      email: payload.email,
    });

    return {
      status: response.status,
      code: response.data.code,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      email: payload.email,
      status: error.response.status,
      code: error.response?.data?.code,
    });
  }
});

interface UserRegisterParam {
  email: string;
  password: string;
  firstName: string;
  secondName: string;
  position: string;
  department: number;
  policyAgreed: boolean;
}

const userRegister = createAsyncThunk(
  "auth/userRegister",
  async (payload: UserRegisterParam, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.AUTH_REGISTER, {
        email: payload.email,
        password: payload.password,
        firstName: payload.firstName,
        secondName: payload.secondName,
        department: payload.department,
        position: payload.position,
        policyAgreed: payload.policyAgreed,
      });

      return {
        status: response.status,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.code,
      });
    }
  }
);

const getPasswordPolicy = createAsyncThunk("auth/getPasswordPolicy", async (payload, thunkAPI) => {
  try {
    const response = await api.get(EndPoints.PASSWORD_POLICY);

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      code: error.response.code,
    });
  }
});

const codeSend = createAsyncThunk("auth/codeSend", async (payload: { email: string }, thunkAPI) => {
  try {
    const responseSendCode = await api.post(EndPoints.CODE_SEND, {
      email: payload.email,
    });

    if (responseSendCode.status === 200) {
      return {
        status: 200,
        email: payload.email,
      };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      data: error.response.data,
    });
  }
});

const policyCheck = createAsyncThunk(
  "auth/policyCheck",
  async (payload: { email: string }, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.POLICY_CHECK, { params: { email: payload.email } });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (payload: { refreshToken: string }) => {
    const response = await api.post(
      EndPoints.AUTH_REFRESH,
      {},
      {
        headers: {
          Refresh: payload.refreshToken,
        },
      }
    );

    return {
      accessToken: response.headers["access-token"],
      idToken: response.headers["authorization"],
      refreshToken: response.headers["refresh"],
    };
  }
);

const putAvatarCode = createAsyncThunk(
  "auth/imagePredefined",
  async (payload: { imageId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const response = await api.put(
        EndPoints.PROFILE_IMAGE_PREDEFINED,
        {
          imageId: payload.imageId,
        },
        {
          headers: {
            // @ts-ignore
            "Access-Token": state?.auth?.oidc?.accessToken,
          },
        }
      );

      return {
        status: response.status,
        avatarCode: payload.imageId,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const putAvatarCodeRegistration = createAsyncThunk(
  "auth/imagePredefined",
  async (payload: { imageId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const response = await api.put(
        EndPoints.PROFILE_IMAGE_PREDEFINED,
        {
          // @ts-ignore
          imageId: state?.auth?.registration?.avatar,
        },
        {
          headers: {
            // @ts-ignore
            "Access-Token": state?.auth?.oidc?.accessToken,
          },
        }
      );

      return {
        status: response.status,
        avatarCode: payload.imageId,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const updateLocalStorage = (oidc: string) => {
  localStorage.oidc = JSON.stringify(oidc);
};

const authInitalState = {
  recovery: {
    error: {
      isError: false,
      code: "",
    },
  },
  registration: {
    avatar: 0,
    requirements: {},
    verifyError: {},
  },
  oidc: (function () {
    if (localStorage.oidc) {
      return JSON.parse(localStorage.oidc);
    }
    return {};
  })(),
  authType: "email",
  authLastError: null,
  win: {
    showcase: {
      expanded: false,
    },
  },
  userExist: false,
  emailCheck: {
    statusCode: "",
    interval: "",
    codeCreate: "",
  },
  registrationIsAllowed: false,
  authRequestIsLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitalState,
  reducers: {
    init(state, action) {
      state.oidc = localStorage?.oidc ? JSON.parse(localStorage.oidc) : {};
    },
    authTypeToggle(state, action) {
      if (state.authType === "login") {
        state.authType = "email";
      } else {
        state.authType = "login";
      }
    },
    clearAuthError(state) {
      state.authLastError = null;
    },
    setAuthTypeLogin(state, action) {
      state.authType = "login";
    },
    setAuthTypeEmail(state, action) {
      state.authType = "email";
    },
    changeShowcaseExpand(state, action) {
      state.win.showcase.expanded = action.payload;
    },
    clear(state) {
      state.oidc = {};
      state.userExist = false;
      // @ts-ignore
      updateLocalStorage({});
    },
    setRegistrationUserAvatar(state, action) {
      state.registration.avatar = parseInt(action.payload);
    },
    setTokens(state, action) {
      state.oidc = action.payload;
      state.authLastError = null;
      // @ts-ignore
      updateLocalStorage(state.oidc);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getToken.fulfilled, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc = action.payload;
        state.authLastError = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getToken.pending, (state, action) => {
        state.authRequestIsLoading = true;
      })
      .addCase(getToken.rejected, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc = {};
        // @ts-ignore
        state.authLastError = action?.payload?.status ?? "unknown_error";
        // @ts-ignore
        updateLocalStorage({});
      })
      .addCase(getTokenAnonymous.fulfilled, (state, action) => {
        state.oidc = Object.assign(state.oidc, action.payload);
        state.oidc.shared = true;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getTokenAnonymous.rejected, (state, action) => {
        state.oidc = {};
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getSharedCourseId.fulfilled, (state, action) => {
        state.oidc.course_id = action.payload?.data?.course_id;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getSharedCourseId.rejected, (state, action) => {
        state.oidc.courseId = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })

      .addCase(isExist.fulfilled, (state) => {
        state.authRequestIsLoading = false;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(isExist.rejected, (state) => {
        state.authRequestIsLoading = false;
        state.oidc.courseId = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(isExist.pending, (state) => {
        state.authRequestIsLoading = true;
        state.oidc.courseId = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(registerVerify.fulfilled, (state, action) => {
        state.registration.requirements = action.payload.data?.data?.requirements;
        state.recovery.error.isError = true;
        state.registration.verifyError = {};
      })
      .addCase(registerVerify.rejected, (state, action) => {
        // @ts-ignore
        state.registration.verifyError = action.payload;
        state.recovery.error.isError = false;
        // @ts-ignore
        state.recovery.error.code = action.payload?.data?.code;
        state.registration.requirements = {};
      })
      .addCase(mailCheck.fulfilled, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc.email = action.payload.email;
      })
      .addCase(mailCheck.pending, (state) => {
        state.authRequestIsLoading = true;
      })
      .addCase(mailCheck.rejected, (state) => {
        state.authRequestIsLoading = false;
        state.recovery.error.isError = true;
        state.registration.verifyError = {};
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.oidc = Object.assign(state.oidc, action.payload);
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.oidc = {};
        // @ts-ignore
        updateLocalStorage({});
        state.userExist = false;
      })
      .addCase(putAvatarCode.fulfilled, (state, action) => {
        state.oidc = Object.assign({}, state.oidc);
        state.oidc.avatarCode = action.payload.avatarCode;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(codeSend.fulfilled, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc.email = action.payload?.email;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(codeSend.pending, (state) => {
        state.authRequestIsLoading = true;
      })
      .addCase(codeSend.rejected, (state) => {
        state.authRequestIsLoading = false;
      })
      .addCase(emailCheckup.fulfilled, (state, action) => {
        state.oidc.email = action.payload?.email;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(policyCheck.fulfilled, (state, action) => {
        state.oidc.policyCheck = action.payload?.showpolicy;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(emailCheckup.rejected, (state, action) => {
        // @ts-ignore
        state.temporaryCodeError = action?.payload?.status ?? null;
      })
      .addCase(getPasswordPolicy.fulfilled, (state, action) => {
        // @ts-ignore
        state.registration.requirements.password = action?.payload.data || {};
      })
      .addCase(getRegistrationIsAllowed.rejected, (state, action) => {
        state.registrationIsAllowed = false;
      })
      .addCase(getRegistrationIsAllowed.fulfilled, (state, action) => {
        state.registrationIsAllowed = action?.payload?.allowed;
      });
  },
});

export const {
  init,
  changeShowcaseExpand,
  clear,
  setTokens,
  setRegistrationUserAvatar,
  authTypeToggle,
  clearAuthError,
} = authSlice.actions;
export {
  getToken,
  getTokenAnonymous,
  getSharedCourseId,
  refreshToken,
  userRegister,
  putAvatarCodeRegistration,
  registerVerify,
  getCode,
  isExist,
  emailCheckup,
  mailCheck,
  policyCheck,
  codeSend,
  getPasswordPolicy,
  putAvatarCode,
  getRegistrationIsAllowed,
};
export default authSlice.reducer;
