import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { TriangleArrow } from "components/Svg/Pics";
import { DiamondIcon } from "../Icons/AchievementIcons";
import { LockIcon } from "../Icons/LockIcon";
import { useClickAway } from "ahooks";
import { ICriteria } from "pages/Achievements/redux/interfaces";
import { useMediaQuery } from "hooks";
import { mediaQuerys } from "app/utils";
import styles from "./Achievement.module.scss";

interface AchievementProps {
  title: string;
  image?: string;
  disabled?: boolean;
  color?: "base" | "white" | "transparent";
  criteria?: ICriteria[];
  description?: string;
  index?: number;
  showDescription?: boolean;
}

export const Achievement: React.FC<AchievementProps> = ({
  title,
  image,
  disabled = false,
  color = "base",
  criteria,
  description,
  index,
  showDescription,
}: AchievementProps) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [bodyWidth, setBodyWidth] = useState<number | null>(null);

  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const handleOpenDescription = () =>
    showDescription &&
    (Capacitor.isNativePlatform() || isMobile) &&
    setOpenDescription((prev) => !prev);

  useClickAway(() => {
    setOpenDescription(false);
  }, [bodyRef]);

  useEffect(() => {
    if (bodyRef.current) {
      const width = bodyRef.current.getBoundingClientRect().width;
      setBodyWidth(width);
    }
  }, [bodyWidth]);

  return (
    <div ref={bodyRef} onClick={handleOpenDescription} style={{ position: "relative" }}>
      <div
        className={classNames(styles["achievement"], styles[color], {
          [styles["achievement--disabled"]]: disabled,
          [styles["disable-hover"]]: Capacitor.isNativePlatform() || isMobile,
        })}
      >
        {image ? (
          <img src={image} alt="" className={styles["achievement__image"]} />
        ) : (
          <DiamondIcon />
        )}
        <p className={styles["achievement__title"]}>{title}</p>
        {disabled && <LockIcon className={styles["achievement__lock-icon"]} />}
      </div>
      {showDescription && openDescription && (Capacitor.isNativePlatform() || isMobile) && (
        <div className={styles["achievement-description"]}>
          <div
            className={classNames(styles["achievement-description_inner"], {
              [styles["right"]]: index && index % 2 !== 0,
              [styles["left"]]: index && index % 2 === 0,
            })}
          >
            <div className={styles["achievement-criteria"]}>
              {criteria?.map((item, index) => (
                <div className={styles["criteria"]} key={index}>
                  {item.name}
                </div>
              ))}
            </div>
            <div className={styles["description"]}>{description}</div>
          </div>
          <TriangleArrow className={styles["arrow"]} />
        </div>
      )}
    </div>
  );
};
