import { SVGProps } from "react";

export const CornerSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={50}
    fill="none"
    viewBox="0 0 39 50"
    {...props}
  >
    <path
      fill="#FFF7E1"
      d="M13.637 29.782C18.37 29.566 39 25.918 39 13v31.998C39 32.97 18.915 29.88 13.637 29.782a6.86 6.86 0 0 1-.637.005c.178-.008.392-.01.637-.005Z"
    />
  </svg>
);
