import classNames from "classnames";
import { ReactNode } from "react";

export const FieldSetItem = ({ children }: { children: ReactNode }) => {
  return <div className="fieldset__item">{children}</div>;
};

export const FieldSetIndent = ({ children }: { children: ReactNode }) => {
  return <div className="fieldset__indent">{children}</div>;
};

const FieldSet = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): JSX.Element => (
  <>{children && <div className={classNames(className || "fieldset")}>{children}</div>}</>
);

export default FieldSet;
