const CalendarDateItem = ({ dayNumber, dayWord, date, selectedDay, disabledDay, onClick }) => {
  return (
    <div onClick={onClick}>
      <div
        className={
          disabledDay
            ? "calendar-dates-item calendar-dates-item--disabled"
            : selectedDay
            ? "calendar-dates-item calendar-dates-item--active"
            : "calendar-dates-item"
        }
      >
        <div
          className={
            disabledDay
              ? "calendar-dates-item__date calendar-dates-item__date--disabled"
              : "calendar-dates-item__date"
          }
        >
          {dayNumber}
        </div>
        <div
          className={
            disabledDay
              ? "calendar-dates-item__day calendar-dates-item__day--disabled"
              : "calendar-dates-item__day"
          }
        >
          {dayWord}
        </div>
      </div>
      <div
        className={
          disabledDay
            ? "calendar-dates-item__year_month calendar-dates-item__year_month--disabled"
            : "calendar-dates-item__year_month"
        }
      >
        {date}
      </div>
    </div>
  );
};

export default CalendarDateItem;
