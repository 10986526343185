import { RootState } from "app/store";
import { MIME } from "../../../../../app/mime";

export const selectTaskDetail = (state: RootState) => {
  return state.courseTask.detail;
};
export const selectTaskIsLoading = (state: RootState) => {
  return state.courseTask.isLoading;
};
export const selectTaskResults = (state: RootState) => {
  return state.courseTask?.results;
};
export const selectTaskMaxmark = (state: RootState) => {
  return state.courseTask?.maxmark;
};

export const selectTaskFileTypes = (state: RootState) => {
  let fielTypes = "";
  if (state.courseTask?.detail?.file_types) {
    fielTypes = state.courseTask?.detail?.file_types
      .replace(".", "")
      .split(",.")
      // @ts-ignore
      .map((item) => MIME[item])
      .filter((item) => item)
      .join();
  }
  return fielTypes;
};

export const selectTaskStatus = (state: RootState) => {
  return state.courseTask?.task_state;
};

export const selectTaskIsStarted = (state: RootState) => {
  return !!state.courseTask?.detail?.start_ts;
};

export const selectTaskAttemptStatus = (state: RootState) => {
  if (state.courseTask?.detail?.total_attempts === 0) {
    return true;
  }
  if (state.courseTask?.detail?.user_attempts >= state.courseTask?.detail?.total_attempts) {
    return false;
  }
  return true;
};

export const selectTaskAttemptsLeft = (state: RootState) => {
  const attempts =
    state.courseTask?.detail?.total_attempts - state.courseTask?.detail?.user_attempts;
  return attempts > 0 ? attempts : 0;
};
