import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const ManWithWinkAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();

  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={234}
        height={234}
        viewBox="0 0 200 200"
        fill="none"
        {...props}
      >
        <g clipPath="url(#a)">
          <rect width={200} height={200} fill="#CFE2F4" rx={100} />
          <path
            fill="#176BD9"
            d="M7.127 287.138c.443.074.812.295 1.18.516 2.434-1.18 6.047-.074 5.973 3.466-.294 13.2-.958 26.4-1.696 39.6H182.12c-.148-11.872-.295-23.671-.295-35.544 0-3.466 3.54-4.646 5.973-3.54 9.955-5.383 19.837-10.914 29.719-16.371l-.222-6.489c-.885-29.866-12.167-59.511-33.479-80.823-19.837-19.836-52.358-34.733-80.823-33.479-60.395 2.729-116.219 50.293-114.301 114.302.073 3.687.22 7.522.294 11.356 5.826 1.844 11.504 4.056 17.109 6.416.442.148.737.369 1.032.59Z"
          />
          <path
            fill="#176BD9"
            d="M77.627 159.562H46.286c-28.391 0-53.906 17.773-63.198 44.541-3.687 10.546-6.932 21.165-10.103 32.005-1.696 5.604-3.318 11.209-5.162 16.74 25.59 12.241 53.095 20.353 79.717 30.455 9.513-27.063 14.748-57.003 28.686-80.527 7.964-13.421 8.259-30.013 1.4-43.214ZM225.481 232.568a20.06 20.06 0 0 0-.516-1.622c-3.245-10.767-6.489-21.459-10.103-32.005-9.291-26.769-34.807-44.541-63.198-44.541h-31.341c-6.858 13.2-6.563 29.719 1.475 43.214 12.537 21.09 17.994 47.417 25.884 72.195 25.81-12.611 51.104-26.474 77.799-37.241Z"
          />
          <path
            fill="#0F1623"
            d="M151.003 114.874c-1.328-6.785-5.236-12.905-7.079-19.616-2.139-7.89-2.655-15.855-1.992-23.966.074-.664-.221-1.033-.663-1.254-11.357-25.22-40.19-42.697-65.263-31.415-.295.148-.442.369-.59.59-8.554 2.286-15.855 7.965-19.542 16.297-2.58 5.9-2.58 12.168-2.36 18.51.222 8.186-.59 22.64-1.99 30.677-1.697 9.808-1.992 20.058 6.12 27.211 6.195 5.457 14.675 6.932 22.344 3.983 9.808-3.761 17.699-14.675 28.908-14.307 11.43.369 19.468 12.094 31.488 10.472 8.702-1.254 12.094-9.218 10.619-17.182Z"
          />
          <path
            fill="#0F1623"
            d="M139.499 43.858c.221-8.038 4.13-16.813-4.277-22.122-8.481-5.384-19.1-.296-27.433 2.654l-26.547 9.513a74.628 74.628 0 0 0-2.507 4.056h.073c-.073.442-.073.811-.073 1.254-2.508 7.743 1.4 22.491 5.53 29.423 5.9 9.734 13.422 20.427 22.418 27.506 7.08 5.605 13.643 5.015 21.238.738 6.932-3.909 15.339-9.292 17.92-17.256 3.318-10.545-6.563-25.589-6.342-35.766Z"
          />
          <path
            fill="#F2A98A"
            d="M124.306 38.697c-4.72.148-9.513.369-14.233.664-4.94.369-9.807.737-14.748 1.327-1.254.148-2.581.295-3.835.443-.516.074-1.253.147-1.548.221-2.36.295-4.72.664-7.08 1.032-3.834.664-7.669 1.328-11.504 2.139-6.71 8.554-10.914 19.69-10.914 30.013v33.996c0 10.029 3.909 19.173 10.25 25.958 6.932 7.374 16.74 12.02 27.654 12.02h4.277c12.389 0 23.377-5.9 30.309-15.044a37.903 37.903 0 0 0 7.669-22.86V74.61c0-12.979-6.415-26.99-16.297-35.913Z"
          />
          <path
            fill="#0F1623"
            d="M102.701 29.625h-4.277c-11.21 0-21.239 4.867-28.17 12.536a37.824 37.824 0 0 0-9.735 25.368v7.448c5.605-1.032 8.039-9.734 10.693-16.96 1.77-4.868 5.9-8.555 10.914-9.588 11.43-2.433 23.967-3.465 35.839-3.097 6.859.222 12.611 5.162 14.159 11.873.221 1.032 4.13 23.598 6.342 24.778.664.368 6.637 4.498 7.448-2.36l-5.162-12.02c-.147-14.011-17.108-37.978-38.051-37.978Z"
          />
          <path
            fill="#F2A98A"
            d="M130.352 122.91c-19.615.664-39.231.885-58.478 4.867 1.327 11.578 1.77 23.156.885 34.733 2.433 11.947 10.84 21.828 24.188 23.229 13.937 1.475 30.898-9.66 30.972-24.999.074-8.554.074-17.034.147-25.588.664-.443 1.254-1.033 1.918-1.549-.369-3.466-.295-7.153.368-10.693ZM72.54 87.588c-20.87-12.61-26.548-10.767-29.719-1.917-3.17 8.849 0 13.937 16.445 22.123C75.71 116.053 72.54 87.588 72.54 87.588ZM127.257 87.588c20.87-12.61 26.548-10.767 29.719-1.917 3.171 8.849 0 13.937-16.445 22.123-16.445 8.259-13.274-20.206-13.274-20.206Z"
          />
          <path
            fill="#0F1623"
            d="M107.79 80.436c-1.328-1.401-3.761.516-2.36 1.917 3.687 3.761 11.799 14.012 2.728 17.182-1.843.664-.663 3.466 1.18 2.803 3.761-1.328 5.826-5.015 5.826-8.997-.074-5.236-3.908-9.365-7.374-12.905ZM84.117 78.887c-3.76.81-5.31 5.9-2.876 8.775 1.254 1.475 3.761-.295 2.434-1.77-1.033-1.18-.738-3.613 1.032-4.055 1.549-.37 3.097 1.327 2.876 2.802-.295 1.917 2.729 2.433 3.024.516.516-3.614-2.803-7.08-6.49-6.268ZM85.002 72.767c-1.99.737-3.982 1.475-5.973 2.286-1.843.663-.885 3.613.959 2.876 1.99-.738 3.982-1.475 5.973-2.286 1.844-.664.885-3.54-.959-2.876ZM120.768 64.065l-4.498.664c-1.917.295-1.549 3.318.442 3.023l4.499-.663c1.917-.295 1.475-3.319-.443-3.024ZM102.405 117.897c-1.106 4.425-6.342 7.448-10.693 5.678-4.35-1.696-6.047-7.595-3.76-11.504.958-1.696-1.475-3.539-2.434-1.843-3.171 5.604-1.254 13.642 5.014 16.15 6.269 2.507 13.274-1.844 14.823-8.112.516-1.844-2.507-2.212-2.95-.369ZM125.709 78.74c-3.835.959-2.286 6.858 1.549 5.9 3.761-1.033 2.286-6.933-1.549-5.9ZM127.184 135.743c-8.48 5.752-17.993 8.259-28.17 7.301-1.917-.148-1.917 2.876 0 3.023 10.619.959 20.87-1.696 29.719-7.743 1.548-1.032.073-3.687-1.549-2.581ZM146.356 86.409c-3.613 2.58-6.12 6.268-7.079 10.692-.369 1.918 2.581 2.73 2.95.812.737-3.614 2.654-6.71 5.678-8.85 1.549-1.106.074-3.76-1.549-2.654ZM60.225 97.913a15.837 15.837 0 0 0-8.48-10.766c-1.77-.885-3.32 1.77-1.55 2.655 3.688 1.77 6.195 4.94 7.08 8.922.442 1.918 3.392 1.107 2.95-.81Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect width={200} height={200} fill="#fff" rx={100} />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "myAcademy") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width="234" height="234" rx="117" fill="#FF8B00" />
          <path
            d="M8.33892 335.952C8.8566 336.038 9.28798 336.297 9.71937 336.555C12.5666 335.175 16.7943 336.469 16.708 340.611C16.3629 356.055 15.5864 371.499 14.7236 386.943H213.08C212.908 373.052 212.735 359.247 212.735 345.356C212.735 341.301 216.877 339.92 219.724 341.215C231.371 334.916 242.933 328.445 254.494 322.06C254.408 319.558 254.322 316.97 254.236 314.468C253.2 279.525 239.999 244.84 215.065 219.906C191.855 196.696 153.806 179.268 120.502 180.735C49.8394 183.927 -15.4742 239.577 -13.231 314.468C-13.1447 318.782 -12.9721 323.268 -12.8858 327.755C-6.06973 329.912 0.573746 332.5 7.13099 335.261C7.64867 335.434 7.9938 335.693 8.33892 335.952Z"
            fill="#FFC422"
          />
          <path
            d="M90.8237 186.688H54.1549C20.9373 186.688 -8.91539 207.481 -19.7866 238.8C-24.1006 251.138 -27.8969 263.562 -31.6069 276.246C-33.5913 282.803 -35.4895 289.36 -37.6465 295.831C-7.70749 310.153 24.4747 319.644 55.6217 331.464C66.7517 299.8 72.8776 264.77 89.1844 237.247C98.5026 221.544 98.8477 202.132 90.8237 186.688Z"
            fill="#FFC422"
          />
          <path
            d="M263.813 272.105C263.64 271.501 263.468 270.897 263.209 270.207C259.412 257.61 255.616 245.099 251.388 232.761C240.517 201.442 210.664 180.648 177.447 180.648H140.778C132.754 196.092 133.099 215.419 142.504 231.208C157.171 255.884 163.556 286.686 172.788 315.676C202.986 300.922 232.579 284.702 263.813 272.105Z"
            fill="#FFC422"
          />
          <path
            d="M176.672 134.402C175.119 126.464 170.546 119.303 168.389 111.451C165.887 102.219 165.283 92.9013 166.06 83.4106C166.146 82.6341 165.801 82.2027 165.283 81.9439C151.996 52.4363 118.261 31.988 88.9258 45.1888C88.5807 45.3613 88.4081 45.6201 88.2355 45.879C78.2271 48.5536 69.6854 55.1972 65.3714 64.9468C62.3516 71.8491 62.3517 79.1829 62.6105 86.6029C62.8694 96.1799 61.9203 113.091 60.281 122.495C58.2965 133.97 57.9514 145.963 67.4422 154.332C74.6896 160.717 84.6118 162.443 93.5848 158.991C105.06 154.591 114.292 141.822 127.406 142.253C140.78 142.685 150.184 156.403 164.248 154.505C174.429 153.038 178.398 143.72 176.672 134.402Z"
            fill="#191229"
          />
          <path
            d="M163.213 51.3142C163.472 41.9098 168.045 31.6425 158.209 25.4304C148.287 19.132 135.862 25.0852 126.113 28.5364L95.0522 39.6665C94.0169 41.2195 93.0678 42.7726 92.1187 44.4119H92.205C92.1188 44.9295 92.1187 45.3609 92.1187 45.8786C89.1852 54.9379 93.758 72.1938 98.5897 80.3041C105.492 91.693 114.293 104.204 124.819 112.486C133.101 119.044 140.78 118.353 149.667 113.349C157.777 108.776 167.613 102.478 170.633 93.1598C174.516 80.8218 162.954 63.2208 163.213 51.3142Z"
            fill="#191229"
          />
          <path
            d="M145.437 45.2754C139.915 45.4479 134.307 45.7068 128.785 46.0519C123.004 46.4833 117.31 46.9147 111.529 47.6049C110.062 47.7775 108.509 47.95 107.042 48.1226C106.439 48.2089 105.576 48.2952 105.231 48.3814C102.47 48.7266 99.7087 49.158 96.9477 49.5894C92.4612 50.3659 87.9746 51.1424 83.4881 52.0914C75.6367 62.0999 70.7188 75.1281 70.7188 87.2072V126.982C70.7188 138.716 75.2916 149.415 82.7116 157.352C90.8219 165.98 102.297 171.416 115.066 171.416H120.071C134.566 171.416 147.421 164.514 155.532 153.815C161.14 146.395 164.505 137.077 164.505 127.068V87.2935C164.505 72.1083 156.998 55.7152 145.437 45.2754Z"
            fill="#F2A98A"
          />
          <path
            d="M120.158 34.6621H115.154C102.04 34.6621 90.3058 40.3566 82.1955 49.3296C75.1206 57.1811 70.8066 67.6209 70.8066 79.0098V87.724C77.3639 86.5161 80.2111 76.3351 83.3172 67.8797C85.3879 62.1853 90.2195 57.8713 96.0865 56.6634C109.46 53.8162 124.127 52.6083 138.018 53.0397C146.042 53.2985 152.772 59.0792 154.584 66.9307C154.843 68.1386 159.416 94.5401 162.004 95.9206C162.781 96.352 169.769 101.184 170.718 93.1597L164.679 79.0961C164.506 62.703 144.662 34.6621 120.158 34.6621Z"
            fill="#191229"
          />
          <path
            d="M152.511 143.807C129.561 144.583 106.611 144.842 84.0918 149.501C85.6448 163.047 86.1625 176.593 85.1272 190.139C87.9744 204.116 97.8102 215.678 113.427 217.317C129.734 219.042 149.578 206.014 149.664 188.068C149.75 178.06 149.75 168.137 149.837 158.129C150.613 157.611 151.304 156.921 152.08 156.317C151.649 152.262 151.735 147.948 152.511 143.807Z"
            fill="#F2A98A"
          />
          <path
            d="M84.8702 102.478C60.4531 87.7245 53.8096 89.8815 50.0995 100.235C46.3895 110.589 50.0995 116.542 69.3399 126.119C88.5802 135.782 84.8702 102.478 84.8702 102.478Z"
            fill="#F2A98A"
          />
          <path
            d="M148.891 102.478C173.308 87.7245 179.952 89.8815 183.662 100.235C187.372 110.589 183.662 116.542 164.421 126.119C145.181 135.782 148.891 102.478 148.891 102.478Z"
            fill="#F2A98A"
          />
          <path
            d="M126.113 94.1088C124.56 92.4695 121.713 94.7128 123.352 96.3521C127.666 100.752 137.157 112.745 126.544 116.455C124.387 117.232 125.768 120.51 127.925 119.734C132.325 118.181 134.741 113.867 134.741 109.208C134.655 103.082 130.168 98.2503 126.113 94.1088Z"
            fill="#191229"
          />
          <path
            d="M98.4156 92.2976C94.0154 93.2467 92.2035 99.2 95.0507 102.565C96.5175 104.29 99.451 102.22 97.8979 100.494C96.69 99.1137 97.0352 96.2665 99.1059 95.7488C100.918 95.3174 102.73 97.3018 102.471 99.0274C102.126 101.271 105.663 101.875 106.008 99.6314C106.612 95.4037 102.73 91.3486 98.4156 92.2976Z"
            fill="#191229"
          />
          <path
            d="M99.4512 85.135C97.1217 85.9978 94.7921 86.8606 92.4626 87.8097C90.3056 88.5862 91.4272 92.0374 93.5842 91.1746C95.9138 90.3118 98.2433 89.449 100.573 88.4999C102.73 87.7234 101.608 84.3585 99.4512 85.135Z"
            fill="#191229"
          />
          <path
            d="M141.298 74.9553L136.035 75.7318C133.792 76.077 134.223 79.6144 136.553 79.2693L141.816 78.4928C144.059 78.1477 143.541 74.6102 141.298 74.9553Z"
            fill="#191229"
          />
          <path
            d="M119.812 137.938C118.518 143.115 112.392 146.653 107.302 144.582C102.211 142.597 100.227 135.695 102.901 131.122C104.023 129.138 101.176 126.981 100.054 128.965C96.344 135.523 98.5873 144.927 105.921 147.86C113.255 150.794 121.451 145.703 123.263 138.37C123.867 136.213 120.33 135.781 119.812 137.938Z"
            fill="#191229"
          />
          <path
            d="M147.079 92.1256C142.592 93.2473 144.404 100.15 148.891 99.028C153.291 97.8201 151.566 90.9177 147.079 92.1256Z"
            fill="#191229"
          />
          <path
            d="M148.805 158.817C138.883 165.547 127.753 168.481 115.847 167.359C113.603 167.187 113.603 170.724 115.847 170.896C128.271 172.018 140.264 168.912 150.617 161.837C152.429 160.629 150.703 157.523 148.805 158.817Z"
            fill="#191229"
          />
          <path
            d="M171.236 101.098C167.008 104.118 164.075 108.432 162.953 113.609C162.522 115.852 165.973 116.801 166.404 114.558C167.267 110.33 169.51 106.706 173.048 104.204C174.86 102.91 173.134 99.804 171.236 101.098Z"
            fill="#191229"
          />
          <path
            d="M70.4609 114.557C69.253 109.122 65.6293 104.462 60.5388 101.96C58.468 100.925 56.6562 104.031 58.7269 105.066C63.0409 107.137 65.9744 110.847 67.0097 115.506C67.5274 117.749 70.9786 116.8 70.4609 114.557Z"
            fill="#191229"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "base") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width={234} height={234} fill="#C861F9" rx={117} />
          <path
            fill="#FFC422"
            d="M8.339 335.952c.518.086.949.345 1.38.603 2.848-1.38 7.075-.086 6.989 4.056-.345 15.444-1.122 30.888-1.984 46.332H213.08c-.172-13.891-.345-27.696-.345-41.587 0-4.055 4.142-5.436 6.989-4.141 11.647-6.299 23.209-12.77 34.77-19.155l-.258-7.592c-1.036-34.943-14.237-69.628-39.171-94.562-23.21-23.21-61.259-40.638-94.563-39.171-70.663 3.192-135.976 58.842-133.733 133.733.086 4.314.259 8.8.345 13.287 6.816 2.157 13.46 4.745 20.017 7.506.518.173.863.432 1.208.691Z"
          />
          <path
            fill="#FFC422"
            d="M90.824 186.688h-36.67c-33.217 0-63.07 20.793-73.94 52.112-4.315 12.338-8.11 24.762-11.82 37.446-1.985 6.557-3.883 13.114-6.04 19.585 29.939 14.322 62.12 23.813 93.268 35.633 11.13-31.664 17.256-66.694 33.562-94.217 9.319-15.703 9.664-35.115 1.64-50.559ZM263.813 272.105c-.173-.604-.345-1.208-.604-1.898-3.797-12.597-7.593-25.108-11.821-37.446-10.871-31.319-40.724-52.113-73.941-52.113h-36.669c-8.024 15.444-7.679 34.771 1.726 50.56 14.667 24.676 21.052 55.478 30.284 84.468 30.198-14.754 59.791-30.974 91.025-43.571Z"
          />
          <path
            fill="#191229"
            d="M176.672 134.402c-1.553-7.938-6.126-15.099-8.283-22.951-2.502-9.232-3.106-18.55-2.329-28.04.086-.777-.259-1.208-.777-1.467-13.287-29.508-47.022-49.956-76.357-36.755-.345.172-.518.431-.69.69-10.009 2.675-18.55 9.318-22.865 19.068-3.02 6.902-3.02 14.236-2.76 21.656.258 9.577-.69 26.488-2.33 35.892-1.984 11.475-2.33 23.468 7.161 31.837 7.248 6.385 17.17 8.111 26.143 4.659 11.475-4.4 20.707-17.169 33.821-16.738 13.374.432 22.778 14.15 36.842 12.252 10.181-1.467 14.15-10.785 12.424-20.103Z"
          />
          <path
            fill="#191229"
            d="M163.213 51.314c.259-9.404 4.832-19.672-5.004-25.884-9.922-6.298-22.347-.345-32.096 3.106l-31.06 11.13a87.424 87.424 0 0 0-2.934 4.746h.086c-.086.517-.086.949-.086 1.467-2.934 9.059 1.639 26.315 6.47 34.425 6.903 11.389 15.704 23.9 26.23 32.182 8.282 6.558 15.961 5.867 24.848.863 8.11-4.573 17.946-10.871 20.966-20.19 3.883-12.337-7.679-29.938-7.42-41.845Z"
          />
          <path
            fill="#F2A98A"
            d="M145.437 45.275c-5.522.173-11.13.432-16.652.777-5.781.431-11.475.863-17.256 1.553-1.467.173-3.02.345-4.487.518-.603.086-1.466.172-1.811.258-2.761.346-5.522.777-8.283 1.208-4.487.777-8.973 1.553-13.46 2.502-7.851 10.009-12.77 23.037-12.77 35.116v39.775c0 11.734 4.574 22.433 11.994 30.37 8.11 8.628 19.585 14.064 32.354 14.064h5.005c14.495 0 27.35-6.902 35.461-17.601a44.346 44.346 0 0 0 8.973-26.747V87.293c0-15.185-7.507-31.578-19.068-42.018Z"
          />
          <path
            fill="#191229"
            d="M120.158 34.662h-5.004c-13.114 0-24.848 5.695-32.959 14.668a44.254 44.254 0 0 0-11.388 29.68v8.714c6.557-1.208 9.404-11.389 12.51-19.844 2.07-5.695 6.903-10.009 12.77-11.217 13.373-2.847 28.04-4.055 41.931-3.623 8.024.258 14.754 6.04 16.566 13.89.259 1.209 4.832 27.61 7.42 28.99.777.432 7.765 5.264 8.714-2.76l-6.039-14.064c-.173-16.393-20.017-44.434-44.521-44.434Z"
          />
          <path
            fill="#F2A98A"
            d="M152.511 143.807c-22.95.776-45.9 1.035-68.42 5.694 1.554 13.546 2.072 27.092 1.036 40.638 2.847 13.977 12.683 25.539 28.3 27.178 16.307 1.725 36.151-11.303 36.237-29.249.086-10.008.086-19.931.173-29.939.776-.518 1.467-1.208 2.243-1.812-.431-4.055-.345-8.369.431-12.51ZM84.87 102.478c-24.417-14.753-31.06-12.596-34.77-2.243-3.71 10.354 0 16.307 19.24 25.884 19.24 9.663 15.53-23.641 15.53-23.641ZM148.891 102.478c24.417-14.753 31.061-12.596 34.771-2.243 3.71 10.354 0 16.307-19.241 25.884-19.24 9.663-15.53-23.641-15.53-23.641Z"
          />
          <path
            fill="#191229"
            d="M126.113 94.109c-1.553-1.64-4.4.604-2.761 2.243 4.314 4.4 13.805 16.393 3.192 20.103-2.157.777-.776 4.055 1.381 3.279 4.4-1.553 6.816-5.867 6.816-10.526-.086-6.126-4.573-10.958-8.628-15.1ZM98.416 92.298c-4.4.949-6.212 6.902-3.365 10.267 1.466 1.725 4.4-.345 2.847-2.071-1.208-1.38-.863-4.228 1.208-4.745 1.812-.432 3.624 1.553 3.365 3.278-.345 2.244 3.192 2.848 3.537.604.604-4.227-3.278-8.282-7.592-7.333ZM99.451 85.135c-2.33.863-4.659 1.726-6.988 2.675-2.157.776-1.036 4.227 1.121 3.365 2.33-.863 4.66-1.726 6.989-2.675 2.157-.777 1.035-4.141-1.122-3.365ZM141.298 74.955l-5.263.777c-2.243.345-1.812 3.882.518 3.537l5.263-.776c2.243-.345 1.725-3.883-.518-3.538ZM119.812 137.938c-1.294 5.177-7.42 8.715-12.51 6.644-5.091-1.985-7.075-8.887-4.401-13.46 1.122-1.984-1.725-4.141-2.847-2.157-3.71 6.558-1.467 15.962 5.867 18.895 7.334 2.934 15.53-2.157 17.342-9.49.604-2.157-2.933-2.589-3.451-.432ZM147.079 92.126c-4.487 1.121-2.675 8.024 1.812 6.902 4.4-1.208 2.675-8.11-1.812-6.902ZM148.805 158.817c-9.922 6.73-21.052 9.664-32.958 8.542-2.244-.172-2.244 3.365 0 3.537 12.424 1.122 24.417-1.984 34.77-9.059 1.812-1.208.086-4.314-1.812-3.02ZM171.236 101.098c-4.228 3.02-7.161 7.334-8.283 12.511-.431 2.243 3.02 3.192 3.451.949.863-4.228 3.106-7.852 6.644-10.354 1.812-1.294.086-4.4-1.812-3.106ZM70.46 114.557a18.53 18.53 0 0 0-9.921-12.597c-2.071-1.035-3.883 2.071-1.812 3.106 4.314 2.071 7.247 5.781 8.283 10.44.517 2.243 3.969 1.294 3.45-.949Z"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return null;
};
