import { TextMessages } from "app/messages";
import { AuthTypes } from "../authRoutes.congig";

export interface AuthData {
  type: AuthTypes;
  titles: {
    main: TextMessages;
    sub?: TextMessages;
  };
  toggles?: TogglesType;
  form: AuthDataForm;
}

export type TogglesType = {
  top: {
    isReg?: boolean;
    title: TextMessages;
    action: () => void;
  };
};

export interface AuthDataForm {
  info?: string | JSX.Element;
  errorMsg?: any;
  hasErrors?: boolean;
  valid?: boolean;
  infoIsValid?: boolean;
  actions?: AuthDataActions;
  submit?: (data?: any) => void;
  fields?: AuthDataFiled[];
  support?: AuthDataActions;
}
export interface AuthDataActions {
  active?: boolean;
  buttons?: {
    primary?: {
      title: string;
      action?: (data?: any) => void;
    };
    addition?: {
      title: string;
      action?: (data?: any) => void;
    };
    secondary?: { title: TextMessages; action: (data?: any) => void };
  };
}

export interface AuthDataFiled {
  label: string;
  name?: UseAuthFormFieldNames;
  value?: string;
  type?: string;
  strength?: boolean;
  onChange?: (data: any) => void;
  rules?: {
    validate?: any;
    required?: TextMessages;
    maxLength?: {
      value: number;
      message: string;
    };
    pattern?: {
      value?: RegExp;
      message?: TextMessages;
    } | null;
  };
  error?: boolean;
}

export enum UseAuthFormFieldNames {
  mail = "mail",
  login = "login",
  code = "code",
  name = "name",
  secondname = "secondname",
  password = "password",
  policy_agreed = "policy_agreed",
  position = "position",
  department = "department",
}
