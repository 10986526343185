import { generateUid } from "app/utils";
import classNames from "classnames";
import styles from "./Accordion.module.scss";
import { AccordionProps, Value } from "./Accordion.types";
import { AccordionRow } from "./components/AccordionRow";

export const Accordion = ({ value, className, customWidth, multiOpen = false }: AccordionProps) => {
  return (
    <div
      className={classNames(styles["container"], className)}
      style={
        customWidth
          ? {
              width: typeof customWidth === "number" ? customWidth + "px" : customWidth,
            }
          : {}
      }
    >
      {value.map((item: Value) => (
        <AccordionRow value={item} key={generateUid()} />
      ))}
    </div>
  );
};
