import { WebAppRoutes } from "app/routes";
import { Link } from "components/Link";
import { useAppSelector } from "hooks/redux";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useNavigate } from "react-router";
import { CapybaraPowerPic, ChairEmptyPic } from "../../../../components/Svg/Pics";
import { TextMain } from "../../../../components/Typography/Texts";
import { H3 } from "../../../../components/Typography/Titles";
import styles from "./OrdersEmpty.module.scss";

const OrdersEmpty = () => {
  const navigate = useNavigate();
  const toShop = () => navigate(WebAppRoutes.REWARDS_SHOP);
  const settings = useAppSelector(selectShopSettings);

  return (
    <div className={styles["orders-empty"]}>
      <div>
        {process.env.REACT_APP_THEME !== "myAcademy" ? <ChairEmptyPic /> : <CapybaraPowerPic />}
      </div>
      <div>
        <H3 className="course-empty-text__title">Заказов еще не было</H3>
        <TextMain className="course-empty-text__descr">
          Выберите товар в <Link onClick={toShop}>{settings?.name || "Магазине наград"}</Link> и
          оформите свой первый заказ.
        </TextMain>
      </div>
    </div>
  );
};
export default OrdersEmpty;
