import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Select } from "../../../../../components/Select";
import { Option } from "../../../../../components/Select/types";
import { useAppDispatch } from "../../../../../hooks/redux";
import { coursesCategorySelectors, setMissGetCourses } from "../../../redux/coursesListSlice";
import styles from "../CoursesSearch.module.scss";

interface CategorySearchProps {
  selectedCourseCategories: Option[] | undefined;
  setSelectedCourseCategories: Dispatch<SetStateAction<Option[] | undefined>>;
  isCompetenceEnabled?: boolean;
}

export const CategorySearch = ({
  selectedCourseCategories,
  setSelectedCourseCategories,
  isCompetenceEnabled = false,
}: CategorySearchProps) => {
  const dispatch = useAppDispatch();
  const courseCategories = useSelector(coursesCategorySelectors.selectAll);

  const handleSetSelectedCourseCategories = (selectedOptions: Option[]) =>
    setSelectedCourseCategories((prev) => {
      if (prev?.length === courseCategories.length && !selectedOptions) {
        dispatch(setMissGetCourses(true));
      } else {
        dispatch(setMissGetCourses(false));
      }
      return selectedOptions as Option[];
    });

  const isSelectedAll = selectedCourseCategories?.length === courseCategories.length;

  return (
    <div className={isCompetenceEnabled ? styles["primary-control"] : styles["secondary-control"]}>
      <Select
        showCleaner={!isSelectedAll && !!selectedCourseCategories}
        label="Категории"
        onChange={(selectedOptions) =>
          handleSetSelectedCourseCategories(selectedOptions as Option[])
        }
        isMulti={true}
        value={selectedCourseCategories}
        options={courseCategories.map(({ id, name }) => ({
          label: name ?? "",
          value: `${id}`,
        }))}
      />
    </div>
  );
};
