import { Badge } from "components/Badge";
import { OrderStatus } from "pages/Orders/redux/interfaces/orders.interface";
import styles from "../OrderItem.module.scss";

interface OrderStatusProps {
  status: OrderStatus;
}

export const OrderStatusBadge = ({ status }: OrderStatusProps) => {
  return (
    <Badge
      className={styles["item-status-badge"]}
      type="status"
      color={
        status === OrderStatus.New
          ? "base"
          : status === OrderStatus.Confirmed
          ? "primary"
          : status === OrderStatus.Cancelled
          ? "dark"
          : "success"
      }
    >
      {status === OrderStatus.New
        ? "Новый"
        : status === OrderStatus.Confirmed
        ? "Подтвержден"
        : status === OrderStatus.Cancelled
        ? "Отменен"
        : "Выполнен"}
    </Badge>
  );
};
