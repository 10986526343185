import { configureStore } from "@reduxjs/toolkit";
import { checkToken } from "../../services/api/api";
import { appReducer } from "./reducer";

export const store = configureStore({
  reducer: appReducer,
});

export type RootState = ReturnType<typeof appReducer>;
export type appState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

(async function () {
  // @ts-ignore
  await checkToken(store);
})();
