import { CircleChart } from "components/Charts";
import styles from "./ResultAttempt.module.scss";
import classNames from "classnames";

interface ResultAttemptProps {
  result?: number;
  minSuccessResult?: number;
  countAnswers?: number;
  countRightAnswers?: number;
  dateAttempt?: string;
  showPercent?: boolean;
}

export const ResultAttempt = ({
  result = 0,
  minSuccessResult = 0,
  countAnswers = 0,
  countRightAnswers = 0,
  dateAttempt,
  showPercent = true,
}: ResultAttemptProps) => (
  <div className={styles["result-attempt"]}>
    <div
      className={classNames(styles["result-attempt__chart"], {
        [styles["result-attempt__success-chart"]]: result >= minSuccessResult,
      })}
    >
      <CircleChart progress={result} />
      <p className={styles["result-attempt__result"]}>
        {result}
        {showPercent ? "%" : ""}
      </p>
    </div>
    <div className={styles["result-attempt__text"]}>
      {countRightAnswers > 0 && countAnswers > 0 && (
        <p className={styles["result-attempt__count-answers"]}>
          {countRightAnswers} из {countAnswers}
        </p>
      )}
      <p className={styles["result-attempt__date-attempt"]}>{dateAttempt}</p>
    </div>
  </div>
);
