import { Capacitor } from "@capacitor/core";
import MainMenuDrawer from "Layout/PublicLayout/components/Drawer/MainMenuDrawer";
import UserWidget from "Layout/PublicLayout/components/UserWidget/UserWidget";
import Footer from "components/Footer/Footer";
import Hamburger from "components/Hamburger/Hamburger";
import { HeaderV2 } from "components/Header";
import { EducationStudioLogo, MyAcademyLogo } from "components/Logos";
import { RowNavMenu } from "components/RowNavMenu/RowNavMenu";
import { ScrollbarContainer } from "components/ScrollbarContainer";
import { useMediaQuery } from "hooks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getInitProfileFields } from "pages/Profile/redux/profile-slice/profile.slice";
import { selectProfileLoad } from "pages/Profile/redux/profile-slice/selectors/profile.selectors";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { headerMenuData } from "../../app/constants";
import styles from "./Main.module.scss";
import { RostechnadzorFullLogo } from "../../components/Logos/RostechnadzorFullLogo";

interface MainProps {
  children?: JSX.Element | JSX.Element[];
}

export const Main = ({ children }: MainProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mob = useMediaQuery("(max-width: 629px)");
  const profileLoad = useAppSelector(selectProfileLoad);
  const shopSettings = useAppSelector(selectShopSettings);

  const onCLickLogo = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!profileLoad) {
      dispatch(getInitProfileFields());
    }
  }, [profileLoad, dispatch]);

  const headerData = shopSettings?.enable
    ? [
        ...headerMenuData,
        {
          label: "Магазин наград",
          path: "/rewards-shop",
        },
      ]
    : headerMenuData;

  return (
    <div className={styles["container"]}>
      <HeaderV2 className={styles["header"]}>
        <div className={styles["header-container"]}>
          <div className={styles["logo"]} onClick={onCLickLogo} role="button">
            {process.env.REACT_APP_THEME === "rostechnadzor" && <RostechnadzorFullLogo />}
            {process.env.REACT_APP_THEME === "myAcademy" && <MyAcademyLogo />}
            {process.env.REACT_APP_THEME === "base" && <EducationStudioLogo />}
          </div>
          {!mob && (
            <>
              <RowNavMenu routes={headerData} className={styles["header-menu"]} />
              <UserWidget />
            </>
          )}
          {mob && <Hamburger />}
        </div>
      </HeaderV2>
      {mob && <MainMenuDrawer />}
      <main className={styles["main"]}>
        <ScrollbarContainer
          className={styles["scrollbar-container"]}
          upButtonClassName={styles["up-button-class-name_rostechnadzor"]}
        >
          <div className={styles["content-container"]}>
            <div className={styles["content"]}>{children}</div>
            {!Capacitor.isNativePlatform() && <Footer />}
          </div>
        </ScrollbarContainer>
      </main>
    </div>
  );
};
