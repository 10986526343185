import { TextMessages } from "app/messages";
import { AuthTypes } from "../authRoutes.congig";
import { AuthData } from "./data.types";
import { WebAppRoutes } from "app/routes";

export const registeredAcc = (nav: (endpoint: any, state?: any) => void): AuthData => ({
  type: AuthTypes.REGISTERED,
  toggles: {
    top: {
      title: TextMessages.AUTHORIZE_TO_ANOTHER,
      action: () => nav(WebAppRoutes.AUTH),
    },
  },
  titles: {
    main: TextMessages.REDISTERED,
    sub: TextMessages.REGISTERED_MESSAGE,
  },
  form: {},
});
