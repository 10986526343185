import { mediaQuerys } from "app/utils";
import { BalanceRow } from "components/BalanceRow";
import { Link } from "components/Link";
import { CapybaraDesktop, DesktopCoffee } from "components/Svg/Pics";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useMediaQuery } from "hooks/useMediaQuery";
import {
  getProfileCoins,
  getProfileCoinsDetails,
  getShopAvailable,
  getShopProducts,
  resetPagination,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import {
  selectProfileCoins,
  selectShopProducts,
  selectShopProductsLoading,
  selectShopSearch,
  selectShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router";
import styles from "./RewardsShop.module.scss";
import { RewardsEmpty } from "./components/RewardsEmpty/RewardsEmpty";
import { RewardsList } from "./components/RewardsList/RewardsList";
import RewardsListSkeleton from "./components/RewardsListSkeleton";
import { RewardsSearch } from "./components/RewardsSearch/RewardsSearch";

export const RewardsShop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const products = useAppSelector(selectShopProducts);
  const isLoading = useAppSelector(selectShopProductsLoading);

  const coins = useAppSelector(selectProfileCoins);
  const settings = useAppSelector(selectShopSettings);
  const search = useAppSelector(selectShopSearch);
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const activeProducts = products.data.filter((x) =>
    search.accessibility ? coins.total_coins >= (x.price || 0) : true
  );

  const { ref: observerTarget, inView: isInView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
    delay: 200,
  });

  useEffect(() => {
    dispatch(getShopAvailable()).catch(() => navigate("/500", { replace: true }));
    dispatch(getProfileCoins());
    dispatch(getProfileCoinsDetails());
    return () => {
      dispatch(resetPagination());
    };
  }, [dispatch, navigate]);

  useEffect(() => {
    if (
      !isInView ||
      products.data.length < 1 ||
      (products.offset + 1) * products.limit >= products.totalCount
    )
      return;

    dispatch(
      getShopProducts({
        offset: products.offset + 1,
        limit: products.limit,
        add: true,
      })
    );

    // eslint-disable-next-line
  }, [dispatch, isInView]);

  useEffect(() => {
    if (settings && !settings.enable) {
      navigate("/500", { replace: true });
    }
  }, [navigate, settings]);

  const openPdf = () => {
    fetch(window.location.origin + "/api" + (settings?.tos?.path || ""))
      .then((response) => response.blob())
      .then((data) => window.open(URL.createObjectURL(data)));
  };

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["container"]}>
        {!isMobile ? (
          <>
            <div className={styles["title-container"]}>
              <div>
                <h1 className={styles["title"]}>{settings?.name ?? "Магазин наград"}</h1>
                <Link onClick={openPdf}>Правила работы</Link>
              </div>

              <div className={styles["balance-container"]}>
                <p>Можно потратить:</p>
                <BalanceRow coins={coins.shop_coins} className={styles["balance-row"]} />
              </div>
            </div>

            <RewardsSearch />

            <div className={styles["courses-container"]}>
              {!isLoading ? (
                activeProducts.length > 0 ? (
                  <>
                    <RewardsList items={activeProducts} />
                    <div ref={observerTarget} style={{ height: "40px" }} />
                  </>
                ) : (
                  <RewardsEmpty />
                )
              ) : (
                <>
                  <RewardsListSkeleton />
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles["title-container"]}>
              <div>
                <h1 className={styles["title"]}>{settings?.name ?? "Магазин наград"}</h1>
              </div>
            </div>
            <div className={styles["mobile-container"]}>
              {process.env.REACT_APP_THEME !== "myAcademy" ? (
                <DesktopCoffee />
              ) : (
                <CapybaraDesktop />
              )}
              <p>Доступно только на ноутбуке или компьютере.</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
