import { useDebounceFn } from "ahooks";
import { TextMain } from "components/Typography/Texts";
import { useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import TextField from "../../../../../../../components/Forms/TextField";
import { H3 } from "../../../../../../../components/Typography/Titles";
import { setQuestAnswer } from "../../../redux/Quze/courseQuizeQuestionsSlice";
import {
  answersByPageSelector,
  selectCourseAnswersOfAttempt,
  selectCurrentOpenPageInQuiz,
} from "../../../redux/Quze/courseQuizeSelectors";
import { IQuizeQuestionProp } from "../../../redux/Quze/interface/quiz.interface";
import { QuestDescription } from "./QuestDescription/QuestDescription";

export const QuestText = ({
  title,
  description,
  questionsId,
  handleChange = () => {},
}: IQuizeQuestionProp) => {
  const dispatch = useDispatch();
  const answersOfAttempt = useAppSelector(selectCourseAnswersOfAttempt);
  const answers: any = useAppSelector(answersByPageSelector);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);

  const defaultAnswers = answers[`${currentOpenPageInQuiz}`]?.find(
    (answer: any) => answer.id === questionsId
  );

  const { control, getValues, setValue } = useForm({
    mode: "onChange",
  });

  const { run: handleOnChange } = useDebounceFn(
    () => {
      const values = getValues();
      if (values?.["quest_" + questionsId] !== undefined) {
        dispatch(
          setQuestAnswer({
            page: currentOpenPageInQuiz,
            answer: { id: questionsId, answer: values?.["quest_" + questionsId] },
          })
        );
        handleChange({ id: questionsId, answer: values?.["quest_" + questionsId] });
      }
    },
    {
      wait: 300,
    }
  );

  useEffect(() => {
    const answer: any = answersOfAttempt.find((answer: any) => answer.question_id === questionsId);
    if (!defaultAnswers?.answer && answer?.answer) {
      setValue("quest_" + questionsId, answer?.answer ?? "");
      handleOnChange();
    } else {
      setValue("quest_" + questionsId, defaultAnswers?.answer ?? "");
    }
  }, [answersOfAttempt, defaultAnswers?.answer, handleOnChange, questionsId, setValue]);

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />
      <TextMain className="course-questions-block-wrapper__title">Напишите свой ответ:</TextMain>
      <div
        className="course-questions-block-wrapper"
        style={{ padding: 0, background: "transparent" }}
      >
        <form onChange={handleOnChange}>
          <Controller
            name={"quest_" + questionsId}
            control={control}
            defaultValue={""}
            render={({ field }) => (
              //@ts-ignore
              <TextField {...field} value={field.value} multiline>
                {field.value}
              </TextField>
            )}
          />
        </form>
      </div>
    </div>
  );
};
