import { TextMain } from "../../../../../../../components/Typography/Texts";
import { H3 } from "../../../../../../../components/Typography/Titles";

import { useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setQuestAnswer } from "../../../redux/Quze/courseQuizeQuestionsSlice";
import {
  answersByPageSelector,
  selectCourseAnswersOfAttempt,
  selectCurrentOpenPageInQuiz,
} from "../../../redux/Quze/courseQuizeSelectors";
import { IQuizeQuestionProp } from "../../../redux/Quze/interface/quiz.interface";
import { QuestDescription } from "./QuestDescription/QuestDescription";

export const QuestRadio = ({
  title,
  multy,
  questions = [],
  questionsId,
  description,
  withHint = true,
  handleChange = () => {},
}: IQuizeQuestionProp) => {
  const dispatch = useDispatch();
  const answersOfAttempt = useAppSelector(selectCourseAnswersOfAttempt);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);
  const answers: any = useAppSelector(answersByPageSelector);

  const defaultAnswers = answers[`${currentOpenPageInQuiz}`]?.find(
    (answer: any) => answer.id === questionsId
  );

  const [currentMultyValue, setCurrentMultyValue] = useState<any[]>([]);

  const handleOnChange = (itemCode: string) => () => {
    if (multy) {
      const currentAnswers = defaultAnswers?.answer ?? [];
      const newAnswer = currentAnswers.includes(itemCode)
        ? currentAnswers.filter((answer: string) => answer !== itemCode)
        : [...currentAnswers, itemCode];

      dispatch(
        setQuestAnswer({
          page: currentOpenPageInQuiz,
          answer: { id: questionsId, answer: newAnswer },
        })
      );
      handleChange({ id: questionsId, answer: newAnswer });

      return;
    }

    dispatch(
      setQuestAnswer({
        page: currentOpenPageInQuiz,
        answer: { id: questionsId, answer: itemCode },
      })
    );
    handleChange({ id: questionsId, answer: itemCode });
  };

  useEffect(() => {
    if (defaultAnswers?.answer) {
      setCurrentMultyValue(defaultAnswers.answer);
    }
  }, [defaultAnswers?.answer]);

  useEffect(() => {
    const answer: any = answersOfAttempt.find((answer: any) => answer.question_id === questionsId);
    if (multy && !defaultAnswers) {
      const answers = questions.reduce((acc: string[], value) => {
        const hasAnswer = answer?.answer?.includes(value.text?.replace(/[a-z]./gm, "").trim());
        if (hasAnswer) {
          return [...acc, value.code];
        }
        return acc;
      }, []);

      answers.forEach((answer) => {
        dispatch(
          setQuestAnswer({
            page: currentOpenPageInQuiz,
            answer: { id: questionsId, answer: [answer] },
          })
        );
      });
      return;
    }

    if (!multy && !defaultAnswers) {
      const findAnswer = questions.find((value) =>
        answer?.answer?.includes(value.text?.replace(/[a-z]./gm, "").trim())
      );

      findAnswer &&
        dispatch(
          setQuestAnswer({
            page: currentOpenPageInQuiz,
            answer: { id: questionsId, answer: findAnswer?.code },
          })
        );
    }
  }, [
    answersOfAttempt,
    currentOpenPageInQuiz,
    defaultAnswers,
    defaultAnswers?.answer,
    dispatch,
    multy,
    questions,
    questionsId,
  ]);

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />
      {withHint && (
        <>
          {multy ? (
            <TextMain className="course-questions-block-wrapper__title">
              Выберите правильные ответы:
            </TextMain>
          ) : (
            <TextMain className="course-questions-block-wrapper__title">
              Выберите один из ответов:
            </TextMain>
          )}
        </>
      )}

      <div className="course-questions-block-wrapper">
        <ul className="course-questions-block-list">
          {questions.map((item) => {
            const checked = multy
              ? currentMultyValue.includes(item.code)
              : +defaultAnswers?.answer === +item.code;

            return (
              <li key={item.code} className={"course-questions-block-list__item"}>
                <label className={"course-questions-bubble"}>
                  <input
                    className={"course-questions-bubble__check"}
                    value={item.code}
                    checked={checked}
                    type={multy ? "checkbox" : "radio"}
                    onChange={handleOnChange(item.code)}
                  />
                  <span className={"course-questions-bubble__item"}>{item.text}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
