import { useEffect, useState } from "react";
import CourseSheetStart from "./CourseSheetStart";
import CourseSheetTest from "./CourseSheetTest";
import CourseSheetThank from "./CourseSheetThank";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import { getCourseQuestionnaireContent } from "./redux/Questionary/courseQuestionarySlice";
import { selectCourseQuestionnaireState } from "./redux/Questionary/courseQuestionarySelectors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { CourseLessonQuestionary } from "../../redux/interfaces";

const CourseSheet = ({ lesson }: { lesson: CourseLessonQuestionary }) => {
  const questionnaireState = useAppSelector(selectCourseQuestionnaireState);
  const [isLoadQuestionnaireData, setIsLoadQuestionnaireData] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      setIsLoadQuestionnaireData(false);
    };
  }, []);

  useEffect(() => {
    dispatch(getCourseQuestionnaireContent(lesson.id)).then(() => {
      setIsLoadQuestionnaireData(true);
    });
    return () => {
      setIsLoadQuestionnaireData(false);
    };
  }, [dispatch, lesson.id]);

  const CourseSheetVariant = (lesson: CourseLessonQuestionary, status: string) => {
    if (lesson && !lesson.questions_length) {
      if (lesson.passed === true) {
        return <CourseSheetThank lesson={lesson} />;
      }
      if (lesson.passed === false) {
        return <CourseSheetStart lesson={lesson} />;
      }
    }

    switch (status) {
      case "idle":
        return <CourseSheetStart lesson={lesson} />;
      case "started":
        return <CourseSheetTest lesson={lesson} />;
      case "complete":
        return <CourseSheetThank lesson={lesson} />;
      default:
        return <CourseLoadSkeleton />;
    }
  };

  return isLoadQuestionnaireData ? (
    CourseSheetVariant(lesson, questionnaireState)
  ) : (
    <CourseLoadSkeleton />
  );
};

export default CourseSheet;
