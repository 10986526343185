import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EndPoints } from "../../../../app/endpoints";
import api from "../../../../services/api/api";

import { Capacitor } from "@capacitor/core";
import { KnownError } from "../profile-slice/interface";
import { RewardShopSlice } from "./interface";
import {
  ClubData,
  IOrderRequest,
  IProductsRequest,
  IProductsResponse,
  IShopProductFull,
  ProfileCoinsData,
  ProfileCoinsDetailedData,
  ProfileTransactionsResponse,
  ShopSettingsResponse,
} from "./interface/rewards-shop-slice.interface";

const initialState: RewardShopSlice = {
  coins: {
    total_coins: 0,
    shop_coins: 0,
  },
  lmsSettings: {
    keywords: false,
    competences: false,
    firstLoginCheck: false,
  },
  transactions: {
    data: [],
    paging: { limit: 40, offset: 0, total: 0 },
  },
  products: {
    data: [],
    limit: 20,
    offset: 0,
    totalCount: 0,
  },
  coinsDetails: {
    total_coins: 0,
    shop_coins: 0,
    club_points: [],
  },
  productsIsLoading: true,
  search: {},
};

const getShopSettings = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "shop/getShopSettings",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(EndPoints.SHOP_SETTINGS);

      return {
        status: response.status,
        settings: response.data,
      };
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getShopAvailable = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "shop/getShopAvailable",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(EndPoints.SHOP_PARAMETERS + "/is-on", {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getProfileTransactions = createAsyncThunk<
  any,
  { offset?: number; limit?: number } | undefined,
  {
    rejectValue: KnownError;
  }
>("profile/getProfileTransactions", async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get(EndPoints.POFILE_TRANSACTIONS, { params });

    return { status: response.status, data: response.data?.data, paging: response.data.paging };
  } catch (error: any) {
    return rejectWithValue({
      status: error.response.status,
      code: error.response.data.code,
      details: error.response.data.details,
    });
  }
});

const getProfileCoins = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileCoins",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await api.get(EndPoints.SHOP_COINS, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getProfileCoinsDetails = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileCoinsDetails",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await api.get(EndPoints.SHOP_COINS_DETAILED, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getShopProducts = createAsyncThunk(
  "shop/getShopProducts",
  async (arg: IProductsRequest & { add?: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const { add, ...request } = arg;
      request.limit = request.limit ?? 20;
      request.offset = request.offset ?? 0;

      const response = await api.get(EndPoints.SHOP_PRODUCTS, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
        params: request,
      });
      return { response: response.data, limit: request.limit, offset: request.offset, add };
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getShopProduct = createAsyncThunk(
  "shop/getShopProduct",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get<IShopProductFull>(EndPoints.SHOP_PRODUCTS + `/${id}`, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const putProductViews = createAsyncThunk(
  "shop/putProductViews",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(EndPoints.SHOP_PRODUCTS + `/${id}/views`, undefined, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const postOrder = createAsyncThunk(
  "shop/postOrder",
  async (request: IOrderRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(EndPoints.SHOP_ORDERS, request, {
        baseURL: Capacitor.isNativePlatform() ? "https://uat-lan.front.fss.local/" : "/",
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error?.response?.status,
        code: error?.response?.data?.code,
        details: error?.response?.data?.details,
      });
    }
  }
);

const getClub = createAsyncThunk(
  "shop/getClub",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(EndPoints.CLUB(id));
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error?.response?.status,
        code: error?.response?.data?.code,
        details: error?.response?.data?.details,
      });
    }
  }
);

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setSearchInput(state, action: PayloadAction<string | undefined>) {
      state.search.input = action.payload;
    },
    setSearchType(state, action: PayloadAction<string | undefined>) {
      state.search.type = action.payload;
    },
    setSearchMinPrice(state, action: PayloadAction<number | undefined>) {
      state.search.min_price = action.payload;
    },
    setSearchMaxPrice(state, action: PayloadAction<number | undefined>) {
      state.search.max_price = action.payload;
    },
    setSearchAccessibility(state, action: PayloadAction<boolean | undefined>) {
      state.search.accessibility = action.payload;
    },
    resetPagination(state) {
      state.products.offset = 0;
      state.products.data = [];
      state.productsIsLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShopSettings.fulfilled, (state, action: PayloadAction<ShopSettingsResponse>) => {
        const shopData = action.payload?.settings?.data?.shop;

        state.shopSettings = shopData || { enable: false };

        state.lmsSettings = action.payload?.settings?.data?.lms || {
          keywords: false,
          firstLoginCheck: false,
        };
      })
      .addCase(getShopSettings.rejected, (state) => {
        state.shopSettings = { enable: false };
      })
      .addCase(getShopAvailable.rejected, (state) => {
        state.shopSettings = { enable: false };
      })
      .addCase(getProfileCoins.fulfilled, (state, action: PayloadAction<ProfileCoinsData>) => {
        state.coins = action.payload;
      })
      .addCase(
        getProfileCoinsDetails.fulfilled,
        (state, action: PayloadAction<ProfileCoinsDetailedData>) => {
          state.coinsDetails = action.payload;
        }
      )
      .addCase(getProfileTransactions.pending, (state) => {
        state.transactions.isLoading = true;
      })
      .addCase(getProfileTransactions.rejected, (state) => {
        state.transactions.isLoading = false;
      })
      .addCase(
        getProfileTransactions.fulfilled,
        (state, action: PayloadAction<ProfileTransactionsResponse>) => {
          const { data, paging } = action.payload;

          state.transactions = {
            ...action.payload,
            data: paging.offset > 0 ? [...state.transactions.data, ...data] : data,
          };
        }
      )
      .addCase(getShopProducts.pending, (state) => {
        state.productsIsLoading = true;
      })
      .addCase(getShopProducts.rejected, (state) => {
        state.productsIsLoading = false;
      })
      .addCase(
        getShopProducts.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: IProductsResponse;
            limit: number;
            offset: number;
            add?: boolean;
          }>
        ) => {
          if (action.payload.add && state.products.data) {
            state.products.data = [...state.products.data, ...action.payload.response.items];
          } else {
            state.products.data = action.payload.response.items;
          }
          state.products.limit = action.payload.limit;
          state.products.offset = action.payload.offset;
          state.products.totalCount = action.payload.response.totalCount;

          state.productsIsLoading = false;
        }
      )
      .addCase(getShopProduct.fulfilled, (state, action: PayloadAction<IShopProductFull>) => {
        state.currentProduct = action.payload;
      })
      .addCase(getClub.fulfilled, (state, action: PayloadAction<ClubData>) => {
        if (!state.clubs) {
          state.clubs = [action.payload];
          return;
        }

        const clubIndex = state.clubs.findIndex((x) => x.id === action.payload.id);
        if (clubIndex === -1) {
          state.clubs.push(action.payload);
        } else {
          state.clubs[clubIndex] = { ...action.payload };
        }
      });
  },
});
export const {
  setSearchInput,
  setSearchType,
  setSearchMinPrice,
  setSearchMaxPrice,
  setSearchAccessibility,
  resetPagination,
} = shopSlice.actions;
export {
  getClub,
  getProfileCoins,
  getProfileCoinsDetails,
  getProfileTransactions,
  getShopAvailable,
  getShopProduct,
  getShopProducts,
  getShopSettings,
  postOrder,
  putProductViews,
};
export default shopSlice.reducer;
