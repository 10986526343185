import classNames from "classnames";
import { Avatar } from "components/Avatar";
import { CourseCardBadge } from "components/CourseCardBadge";
import { format } from "date-fns";
import { FeedbackMessage } from "pages/Course/redux/interfaces/course.interface";
import styles from "./FeedbackCard.module.scss";

interface FeedbackCardProps {
  feedbackMessage: FeedbackMessage;
  className?: string;
}

export const FeedbackCard = ({
  feedbackMessage: { user_name, text, created_ts, rating, user },
  className,
}: FeedbackCardProps): JSX.Element => {
  const formatedDate = format(new Date(created_ts * 1000), "dd.MM.yyy");

  const formatedText = text?.slice(0, 300);

  return (
    <div className={classNames(styles["container"], className)}>
      <div className={styles["header"]}>
        <Avatar src={user?.avatar_code || "3"} className={styles["avatar"]} />
        <div className={styles["info"]}>
          <span className={styles["name"]} title={user.fullname}>
            {user.fullname}
          </span>
          <div className={styles["rating-date"]}>
            {!!rating && (
              <CourseCardBadge
                type="rating"
                text={rating}
                className={classNames(styles["rating"], {
                  [styles["rating-myacademy"]]: process.env.REACT_APP_THEME === "myAcademy",
                  [styles["rating-rostechnadzor"]]: process.env.REACT_APP_THEME === "rostechnadzor",
                })}
              />
            )}
            <span className={styles["date"]}>{formatedDate}</span>
          </div>
        </div>
      </div>
      <span className={styles["text"]}>{formatedText}</span>
    </div>
  );
};
