import { WebAppRoutes } from "app/routes";
import { mediaQuerys } from "app/utils/utils";
import { Link } from "components/Link";
import PaperBox from "components/Paper/PaperBox";
import { Switch } from "components/Switch/Switch";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useMediaQuery } from "hooks/useMediaQuery";
import { getShopAvailable } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./Orders.module.scss";
import { OrderList } from "./components/OrderList/OrderList";
import OrdersActiveEmpty from "./components/OrdersEmpty/OrdersActiveEmpty";
import OrdersEmpty from "./components/OrdersEmpty/OrdersEmpty";
import { WalletWidget } from "./components/WalletWidget/WalletWidget";
import { OrderStatus } from "./redux/interfaces/orders.interface";
import { getUserOrders } from "./redux/orders.slice";
import classNames from "classnames";
import { selectOrdersIsLoading, selectUserOrders } from "./redux/selectors/orders.selectors";
import { Skeleton } from "components/Skeleton";

export const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mdMin = useMediaQuery(`(min-width:${mediaQuerys.mdMin}px)`);

  const orders = useAppSelector(selectUserOrders);
  const settings = useAppSelector(selectShopSettings);
  const isLoading = useAppSelector(selectOrdersIsLoading);

  const [active, setActive] = useState<boolean>(true);

  const activeOrders = useMemo(
    () =>
      active
        ? orders.filter((o) => o.status === OrderStatus.New || o.status === OrderStatus.Confirmed)
        : orders,
    [orders, active]
  );

  useEffect(() => {
    if (settings && !settings.enable) {
      navigate("/500", { replace: true });
    }
  }, [navigate, settings]);

  useEffect(() => {
    dispatch(getShopAvailable());
    dispatch(getUserOrders());
  }, [dispatch]);

  const toProfile = () => navigate(WebAppRoutes.PROFILE);

  return (
    <main
      className={classNames(styles["page"], {
        [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
      })}
    >
      <div className={styles["page-container"]}>
        <article className={styles["page-content"]}>
          <PaperBox className={styles["page-box"]} data-is-edit={false}>
            <div className={styles["page-content-header"]}>
              <Link onClick={toProfile}>Перейти в профиль</Link>
              <h1 className={styles["page-content-title"]}>Мои заказы</h1>
              <div className={styles["page-filter"]}>
                <p>Время указывается по МCК (UTC+3)</p>

                {orders && orders.length > 0 && (
                  <Switch label="Только активные заказы" isChecked={active} onChange={setActive} />
                )}
              </div>
            </div>
            {isLoading ? (
              <>
                <Skeleton variant="text" width="100%" height="120px" sx={{ marginTop: "-20px" }} />
                <Skeleton variant="text" width="100%" height="120px" sx={{ marginTop: "-20px" }} />
                <Skeleton variant="text" width="100%" height="120px" sx={{ marginTop: "-20px" }} />
                <Skeleton variant="text" width="100%" height="120px" sx={{ marginTop: "-20px" }} />
              </>
            ) : activeOrders && activeOrders.length > 0 ? (
              <OrderList orders={activeOrders} />
            ) : activeOrders.length < 1 && orders.length > 0 ? (
              <OrdersActiveEmpty onRemoveActive={() => setActive(false)} />
            ) : (
              <OrdersEmpty />
            )}
          </PaperBox>
        </article>
        {mdMin && <WalletWidget />}
      </div>
    </main>
  );
};
