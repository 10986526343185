import { CourseTypeTile } from "components/CourseTypeTile";
import { CheckCircleIcon } from "components/Icons";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import styles from "../../TrajectoryResultCard.module.scss";
import classNames from "classnames";

interface ResultProps {
  isFinished: boolean;
  format: CourseFormat;
}

export const Statuses = ({ isFinished, format }: ResultProps): JSX.Element => {
  const myAcademy = process.env.REACT_APP_THEME === "myAcademy";

  return (
    <div className={styles["statuses"]}>
      <CourseTypeTile type={format} className={styles["type"]} />
      {isFinished && (
        <div className={classNames(styles["status"], { [styles["status-light"]]: myAcademy })}>
          <CheckCircleIcon />
          Завершен
        </div>
      )}
    </div>
  );
};
