import { ResultAttempt } from "components/ResultAttempt";
import { format } from "date-fns";

const CourseTestAchieve = ({ results = [] }) => {
  if (results?.length === 0) {
    return <></>;
  }

  return (
    <div className={"course-test-achieve"}>
      {results
        .filter((item) => item?.score > 0)
        .map((item, index) => {
          return (
            <ResultAttempt
              key={index}
              minSuccessResult={index === 0 ? item.score : 1000}
              result={item.score}
              dateAttempt={item.end_ts ? format(+`${item.end_ts}000`, "dd.MM.yyyy") : ""}
            />
          );
        })}
    </div>
  );
};

export default CourseTestAchieve;
