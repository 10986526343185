import { SVGProps } from "react";

export const MediaIcon = ({
  iconColor = "#FFC422",
  rectColor = "#191229",
  ...otherProps
}: { iconColor?: string; rectColor?: string } & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={92}
      height={96}
      viewBox="0 0 92 96"
      fill="none"
      {...otherProps}
    >
      <path
        fill={rectColor}
        d="M56.855 78.846c-10.816 10.403-26.823 3.033-35.476-7.803-13.188-16.516-12.98-35.977 3.893-47.68 16.873-11.704 39.802-3.035 49.752 9.969 9.951 13.004-7.353 35.11-18.17 45.514Z"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M25.333 41.854c0-13.623 11.044-24.666 24.667-24.666 13.623 0 24.667 11.043 24.667 24.666 0 13.623-11.044 24.667-24.667 24.667-13.623 0-24.667-11.044-24.667-24.667ZM50 13.188c-15.832 0-28.667 12.834-28.667 28.666S34.168 70.521 50 70.521s28.667-12.835 28.667-28.667S65.832 13.188 50 13.188Zm-4.224 16.335a2 2 0 0 0-3.11 1.665V52.52a2 2 0 0 0 3.11 1.664l16-10.667a2 2 0 0 0 0-3.328l-16-10.667Zm11.285 12.331-10.394 6.93v-13.86l10.394 6.93Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
