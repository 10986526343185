import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const ManAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();

  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={234}
        height={234}
        viewBox="0 0 200 200"
        fill="none"
        {...props}
      >
        <g clipPath="url(#a)">
          <rect width={200} height={200} fill="#DCECF7" rx={100} />
          <path
            fill="#798CB3"
            d="M-1.896 331.484c-11.27-1.196-27.354-2.819-48.252-4.869-4.996-.619-9.74-5.843-9.415-11.278-1.017-13.202 7.776-39.318 9.69-44.847 4.378-12.384 12.258-36.275 15.135-47.283 8.006-31.146 17.888-42.779 21.265-46.156 3.878-4.003 7.63-6.755 10.132-8.506 16.886-11.633 34.774-13.384 43.905-13.634.25-.125.5-.125.75-.125l20.452-.288c10.642-2.478 21.096-3.275 29.625-3.917 12.482-.87 25.368-1.787 38.08-.163l34.802.49c.25 0 .5 0 .751.125 9.131.25 27.018 2.002 43.905 13.634 2.501 1.752 6.254 4.504 10.132 8.506 3.377 3.378 13.259 15.011 21.264 46.157 2.877 11.007 10.757 34.899 15.135 47.282 1.358 3.922 4.261 16.583 8.71 37.983.373.574.634 1.199.762 1.866a4.795 4.795 0 0 1-.028 1.674l.727 3.531c-1.024.019-2.044.04-3.06.063-1.419 1.139-3.367 1.938-5.585 2.16l-33.933 3.595c5.477 33.865 6.12 62.267 3.053 80.025 0 .393-.139.917-.278 1.31-.694 4.063-216.655 8.913-218.183 7.471-13.888-12.451-10.833-42.203-10.833-42.203.435-11.655.866-22.506 1.292-32.603Z"
          />
          <path
            fill="#FFC422"
            d="m81.092 182.355 20.602-12.306 14.024 14.77-.016.131a7.507 7.507 0 0 1-5.014 6.204l-1.895 128.625c-.005.355-.16.691-.428.924L88.758 337.82a1.251 1.251 0 0 1-1.753-.106l-17.764-19.741a1.25 1.25 0 0 1-.31-1l16.882-128.401a7.517 7.517 0 0 1-4.72-6.217Z"
          />
          <path
            fill="#0F1623"
            d="M103.909 148.771c-7.144 1.27-33.495 1.568-34.246 10.155-.501 5.725-.232 15.649.809 29.772l31.221-18.649 22.948 24.169c13.431-21.451 15.577-34.844 6.437-40.178-13.708-8.001-20.024-6.54-27.169-5.269Z"
            opacity={0.3}
          />
          <path
            fill="#F2A98A"
            d="m128.293 121.849-6.138 38.544c-.168.859-.703 1.647-1.511 1.872a43.985 43.985 0 0 1-14.434 2.801c-10.645.352-19.029-3.061-23.654-5.49-.934-.436-1.48-1.562-1.29-2.544l3.71-21.615c-6.938 1.149-6.53.978-7.203 1.066-8.117.461-14.7-1.074-15.88-1.558-4.349-1.227-8.299-5.818-11.256-12.892-4.025-9.447-6.53-23.441-6.2-39.56-.061-2.306.245-4.54.552-6.774l3.781-22.074c.832-4.935 2.991-9.486 6.207-13.575 1.364-1.774 2.657-3.18 4.147-4.291 9.258-8.067 20.653-9.275 29.499-8.557 4.723.306 8.916 1.15 11.872 2.064 1.081.337 4.915 1.592 10.492 3.058 3.022.843 5.477 1.32 6.927 1.73 18.152 4.292 25.826 3.49 34.08 10.192 1.552 1.193 5.052 4.167 7.655 9.133 7.337 14.042 1.949 29.305.428 33.851-4.931 13.569-14.706 20.588-17.854 23.672-3.767 3.75-9.729 8.07-13.93 10.947ZM-52.749 377.534l6.925-50.539 31.246 3.013 13.226 1.458-.8 28.749c5.122-.778 9.738-1.047 13.85-.807 30.174-7.135 117.858-27.492 167.564-35.093l-.265-2.501 78.862-8.356c2.218-.222 4.166-1.021 5.585-2.16l1.319-.029c4.809 37.443.943 84.282-43.882 97.388H18.847c.017.061.035.12.055.179h-92.691c-.512-12.203 6.501-22.637 21.04-31.302Z"
          />
          <path
            fill="#0F1623"
            d="m84.698 135.513 19.046-4.314-20.77 15.317 1.724-11.003Z"
            opacity={0.2}
          />
          <path
            fill="#0F1623"
            d="M58.087 80.275c-.528 2.063-1.565 5.43-3.032 6.419-2.445 1.436-3.953-5.229-1.617-8.725.924-1.477 1.832-1.555 2.79-1.241 1.426.532 2.146 2.073 1.86 3.547Zm22.05 3.903c-.282 2.112-.95 5.55-2.465 6.785-2.247 1.729-4.54-4.833-2.548-8.524.801-1.5 1.732-1.702 2.715-1.51 1.473.286 2.462 1.752 2.299 3.25ZM65.151 107.516c.907-.078 3.114-.287 3.745-1.566.048-.245.242-.59.167-.859-.324-.955-1.893-.75-4.127-1.057-3.977-.518-7.098-1.507-7.098-1.507s.383 2.622.853 3.478c.993 2.105 5.16 1.641 6.46 1.511ZM61.598 81.213l.045-.168-.028.082-.017.086Zm-4.35 12.612c-.5 1.34.309 2.908 1.663 3.414.3.088.3.088.496.142 2.041.562 3.325.873 5.549 1.305a1.251 1.251 0 0 0 .477-2.456 62.008 62.008 0 0 1-5.361-1.261l-.392-.108c-.002-.006-.086-.169-.078-.192l4.407-12.81.045-.169 1.408-7.244a1.25 1.25 0 0 0-2.456-.477l-1.391 7.158-4.367 12.698ZM139.907 86.181c-.137 3.707-1.546 7.498-3.792 10.33-2.756 3.584-5.971 5.206-9.904 6.17a1.251 1.251 0 0 0 .596 2.43c1.729-.424 3.126-.887 4.804-1.733 2.472-1.247 4.675-2.986 6.476-5.327 2.564-3.234 4.163-7.536 4.32-11.778a1.25 1.25 0 1 0-2.5-.092Z"
          />
          <path
            fill="#0F1623"
            d="M139.976 85.754a16.324 16.324 0 0 0-1.482-6.798c-2.855-6.289-9.625-10.79-15.901-9.972-3.53.461-6.242 2.61-6.535 2.808a21.19 21.19 0 0 0-2.922 3.07L48.043 57.277c1.434-14.128 8.592-23.493 21.473-28.097 3.48-1.243 12.86-3.075 21.028-2.015 12.607 1.636 24.726 5.103 28.131 6.023 2.777.795 7.512 1.27 8.791 1.646 13.906 1.329 24.444 7.495 28.054 10.717a25.625 25.625 0 0 1 6.715 8.951c6.921 14.853 1.561 30.249-.242 33.721-1.261 2.431-3.008 3.876-3.008 3.876-5.202-1.284-11.539-3.398-19.009-6.345Zm-89.772-16.06c-1.18-.483-1.238-2.151-.212-2.844 1.296-.767 3.452-1.368 5.908-.89 5.034.978 9.792 5.344 3.858 5.72-3.507.337-7.34-.918-9.554-1.985Zm25.127 5.777c-1.18-.484-1.238-2.152-.089-2.82 1.296-.768 3.452-1.368 6.03-.867 5.035.978 9.769 5.466 3.858 5.72-3.775.412-7.439-1.064-9.799-2.033Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect width={200} height={200} fill="#fff" rx={100} />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "myAcademy") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width="234" height="234" rx="117" fill="#FFEFB9" />
          <path
            d="M-2.21714 387.838C-15.4031 386.438 -34.2213 384.538 -58.6717 382.14C-64.5173 381.417 -70.0671 375.305 -69.6878 368.945C-70.8775 353.498 -60.5899 322.944 -58.3504 316.474C-53.2281 301.985 -44.0081 274.032 -40.642 261.154C-31.2757 224.713 -19.714 211.102 -15.7626 207.151C-11.2257 202.467 -6.83524 199.248 -3.90825 197.199C15.849 183.588 36.777 181.539 47.4605 181.247C47.7532 181.1 48.0459 181.1 48.3386 181.1L72.2675 180.763C84.7179 177.864 96.9493 176.931 106.928 176.181C121.532 175.163 136.609 174.09 151.481 175.99L192.2 176.563C192.493 176.563 192.786 176.563 193.079 176.71C203.762 177.002 224.69 179.051 244.447 192.662C247.374 194.711 251.765 197.93 256.302 202.614C260.253 206.565 271.815 220.176 281.181 256.617C284.547 269.496 293.767 297.448 298.889 311.937C300.478 316.526 303.875 331.339 309.08 356.377C309.516 357.048 309.822 357.78 309.971 358.56C310.077 359.224 310.062 359.881 309.938 360.519C310.217 361.868 310.501 363.245 310.789 364.65C309.591 364.672 308.398 364.697 307.209 364.724C305.549 366.056 303.27 366.991 300.674 367.251L260.973 371.457C267.38 411.079 268.133 444.31 264.545 465.086C264.545 465.546 264.383 466.159 264.22 466.619C263.408 471.373 10.733 477.047 8.94561 475.36C-7.30356 460.792 -3.72874 425.982 -3.72874 425.982C-3.21973 412.346 -2.71569 399.651 -2.21714 387.838Z"
            fill="#FF8B00"
          />
          <path
            d="M94.8782 213.355L118.982 198.957L135.391 216.239L135.372 216.391C134.957 219.845 132.588 222.589 129.505 223.65L127.289 374.142C127.283 374.557 127.101 374.95 126.788 375.223L103.847 395.249C103.245 395.775 102.332 395.72 101.797 395.125L81.0123 372.028C80.7261 371.71 80.5934 371.283 80.6492 370.859L100.402 220.629C97.3591 219.436 95.2114 216.619 94.8782 213.355Z"
            fill="#FFC422"
          />
          <path
            opacity="0.3"
            d="M121.575 174.061C113.216 175.548 82.3851 175.896 81.5061 185.943C80.92 192.641 81.2355 204.252 82.4525 220.776L118.982 198.956L145.831 227.235C161.545 202.137 164.055 186.467 153.362 180.226C137.323 170.865 129.933 172.574 121.575 174.061Z"
            fill="#191229"
          />
          <path
            d="M150.104 142.563L142.922 187.659C142.726 188.664 142.1 189.586 141.154 189.85C136.972 191.422 131.154 192.975 124.266 193.127C111.812 193.539 102.003 189.545 96.5914 186.704C95.4979 186.193 94.8594 184.876 95.0828 183.727L99.4221 158.437C91.3048 159.781 91.7829 159.582 90.9956 159.684C81.4982 160.224 73.7963 158.428 72.4156 157.862C67.3276 156.426 62.7062 151.055 59.2459 142.779C54.5364 131.725 51.6063 115.352 51.9922 96.4928C51.9202 93.7952 52.2792 91.1814 52.6382 88.5675L57.0621 62.7405C58.0355 56.9662 60.5611 51.6426 64.3239 46.8577C65.92 44.7826 67.4323 43.1384 69.1761 41.8374C80.0083 32.3989 93.34 30.9854 103.69 31.8261C109.215 32.1838 114.122 33.1716 117.58 34.2402C118.845 34.6352 123.331 36.1034 129.855 37.819C133.391 38.8044 136.264 39.3629 137.96 39.8417C159.198 44.8644 168.177 43.9261 177.834 51.7669C179.65 53.1635 183.745 56.643 186.79 62.453C195.374 78.8815 189.07 96.7395 187.291 102.059C181.522 117.934 170.085 126.147 166.402 129.755C161.994 134.142 155.018 139.196 150.104 142.563ZM-61.7156 441.714L-53.6139 382.584L-17.0552 386.108L-1.5811 387.815L-2.51798 421.451C3.47539 420.541 8.87682 420.226 13.6863 420.507C48.991 412.159 151.581 388.341 209.738 379.448L209.427 376.522L301.695 366.745C304.291 366.485 306.57 365.551 308.23 364.218C308.744 364.207 309.258 364.195 309.774 364.185C315.4 407.993 310.877 462.794 258.432 478.128H22.0516C22.0714 478.199 22.0929 478.269 22.1161 478.337H-86.3328C-86.9314 464.06 -78.7257 451.852 -61.7156 441.714Z"
            fill="#F2A98A"
          />
          <path
            opacity="0.2"
            d="M99.0968 158.551L121.381 153.504L97.0809 171.424L99.0968 158.551Z"
            fill="#191229"
          />
          <path
            d="M67.9623 93.9206C67.3439 96.3349 66.131 100.274 64.4152 101.431C61.5541 103.111 59.7898 95.3138 62.523 91.2233C63.6044 89.4953 64.6658 89.4035 65.7872 89.7706C67.4553 90.393 68.2974 92.1966 67.9623 93.9206ZM93.7614 98.4881C93.4303 100.958 92.6484 104.981 90.8767 106.425C88.2471 108.449 85.565 100.771 87.8952 96.4533C88.8328 94.6974 89.9222 94.4619 91.0715 94.6853C92.7955 95.0204 93.9528 96.7363 93.7614 98.4881Z"
            fill="#191229"
          />
          <path
            d="M76.2281 125.794C77.2896 125.702 79.8714 125.459 80.6096 123.962C80.6654 123.675 80.8929 123.272 80.805 122.957C80.4259 121.839 78.5903 122.079 75.9764 121.72C71.3234 121.114 67.672 119.957 67.672 119.957C67.672 119.957 68.1191 123.025 68.6699 124.027C69.8313 126.489 74.7077 125.946 76.2281 125.794Z"
            fill="#191229"
          />
          <path
            d="M72.0713 95.0195L72.124 94.8228L72.0908 94.9193L72.0713 95.0195ZM66.9811 109.776C66.3957 111.343 67.3425 113.178 68.927 113.77C69.279 113.873 69.279 113.873 69.508 113.936C71.8955 114.593 73.3977 114.957 75.9992 115.463C76.7927 115.617 77.5609 115.099 77.7151 114.305C77.8693 113.512 77.3511 112.744 76.5577 112.59C74.0379 112.1 72.6057 111.753 70.2851 111.114L69.8272 110.987C69.8245 110.98 69.7262 110.79 69.7362 110.763L74.8918 95.7748L74.9445 95.578L76.5921 87.102C76.7463 86.3086 76.2281 85.5404 75.4347 85.3862C74.6413 85.2319 73.8731 85.7501 73.7188 86.5435L72.0908 94.9193L66.9811 109.776Z"
            fill="#191229"
          />
          <path
            d="M163.693 100.832C163.532 105.17 161.884 109.605 159.257 112.919C156.032 117.112 152.27 119.009 147.669 120.137C146.884 120.329 146.403 121.122 146.596 121.907C146.788 122.692 147.581 123.172 148.366 122.98C150.389 122.484 152.024 121.943 153.987 120.953C156.878 119.494 159.457 117.46 161.563 114.72C164.563 110.937 166.434 105.903 166.618 100.941C166.648 100.133 166.017 99.4538 165.209 99.4239C164.402 99.394 163.723 100.025 163.693 100.832Z"
            fill="#191229"
          />
          <path
            d="M163.772 100.333C163.765 97.676 163.231 94.9763 162.039 92.3792C158.698 85.0209 150.777 79.7539 143.434 80.7121C139.304 81.2511 136.132 83.7653 135.789 83.9968C134.419 85.1684 133.288 86.4103 132.37 87.5884L56.2109 67.0148C57.8893 50.485 66.2637 39.5273 81.3341 34.1416C85.4059 32.6864 96.3802 30.5435 105.937 31.7834C120.688 33.6973 134.866 37.7539 138.85 38.8309C142.099 39.7605 147.639 40.3165 149.136 40.7566C165.406 42.3106 177.735 49.5256 181.959 53.2956C183.543 54.6471 187.234 58.0483 189.816 63.7681C197.913 81.1455 191.641 99.1589 189.533 103.222C188.057 106.066 186.014 107.756 186.014 107.756C179.926 106.254 172.513 103.78 163.772 100.333ZM58.7393 81.5434C57.3586 80.9768 57.2906 79.0254 58.4917 78.2153C60.0081 77.3173 62.53 76.6148 65.4033 77.1733C71.2934 78.3182 76.8605 83.4258 69.917 83.8651C65.8147 84.2605 61.3293 82.7922 58.7393 81.5434ZM88.138 88.3015C86.7573 87.7349 86.6893 85.7836 88.0341 85.0013C89.5504 84.1034 92.0724 83.4009 95.0893 83.9873C100.979 85.1322 106.519 90.3834 99.603 90.6791C95.1855 91.1623 90.8995 89.4346 88.138 88.3015Z"
            fill="#191229"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "base") {
    return (
      <svg
        width="234"
        height="234"
        viewBox="0 0 234 234"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath={`url(#${id})`}>
          <rect width={234} height={234} fill="#EEE1FF" rx={117} />
          <path
            fill="#C861F9"
            d="M-2.217 387.838c-13.186-1.4-32.004-3.3-56.455-5.698-5.845-.723-11.395-6.835-11.016-13.195-1.19-15.447 9.098-46.001 11.338-52.471 5.122-14.489 14.342-42.442 17.708-55.32 9.366-36.441 20.928-50.052 24.88-54.003 4.536-4.684 8.927-7.903 11.854-9.952 19.757-13.611 40.685-15.66 51.369-15.952.292-.147.585-.147.878-.147l23.928-.337c12.45-2.899 24.682-3.832 34.661-4.582 14.604-1.018 29.681-2.091 44.553-.191l40.719.573c.293 0 .586 0 .879.147 10.683.292 31.611 2.341 51.368 15.952 2.927 2.049 7.318 5.268 11.855 9.952 3.951 3.951 15.513 17.562 24.879 54.003 3.366 12.879 12.586 40.831 17.708 55.32 1.589 4.589 4.986 19.402 10.191 44.44.436.671.742 1.403.891 2.183a5.596 5.596 0 0 1-.033 1.959c.279 1.349.563 2.726.851 4.131-1.198.022-2.391.047-3.58.074-1.66 1.332-3.939 2.267-6.535 2.527l-39.701 4.206c6.407 39.622 7.16 72.853 3.572 93.629 0 .46-.162 1.073-.325 1.533-.812 4.754-253.487 10.428-255.274 8.741-16.25-14.568-12.675-49.378-12.675-49.378a7858.261 7858.261 0 0 1 1.512-38.144Z"
          />
          <path
            fill="#FFC422"
            d="m94.878 213.355 24.104-14.398 16.409 17.282-.019.152a8.783 8.783 0 0 1-5.867 7.259l-2.216 150.492a1.462 1.462 0 0 1-.501 1.081l-22.941 20.026a1.463 1.463 0 0 1-2.05-.124l-20.785-23.097a1.462 1.462 0 0 1-.363-1.169l19.753-150.23a8.793 8.793 0 0 1-5.524-7.274Z"
          />
          <path
            fill="#191229"
            d="M121.575 174.061c-8.359 1.487-39.19 1.835-40.069 11.882-.586 6.698-.27 18.309.947 34.833l36.529-21.82 26.849 28.279c15.714-25.098 18.224-40.768 7.531-47.009-16.039-9.361-23.429-7.652-31.787-6.165Z"
            opacity={0.3}
          />
          <path
            fill="#F2A98A"
            d="m150.104 142.563-7.182 45.096c-.196 1.005-.822 1.927-1.768 2.191-4.182 1.572-10 3.125-16.888 3.277-12.454.412-22.263-3.582-27.675-6.423-1.093-.511-1.732-1.828-1.508-2.977l4.34-25.29c-8.118 1.344-7.64 1.145-8.427 1.247-9.498.54-17.2-1.256-18.58-1.822-5.088-1.436-9.71-6.807-13.17-15.083-4.71-11.054-7.64-27.427-7.254-46.286-.072-2.698.287-5.312.646-7.925l4.424-25.828c.974-5.774 3.5-11.097 7.262-15.882 1.596-2.075 3.108-3.72 4.852-5.02 10.832-9.44 24.164-10.853 34.514-10.012 5.525.358 10.432 1.346 13.89 2.414 1.265.395 5.751 1.863 12.275 3.579 3.536.985 6.409 1.544 8.105 2.023 21.238 5.022 30.217 4.084 39.874 11.925 1.816 1.396 5.911 4.876 8.956 10.686 8.584 16.428 2.28 34.287.501 39.606-5.769 15.875-17.206 24.088-20.889 27.696-4.408 4.387-11.384 9.441-16.298 12.808Zm-211.82 299.151 8.102-59.13 36.559 3.524 15.474 1.707-.937 33.636c5.993-.91 11.395-1.225 16.204-.944 35.305-8.348 137.895-32.166 196.052-41.059l-.311-2.926 92.268-9.777c2.596-.26 4.875-1.194 6.535-2.527.514-.011 1.028-.023 1.544-.033 5.626 43.808 1.103 98.609-51.342 113.943H22.052c.02.071.04.141.064.209H-86.333c-.598-14.277 7.607-26.485 24.617-36.623Z"
          />
          <path
            fill="#191229"
            d="m99.097 158.551 22.284-5.047-24.3 17.92 2.016-12.873Z"
            opacity={0.2}
          />
          <path
            fill="#191229"
            d="M67.962 93.92c-.618 2.415-1.831 6.354-3.547 7.511-2.86 1.68-4.625-6.117-1.892-10.208 1.081-1.728 2.143-1.82 3.264-1.452 1.668.622 2.51 2.426 2.175 4.15Zm25.8 4.568c-.332 2.47-1.114 6.493-2.885 7.937-2.63 2.024-5.312-5.654-2.982-9.972.938-1.756 2.027-1.991 3.177-1.768 1.724.335 2.88 2.051 2.69 3.803ZM76.228 125.794c1.062-.092 3.643-.335 4.382-1.832.055-.287.283-.69.195-1.005-.38-1.118-2.215-.878-4.829-1.237-4.653-.606-8.304-1.763-8.304-1.763s.447 3.068.998 4.07c1.161 2.462 6.038 1.919 7.558 1.767ZM72.071 95.02l.053-.197-.033.096-.02.1Zm-5.09 14.756c-.585 1.567.362 3.402 1.946 3.994.352.103.352.103.581.166a75.636 75.636 0 0 0 6.491 1.527 1.464 1.464 0 0 0 .559-2.873 72.605 72.605 0 0 1-6.273-1.476l-.458-.127c-.002-.007-.1-.197-.09-.224l5.155-14.988.053-.197 1.647-8.476a1.463 1.463 0 0 0-2.873-.559L72.09 94.92l-5.11 14.857ZM163.693 100.832c-.161 4.338-1.809 8.773-4.436 12.087-3.225 4.193-6.987 6.09-11.588 7.218a1.463 1.463 0 1 0 .697 2.843c2.023-.496 3.658-1.037 5.621-2.027 2.891-1.459 5.47-3.493 7.576-6.233 3-3.783 4.871-8.817 5.055-13.779a1.464 1.464 0 0 0-2.925-.109Z"
          />
          <path
            fill="#191229"
            d="M163.772 100.333a19.083 19.083 0 0 0-1.733-7.954c-3.341-7.358-11.262-12.625-18.605-11.667-4.13.54-7.302 3.053-7.645 3.285a24.707 24.707 0 0 0-3.419 3.591L56.21 67.015c1.68-16.53 10.054-27.488 25.124-32.873 4.072-1.456 15.046-3.599 24.603-2.359 14.751 1.914 28.929 5.97 32.913 7.048 3.249.93 8.789 1.485 10.286 1.926 16.27 1.554 28.599 8.769 32.823 12.539a29.995 29.995 0 0 1 7.857 10.472c8.097 17.377 1.825 35.39-.283 39.454-1.476 2.844-3.519 4.534-3.519 4.534-6.088-1.502-13.501-3.976-22.242-7.423ZM58.739 81.543c-1.38-.566-1.448-2.518-.247-3.328 1.516-.898 4.038-1.6 6.911-1.042 5.89 1.145 11.457 6.253 4.514 6.692-4.102.395-8.588-1.073-11.178-2.322Zm29.399 6.758c-1.38-.566-1.449-2.517-.104-3.3 1.516-.898 4.038-1.6 7.055-1.014 5.89 1.145 11.43 6.396 4.514 6.692-4.417.483-8.703-1.244-11.465-2.377Z"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="234" height="234" rx="117" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return null;
};
