import { H2 } from "components/Typography/Titles";
import styles from "./DescriptionBlock.module.scss";

export const DescriptionBlock = ({ text }: { text?: string }) => {
  return (
    <div id="more" className={styles["container"]}>
      <H2 className={styles["title"]}>Подробнее</H2>
      {text}
    </div>
  );
};
