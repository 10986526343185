import { H3 } from "../../../../../components/Typography/Titles";
import { TextMain } from "../../../../../components/Typography/Texts";
import { useDispatch } from "react-redux";
import { setQuestionnaireQuestion } from "../redux/Questionary/courseQuestionarySlice";

const QuestRadio = ({ multy, question }) => {
  const dispatch = useDispatch();
  const { title = "", answer = [] } = question;
  const handleClick = (value) => {
    if (multy) {
      if (answer.some((item) => item === value)) {
        dispatch(
          setQuestionnaireQuestion({
            question: {
              ...question,
              answer: question.answer.filter((item) => item !== value),
              valid: question.required
                ? question.answer.filter((item) => item !== value).length > 0
                  ? true
                  : false
                : true,
            },
          })
        );
      } else {
        dispatch(
          setQuestionnaireQuestion({
            question: {
              ...question,
              answer: [...question.answer, value],
              valid: true,
            },
          })
        );
      }
    } else {
      dispatch(
        setQuestionnaireQuestion({
          question: {
            ...question,
            answer: [value],
            valid: true,
          },
        })
      );
    }
  };
  return (
    <>
      <H3 className="course-sheet-test-block__title">{title}</H3>
      {multy ? (
        <TextMain className="course-sheet-test-block__descr">
          Можно выбрать несколько ответов:
        </TextMain>
      ) : null}
      {question.position === "horizontal" ? (
        <div className="course-sheet-test-block-content">
          <div className="course-sheet-test-block-content-wrapper">
            <ul
              className="course-sheet-list course-sheet-list-first"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(60px, 1fr))",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              {question.options.map((option, index) => {
                return option.explanation ? (
                  <p
                    className="text-main course-sheet-test-block-badgrade course-sheet-list-badgrade"
                    key={index}
                  >
                    {option.explanation}
                  </p>
                ) : (
                  <li
                    key={index}
                    className={
                      answer.some((item) => item === +option.value)
                        ? "course-sheet-test-list__item course-sheet-test-list__item--active course-sheet-list-grade__item"
                        : "course-sheet-test-list__item course-sheet-list-grade__item"
                    }
                    style={{
                      listStyle: "none",
                      padding: "10px",
                      backgroundColor: "#f0f0f0",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleClick(+option.value);
                    }}
                  >
                    {option.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <div className="course-sheet-test-block-content">
          <ul className="course-questions-block-list">
            {question.options.map((option, index) => (
              <li
                key={index}
                className={
                  answer.some((item) => item === +option.value)
                    ? "course-questions-block-list__questionnaire-item course-questions-block-list__questionnaire-item--active"
                    : "course-questions-block-list__questionnaire-item"
                }
                onClick={() => {
                  handleClick(+option.value);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default QuestRadio;
