import classNames from "classnames";
import { RulesIcon } from "./icons/getRulesIcon";
import styles from "./RulesCard.module.scss";

interface RulesCardProps {
  rule: {
    type: string;
    title: string;
    description: string;
  };
  className?: string;
}

export const RulesCard = ({
  rule: { type, title, description },
  className,
}: RulesCardProps): JSX.Element => (
  <div className={classNames(styles["container"], className)}>
    <RulesIcon type={type} />
    <div className={styles["info"]}>
      <div className={styles["title"]}>{title}</div>
      <div className={styles["description"]}>{description}</div>
    </div>
  </div>
);
