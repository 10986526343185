import { Skeleton } from "components/Skeleton";
import listStyles from "./RewardsList/RewardsList.module.scss";
import cardStyles from "./ShopCard/ShopCard.module.scss";

const RewardsListSkeleton = () => {
  return (
    <div className={listStyles["container"]}>
      <div className={cardStyles["card"]}>
        <div className={cardStyles["card__body"]}>
          <Skeleton variant="rounded" height={"300px"} />
          <Skeleton variant="text" height={"50px"} width="85%" />
          <Skeleton variant="text" height={"50px"} width="70%" />
        </div>
      </div>
      <div className={cardStyles["card"]}>
        <div className={cardStyles["card__body"]}>
          <Skeleton variant="rounded" height={"300px"} />
          <Skeleton variant="text" height={"50px"} width="85%" />
          <Skeleton variant="text" height={"50px"} width="70%" />
        </div>
      </div>
      <div className={cardStyles["card"]}>
        <div className={cardStyles["card__body"]}>
          <Skeleton variant="rounded" height={"300px"} />
          <Skeleton variant="text" height={"50px"} width="85%" />
          <Skeleton variant="text" height={"50px"} width="70%" />
        </div>
      </div>
      <div className={cardStyles["card"]}>
        <div className={cardStyles["card__body"]}>
          <Skeleton variant="rounded" height={"300px"} />
          <Skeleton variant="text" height={"50px"} width="85%" />
          <Skeleton variant="text" height={"50px"} width="70%" />
        </div>
      </div>
    </div>
  );
};

export default RewardsListSkeleton;
