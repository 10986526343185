import { formatNumber, generateUid, mediaQuerys, numWord } from "app/utils";
import { CourseWithoutReports } from "components/Reports";
import { TestResultLeftLeaf, TestResultRightLeaf, СurvedLine } from "components/Svg/Pics";
import { selectCourseResultIsFinish, selectScores } from "pages/Course/redux/courseSelectors";
import {
  clearScores,
  getCourseReportsScores,
  showAsideNav,
  showBottomNav,
  updateVisite,
} from "pages/Course/redux/courseSlice";
import { useEffect, useState } from "react";
import Container from "../../../../components/Container/Container";
import { CourseGradeCard } from "../../../../components/CourseTest/CourseGradeCard";
import {
  CourseGradeLeavesIconLeft,
  CourseGradeLeavesIconRight,
} from "../../../../components/Svg/Icons";
import { TextCard } from "../../../../components/Typography/Texts";
import { H1 } from "../../../../components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import classNames from "classnames";

export interface CourseReportScore {
  description: string | null;
  id: number;
  score: number | null;
  title: string;
  type: string;
}

export const CourseReport = ({ courseId = "4" }: { courseId?: string }) => {
  const dispatch = useAppDispatch();

  const { scores, course_score } = useAppSelector(selectScores) ?? {};
  const courseResultIsFinish = useAppSelector(selectCourseResultIsFinish);

  const [isLoading, setIsLoading] = useState(true);

  const smMin = useMediaQuery(`(min-width:${mediaQuerys.smMin}px)`);
  const xxsMax = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);
  const courseScore = course_score ? formatNumber(course_score) : "0";

  useEffect(() => {
    // @ts-ignore
    dispatch(getCourseReportsScores(courseId)).then(() => setIsLoading(false));

    dispatch(updateVisite(courseId));

    return () => {
      dispatch(clearScores());
    };
  }, [courseId, dispatch]);

  useEffect(() => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
  }, [dispatch]);

  if (isLoading) {
    return <CourseLoadSkeleton />;
  }

  return (
    <Container>
      <div className="course-grade-block">
        <H1
          className="course-grade__title"
          style={{ marginBottom: !scores || scores?.length === 0 ? "19px" : "" }}
        >
          Оценки
        </H1>
        {(!scores || scores?.length === 0) && (
          <CourseWithoutReports isFinished={courseResultIsFinish} />
        )}
        {scores && scores?.length > 0 && (
          <div className="course-grade-cards">
            {scores?.map(({ score, type, title, description }: any) => (
              <CourseGradeCard
                key={generateUid()}
                type={type}
                title={title}
                description={description}
                score={score}
              />
            ))}
          </div>
        )}
        {scores && scores?.length >= 0 && (
          <div
            className={classNames("course-grade-result", {
              "course-grade-result_rostechnadzor": process.env.REACT_APP_THEME === "rostechnadzor",
            })}
          >
            {process.env.REACT_APP_THEME === "base" && <СurvedLine />}
            <div className="course-grade-result-info">
              {smMin && (
                <>
                  {scores.some((item: any) => item.score != null) ? (
                    <>
                      <TestResultLeftLeaf
                        style={{
                          width:
                            process.env.REACT_APP_THEME === "rostechnadzor" ? "23.5px" : "16px",
                          opacity:
                            process.env.REACT_APP_THEME === "rostechnadzor" ? "0.6" : undefined,
                          marginRight: "7.12px",
                        }}
                      />
                      <TestResultRightLeaf
                        style={{
                          width: "16px",
                          opacity:
                            process.env.REACT_APP_THEME === "rostechnadzor" ? "0.6" : undefined,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <CourseGradeLeavesIconLeft />
                      <CourseGradeLeavesIconRight />
                    </>
                  )}
                </>
              )}
              <div className="course-grade-result-info-text">
                <TextCard className="course-grade-result-info-text__title" hidden={false}>
                  Промежуточная оценка за курс
                </TextCard>
                <TextCard className="course-grade-result-info-text__descr" hidden={false}>
                  Оценка предварительная и расчитана на основании выполненных заданий, без учета
                  незаполненных
                </TextCard>
              </div>
            </div>

            <div className="course-grade-result-raiting">
              {xxsMax &&
                (scores.some((item: any) => item.score != null) ? (
                  <TestResultLeftLeaf
                    style={{
                      width: process.env.REACT_APP_THEME === "rostechnadzor" ? "23.5px" : "16px",
                      opacity: process.env.REACT_APP_THEME === "rostechnadzor" ? "0.6" : undefined,
                      marginRight: "16px",
                    }}
                  />
                ) : (
                  <CourseGradeLeavesIconLeft />
                ))}
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <b className="course-grade-result-raiting__title">{courseScore}</b>
                <span className="course-grade-result-raiting__subtitle">
                  {numWord(courseScore, ["балл", "балла", "баллов"])}
                </span>
              </div>
              {xxsMax &&
                (scores.some((item: any) => item.score != null) ? (
                  <TestResultRightLeaf
                    style={{
                      width: "16px",
                      marginLeft: "16px",
                      opacity: process.env.REACT_APP_THEME === "rostechnadzor" ? "0.6" : undefined,
                    }}
                  />
                ) : (
                  <CourseGradeLeavesIconRight />
                ))}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};
