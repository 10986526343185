import { allAnswersSelector } from "pages/Course/components/Quiz/redux/Quze/courseQuizeSelectors";
import { useSelector } from "react-redux";
import { TextMain } from "../../../../../../../../components/Typography/Texts";
import { H3 } from "../../../../../../../../components/Typography/Titles";
import { IQuizeQuestionProp } from "../../../../redux/Quze/interface/quiz.interface";
import { QuestResultAlert } from "./QuestResultAlert";
import { QuestDescription } from "../QuestDescription/QuestDescription";

export const QuestCorrelateResult = ({
  title,
  multy,
  right,
  wrong,
  questions = [],
  questionsId,
  description,
}: IQuizeQuestionProp) => {
  const allAnswers = useSelector(allAnswersSelector);

  const findedAnwer: any = allAnswers.find((answer: any) => answer.id === questionsId);
  const column1 = questions.filter((item) => item.column === 1);
  const selectOptions = questions
    .filter((item) => item.column === 2)
    .map((item) => {
      return { text: item.text, value: item.code };
    });

  const getSelectOptionName = (code: string) => {
    const item = selectOptions?.filter((item) => item.value === code)[0];
    return item?.text ? item?.text : "Ответ не выбран";
  };
  const rightClass = right ? " course-questions-bubble__item--right" : "";
  const wrongClass = wrong ? " course-questions-bubble__item--misstake" : "";

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />

      <div className="course-questions-block-wrapper">
        <div className="course-questions-block-list course-questions-block-list__correlate">
          {column1.map((item, index) => {
            return (
              <div key={index} className="test-choose-row">
                <div className="course-questions-block-list__item ">
                  <div
                    className={
                      "course-questions-bubble__item  course-questions-bubble__item_fluide course-questions-bubble__item--active" +
                      rightClass +
                      wrongClass
                    }
                  >
                    {item.text}
                  </div>
                </div>
                <TextMain className="test-choose-row-text">
                  {
                    /*@ts-ignore*/
                    getSelectOptionName(findedAnwer?.answer?.[item.code])
                  }
                </TextMain>
              </div>
            );
          })}
        </div>
      </div>
      <QuestResultAlert wrong={wrong} right={right} isSelect={true} />
    </div>
  );
};
