import { CheckedTestChunk, CompletedTestChunk } from "../../components/Buttons/InfoChunks";

const CourseTestContentHeader = ({
  title,
  time,
  chunkValue,
  isCheckedChunk,
  isCompletedChunk,
  descr,
  buttonValue,
  secondaryButton,
  attemptText,
  children,
}) => {
  const renderChunck = (chunkValue, isCheckedChunk) => {
    if (!chunkValue || !isCheckedChunk) {
      return null;
    }

    if (isCompletedChunk) {
      return (
        <div className="course-test-top-header__chunk">
          <div className="course-test-chunk-wrapper">
            <CompletedTestChunk value={chunkValue} />
          </div>
        </div>
      );
    }
    if (isCheckedChunk) {
      return (
        <div className="course-test-top-header__chunk">
          <div className="course-test-chunk-wrapper">
            <CheckedTestChunk value={chunkValue} />
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <div className="course-test-top-header">
        <div className="course-test-top ">
          <div className="course-test-top-header__h">{children}</div>
        </div>
        {renderChunck(chunkValue, isCheckedChunk)}
      </div>
    </>
  );
};

export default CourseTestContentHeader;
