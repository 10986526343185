import Container from "../../../../components/Container/Container";
import { PrevArrowIcon } from "../../../../components/Svg/Icons";
import { H1 } from "../../../../components/Typography/Titles";
import { useEffect } from "react";
import {
  QuestRadioResult,
  QuestTextResult,
  QuestCorrelateResult,
  QuestCalculateResult,
} from "./components";

import CourseTestContentHeader from "../CourseTestContentHeader";
import {
  selectCourseIsLoadingQuizeResults,
  selectCourseQuizeDetail,
  selectCourseQuizeResults,
} from "./redux/Quze/courseQuizeSelectors";
import { clearQuestAnswer, questionsSelectors } from "./redux/Quze/courseQuizeQuestionsSlice";
import { getQuize, setQuizeIdle } from "./redux/Quze/courseQuizeSlice";
import Alert from "../../../../components/Alert/Alert";
import { generateUid, secondsToHmsFormat } from "../../../../app/utils";
import { getCourseLesson, showAsideNav, showBottomNav } from "../../redux/courseSlice";
import QuizeAttemptsResultInfo from "./components/QuizeAttemptsResultInfo";
import QuizeLoadSkeleton from "./components/QuizeLoadSkeleton";
import { CourseLessonQuiz } from "../../redux/interfaces";
import { useAppSelector } from "hooks/redux";
import { useAppDispatch } from "hooks/redux";
import { IQuizResult } from "./redux/Quze/interface";
import { AttemptComment } from "./components/AttemptComment";
import { CourseTestsImg } from "components/Svg/Pics";
import { LessonDescription } from "../LessonDescription";

const CourseTestResult = ({ lesson }: { lesson: CourseLessonQuiz }) => {
  const dispatch = useAppDispatch();
  const questionsAll = useAppSelector(questionsSelectors.selectAll);
  const quizeDetail = useAppSelector(selectCourseQuizeDetail);
  const quizeResult = useAppSelector(selectCourseQuizeResults);
  const isLoadingQuizeResults = useAppSelector(selectCourseIsLoadingQuizeResults);

  useEffect(() => {
    if (quizeResult[0]?.answered === null && !isLoadingQuizeResults) {
      dispatch(setQuizeIdle());
    }

    if (quizeResult.length === 0 && !isLoadingQuizeResults) {
      dispatch(showBottomNav);
    }
    dispatch(showAsideNav());
  }, [dispatch, isLoadingQuizeResults, quizeResult, quizeResult.length]);

  const handleDoneQuest = () => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
    dispatch(setQuizeIdle());
    dispatch(clearQuestAnswer());
    if (lesson?.id) {
      dispatch(getQuize(lesson?.id));
      dispatch(getCourseLesson(lesson?.id));
    }
  };

  const renderQuestResutl = (quest: any, qResult: IQuizResult[], index: number) => {
    if (quest) {
      const questTitle = quest.title ? quest.title : `Вопрос ${index + 1}`;
      const questText = Array.isArray(quest.text) ? quest.text[0] : quest.text;

      const isRight = !!qResult?.[0]?.right?.includes(quest.id);
      const isWrong = qResult?.[0]?.answered?.includes(quest.id) && !isRight;

      switch (quest.question_type) {
        case "text":
          return (
            <QuestTextResult
              key={"quest-result_" + quest.id}
              title={questTitle}
              right={isRight}
              wrong={isWrong}
              questionsId={quest.id}
              description={questText}
            />
          );
        case "bool":
          return (
            <QuestRadioResult
              key={"quest-result_" + quest.id}
              multy={false}
              title={questTitle}
              right={isRight}
              wrong={isWrong}
              questionsId={quest.id}
              questions={quest.options}
              description={questText}
            />
          );
        case "select":
          return (
            <QuestRadioResult
              key={"quest-result_" + quest.id}
              multy={false}
              questionsId={quest.id}
              title={questTitle}
              right={isRight}
              wrong={isWrong}
              questions={quest.options}
              description={questText}
            />
          );
        case "correlate":
          return (
            <QuestCorrelateResult
              multy={false}
              key={"quest-result_" + quest.id}
              questionsId={quest.id}
              title={questTitle}
              right={isRight}
              wrong={isWrong}
              questions={quest.options}
              description={questText}
            />
          );
        case "numeric":
          return (
            <QuestCalculateResult
              key={"quest-result_" + quest.id}
              title={questTitle}
              right={isRight}
              wrong={isWrong}
              questionsId={quest.id}
              description={questText}
            />
          );
        default:
          //@ts-ignore
          return <Alert>Тип вопроса не поддерживается</Alert>;
      }
    } else {
      return null;
    }
  };

  if (quizeResult.length === 0 && !isLoadingQuizeResults) {
    return (
      <Container>
        <div className="course-test-block">
          <div className="course-test-content ">
            <CourseTestContentHeader fixed={true} status={lesson.status} coins={lesson.coins}>
              <H1 className="course-test-top__title">
                {lesson.title}
                {quizeDetail.time_to_complete ? (
                  <span className="course-test-top__title-addition">
                    <span className="course-test-top-time">
                      <span className="course-test-top__time--default">
                        {secondsToHmsFormat(quizeDetail.time_to_complete)}
                      </span>
                    </span>
                  </span>
                ) : null}
              </H1>
            </CourseTestContentHeader>
            <LessonDescription stringHTML={lesson?.description ?? quizeDetail.description} />
          </div>
          <div className="course-test-img course-test-main-img">
            <CourseTestsImg />
          </div>
        </div>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <div className="course-result-block">
          <div className="course-test-content course-test-content_page">
            <CourseTestContentHeader status={""}>
              <H1 className="course-test-top__title">
                {quizeDetail.title}
                {quizeDetail.time_to_complete ? (
                  <span className="course-test-top__title-addition">
                    <span className="course-test-top-time">
                      <span className="course-test-top__time--default">
                        {secondsToHmsFormat(quizeDetail.time_to_complete)}
                      </span>
                    </span>
                  </span>
                ) : null}
              </H1>
            </CourseTestContentHeader>
            {quizeResult.length ? (
              <>
                <div className="course-result-info">
                  {quizeResult.slice(0, 3).map((item, index) => {
                    let bestTry = false;
                    if (index === 0) {
                      bestTry = !!quizeResult.filter(
                        (filterItem, fIndex) =>
                          fIndex !== index &&
                          item?.score_percentage_correct <= filterItem.score_percentage_correct
                      ).length;
                    }
                    return (
                      <QuizeAttemptsResultInfo
                        key={generateUid()}
                        first={!index}
                        currentPoints={item?.attempt_received_score}
                        totalPoints={item?.attempt_max_score}
                        rightQuestions={item?.right?.length}
                        totalQuestions={item?.total_questions}
                        percent={item?.score_percentage_correct || item?.percentage_correct}
                        endTs={item.end_ts}
                        best={!bestTry}
                      />
                    );
                  })}
                </div>
                {quizeResult[0].attempt_comment && (
                  <AttemptComment comment={quizeResult[0].attempt_comment} />
                )}
                <ul className="course-questions-list">
                  {questionsAll.map((item, index) => {
                    let questionsRightClass = " course-questions-list__item--misstake";
                    if (quizeResult[0]?.right?.length && quizeResult[0]?.right?.includes(item.id)) {
                      questionsRightClass = " course-questions-list__item--right";
                    }
                    return (
                      <li
                        key={item.id}
                        className={"course-questions-list__item" + questionsRightClass}
                      >
                        <span>{index + 1}</span>{" "}
                      </li>
                    );
                  })}
                </ul>

                {questionsAll.map((quest, index) => (
                  <>{renderQuestResutl(quest, quizeResult, index)}</>
                ))}
              </>
            ) : (
              <QuizeLoadSkeleton />
            )}
          </div>
        </div>
      </Container>
      <footer className="course-test-footer">
        <Container>
          <div className="course-test-footer-block">
            <div className="course-test-footer-prev">
              <div className="course-test-footer-info course-test-footer-info__left">
                <a href="#back" onClick={handleDoneQuest}>
                  <PrevArrowIcon />
                </a>
                <a href="#back" className="course-test-footer__link" onClick={handleDoneQuest}>
                  Вернуться
                </a>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default CourseTestResult;
