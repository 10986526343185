import Container from "../../../../components/Container/Container";
import { H1 } from "../../../../components/Typography/Titles";
import CourseTestContentHeader from "../../../../components/Course/CourseTestContentHeader";
import { useEffect } from "react";
import { CourseTrainingIframe } from "./components";
import { getScormTrackedData } from "pages/Course/redux/course-trainer.slice";
import { useAppDispatch } from "../../../../hooks/redux";
import { getCourseLesson } from "../../redux/courseSlice";
import { useParams } from "react-router-dom";

const CourseTraining = ({ trainer }: { trainer: any }) => {
  const { lesson_id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (trainer.attempt_status === "started") {
      dispatch(getScormTrackedData(trainer.id));
    }

    return () => {
      dispatch(getCourseLesson(lesson_id));
    };
  }, [dispatch, lesson_id, trainer.attempt_status, trainer.id]);

  return (
    <>
      <Container>
        <div className=" course-test-block">
          <div className="course-test-content course-test-content_page">
            <CourseTestContentHeader
              title={""}
              time={""}
              chunkValue={""}
              isCheckedChunk={""}
              isCompletedChunk={""}
              descr={""}
              buttonValue={""}
              secondaryButton={""}
              attemptText={""}
            >
              <H1 className="course-test-top__title">{trainer.title}</H1>
            </CourseTestContentHeader>
            <CourseTrainingIframe trainerlink={trainer.launch_url} trainerId={trainer.id} />
          </div>
        </div>
      </Container>
    </>
  );
};

export default CourseTraining;
