import { EndPoints } from "app/endpoints";
import { dateISOToFormat } from "app/utils/date.util";
import { mediaQuerys } from "app/utils/utils";
import classNames from "classnames";
import { BalanceRow } from "components/BalanceRow";
import { useModal } from "components/ModalsContainer";
import { Subtitle } from "components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useMediaQuery } from "hooks/useMediaQuery";
import { IOrder, OrderStatus } from "pages/Orders/redux/interfaces/orders.interface";
import { getUserOrders, updateOrderStatus } from "pages/Orders/redux/orders.slice";
import {
  selectOrderProduct,
  selectOrderProductLoading,
} from "pages/Orders/redux/selectors/orders.selectors";
import {
  getProfileCoins,
  getShopProduct,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { useMemo, useState } from "react";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import { VerticalArrow } from "../VerticalArrow";
import styles from "./OrderItem.module.scss";
import { CancelChip } from "./components/CancelChip";
import { DescriptionBlock } from "./components/DescriptionBlock/DescriptionBlock";
import { OrderStatusBadge } from "./components/OrderStatus";

interface OrderItemProps {
  order: IOrder;
}

export const OrderItem = ({ order }: OrderItemProps) => {
  const dispatch = useAppDispatch();
  const details = useAppSelector(selectOrderProduct);
  const detailsIsLoading = useAppSelector(selectOrderProductLoading);
  const xxsMin = useMediaQuery(`(min-width:${mediaQuerys.xxsMin}px)`);

  const [expanded, setExpanded] = useState<boolean>(false);
  const { openModal, closeModal } = useModal();

  const { description, takeInfo } = useMemo(() => {
    if (details?.id !== order.product_id) {
      return {
        description: "",
        takeInfo: "",
      };
    }
    return {
      description: details.description,
      takeInfo: details.how_receive,
    };
  }, [details?.id, order.product_id, details?.description, details?.how_receive]);

  const isOrderLoading = detailsIsLoading && details?.id === order.product_id;

  const onConfirmCancel = async () => {
    await dispatch(updateOrderStatus(order.id))
      .unwrap()
      .then(async () => {
        await dispatch(getUserOrders());
        await dispatch(getProfileCoins());
        closeModal();
      })
      .catch(() => {
        openModal({
          content: (
            <ErrorModal
              title="Ошибка"
              description="Не удалось обновить статус заказа. Попробуйте позже"
            />
          ),
        });
      });
  };

  const onCancel = () => {
    openModal({
      content: (
        <ConfirmModal
          orderNumber={order.number}
          onCancel={closeModal}
          onConfirm={onConfirmCancel}
        />
      ),
    });
  };

  return (
    <div
      className={classNames(styles["item"], {
        [styles["item--disabled"]]: order.status === OrderStatus.Cancelled,
      })}
    >
      <div className={styles["item-main"]}>
        <div className={styles["item-expander"]}>
          <VerticalArrow
            className={styles["item-expander-icon"]}
            position={expanded ? "up" : "down"}
            onClick={() =>
              setExpanded((prev) => {
                if (!prev) {
                  dispatch(getShopProduct(order.product_id));
                }
                return !prev;
              })
            }
          />
          <img
            className={styles["item-expander-img"]}
            alt={order.name}
            src={order.picture_id ? `${EndPoints.SHOP_FILES}/${order.picture_id}` : undefined}
          />
        </div>
        {!xxsMin && (
          <div className={styles["item-text-info"]}>
            <div className={styles["item-text-info-text-price"]}>
              <Subtitle className={styles["item-text-info-title"]}>{order.number}</Subtitle>
              <BalanceRow className={styles["item-price-row"]} coins={order.price} />
            </div>

            <p>{order.name}</p>
          </div>
        )}
      </div>

      {xxsMin && (
        <div className={styles["item-text-info"]}>
          <Subtitle className={styles["item-text-info-title"]}>{order.number}</Subtitle>

          <p>{order.name}</p>
          {expanded && (
            <DescriptionBlock
              loading={isOrderLoading}
              description={description}
              takeInfo={takeInfo}
            />
          )}
        </div>
      )}

      <div className={styles["item-status-info"]}>
        <OrderStatusBadge status={order.status} />
        <p>{dateISOToFormat(order.created, true)}</p>
        {xxsMin && order.status === OrderStatus.New && expanded && (
          <CancelChip onCancel={onCancel} />
        )}
      </div>
      {!xxsMin && expanded && (
        <>
          {order.status === OrderStatus.New && <CancelChip onCancel={onCancel} />}
          <DescriptionBlock
            loading={isOrderLoading}
            description={description}
            takeInfo={takeInfo}
          />
        </>
      )}
      {xxsMin && (
        <div className={styles["item-price"]}>
          <BalanceRow className={styles["item-price-row"]} coins={order.price} />
        </div>
      )}
    </div>
  );
};
