import { TextMessages } from "app/messages";
import { AuthTypes, UseAuthForm } from "../authRoutes.congig";
import { AuthData, UseAuthFormFieldNames } from "./data.types";
import { WebAppRoutes } from "app/routes";
import { codeSend } from "features/auth/authSlice";
import { registrationCodeSubmit } from "./registrationCodeSubmit";
import { ChangeEvent, PointerEvent } from "react";

export const registrationCodeCheckData = (
  dep: any,
  text?: { title: TextMessages; info: TextMessages; togglesTypeIsReg?: boolean }
): AuthData => {
  const { nav, authOIDC, dispatch } = dep;

  const formActions = {
    type: AuthTypes.REGISTRATION_CODE_CHECK,
    toggles: {
      top: {
        isReg: !!text?.togglesTypeIsReg,
        title: text?.title || TextMessages.ACCOUNT_ENTER,
        action: () => {
          nav(WebAppRoutes.AUTH);
        },
      },
    },
    titles: {
      main: TextMessages.ACCOUNT_CREATE,
    },
    form: {
      info: text?.info || TextMessages.CODE_SEND_MESSAGE,
      infoIsValid: true,
      submit: async (formData: UseAuthForm) => {
        formData.code && (await registrationCodeSubmit(formData.code, dep));
      },
      policyCheck: authOIDC.policyCheck,
      support: {
        active: false,
        buttons: {
          secondary: {
            title: TextMessages.CODE_GET,
            action: (e: PointerEvent<HTMLButtonElement>) => {
              e.preventDefault();
              dispatch(
                codeSend({
                  email: authOIDC.email,
                })
              );
            },
          },
        },
      },
      fields: [
        {
          label: TextMessages.CODE_ENTER,
          name: UseAuthFormFieldNames.code,
          mask: "9".repeat(4),
          maskChar: " ",
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            const valueFormatted = event.target?.value?.trim();
            if (valueFormatted?.length >= 4) {
              formActions.form.submit({ code: valueFormatted }).then(() => {
                if (event.target.value.trim() === "") {
                  setTimeout(function () {
                    event.target.setSelectionRange(0, 0); //cursor to start
                  }, 0);
                }
              });
            }
          },
        },
      ],
    },
  };

  return formActions;
};
