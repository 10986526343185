import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  answersByPageSelector,
  selectCourseNavigation,
  selectCoursePosition,
  selectCurrentOpenPageInQuiz,
} from "../../redux/Quze/courseQuizeSelectors";
import {
  clearQuestAnswer,
  questionsSelectors,
  setCurrentOpenPageInQuiz,
} from "../../redux/Quze/courseQuizeQuestionsSlice";

import { generateUid } from "app/utils";
import { useEffect } from "react";
import classNames from "classnames";

export const QuestionsProgress = () => {
  const dispatch = useAppDispatch();
  const position = useAppSelector(selectCoursePosition);
  const questions = useAppSelector(questionsSelectors.selectAll);
  const answers: any = useAppSelector(answersByPageSelector);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);

  const navigation = useAppSelector(selectCourseNavigation);

  const handleClick = (index: number) => () => {
    if (navigation === "free") {
      dispatch(setCurrentOpenPageInQuiz(index + 1));
    }
  };

  const countPages = position === 0 ? 0 : Math.ceil(questions.length / position);

  const pageNumbers = new Array(countPages < 1 ? 1 : countPages).fill(undefined);

  useEffect(
    () => () => {
      dispatch(clearQuestAnswer());
    },
    [dispatch]
  );

  return (
    <ul className="course-questions-list">
      {pageNumbers.map((_, index) => {
        const answersCurrentStep = answers[index + 1]?.filter((answer: any) => !!answer.answer);

        return (
          <li
            key={generateUid()}
            className={classNames("course-questions-list__item", {
              "course-questions-list__item--completed": currentOpenPageInQuiz === index + 1,
              "course-questions-list__item--current": answersCurrentStep?.length === position,
              "course-questions-list__item--disabled": navigation === "consistent",
            })}
            onClick={handleClick(index)}
          >
            <span>{index + 1}</span>{" "}
          </li>
        );
      })}
    </ul>
  );
};
