import Slider from "react-slick";
import CalendarEventBlock from "../Calendar/CalendarEventBlock";
import { TextCard } from "../Typography/Texts";
import { WebAppRoutes } from "../../app/routes";
import { useLocation, useNavigate } from "react-router-dom";

var sliderSettings = {
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 5000,
};

const StudyCalendarEvents = ({ events }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handlerCourseClick = (eventItem) => {
    const { course, type, id } = eventItem ?? {};

    switch (type) {
      case "quiz":
      case "meeting":
        navigate(`${WebAppRoutes.COURSE}/${course?.id}/lesson/${id}`, {
          state: { from: location },
        });
        break;
      case "course":
        navigate(`${WebAppRoutes.COURSE}/${id}`, {
          state: { from: location },
        });
        break;
      default:
        return;
    }

    const url =
      type === "quiz"
        ? `${WebAppRoutes.COURSE}/${course?.id}/lesson/${id}`
        : type === "quiz"
        ? `${WebAppRoutes.COURSE}/${course?.id}/lesson/${id}` // id — это айди квиза
        : type === "course"
        ? `${WebAppRoutes.COURSE}/${id}` // id — это айди курса
        : "";
    url &&
      navigate(url, {
        state: { from: location },
      });
  };

  return (
    <CalendarEventBlock>
      {events.length === 1 ? (
        <>
          {events.map((eventItem, index) => (
            <div
              className="calendar-slider-item"
              key={index}
              title={eventItem.name}
              onClick={() => {
                handlerCourseClick(eventItem);
              }}
            >
              <TextCard className="calendar-slider-item__title">
                {eventItem.title || <br />}
              </TextCard>
              <TextCard className={"calendar-slider-item__descr"}>{eventItem.name}</TextCard>
            </div>
          ))}
        </>
      ) : (
        <div className="calendar-slider">
          <Slider {...sliderSettings}>
            {events.map((eventItem, index) => (
              <div
                className="calendar-slider-item"
                key={index}
                title={eventItem.name}
                onClick={() => {
                  handlerCourseClick(eventItem);
                }}
              >
                <TextCard className="calendar-slider-item__title">
                  {eventItem.title || <br />}
                </TextCard>
                <TextCard
                  className={"calendar-slider-item__descr calendar-slider-item__descr--short"}
                >
                  {eventItem.name}
                </TextCard>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </CalendarEventBlock>
  );
};

export default StudyCalendarEvents;
