import { Switch as MuiSwitch, styled } from "@mui/material";
import styles from "./Switch.module.scss";

interface SwitchProps {
  isChecked: boolean | undefined;
  onChange: (value: boolean) => void;
  label?: string;
}

const CSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 16,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(16px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: process.env.REACT_APP_THEME !== "myAcademy" ? "transparent" : "#00AEFF",
        border: process.env.REACT_APP_THEME !== "myAcademy" ? "2px solid #CFD3DE" : "#00AEFF",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: process.env.REACT_APP_THEME !== "myAcademy" ? "#FFC422" : "#fff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: "#A4A4AF",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: "transparent",
    boxSizing: "border-box",
    border: "2px solid #CFD3DE",
  },
}));

export const Switch = ({ isChecked, onChange, label }: SwitchProps) => (
  <div className={styles["switch-control"]}>
    <CSwitch checked={isChecked} onChange={(_, checked) => onChange(checked)} />
    <p>{label}</p>
  </div>
);
