import { emailValidationPattern } from "app/constants";
import { TextMessages } from "app/messages";
import { WebAppRoutes } from "app/routes";
import { getToken } from "features/auth/authSlice";
import { getMyCorsesList } from "pages/My/redux/myCoursesSlice";
import { getInitProfileFields } from "pages/Profile/redux/profile-slice/profile.slice";
import { AuthTypes, UseAuthForm } from "../authRoutes.congig";
import { AuthData, UseAuthFormFieldNames } from "./data.types";
import { getMobileSettings } from "components/UpdateMobileModal/redux/mobile.slice";

export const EMAIL_VALIDATION_RULES = {
  required: TextMessages.ERROR_FIELD_IS_EMPTY,
  validate: (data: any) => {
    if (data === "") {
      return true;
    }
    if (!emailValidationPattern.test(data.trim())) {
      return TextMessages.ERROR_EMAIL_IS_NOT_VALID;
    }
    return true;
  },
};

export const LOGIN_VALIDATION_RULES = {
  required: TextMessages.ERROR_LOGIN_IS_REQUIRED,
  pattern: null,
};

export const signInData = (
  type: AuthTypes.AUTH | AuthTypes.AUTH_EXIST,
  dep: any,
  subtitle?: string
): AuthData => {
  const {
    nav,
    authLastError,
    navigate,
    dispatch,
    authType,
    setError,
    setShowError,
    authOIDC,
    registrationIsAllowed,
    showError,
    isHasInternet,
    firstLoginCheck,
  } = dep;

  return {
    type,
    titles: {
      main: TextMessages.AUTHORIZE,
    },
    toggles: {
      top: {
        isReg: true,
        title:
          type === AuthTypes.AUTH_EXIST
            ? TextMessages.ACCOUNT_NEW_TO_CREATE
            : TextMessages.ACCOUNT_TO_CREATE,
        action: () => {
          nav(WebAppRoutes.REGISTRATION, { is_allowed: true });
        },
      },
    },
    form: {
      info:
        process.env.REACT_APP_THEME !== "myAcademy" && subtitle
          ? subtitle
          : !showError
          ? process.env.REACT_APP_THEME === "myAcademy"
            ? TextMessages.EMAIL_ENTER_ACADEMY
            : process.env.REACT_APP_THEME === "base"
            ? TextMessages.EMAIL_ENTER
            : undefined
          : undefined,
      errorMsg: (function () {
        if (!isHasInternet) {
          return TextMessages.ERROR_INTERNET_NOT_AVAILABLE;
        }

        if (authLastError === 401) {
          return authType === UseAuthFormFieldNames.login
            ? TextMessages.ERROR_LOGIN_ENTER_BAD
            : TextMessages.ERROR_EMAIL_ENTER_BAD;
        } else if (authLastError === 403) {
          return TextMessages.CODE_COUNT_OUT_OF_RANGE;
        } else if (authLastError === 404) {
          return registrationIsAllowed
            ? TextMessages.ERROR_EMAIL_NOT_FOUND_SELF
            : TextMessages.ERROR_EMAIL_NOT_FOUND;
        } else if (authLastError || authLastError === "unknown_error") {
          return TextMessages.UNKNOWN_ERROR;
        } else if (process.env.REACT_APP_THEME === "myAcademy" && subtitle) {
          return subtitle;
        } else {
          return null;
        }
      })(),
      valid: false,
      actions: {
        buttons: {
          primary: {
            title: TextMessages.ENTER,
          },
          addition: {
            title: TextMessages.FORGOT_PASSWORD,
            action: () => nav(WebAppRoutes.PASSWORD_RECOVERY),
          },
        },
      },
      submit: (formData: UseAuthForm) => {
        if (!isHasInternet) {
          setShowError(true);
          return;
        }

        const { password, login } = formData;
        password &&
          login &&
          dispatch(
            getToken({
              password: password,
              login: login,
            })
          ).then((result: any) => {
            if (result.payload?.status === 404) {
              setShowError(true);
              setError(UseAuthFormFieldNames.login);
            } else if (
              result.meta.requestStatus.toLowerCase() === "rejected" ||
              result.payload?.status === 401
            ) {
              setShowError(true);
              setError(UseAuthFormFieldNames.login);
              setError("password");
            }

            if (result?.payload?.idToken) {
              dispatch(getInitProfileFields()).then((result: any) => {
                if (result.payload?.status === 401 || result.payload?.status === 403) {
                  nav(WebAppRoutes.AUTH, { replace: true });
                }
                if (!result.payload.data?.policy_agreed && firstLoginCheck) {
                  navigate(WebAppRoutes.AUTH_AGREEMENT);
                } else {
                  nav(WebAppRoutes.HOME);
                  dispatch(getMyCorsesList());
                  dispatch(getMobileSettings());
                }
              });
            }
          });
      },
      fields: [
        {
          label: authType === UseAuthFormFieldNames.login ? TextMessages.LOGIN : TextMessages.EMAIL,
          name: UseAuthFormFieldNames.login,
          value: authOIDC.mail || "",
          rules:
            authType === UseAuthFormFieldNames.login
              ? LOGIN_VALIDATION_RULES
              : EMAIL_VALIDATION_RULES,
        },
        {
          label: TextMessages.PASSWORD,
          name: UseAuthFormFieldNames.password,
          type: "password",
          value: "",
          rules: {
            required: TextMessages.ERROR_FIELD_IS_EMPTY,
          },
        },
      ],
    },
  };
};
