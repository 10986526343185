import { generateUid } from "../../app/utils";

function Avatar1() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#DAD6F5"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#C861F9"
            d="M238.738 337.27l41.646-13.061c2.853-3.508 27.231-50.62-22.058-73.736a190.152 190.152 0 00-11.494-17.981c-12.679-17.75-23.104-32.119-41.699-40.713-6.988-3.205-14.013-5.057-20.783-5.972-9.405-3.546-19.686-5.221-30.355-4.593l-32.401 1.972c-41.135 2.254-72.269 37.191-69.733 77.904l.141 2.536 4.093 76.278 3.603 73.108 2.024 14.028c0 1.127.141 2.113.282 3.24.141 1.268.282 2.395.423 3.663.422 3.24 9.86 24.653 10.424 25.921l177.362.282-11.475-122.876z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M45.546 822.915l7.442-4.736 39.445-26.202 42.961-26.695L158.033 798l-88.08 59.778-24.407-34.863zm-266.894.565l108.373 16.106c.419.06.837.102 1.254.127l-12.293 47.535-117.659-3.903 20.325-59.865zm377.192-694.606l-2.15 16.537-.884 12.648-1.677 23.977-29.768 1.812a75.443 75.443 0 00-12.392 1.713l1.544-20.941c-.591.096-1.198.192-1.821.289-12.573 2.086-11.85 1.855-12.704 1.936-9.213.486-16.573-1.3-17.948-1.82-11.347-3.335-20.256-27.401-19.59-59.128.036-2.54.353-5.059.67-7.58l4.28-24.837c.955-5.582 3.295-10.784 6.842-15.055 1.543-1.87 3.066-3.458 4.71-4.755 18.344-15.946 44.061-8.075 48.61-6.486l1.657.54c4.921 2.32 9.621 3.779 14.49 4.826 2.229.438 3.627 2.654 3.189 4.883l-6.993 35.38c-.658 3.344 26.635-10.994 27.107 18.277 1.109 7.199-2.344 13.823-7.172 17.784zM59.298 391.378c3.185-.533 6.792-1.118 10.888-1.761 44.786-6.995 82.601-12.284 114.144-16.548l-.03-.014c-1.371-2.493-4.482-9.042-8.383-17.438.968.048 1.958.082 2.97.102l100.229-31.435c.882 2.368 1.772 4.772 2.669 7.212 16.699 45.555 25.855 70.033 23.874 87.099.118.019.231.035.341.048-.121.108-.266.435-.471.955a33.702 33.702 0 01-.294 1.725l-.324-.131c-2.276 5.755-8.907 20.212-32.158 34.226-19.421 11.705-105.073 13.281-256.955 4.728l-.45-.344c-16.126-1.283-33.742-4.672-52.848-10.168a335.377 335.377 0 0015.855 4.194l-74.087-9.78c-12.543-3.29-48.048-12.939-74.915-20.158a32605.07 32605.07 0 01-24.683-6.431c-15.637-4.086-27.189-7.185-27.189-7.185.845-16.905 27.612-12.678 39.586-9.861 14.651.704 29.302 1.55 44.094 2.254 2.28-.597 4.979-1.257 8.015-1.819-10.961-7.558 1.488-13.625 37.348-18.2 18.622-2.375 32.809.106 47.27 4.19l77.05-14.298-.113-.102 23.472-66.722 2.219 37.378.14 1.409 2.736 46.875z"
          ></path>
          <path
            fill="#23355F"
            d="M109.327 175.82l16.265-15.16-16.265 3.624v11.536zM59.426 126.407c-4.583 5.825-7.072 11.617-5.615 16.474 1.853 6.141 7.683 9.061 13.64 12.002l.149.073c-3.814-6.848-6.729-16.711-8.174-28.549zm81.752 5.366c-1.983 7.281-5.206 16.828-9.67 28.642 17.182 7.228 30.64 7.228 40.371 0 4.021-2.967 12.611-33.327 18.198-48.612 1.769-5.101 7.916-22.324-.578-38.17-3-5.576-7-8.962-8.738-10.355-9.496-7.583-18.177-6.637-39.011-11.624-4.307-1.008-10.693-2.584-18.285-5.092-.562-.04-1.114-.219-1.666-.399-4.55-1.589-29.768-8.36-47.088 7.093-1.071.99-1.835 1.743-2.293 2.261 3.566 2.915 6.537 4.879 8.914 5.892 10.014 4.231 19.318 4.458 25.139 4.018a58.76 58.76 0 004.384 12.028c5.903 12.28 15.273 19.872 21.107 23.884 1.275-1.26 4.236-3.922 8.127-5.741.442-.252 3.782-1.572 7.557-1.025 6.716.893 12.457 7.649 13.502 14.925 1.165 7.566-2.568 14.507-7.782 18.379-4.7 3.491-9.869 3.849-12.188 3.896z"
          ></path>
          <path
            fill="#23355F"
            d="M68.248 99.397c-.26 2.651-.567 6.947-2.125 8.57-2.53 2.62-6.141-5.365-4.23-10.07.863-1.922 2.062-2.57 3.223-2.323 1.918.258 3.239 1.892 3.132 3.823zm30.9 3.688c-.26 2.651-.566 6.946-2.124 8.57-2.53 2.619-6.141-5.365-4.23-10.071.863-1.922 2.062-2.569 3.223-2.322 1.918.257 3.239 1.891 3.132 3.823zM75.204 101.894l-4.08 13.098c-.365 1.687.587 3.514 2.198 3.856.937.278 1.548.407 4.01.883 1.102.213 1.855.364 2.742.552a1.409 1.409 0 10.586-2.756c-.907-.192-1.676-.346-2.793-.562-2.364-.457-2.937-.579-3.857-.847-.062-.017-.194-.271-.163-.414l4.047-12.972a1.41 1.41 0 00-2.69-.838zM80.345 129.036c.984-.106 3.44-.686 4.023-2.138.064-.301.292-.726.192-.904-.365-.866-2.2-.626-4.66-.676-4.362-.139-7.907-.893-7.907-.893s.637 2.814 1.133 3.707c1.06 2.116 5.647 1.2 7.22.904z"
          ></path>
          <path
            fill="#7B61FF"
            d="M82.252 99.882c2.283-10.63 12.742-17.411 23.383-15.15 7.838 1.667 13.591 7.776 15.183 15.131l.072.684-.072-.684c.048.221.092.443.132.667l10.945-3.222a2.113 2.113 0 111.193 4.054l-11.452 3.371c-.13.038-.261.064-.392.077a19.843 19.843 0 01-.418 3.314c-2.265 10.655-12.738 17.456-23.392 15.191-9.074-1.928-15.353-9.81-15.617-18.688l.044-.536-4.81-.357-.09.554C74.23 114.3 64.118 120.555 53.838 118.37c-10.654-2.265-17.455-12.738-15.19-23.392 2.264-10.655 12.737-17.456 23.391-15.191 9.436 2.005 15.85 10.448 15.62 19.754l4.593.34zm16.06 19.299c8.372 1.78 16.601-3.564 18.38-11.935 1.779-8.372-3.565-16.6-11.936-18.38-8.371-1.78-16.6 3.565-18.38 11.936-1.779 8.371 3.565 16.6 11.936 18.379zm-43.595-4.945c8.371 1.779 16.6-3.565 18.38-11.936 1.779-8.371-3.565-16.6-11.936-18.38-8.372-1.779-16.6 3.565-18.38 11.936-1.78 8.372 3.565 16.6 11.936 18.38z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar2() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#D1ED80"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#7D6BF2"
            d="M242.894 244.205l-.701 3.564-2.076 10.076-269.748 18.631c10.494-25.634 29.183-47.976 50.867-65.775 32.165-26.244 76.198-33.584 116.73-29.65 37.766 3.659 78.165 28.786 100.86 57.617a111.758 111.758 0 014.068 5.537z"
          ></path>
          <path
            fill="#23355F"
            d="M161.908 111.618c-2.942-1.917-8.179-1.453-9.91 2.107a1829.944 1829.944 0 01-9.472 19.577c-1.642 3.445-1.431 7.59 2.086 9.955 2.941 1.917 8.178 1.453 9.91-2.107 3.119-6.556 6.25-13.008 9.472-19.577 1.629-3.547 1.418-7.693-2.086-9.955z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M149.463 66.492c-.029-2.717-.16-5.42-.609-8.188-.052-.412-.218-.913-.269-1.325-.166-.502-.447-1.094-.561-1.184-.013-.102-.192-.707-.472-1.299-.255-.385-.497-.668-.752-1.054a38.303 38.303 0 00-1.134-1.632l-.343-.27c-.802-.628-3.31-3.338-4.429-3.194 2.443 1.359 2.8 1.731 1.349.872-.331-.166-.547-.243-.878-.41-.979-.396-1.971-.895-3.065-1.382-1.081-.383-2.06-.78-3.142-1.163-.33-.167-.763-.32-.992-.5-1.5-.433-3.267-.519-4.526-.67a98.999 98.999 0 00-13.603-1.38c-1.043-.074-2.073-.045-3.217-.107-2.846.369-2.554.226-1.538.095-1.754.017-3.521-.068-5.173-.064-7.716.266-15.494.853-23.184 1.325-4.555 5.717-7.723 12.614-8.817 20.395l-7.777 53.536c-2.391 16.424 4.928 32.01 17.589 40.732.256 10.327.412 20.666.668 30.992.345 18.477 20.906 31.723 37.479 29.58 12.608-1.631 21.341-9.458 25.839-19.771 1.837-4.319 2.58-9.124 2.306-13.797-1.378-20.96.663-41.838 4.242-62.811 2.054-12.509 3.682-29.253 9.128-42.723-6.866-1.205-14.196-6.954-14.119-14.603z"
          ></path>
          <path
            fill="#23355F"
            d="M136.955 31.92l-14.466-2.108c-14.889-2.158-29.061 3.79-38.14 14.488.232.178.579.445.823.726 14.473 16.114 38.585.5 55.183 11.987 10.877 7.543 5.068 23.429 7.472 34.122 3.843 1.484 6.13 2.232 6.156 2.436 5.107-3.346 10.961-5.748 17.252-7.169l1.069-7.191c3.359-22.838-12.482-43.967-35.349-47.291z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M149.342 94.24c28.641-12.037 35.44-8.646 37.769 3.094 2.328 11.739-6.229 19.153-28.479 26.437-22.251 7.283-9.29-29.532-9.29-29.532z"
          ></path>
          <path
            fill="#23355F"
            d="M153.226 109.536c4.934-6.493 12.035-10.783 20.132-12.207 2.256-.385 2.696 3.065.453 3.552-7.168 1.308-13.38 5.177-17.773 10.985-1.353 1.715-4.152-.513-2.812-2.33zM83.111 83.935c1.56-1.563 4.406.58 2.758 2.26-4.399 4.444-13.84 16.452-3.362 20.226 2.16.767.717 4.095-1.442 3.328-4.433-1.625-6.724-5.935-6.693-10.651.157-6.198 4.72-10.978 8.74-15.163zM84.91 157.775c10.228 1.919 20.574 3.107 30.946 3.679 2.328.124 1.716 3.572-.508 3.435-10.372-.572-20.73-1.861-30.946-3.679-2.263-.438-1.755-3.873.508-3.435zM107.811 77.157c2.225 1.066 4.552 2.12 6.776 3.186 2.109.977.649 4.273-1.46 3.296-2.224-1.066-4.551-2.12-6.776-3.186-2.019-1.093-.661-4.376 1.46-3.296zM72.789 66.738l5.335.777c2.242.338 1.743 3.806-.5 3.468l-5.335-.776c-2.333-.224-1.743-3.807.5-3.469zM86.622 125.147c1.28 5.124 7.377 8.706 12.452 6.716 5.075-1.989 7.136-8.78 4.559-13.429-1.083-1.936 1.768-4.162 2.863-2.124 3.763 6.572 1.397 15.888-6.068 18.802-7.465 2.914-15.647-2.269-17.418-9.611-.478-2.116 3.12-2.572 3.612-.354zM71.611 79.04c-4.468.883-3.163 7.897 1.305 7.014 4.583-.794 3.163-7.898-1.305-7.014zM105 87.945c-4.468.884-3.163 7.898 1.305 7.015 4.583-.794 3.176-7.795-1.305-7.015zM141.814 60.047c.586 10.633.328 21.06-.75 31.49-.953 8.943 10.292 10.419 11.144 1.489 1.09-10.326 1.336-20.857.75-31.49-.497-9.069-11.654-10.661-11.144-1.489z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar3() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#FFCB3D"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#7D6BF2"
            d="M-38.917 316.313c.147-5.872.22-11.67.367-17.468.807-44.401 22.384-82.565 51.814-107.151 18.495-1.174 37.063-1.834 55.63-1.688 30.605.221 62.09.441 92.547 5.578.44.441.954.881 1.394 1.395 24.66 25.98 39.632 64.217 43.668 106.27.881 9.247 1.321 18.715 1.101 28.256-1.688 89.39-3.449 178.78-5.137 268.244l-22.018-.66L-.24 593.731c-1.247-1.835-2.495-3.596-3.67-5.357-4.99-8.661-10.788-16.88-15.118-24.293-6.605-11.376-12.917-23.118-19.669-34.42-1.247-3.303-2.568-6.532-4.11-9.615.147-6.898.294-13.724.44-20.623 1.102-61.061 2.276-122.122 3.45-183.11z"
          ></path>
          <path
            fill="#23355F"
            d="M28.089 127.184c2.201-3.009 5.21-5.358 7.926-7.853 8.807-7.853 10.054-12.623 12.183-24.292 3.89-21.65 12.55-26.201 34.42-31.999 19.596-5.21 42.2-9.54 59.887-.954 3.156-5.871 14.312-5.651 15.926 2.862 2.129 11.156 27.228 11.23 35.228 18.568 6.385 5.798 9.321 13.578 7.192 22.091-2.935 11.522-9.467 17.173-4.476 30.09 3.302 8.514 6.018 17.027 2.348 26.054-8.44 20.843-33.393 18.935-51.594 18.201-27.742-1.027-52.548 4.037-80.437.881-19.962-2.275-40.071-6.018-44.181-26.788-1.761-9.394-.22-19.082 5.578-26.861z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M143.092 90.341c24.22-15.852 30.972-13.797 35.081-3.449 4.11 10.348.588 16.44-18.567 26.861-19.156 10.422-16.514-23.412-16.514-23.412z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M77.48 77.132c.588-.22 1.175-.367 1.762-.587 7.56-2.349 15.045-4.697 22.311-7.78 14.311-6.018 28.182-12.99 41.759-20.403 3.303 1.982 6.165 4.918 8.954 9.174 4.257 6.532 10.862 9.761 10.568 18.201l-1.761 50.494c-.514 15.485-9.981 28.475-23.192 34.42-1.908 9.467-3.889 18.862-5.798 28.329-3.449 16.88-24.732 25.54-39.85 20.843-14.532-4.55-21.578-17.32-21.725-31.044 5.725-20.477 7.56-41.466 7.78-62.897.22-11.302 1.394-25.907-.807-38.75z"
          ></path>
          <path
            fill="#23355F"
            d="M124.891 35.152h.074c21.65.734 38.603 18.935 37.869 40.586l-.22 5.724c-9.027-20.11-28.329-27.815-37.723-46.31z"
          ></path>
          <path
            fill="#23355F"
            d="M79.243 85.572c-2.716-.808-5.651-1.322-8.807-2.716l.293-10.421c.734-21.65 18.935-38.604 40.586-37.87l13.651.44c13.283.44 24.659 7.486 31.411 17.834-23.192 16-49.466 28.035-77.134 32.733z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M91.055 91.442c-24.219-15.853-30.97-13.798-35.08-3.45-4.11 10.349-.588 16.44 18.567 26.862 19.155 10.421 16.513-23.412 16.513-23.412z"
          ></path>
          <path
            fill="#23355F"
            d="M84.895 104.947c-3.376-6.898-9.173-11.963-16.292-14.678-1.982-.734-3.01 2.422-1.028 3.156 6.385 2.422 11.376 7.045 14.385 13.063.88 1.982 3.89.367 2.935-1.541zM133.26 98.268c-1.175-1.762-4.184-.22-2.936 1.614 3.303 4.771 10.055 17.321-.294 19.082-2.128.367-1.321 3.596.808 3.229 4.33-.734 7.265-4.33 7.999-8.587.881-5.724-2.495-10.861-5.577-15.338zM137.881 161.017c-9.614.514-19.228.367-28.842-.367-2.129-.146-2.055 3.156.073 3.376 9.614.734 19.228.881 28.843.367 2.128-.147 2.055-3.449-.074-3.376zM107.569 81.902c-2.275.44-4.55.88-6.899 1.321-2.128.44-1.614 3.743.514 3.303 2.275-.44 4.55-.88 6.899-1.321 2.128-.44 1.615-3.743-.514-3.303zM152.416 81.609l-4.99-.588c-2.129-.22-2.496 3.083-.367 3.303l4.99.587c2.129.294 2.496-3.009.367-3.302zM150.434 92.91c3.89 1.835 1.028 7.853-2.862 6.019-3.89-1.835-1.028-7.927 2.862-6.019zM107.427 92.25c3.89 1.835 1.028 7.853-2.862 6.018s-1.028-7.853 2.862-6.018zM163.42 94.597c1.321-1.467 2.568-2.935 3.889-4.476 1.395-1.615 3.743.734 2.349 2.348-1.321 1.468-2.569 2.936-3.89 4.477-1.394 1.615-3.743-.734-2.348-2.349zM73.005 22.088c5.651-7.853 16.146-8 23.485-2.422 7.633 5.798 11.816 15.486 14.825 24.366 2.569 7.486-5.504 11.816-11.302 9.54-8.734-.146-17.834-1.247-24.366-7.632-6.458-6.311-8-16.366-2.642-23.852zM106.614 126.67c1.027 5.358 4.917 9.541 10.128 10.862 2.055.514 1.174 3.743-.881 3.229-6.312-1.614-11.229-6.825-12.477-13.21-.367-2.128 2.863-3.009 3.23-.881z"
          ></path>
          <path
            fill="#7D6BF2"
            d="M138.323 210.188c7.412-13.21 51.3-26.934 54.603-25.76 113.316 40.805 203.66 129.462 182.45 221.201-2.348 10.348-6.018 20.109-10.642 29.209-27.448-16.513-52.988-35.888-80.143-52.841.074-1.175.22-2.349.22-3.67.294-23.338-29.136-52.108-63.997-75.226-28.989-19.155-61.648-34.347-83.959-39.044 3.743-14.898-6.165-40.218 1.468-53.869zM15.247 183.034c.88-.294 26.714 14.458 27.595 14.165 19.889 25.173 9.76 38.75 23.191 67.813-1.541.44-3.156.881-4.844 1.248-26.347 5.357-67.593 25.686-99.885 50.052-26.42 19.889-46.823 42.42-48.438 61.576-28.623 15.118-57.612 29.503-86.748 43.594-1.542-4.404-2.936-8.88-3.964-13.577-21.944-94.675 74.786-185.974 193.093-224.871z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M175.239 178.557c5.944 1.835 11.889 3.816 17.687 5.945-5.798 4.623-11.89 8.806-18.275 12.476-19.742 11.449-42.126 18.054-64.951 18.935-7.192.294-14.531 0-21.724-.881-.44-.073-.954-.073-1.394-.146-23.779-3.23-46.31-12.697-65.245-27.008a126.3 126.3 0 01-6.091-4.844c83.225-20.917 150.745-7.413 159.993-4.477z"
          ></path>
          <path
            fill="#23355F"
            d="M128.049 192.281c1.321-4.991 5.357-25.174 6.605-30.164.514-2.055 3.743-1.174 3.229.881-1.321 4.99-5.357 25.173-6.605 30.163-.514 2.129-3.743 1.248-3.229-.88z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar4() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#7D6BF2"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M189.425 90.962c12.298 6.11 16.496 12.159 17.15 17.449.96 8.9-1.12 16.052-6.079 27.813-9.597 23.523-17.756 47.363-24.314 71.839-1.921-.414-3.837-.85-5.748-1.308 4.008 15.281 3.867 26.968 3.867 26.968H-90.801c4.496 0 36.998-88.008 151.687-96.953a176.19 176.19 0 019.6-.504c.702-2.919 1.907-5.555 4.095-9.539-4.806-11.779-8.506-39.385-9.761-50.88 0-.473.161-.945.322-1.418.645-1.26 2.42-1.89 3.87-1.26l36.305 17.793c6.2-2.268 12.759-4.33 19.676-6.392 6.552-2.002 13.103-3.71 19.264-5.276l12.997-33.77c.479-1.294 2.077-2.102 3.356-1.455.479.161.799.485 1.119.97 6.726 9.49 23.252 33.688 27.696 45.923z"
          ></path>
          <path
            fill="#CC975A"
            d="M-97.691 222.881c-1.353-8.562 2.257-13.771 10.831-15.629 35.361-1.958 66.826.667 94.393 7.873C35.101 222.332 51.156 228.624 55.7 234H-86.86c-6.053 0-10.831-4.906-10.831-11.119zm280.844-57.92c4.123-4.778 16.808-25.324 16.808-25.324s-13.954 42.365-22.358 68.644c.476-2.23-6.184 15.608-6.976 10.989v-.159c-1.11-5.256-5.391-9.078-10.783-9.397-5.074-.318-10.465-.477-16.173-.477-7.294 0-14.746.477-22.04 1.433l-10.624-32.331c10.307 5.256 22.04 7.167 33.457 6.211 3.964-.318 7.769-1.115 11.575-2.229 10.465-3.027 19.82-9.238 27.114-17.36z"
          ></path>
          <path
            fill="#23355F"
            d="M-97.691 222.881c-1.353-8.562 2.257-13.771 10.831-15.629 35.361-1.958 66.826.667 94.393 7.873C35.101 222.332 51.156 228.624 55.7 234H-86.86c-6.053 0-10.831-4.905-10.831-11.119zm242.7-97.013c2.25 2.521 5.916 3.392 9.094 1.956 2.891-1.683 4.425-4.939 3.813-8.248a2.565 2.565 0 115.046-.933c1.02 5.514-1.582 10.96-6.419 13.697l-.177.092c-4.3 2.008-9.151 1.449-12.828-1.071-1.148 2.755-3.436 5.111-6.518 6.504-6.196 2.354-13.142.036-16.267-5.438a2.566 2.566 0 014.457-2.544c1.847 3.236 6.109 4.658 9.84 3.246 2.825-1.28 4.44-3.896 4.148-6.46l-.026-.05c-.743-1.291-.891-2.905-.743-4.358.149-1.13.743-2.098 1.486-2.583 1.485-1.13 3.713-.484 4.605 1.13.786 1.554.85 3.445.489 5.06zm-43.334.072c1.803 3.39.15 8.232-1.653 8.555-1.353.162-3.006-1.614-3.607-2.744-.751-1.291-.901-2.905-.751-4.358.15-1.13.751-2.099 1.503-2.583 1.502-1.13 3.606-.484 4.508 1.13zm77.038-27.37c1.669 3.39.152 7.909-1.669 8.555-1.366.161-3.035-1.614-3.642-2.744-.759-1.292-.91-2.906-.759-4.358.152-1.13.759-2.099 1.518-2.583 1.517-1.13 3.642-.484 4.552 1.13z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar5() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#FFE872"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M209.558 200.84l-84.26 18.062-8.097-22.689-10.499-29.006-18.062-50.182 61.482-19.219c1.957 6.228 59.436 103.034 59.436 103.034z"
          ></path>
          <path
            fill="#000"
            d="M117.11 196.302l.445 1.157c11.211-9.609 18.507-23.045 21.799-38.26 4.36-20.197-.356-37.814-5.25-56.41l-45.377 14.236 18.062 50.182 10.321 29.095z"
          ></path>
          <path
            fill="#FFA79B"
            d="M163.201 106.793c-1.424 12.813-10.233 40.751-11.3 43.064-11.923 26.693-46.357 46.179-72.338 25.003-.178-.178-.356-.267-.534-.445-.266-.178-.444-.445-.711-.712-.178-.178-.356-.445-.623-.712-.09-.089-.09-.178-.178-.267-.356-.445-.623-.89-.979-1.334-.445-.623-.8-1.335-1.245-2.136-.178-.267-.267-.534-.445-.801-.178-.356-.445-.8-.623-1.245-.178-.267-.267-.623-.445-.979-.267-.623-.534-1.335-.89-2.047a61.235 61.235 0 01-.8-2.224c-.98-2.58-1.87-5.427-2.67-8.453v-.178c-.089-.444-.267-.889-.356-1.334-.089-.445-.267-.89-.356-1.335-.8-3.203-1.601-6.673-2.402-10.232-1.246-5.784-2.313-11.834-3.292-17.884 0-.089 0-.178-.09-.356-2.668-16.817-4.537-33.633-5.605-44.132-.089-.534-.089-1.068-.178-1.513 0-.178 0-.356-.089-.534-3.203-31.23 71.982-52.05 93.603-28.65 12.724 13.703 15.126 37.014 12.546 59.436z"
          ></path>
          <path
            fill="#697CCC"
            d="M150.922 47.536c-12.812-13.79-44.221-12.19-67.177-2.135-4.093 0-8.097.356-12.1.979-4.45.712-10.055 1.423-13.08 5.16-1.334 1.69-1.957 3.737-1.78 5.695-.444.267-.89.534-1.423.8-5.605 3.293-9.876 11.212-3.737 16.372 5.605 4.627 15.66 1.958 21.8.267.355-.089.8-.267 1.156-.356a55.584 55.584 0 0031.23-5.516c5.072.711 9.966.533 14.859-.445.445.178.89.445 1.335.712 2.135 1.334 3.648 3.292 4.894 5.427.444.356 11.833 26.07 3.47 31.942-21.266 15.126-48.937 19.308-67.177 16.283.979 6.05 2.046 12.19 3.292 17.884a157.15 157.15 0 002.402 10.232c.09.445.267.89.356 1.335.09.445.267.89.356 1.335v.178c.89 3.025 1.78 5.872 2.67 8.452.266.712.533 1.513.8 2.225.267.711.534 1.423.89 2.046.178.356.267.623.445.979.178.445.445.89.623 1.246.178.266.267.533.445.8.444.712.8 1.424 1.245 2.136.267.445.623.889.979 1.334.089.089.089.178.178.267.178.267.356.445.623.712.267.267.445.534.712.712.177.178.356.267.533.445 25.981 21.176 61.661 3.47 72.872-23.579 2.758-6.762 0-22.956-1.424-29.095-.89-3.915 7.652-9.787 12.101-15.482 2.491-22.51.089-45.822-12.368-59.347z"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M79.082 109.5c-1.5 4-1 12.5 13.5 8"
          ></path>
          <path
            stroke="#697CCC"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M62.082 86l8.5-2.5M98.082 80.5l8.5-2.5"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M67.582 97.5l2.5 7"
          ></path>
          <path
            stroke="#FFA79B"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M125.082 144.5c-2 5-6 11-13.5 13"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M109.582 94l2.5 7"
          ></path>
          <path
            fill="#FFA79B"
            d="M177.616 85.528c-5.961-9.52-23.223-11.656-32.654.356a826.727 826.727 0 016.139 36.124c6.851 1.602 19.753-.979 25.803-8.453 6.94-8.63 7.118-17.795.712-28.027z"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M155.082 107.5l8-13.5"
          ></path>
          <path
            fill="#C861F9"
            d="M269.082 199.861v119.94H-22.759c-.267-13.613.178-40.751 4.983-80.256 6.495-52.407 45.021-46.179 124.388-42.531 9.342.445 19.218.801 29.718 1.068 21.265.534 50.894.979 78.832 1.246 20.109.266 39.328.444 53.92.533z"
          ></path>
          <path
            stroke="#FFE872"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M117.582 198l-3.5 62.5 34-20.5"
          ></path>
          <path
            fill="#F7ACA9"
            d="M135.272 197.459c-3.19-.177-14.756.178-17.718 0l15.174 49.204 34.354-49.204c-13.078-.444-21.694.445-31.81 0z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar6() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#DAD6F5"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#7B61FF"
            d="M190.801 383c-75.776 37.221-122.775 63.975-140.994 80.262l-5.495 2.733c-27.724-36.872-38.939-86.8-33.645-149.783-13.243-3.241-24.6-6.558-29.788-8.987-10.377-4.859-13.186-15.489-8.428-31.892 3.986-15.761 12.05-34.036 24.19-54.826 14.43-24.709 34.788-25.429 47.927-23.78 11.476-8.545 67.413-9.516 77.907-7.388 16.511 3.47 40.994 8.331 54.409 28.245 4.307 6.207 6.31 12.241 7.353 16.555 0 0 2.003 6.034 3.426 12.609 3.27 15.035 9.753 67.653 3.138 136.252z"
          ></path>
          <path
            fill="#474961"
            d="M354.7 185.159L216.388 309.695a7.639 7.639 0 1010.223 11.354l138.312-124.536a7.64 7.64 0 00-10.223-11.354z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M78.394 138.604c-4.154-5.38-7.876-10.44-9.852-14.602-2.93-6.06-1.403-23.73 4.137-30.78 5.54-7.048 49.47-39.653 50.024-39.692 4.682-.745 31.058-3.843 46.013 14.883a27.963 27.963 0 013.722 5.448c2.437 4.981 3.794 10.456 3.766 16.027l-.215 24.798a62.755 62.755 0 01-.733 7.431c-2.798 17.739-8.312 32.465-14.61 41.956-4.652 7.147-9.922 11.414-14.762 11.892-1.376.236-8.868.62-17.781-1.402-.581-.15-.428-.026-4.054-1.31l.741 16.341c12.025 2.515 27.593 5.985 40.118 15.115-17.88 9.513-37.681 13.793-59.403 12.842-9.343-.409-19.195-1.663-29.555-3.76-7.688-9.101-17.057-14.46-22.287-15.325-2.384-.394-5.615-1.049-9.4-1.483l.198-.169c4.57-3.763 17.703-6.099 32.038-7.371l.893-51.154 1.002.315zm117.158 199.055c.322-.002.644-.021.964-.057-.31.014-.62.013-.93-.004l-.034.061zm18.579-15.182l5.892 3.921 5.471-4.869c.18-.135.355-.279.526-.433l3.271-2.945 78.141-69.531a13.911 13.911 0 002.695-3.254l23.63-21.277c10.701-5.29 19.003-7.378 21.774-3.482 0 0-41.425 44.923-63.303 68.501-5.724 15.004-14.208 34.192-25.453 57.565-28.009 13.095-53.411 25.242-76.204 36.44a615.05 615.05 0 002.748-41.554c-27.212 46.613-69.274 86.004-126.187 118.172-72.783 41.139-84.647-99.118-86.535-153.266.267.133.54.262.82.387 14.925 6.682 84.144 22.084 96.97 20a4.598 4.598 0 001.067-.257l-2.18 33.65 80.753-57.358.05-.065 40.19-51.441a9.027 9.027 0 0112.672-1.556 8.987 8.987 0 012.725 3.519l1.163-1.724a9.029 9.029 0 0116.501 5.528 8.997 8.997 0 016.292 1.777l.025.019a8.639 8.639 0 011.465 12.398l-.591.716a9.028 9.028 0 018.697 14.94l-33.085 35.479zm295.416 288.827c24.381 10.023 60.911 24.319 81.447 30.39l-6.606 33.955-78.086-23.668c.606-4.131.582-8.313.482-9.445-.877-6.592-1.497-15.417-1.955-25.99-.073-2.079-.155-4.248-.246-6.509l4.964 1.267zm-140.193 60.292c11.456.169 26.501-1.084 46.381-1.638 14.19-.395 29.029-.055 42.467-.217l16.294 30.398-1.2 3.385-103.847-1.48-.095-30.448z"
          ></path>
          <path
            fill="#8C2A00"
            d="M192.928 340.532a257.82 257.82 0 001.643-2.887 9.01 9.01 0 007.11-2.857l11.689-12.536 6.229 4.146 5.472-4.869c.18-.135.355-.279.525-.433l3.271-2.945 78.141-69.531a13.916 13.916 0 002.696-3.254l23.63-21.277c10.7-5.29 19.002-7.378 21.774-3.482 0 0-41.426 44.923-63.304 68.502-5.724 15.003-14.208 34.191-25.452 57.564-28.01 13.095-53.411 25.242-76.205 36.44a614.82 614.82 0 002.781-42.581z"
            opacity="0.2"
          ></path>
          <path
            fill="#23355F"
            d="M124.053 173.299l-19.322-6.533 19.977 22.79-.655-16.257zM146.098 54.178c7.761 1.986 15.915 5.998 22.11 13.755a27.965 27.965 0 013.723 5.448 37.728 37.728 0 012.567 6.843l-70.357 24.536 9.024-47.394c13.46-1.96 24.438-3.023 32.933-3.188zM182.155 112.729c-3.051.139-7.73.028-11.545-1.768-6.367-2.945.713-5.648 6.782-4.718 2.961.453 4.977 2.125 6.128 3.514 1.048 1.069.161 2.903-1.365 2.972zm-28.664-3.655c-3.034.352-7.71.568-11.927-1.132-6.569-2.344.318-5.684 6.437-5.18a10.026 10.026 0 016.507 3.09c1.257 1.156.5 3.047-1.017 3.222zM169.618 122.548c.358-1.767 2.02-3.08 3.805-2.87 1.19.14 2.161.857 2.38 2.843.779 4.917-4.505 11.382-6.29 8.609-1.129-1.942-.49-6.089.105-8.582zm-26.64-2.982c.359-1.768 2.021-3.08 3.806-2.87 1.19.14 2.161.857 2.38 2.843.778 4.917-4.505 11.382-6.29 8.609-1.129-1.942-.49-6.089.104-8.582zM150.296 150.578c-.991-.455-3.394-1.58-3.588-3.276.045-.296-.035-.763.158-1.036.751-.946 2.437-.082 5.078.474 4.715.874 8.631 1.019 8.631 1.019s-1.489 2.802-2.366 3.577c-1.843 2.141-6.353-.064-7.913-.758zM161.911 123.237l.014-.196-.001.099-.013.097zm.013-.097l-.057 16.102c.023 1.726-1.522 3.167-3.252 3.19-.193 0-.368-.002-.543-.005-1.066-.024-2.052-.109-3.874-.315l-1.362-.156a280.29 280.29 0 00-1.576-.177 1.496 1.496 0 11.324-2.977c.508.055.997.11 1.592.178l1.359.156c1.738.197 2.651.275 3.603.296.153.004.304.005.456.005.118-.001.279-.151.278-.18l.058-16.226.013-.197 1.19-8.756a1.498 1.498 0 012.968.403l-1.177 8.659z"
          ></path>
          <path
            fill="#DC1CB2"
            d="M151.542 57.16c-24.636 24.124-40.324 39.886-47.065 47.284-.248.36-.498.731-.749 1.114-.299-1.258-1.456-5.351-4.631-9.304-.296-.257-2.865-3.14-6.574-4.413-6.686-2.039-15.073 1.472-19.368 7.759-4.561 6.445-3.99 14.62-.921 20.671 3.068 6.051 8.398 8.602 10.258 9.307l7.479 1.426.755-.343c-5.498 11.634-10.51 22.103-13.718 22.861-5.834 1.379-14.829-11.078-21.18-17.113-4.036-3.834-13.732-16.381-16.632-33.856-.862-5.78-4.064-25.386 7.978-40.83 4.202-5.367 9.193-8.175 11.243-9.395 6.98-3.72 33.774-5.846 54.359-6.972-3.921-6.075-6.305-18.665-7.152-37.77a2.776 2.776 0 014.409-2.37c17.054 12.398 27.908 21.406 32.562 27.023 3.416-5.437 13.385-11.771 29.907-19.002a2.777 2.777 0 013.794 3.273c-6.852 25.211-12.066 38.456-15.641 39.733-1.965.703-5.003 1.008-9.113.917z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar7() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#C861F9"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M112.406 131.298l-21.267-5.361-21.95-5.484-.009-.051-29.169-7.352c-9.203-2.32-14.793-11.65-12.497-20.859 2.295-9.202 11.615-14.802 20.817-12.508l30.158 7.52c9.61-10.197 25.945-23.223 50.36-16.222 24.278 6.961 33.307 28.476 27.086 64.543a48.767 48.767 0 016.441 4.897l81.302 73.167c19.848 17.862 21.458 48.433 3.595 68.281l-.016.018a48.21 48.21 0 01-29.446 15.566l-.157.109h-.706c-3.758.44-7.562.441-11.328 0h-68.518c-3.325-12.954 3.528-20.662 20.559-23.127 1.862-.269 4.635-.571 8.047-.858l-35.773-32.194c-2.128 12.162-7.249 31.058-15.363 56.686l-.024-.007v.007H73.999c2.748-8.515 7.365-12.773 13.853-12.773.892 0 2.2-.161 3.766-.38a700.288 700.288 0 00-3.17-61.578c-.74-7.712-.79-15.188-.153-22.427-10.432-17.696-8.74-40.811 5.73-56.891l.016-.018a48.185 48.185 0 0118.365-12.704z"
          ></path>
          <path
            fill="#7D6BF2"
            d="M96.966 127.327l43.975 5.865c-1.191 12.815-5.004 19.222-11.439 19.222-6.436 0-18.559-2.966-36.37-8.897l3.834-16.19zm33.982 113.693a1.5 1.5 0 11-2.893-.794c3.996-14.558 4.992-27.499 3.008-38.823a1.5 1.5 0 012.955-.518c2.066 11.794 1.037 25.174-3.07 40.135zm35.749 30.438h25.117c-8.861-14.285-8.345-28.783 1.542-43.213a1.501 1.501 0 012.475 1.696c-9.729 14.199-9.73 28.143-.005 42.162.69.995-.022 2.355-1.232 2.355h-27.897a1.5 1.5 0 010-3z"
          ></path>
          <path
            fill="#23355F"
            d="M258.994 241.373c23.266-2.338 13.1-21.153 5.899-34.69-8.176-15.368-.721-40.849 4.312-57.06 5.032-16.211 21.342-9.995 28.503-1.611 7.161 8.385-7.732 29.372-9.172 48.832-1.44 19.46 2.427 11.244 4.887 36.89 2.46 25.646-21.342 27.575-28.257 28.634-1.981.303-4.324.08-7.028-.67a48.581 48.581 0 00.856-20.325zM131.582 71.118c20.767 3.05 33.216 10.149 37.347 21.295 8.412 22.702 6.715 36.538-.186 59.36-6.9 22.822-27.018 25.056-38.938 10.752-7.947-9.536-7.354-40.005 1.777-91.407zM106.564 94.37a3.856 3.856 0 112.125-7.412 3.856 3.856 0 012.645 4.77 3.856 3.856 0 01-4.77 2.642zm-77.753-4.352c-4.255-.75-1.726-6.046 1.75-9.63 3.477-3.583 10.048-5.034 12.524-1.319 2.622 3.935-10.02 11.7-14.274 10.95z"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M101.75 84c3.75-1.75 10.75-2.75 14.5 3.5"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar8() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#DAD6F5"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#C861F9"
            d="M-2.218 387.839c-13.186-1.4-32.004-3.3-56.455-5.698-5.845-.723-11.395-6.835-11.016-13.195-1.19-15.447 9.098-46.001 11.338-52.471 5.122-14.489 14.342-42.442 17.708-55.32 9.366-36.442 20.928-50.052 24.88-54.003 4.536-4.684 8.927-7.903 11.854-9.952 19.757-13.611 40.685-15.66 51.369-15.952.292-.147.585-.147.878-.147l23.928-.337c12.45-2.899 24.682-3.832 34.661-4.582 14.604-1.018 29.681-2.091 44.553-.191l40.719.573c.293 0 .586 0 .879.147 10.683.292 31.611 2.341 51.368 15.952 2.927 2.049 7.318 5.268 11.855 9.952 3.951 3.951 15.513 17.562 24.879 54.003 3.366 12.879 12.586 40.831 17.708 55.32 1.589 4.589 4.986 19.402 10.191 44.44.436.671.742 1.403.891 2.183a5.596 5.596 0 01-.033 1.959c.279 1.349.563 2.726.851 4.131-1.198.022-2.391.047-3.58.074-1.66 1.332-3.939 2.267-6.535 2.527l-39.701 4.206c6.407 39.622 7.16 72.853 3.572 93.629 0 .46-.162 1.073-.325 1.533-.812 4.754-253.487 10.428-255.274 8.741-16.25-14.568-12.675-49.378-12.675-49.378a7858.2 7858.2 0 011.512-38.144z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M94.878 213.355l24.104-14.398 16.409 17.282-.019.152a8.781 8.781 0 01-5.867 7.259l-2.216 150.492a1.462 1.462 0 01-.501 1.081l-22.941 20.026a1.463 1.463 0 01-2.05-.123l-20.785-23.097a1.464 1.464 0 01-.363-1.17l19.753-150.229a8.796 8.796 0 01-5.524-7.275z"
          ></path>
          <path
            fill="#880BC4"
            d="M121.575 174.062c-8.359 1.487-39.19 1.835-40.069 11.882-.586 6.699-.27 18.31.947 34.833l36.529-21.82 26.849 28.279c15.714-25.098 18.224-40.768 7.531-47.008-16.039-9.362-23.429-7.653-31.787-6.166z"
            opacity="0.5"
          ></path>
          <path
            fill="#F7ACA9"
            d="M150.103 142.563l-7.182 45.096c-.196 1.006-.822 1.928-1.768 2.191a51.443 51.443 0 01-16.888 3.277c-12.454.412-22.263-3.582-27.675-6.423-1.093-.511-1.732-1.827-1.508-2.977l4.34-25.289c-8.118 1.344-7.64 1.144-8.427 1.247-9.498.539-17.2-1.256-18.58-1.823-5.088-1.436-9.71-6.807-13.17-15.083-4.71-11.054-7.64-27.427-7.254-46.286-.072-2.697.287-5.311.646-7.925l4.424-25.827c.974-5.774 3.5-11.098 7.262-15.883 1.596-2.075 3.108-3.72 4.852-5.02 10.832-9.439 24.164-10.852 34.514-10.012 5.525.358 10.432 1.346 13.89 2.415 1.265.395 5.751 1.863 12.275 3.578 3.536.986 6.409 1.544 8.105 2.023 21.238 5.023 30.217 4.084 39.874 11.925 1.816 1.397 5.911 4.876 8.956 10.686 8.584 16.429 2.28 34.287.501 39.606-5.769 15.875-17.206 24.088-20.889 27.696-4.408 4.387-11.384 9.442-16.298 12.808zm-211.82 299.152l8.102-59.13 36.559 3.524 15.474 1.706-.937 33.636c5.993-.91 11.395-1.225 16.204-.944 35.305-8.348 137.895-32.165 196.052-41.059l-.311-2.925 92.269-9.777c2.595-.26 4.874-1.195 6.534-2.527l1.544-.034c5.626 43.808 1.103 98.609-51.342 113.944H22.051c.02.071.041.14.064.209H-86.334c-.598-14.278 7.607-26.486 24.617-36.623z"
          ></path>
          <path
            fill="#23355F"
            d="M99.097 158.552l22.284-5.047-24.3 17.92 2.016-12.873zM67.961 93.922c-.618 2.414-1.83 6.353-3.547 7.51-2.86 1.681-4.625-6.117-1.892-10.207 1.081-1.728 2.143-1.82 3.264-1.453 1.668.622 2.51 2.426 2.175 4.15zm25.8 4.568c-.332 2.47-1.114 6.492-2.885 7.937-2.63 2.023-5.312-5.654-2.982-9.972.938-1.756 2.027-1.992 3.177-1.768 1.724.335 2.88 2.05 2.69 3.803zM76.227 125.794c1.062-.092 3.643-.335 4.382-1.832.055-.287.283-.69.195-1.006-.38-1.117-2.215-.877-4.829-1.236-4.652-.607-8.304-1.764-8.304-1.764s.447 3.069.998 4.071c1.161 2.462 6.038 1.919 7.558 1.767zM72.07 95.02l.053-.197-.033.096-.02.1zm-5.09 14.755c-.585 1.568.361 3.403 1.946 3.994.352.103.352.103.581.166a75.252 75.252 0 006.491 1.528 1.464 1.464 0 00.559-2.874c-2.52-.489-3.952-.837-6.273-1.476l-.458-.126c-.002-.007-.1-.197-.09-.224l5.155-14.989.052-.196 1.648-8.476a1.464 1.464 0 00-2.873-.559L72.09 94.92l-5.11 14.856zM163.691 100.834c-.161 4.337-1.809 8.772-4.436 12.086-3.225 4.193-6.987 6.09-11.588 7.218a1.464 1.464 0 10.697 2.843c2.023-.496 3.658-1.037 5.621-2.027 2.891-1.459 5.47-3.493 7.576-6.233 3-3.783 4.871-8.817 5.055-13.779a1.464 1.464 0 10-2.925-.108z"
          ></path>
          <path
            fill="#23355F"
            d="M163.771 100.333a19.083 19.083 0 00-1.733-7.953c-3.341-7.359-11.262-12.626-18.605-11.667-4.13.538-7.302 3.053-7.645 3.284a24.713 24.713 0 00-3.419 3.592L56.21 67.015c1.678-16.53 10.053-27.487 25.123-32.873 4.072-1.455 15.046-3.598 24.603-2.358 14.751 1.914 28.93 5.97 32.913 7.047 3.249.93 8.789 1.486 10.286 1.926 16.27 1.554 28.599 8.77 32.823 12.54a29.994 29.994 0 017.857 10.472c8.097 17.377 1.825 35.39-.283 39.453-1.476 2.844-3.519 4.534-3.519 4.534-6.088-1.501-13.501-3.975-22.242-7.423zM58.738 81.543c-1.38-.566-1.448-2.517-.247-3.327 1.516-.898 4.038-1.6 6.911-1.042 5.89 1.145 11.457 6.252 4.514 6.692-4.102.395-8.588-1.073-11.178-2.322zm29.399 6.759c-1.38-.567-1.449-2.518-.104-3.3 1.516-.898 4.038-1.6 7.055-1.014 5.89 1.145 11.43 6.396 4.514 6.692-4.418.483-8.703-1.245-11.465-2.378z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar9() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#7B61FF"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#C861F9"
            d="M78.202 120.717C62.222 131.67 58.488 149.9 67 175.408c12.768 38.262 40.053 76.408 70.707 86.393 30.655 9.984 57.741 7.347 57.741-16.434 0-23.781-18.567-139.367-52.239-139.367-22.447 0-44.117 4.906-65.007 14.717z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M70.076 76.52C79.575 66.903 95.883 59.396 119 54c36.184-8.446 44.438-32.321 48.854-26.19 2.944 4.087-2.515 12.817-16.377 26.19 20.638-10.12 30.957-12.65 30.957-7.59 0 5.06-8.139 12.345-24.415 21.855 16.276-3.633 24.415-3.054 24.415 1.735 0 4.692-14.697 11.716-44.09 21.072.898 3.393 1.535 6.859 1.96 10.02 15.229 1.728 24.574 6.942 28.033 15.641 24.598 61.856 32.609 129.341 42.481 148.228 11.992 22.945 11.992 33.625 0 32.039-26.229-10.812-47.289-24.052-63.18-39.719-23.836-23.5-31.347-46.828-41.277-70.64-4.359-10.454-1.064-26.401 9.883-47.844-4.266.911-9.3 1.442-15.244 1.445H86.835C69.612 140.275 61 134.018 61 121.471c0-16.732 0-37.592 9.076-44.951z"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M140 149c-10 17-17 24-5 44s21 30 28 39"
          ></path>
          <path
            fill="#23355F"
            d="M87.793 124.061l15.561-17.042c.292 4.645-.015 8.537-.921 11.674-1.359 4.706-2.961 5.171-4.37 6.647-2.034 2.131-5.458 1.705-10.27-1.279z"
          ></path>
          <path
            fill="#7B61FF"
            d="M80.729 101.795c-7.055 16.832-9.418 27.067-7.09 30.707 3.494 5.461 4.514 1.042 21.062-8.864 2.117-1.268 5.002-6.807 8.654-16.619-3.881-3.195-8.014-5.299-12.398-6.311-4.385-1.013-7.794-.65-10.228 1.087z"
          ></path>
          <path
            fill="#482D1F"
            d="M114 111a5 5 0 11.001-10.001A5 5 0 01114 111zm-47 0a5 5 0 110-10 5 5 0 010 10z"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M113 94l10 5M73 96l-11 2"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar10() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#7B61FF"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#FE4E4E"
            d="M102.36 73.821c-1.757-3.885-6.735-2.83-8.844-.122-.276.3-.464.624-.74.924-3.064-2.65-8.913-1.555-8.615 3.43 2.493 33.781-32.753 40.211-54.024 20.395C18.514 87.651 9.557 74.868-3.21 65.07c-22.7-17.482-55.364-31.159-84.592-24.74-21.578 4.699-41.382 23.938-37.997 47.224 3.059 20.745 22.393 32.289 40.401 40.446-19.528 21.955-12.427 56.651 13.13 72.483 28.731 17.759 61.883 8.292 86.392-11.042 1.675 11.873 9.991 22.215 22.71 26.156 18.238 5.676 65.689.97 77.847-12.212 30.359-33.153 5.244-90.05-12.321-129.563z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M235.376 440.273c-.048-21.429-1.809-42.809-.395-64.079.032-1.696.177-3.452.209-5.148-.95-22.84-.353-47.39 15.913-63.393 1.303-14.86 2.717-29.781 3.76-44.72-8.194-25.133-24.135-47.69-45.084-63.239-4.621 4.756-10.126 8.676-15.988 11.256-18.907 8.38-40.042 7.745-59.799 2.791-15.18-3.849-31.004-10.925-42.888-21.733-38.063 18.864-65.917 56.081-65.834 102.157.072 38.492-12.179 140.046-14.93 152.477 74.681 10.631 149.923 13.663 225.291 15.312a939.815 939.815 0 01-.255-21.681z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M63.26 314.227c1.8-12.366 10.896-29.068 19.193-45.923 2.413-21.845 4.029-43.842 7.437-65.569-4.099-6.35-8.507-11.934-8.84-11.75-24.167 7.514-48.093 21.134-63.584 31.347-46.804 30.823-83.854 75.786-105.287 128.672-21.05 51.855-26.588 109.685-17.503 165.362 11.176-4.183 22.169-8.71 33.308-13.095 19.883-7.889 40.533-13.529 60.427-21.128.025-.088-.011-.291.014-.379-3.43-91.353 11.022-99.208 30.934-128.439 12.144-17.574 26.937-28.961 43.902-39.098z"
          ></path>
          <path
            fill="#23355F"
            d="M155.02 43.967c-1.321-3.908-3.375-7.17-5.875-9.803-.778.821-1.492 1.754-2.182 2.599 2.13 2.253 3.86 4.96 4.926 8.052.484 2.094 3.813 1.206 3.131-.848z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M151.409 222.787c24.847 1.739 45.238-7.54 59.24-22.62-15.078-12.926-44.125-21.257-69.25-19.762-19.715 1.181-38.861 6.723-55.692 15.772 13.294 17.874 45.971 25.31 65.702 26.61z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M176.656 185.728c-1.122-9.75-2.158-19.477-3.28-29.228 10.687-9.831 15.602-25.393 11.291-40.453l-13.978-49.433c-2.25-7.886-6.601-14.457-12.294-19.412a263.965 263.965 0 01-29.943 30.638c-10.148 10.262-21.504 19.438-33.478 27.407 3.348 9.036 5.711 18.653 8.116 26.298 6.387 20.727 11.14 41.669 12.103 63.275 4.394 13.289 15.06 23.473 30.219 23.363 15.726-.144 33.183-15.02 31.244-32.455z"
          ></path>
          <path
            fill="#FE4E4E"
            d="M98.47 103.315c-.099-1.055 39.547-16.577 59.369-54.22-9.376-9.703-24.183-13.372-38.082-9.41l-13.258 3.667c-20.98 5.909-33.09 27.745-27.045 48.719l1.931 6.594c6.13.606 11.833 2.127 17.085 4.65z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M103.397 104.216c-28.526-7.592-34.503-3.58-35.18 7.558-.677 11.137 4.689 15.868 26.597 19.888 21.821 3.996 8.583-27.446 8.583-27.446z"
          ></path>
          <path
            fill="#23355F"
            d="M101.823 118.828c-5.554-5.38-12.965-8.372-20.8-8.626-2.261-.092-2.08 3.25.09 3.32 6.985.231 13.411 2.983 18.36 7.758 1.613 1.491 3.963-.961 2.35-2.452zM164.298 84.667c-1.713-1.31-4.011 1.078-2.321 2.476 4.66 3.614 15.111 13.687 5.639 18.554-1.934.982-.172 3.904 1.762 2.923 3.89-2.051 5.575-6.393 4.944-10.797-.835-5.776-5.689-9.724-10.024-13.156zM172.985 154.641c-9.336 3.059-18.867 5.42-28.461 7.303-2.181.365-1.139 3.59.953 3.202 9.682-1.86 19.124-4.244 28.46-7.303 2.073-.67 1.12-3.872-.952-3.202zM139.854 81.821c-1.925 1.264-3.961 2.592-5.886 3.856-1.815 1.2-.044 4.108 1.77 2.908 1.925-1.264 3.961-2.592 5.886-3.856 1.815-1.2.044-4.108-1.77-2.908zM171.336 67.015l-4.904 1.413c-2.13.558-1.187 3.778.943 3.22l4.903-1.413c2.042-.581 1.099-3.801-.942-3.22zM166.693 124.142c-.464 4.972-5.804 9.056-10.877 7.839-5.073-1.216-7.907-7.316-6.026-11.922.816-2.011-2.167-3.613-2.983-1.602-2.675 6.53.85 15.029 8.249 16.753 7.4 1.724 14.419-4.055 15.144-11.088.196-2.171-3.246-2.041-3.507.02zM151.371 90.369c-1.215-1.817-4.097-.154-2.882 1.662 1.02 1.485.775 3.847-.953 4.696-1.64.872-3.875-.281-4.364-1.998-.683-2.049-3.909-1.224-3.248.912 1.13 3.567 5.425 5.827 8.968 4.153 3.432-1.61 4.671-6.323 2.479-9.425zM177.75 78.1c-1.215-1.816-4.097-.153-2.882 1.663 1.02 1.484.775 3.847-.953 4.696-1.639.872-3.874-.282-4.363-1.999-.684-2.048-3.909-1.224-3.248.912 1.13 3.568 5.425 5.828 8.967 4.154 3.432-1.61 4.561-6.26 2.479-9.426zM149.808 42.694c1.096-.267 2.215-.623 3.334-.978-9.471-18.175-37.076-18.967-53.245-9.518-24.97 14.592-10.883 46.994-9.347 69.135.174 2.21 3.54 2.18 3.367-.03-1.264-18.87-12.257-41.97-.05-59.169 11.538-16.063 45.598-17.85 55.941.56z"
          ></path>
          <path
            fill="#23355F"
            d="M111.223 122.54c-1.628 6.204-6.125 11.177-11.713 13.148-1.126.354-2.34.685-3.507.842-1.939.231-3.873.09-5.892-.448-9.478-2.526-16.468-13.458-13.29-22.709 3.771-11.15 12.218-15.07 21.696-12.545 9.478 2.525 15.183 12.274 12.706 21.712z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M166.488 221.709c-4.942.706-10.246 1.98-15.833 1.518-21.41-1.434-59.754-10.71-69.451-32.253 22.886-7.833 45.728-10.111 62.825-.203 9.752 6.352 17.506 16.339 21.477 28.784.377 1.103.783 1.096.982 2.154z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar11() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#7B61FF"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M8.34 335.95c.518.087.949.345 1.38.604 2.848-1.38 7.075-.086 6.989 4.055-.345 15.444-1.122 30.888-1.984 46.332H213.08c-.172-13.891-.345-27.695-.345-41.586 0-4.055 4.141-5.436 6.989-4.142 11.647-6.298 23.209-12.769 34.77-19.154l-.258-7.592c-1.036-34.944-14.237-69.628-39.171-94.563-23.21-23.209-61.259-40.637-94.563-39.171C49.84 183.926-15.473 239.576-13.23 314.467c.086 4.314.259 8.8.345 13.287 6.816 2.157 13.46 4.745 20.017 7.506.518.173.863.431 1.208.69z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M90.824 186.687h-36.67c-33.217 0-63.07 20.793-73.94 52.113-4.315 12.338-8.11 24.762-11.82 37.445-1.985 6.557-3.883 13.115-6.04 19.586 29.939 14.322 62.12 23.813 93.268 35.633 11.13-31.665 17.256-66.694 33.562-94.217 9.319-15.703 9.664-35.116 1.64-50.56zM263.813 272.104c-.173-.604-.345-1.208-.604-1.899-3.797-12.596-7.593-25.107-11.821-37.445-10.871-31.319-40.724-52.113-73.941-52.113h-36.669c-8.024 15.444-7.679 34.771 1.726 50.56 14.667 24.676 21.052 55.478 30.284 84.468 30.198-14.754 59.791-30.975 91.025-43.571z"
          ></path>
          <path
            fill="#23355F"
            d="M176.674 134.401c-1.553-7.938-6.126-15.099-8.283-22.951-2.502-9.232-3.106-18.55-2.329-28.04.086-.777-.259-1.208-.777-1.467-13.287-29.508-47.022-49.956-76.357-36.755-.345.172-.518.431-.69.69-10.009 2.675-18.55 9.318-22.865 19.068-3.02 6.902-3.02 14.236-2.76 21.656.258 9.577-.69 26.488-2.33 35.892-1.985 11.475-2.33 23.468 7.161 31.837 7.248 6.385 17.17 8.111 26.143 4.659 11.475-4.4 20.707-17.169 33.821-16.738 13.374.432 22.778 14.15 36.842 12.252 10.181-1.467 14.15-10.785 12.424-20.103z"
          ></path>
          <path
            fill="#23355F"
            d="M163.214 51.313c.259-9.404 4.832-19.671-5.004-25.883-9.922-6.299-22.347-.346-32.096 3.106l-31.06 11.13a87.422 87.422 0 00-2.934 4.745h.086c-.086.518-.086.95-.086 1.467-2.934 9.06 1.639 26.315 6.47 34.425 6.903 11.39 15.704 23.9 26.23 32.183 8.282 6.557 15.961 5.867 24.848.862 8.11-4.572 17.946-10.871 20.966-20.189 3.883-12.338-7.679-29.939-7.42-41.846z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M145.439 45.274c-5.522.172-11.13.431-16.652.776-5.781.432-11.475.863-17.256 1.553-1.467.173-3.02.346-4.487.518-.604.086-1.466.173-1.811.259-2.761.345-5.522.776-8.283 1.208-4.487.776-8.973 1.553-13.46 2.502-7.851 10.008-12.77 23.037-12.77 35.116v39.775c0 11.734 4.573 22.432 11.994 30.37 8.11 8.628 19.585 14.064 32.354 14.064h5.005c14.495 0 27.35-6.903 35.46-17.601a44.343 44.343 0 008.974-26.747V87.292c0-15.185-7.507-31.578-19.068-42.018z"
          ></path>
          <path
            fill="#23355F"
            d="M120.16 34.661h-5.004c-13.114 0-24.848 5.695-32.959 14.668a44.253 44.253 0 00-11.388 29.68v8.714c6.557-1.208 9.404-11.389 12.51-19.844 2.07-5.694 6.903-10.008 12.77-11.216 13.373-2.847 28.04-4.055 41.931-3.624 8.024.259 14.754 6.04 16.566 13.891.259 1.208 4.832 27.61 7.42 28.99.777.431 7.765 5.263 8.714-2.761l-6.039-14.064c-.173-16.393-20.017-44.434-44.521-44.434z"
          ></path>
          <path
            fill="#F7ACA9"
            d="M152.513 143.805c-22.95.777-45.9 1.035-68.42 5.694 1.554 13.546 2.072 27.092 1.036 40.638 2.847 13.977 12.683 25.539 28.3 27.178 16.307 1.726 36.151-11.302 36.237-29.249.086-10.008.086-19.93.173-29.939.776-.517 1.466-1.208 2.243-1.811-.431-4.056-.345-8.37.431-12.511zM84.872 102.477c-24.417-14.753-31.06-12.596-34.77-2.243-3.71 10.354 0 16.307 19.24 25.884 19.24 9.663 15.53-23.641 15.53-23.641zM148.892 102.477c24.417-14.753 31.06-12.596 34.771-2.243 3.71 10.354 0 16.307-19.241 25.884-19.24 9.663-15.53-23.641-15.53-23.641z"
          ></path>
          <path
            fill="#23355F"
            d="M126.114 94.108c-1.553-1.64-4.4.604-2.761 2.243 4.314 4.401 13.805 16.393 3.192 20.104-2.157.776-.776 4.055 1.381 3.278 4.4-1.553 6.816-5.867 6.816-10.526-.086-6.126-4.573-10.957-8.628-15.099zM98.418 92.296c-4.4.95-6.213 6.903-3.365 10.268 1.466 1.725 4.4-.345 2.847-2.071-1.208-1.38-.863-4.228 1.208-4.745 1.812-.432 3.624 1.553 3.365 3.278-.345 2.243 3.192 2.847 3.537.604.604-4.227-3.278-8.283-7.592-7.334zM99.453 85.135c-2.33.863-4.659 1.726-6.989 2.675-2.156.776-1.035 4.227 1.122 3.365 2.33-.863 4.66-1.726 6.989-2.675 2.157-.776 1.035-4.141-1.122-3.365zM141.3 74.954l-5.263.777c-2.243.345-1.812 3.883.517 3.538l5.264-.777c2.243-.345 1.725-3.883-.518-3.538zM119.815 137.938c-1.294 5.176-7.42 8.714-12.511 6.643-5.09-1.984-7.074-8.887-4.4-13.459 1.122-1.985-1.725-4.142-2.847-2.157-3.71 6.557-1.467 15.961 5.867 18.895 7.334 2.933 15.53-2.157 17.342-9.491.604-2.157-2.933-2.588-3.451-.431zM147.081 92.124c-4.487 1.122-2.675 8.024 1.812 6.902 4.4-1.207 2.674-8.11-1.812-6.902zM148.806 158.817c-9.922 6.73-21.052 9.664-32.959 8.542-2.243-.172-2.243 3.365 0 3.538 12.425 1.121 24.418-1.985 34.771-9.06 1.812-1.208.086-4.314-1.812-3.02zM171.238 101.097c-4.228 3.02-7.161 7.334-8.283 12.51-.431 2.244 3.02 3.193 3.451.949.863-4.227 3.106-7.851 6.644-10.353 1.812-1.294.086-4.4-1.812-3.106zM70.464 114.557a18.528 18.528 0 00-9.922-12.597c-2.071-1.035-3.883 2.071-1.812 3.106 4.314 2.071 7.247 5.781 8.283 10.44.517 2.243 3.968 1.294 3.45-.949z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function Avatar12() {
  const id = generateUid();
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="234"
        height="234"
        fill="none"
        viewBox="0 0 234 234"
      >
        <g clipPath={`url(#${id})`}>
          <path
            fill="#C861F9"
            d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M.57 328.79c-13.586-15.225-22.01-35.345-22.01-57.368 0-40.783 28.53-75.041 66.571-83.741v-26.645c-52.441 9.516-92.384 55.193-92.384 110.386 0 29.908 11.956 57.096 30.976 77.216 5.434-6.797 11.412-13.323 16.846-19.848z"
          ></path>
          <path
            fill="#23355F"
            d="M-46.438 285.017c-.272-1.632-.272-3.535-.543-5.166 8.423-1.631 17.118-2.991 25.541-4.078 0 1.631.272 3.534.543 5.165-8.423.816-17.118 2.176-25.541 4.079zm1.359-35.346l25.27 5.166c.271-1.631.815-3.262 1.086-5.166l-25.27-5.165c-.271 1.631-.815 3.262-1.086 5.165zm35.866-62.262c-1.358 1.088-2.445 2.447-3.804 3.535L3.83 210.792c1.087-1.36 2.445-2.447 3.804-3.535l-16.847-19.848zm-4.89 118.815c-.816-1.631-1.36-3.263-1.903-4.894-7.336 4.622-14.944 8.7-22.824 12.779.544 1.631 1.359 3.262 2.174 4.622 7.608-3.807 15.216-8.157 22.552-12.507zm32.062-136.487c-1.63.815-3.26 1.359-4.62 2.175l9.511 24.198c1.63-.816 2.989-1.632 4.62-2.447l-9.511-23.926zm-51.355 47.852l21.466 14.682c.815-1.632 1.63-2.991 2.446-4.622l-21.466-14.682c-.815 1.631-1.63 2.99-2.446 4.622z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M233.159 328.79c13.586-15.225 22.009-35.345 22.009-57.368 0-40.783-28.258-74.769-66.299-83.741v-26.645c52.442 9.244 92.384 55.193 92.384 110.114 0 29.908-11.955 57.096-30.976 77.216l-17.118-19.576z"
          ></path>
          <path
            fill="#23355F"
            d="M254.624 280.938c.272-1.631.272-3.535.544-5.166a385.166 385.166 0 0125.541 4.078c0 1.632-.272 3.535-.543 5.166a200.012 200.012 0 00-25.542-4.078zm23.096-36.433l-25.269 5.166c.543 1.631.815 3.263 1.086 5.166l25.27-5.166c-.272-1.903-.543-3.535-1.087-5.166zm-51.354-37.52c1.358 1.087 2.445 2.447 3.804 3.534l16.846-19.847c-1.358-1.088-2.445-2.447-3.804-3.535l-16.846 19.848zm44.29 111.745c.815-1.631 1.358-3.262 2.173-4.622-7.879-3.806-15.488-8.156-22.824-12.779-.543 1.632-1.359 3.263-1.902 4.894 7.065 4.351 14.673 8.701 22.553 12.507zM206.53 193.934c1.631.816 2.989 1.631 4.619 2.447l9.511-24.198c-1.631-.815-2.989-1.631-4.62-2.175l-9.51 23.926zm58.148 19.032l-21.466 14.682c.815 1.36 1.63 2.991 2.446 4.622l21.465-14.682c-.543-1.631-1.358-2.99-2.445-4.622z"
          ></path>
          <path
            fill="#FFCB3D"
            d="M198.923 223.298l.272-73.953H34.805v73.953l10.326 10.06v51.659h143.467v-51.659l10.325-10.06z"
          ></path>
          <path
            fill="#23355F"
            d="M78.824 149.345l12.77-6.797-12.77-6.797h76.353l-13.043 6.797 13.043 6.797H78.824zM188.054 83.004h-13.586v33.986h13.586V83.004z"
          ></path>
          <path fill="#23355F" d="M196.478 87.626h-12.499v24.742h12.499V87.626z"></path>
          <path fill="#23355F" d="M188.325 87.626V59.35h2.989v35.617l-2.989-7.341z"></path>
          <path
            fill="#23355F"
            d="M189.955 63.7a4.348 4.348 0 004.347-4.35 4.348 4.348 0 10-8.695 0 4.35 4.35 0 004.348 4.35zM59.26 83.004H45.674v33.986H59.26V83.004z"
          ></path>
          <path fill="#23355F" d="M49.75 87.626H37.251v24.742H49.75V87.626z"></path>
          <path fill="#23355F" d="M45.402 87.626V59.35h-2.989v35.617l2.989-7.341z"></path>
          <path
            fill="#23355F"
            d="M44.044 63.7a4.349 4.349 0 004.347-4.35A4.349 4.349 0 0044.044 55a4.349 4.349 0 00-4.348 4.35 4.349 4.349 0 004.348 4.35z"
          ></path>
          <path
            fill="#FCE26B"
            d="M91.322 115.087c7.353 0 13.314-5.965 13.314-13.323 0-7.358-5.96-13.322-13.314-13.322-7.353 0-13.314 5.964-13.314 13.322 0 7.358 5.96 13.323 13.314 13.323zM141.863 115.087c7.353 0 13.314-5.965 13.314-13.323 0-7.358-5.961-13.322-13.314-13.322s-13.314 5.964-13.314 13.322c0 7.358 5.961 13.323 13.314 13.323z"
          ></path>
          <path fill="#FFCB3D" d="M175.012 67.506H58.717v67.972h116.295V67.506z"></path>
          <path
            fill="#fff"
            d="M91.052 112.912c7.353 0 13.314-5.965 13.314-13.323 0-7.357-5.961-13.322-13.314-13.322-7.354 0-13.315 5.965-13.315 13.323 0 7.357 5.961 13.322 13.315 13.322z"
          ></path>
          <path
            fill="#23355F"
            stroke="#23355F"
            d="M96.258 99.59c0 .52.176.994.52 1.338.344.344.817.521 1.339.521.521 0 .994-.177 1.338-.521.344-.344.52-.818.52-1.339 0-4.898-4.027-8.928-8.923-8.928s-8.923 4.03-8.923 8.928c0 .521.176.995.52 1.339.344.344.817.521 1.338.521.522 0 .995-.177 1.34-.521.343-.344.52-.818.52-1.339 0-2.971 2.477-5.21 5.205-5.21 2.969 0 5.206 2.48 5.206 5.21z"
          ></path>
          <path
            stroke="#23355F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M110 114.5c6 6.5 10 6.5 16 0"
          ></path>
          <path
            fill="#fff"
            d="M141.591 112.912c7.353 0 13.314-5.965 13.314-13.323 0-7.357-5.961-13.322-13.314-13.322-7.354 0-13.315 5.965-13.315 13.323 0 7.357 5.961 13.322 13.315 13.322z"
          ></path>
          <path
            fill="#23355F"
            stroke="#23355F"
            d="M146.797 99.59c0 .52.176.994.52 1.338.344.344.818.521 1.339.521.506 0 1.037-.167 1.416-.498.385-.337.604-.848.442-1.437-.041-4.864-4.052-8.853-8.923-8.853-4.895 0-8.923 4.03-8.923 8.928 0 .521.176.995.52 1.339.344.344.817.521 1.339.521.521 0 .994-.177 1.338-.521.344-.344.52-.818.52-1.339 0-2.971 2.478-5.21 5.206-5.21 2.969 0 5.206 2.48 5.206 5.21z"
          ></path>
          <path fill="#fff" d="M168.763 181.427H64.967v29.908h103.796v-29.908z"></path>
          <path
            fill="#7D6BF2"
            d="M87.519 241.515c4.652 0 8.423-3.774 8.423-8.429s-3.771-8.428-8.423-8.428-8.423 3.773-8.423 8.428c0 4.655 3.77 8.429 8.423 8.429zM115.506 241.515c4.652 0 8.423-3.774 8.423-8.429s-3.771-8.428-8.423-8.428-8.423 3.773-8.423 8.428c0 4.655 3.771 8.429 8.423 8.429zM143.493 241.515c4.652 0 8.423-3.774 8.423-8.429s-3.771-8.428-8.423-8.428-8.424 3.773-8.424 8.428c0 4.655 3.772 8.429 8.424 8.429z"
          ></path>
          <path
            fill="#23355F"
            d="M199.195 149.072h19.564l-19.564 41.599v-41.599zM34.806 149.072H15.242l19.564 41.599v-41.599z"
          ></path>
        </g>
        <defs>
          <clipPath id={`${id}`}>
            <path
              fill="#fff"
              d="M0 117C0 52.383 52.383 0 117 0c64.617 0 117 52.383 117 117 0 64.617-52.383 117-117 117C52.383 234 0 181.617 0 117z"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export {
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
};
