import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../services/api/api";
import { EndPoints } from "../../../app/endpoints";
import { AchievementsState, IBadgesRequest, IBadgesResponse } from "./interfaces";

const name = "achievement";

const initialState: AchievementsState = {
  isLoading: false,
  paging: {
    total: 0,
    offset: 0,
    limit: 30,
    hasMore: true,
  },
  achievements: [],
};

const getBadges = createAsyncThunk(
  `${name}/getBadges`,
  async (params: IBadgesRequest, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.BADGES, { params });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const achievementSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBadges.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBadges.fulfilled, (state, action: PayloadAction<IBadgesResponse>) => {
        state.isLoading = false;
        state.achievements = [...state.achievements, ...action.payload.data];

        const paging = action.payload.paging;
        state.paging = {
          total: paging.total,
          limit: paging.limit,
          offset: paging.offset + paging.limit,
          hasMore: state.achievements.length < paging.total,
        };
      })
      .addCase(getBadges.rejected, (state) => {
        state.paging.hasMore = false;
      });
  },
});

export { getBadges };

const achievementReducer = achievementSlice.reducer;
export { achievementReducer };
