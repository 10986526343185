import { H2 } from "components/Typography/Titles";
import { CourseFormat, MultiCourse } from "../../../Course/redux/interfaces/course.interface";
import { MultiCoursesCard } from "./MultiCoursesCard";
import { useAppSelector } from "hooks/redux";
import { selectFormats } from "pages/Courses/redux/coursesListSliceSelectors";
import styles from "./MultiCoursesList.module.scss";

interface MultiCoursesListProps {
  multiCourses: MultiCourse[];
  currentFormat?: CourseFormat;
}

export const MultiCoursesList = ({ multiCourses = [], currentFormat }: MultiCoursesListProps) => {
  const formats = useAppSelector(selectFormats);

  const formatName = formats.find((format) => format.value === currentFormat)?.label || "курс";

  return (
    <div className={styles["multi-courses-list"]}>
      <H2 className={styles["title"]}>Входит в траектории обучения</H2>

      <p className={styles["description"]}>
        Вы можете записаться на {formatName} отдельно или на всю траекторию обучения. Записываясь на
        траекторию обучения, вы регистрируетесь сразу на все курсы, которые в нее входят.
      </p>

      <div className={styles["list"]}>
        {multiCourses.map((course) => (
          <MultiCoursesCard key={course.id} course={course} />
        ))}
      </div>
    </div>
  );
};
