import { useEffect, useState } from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { mediaQuerys, numWord } from "../../../app/utils";
import { CourseHamburgerIcon, CourseHamburgerCloseIcon } from "../../../components/Svg/Icons";
import { lessonsSelectors } from "../../../pages/Course/redux/courseSlice";
import UserWidgetShared from "../../PublicLayout/components/UserWidget/UserWidgetShared";
import { useAppSelector } from "../../../hooks/redux";
import { Skeleton } from "components/Skeleton";

const CourseSharedHeader = ({
  courseTitle,
  isOpen,
  toggleMobileMenuCallback,
}: {
  courseTitle?: string;
  isOpen: boolean;
  toggleMobileMenuCallback: () => void;
}) => {
  const smMin = useMediaQuery(`(min-width:${mediaQuerys.smMin}px)`);
  const xsMax = useMediaQuery(`(max-width:${mediaQuerys.xsMax}px)`);
  const allLesson = useAppSelector(lessonsSelectors.selectAll);
  const [progress, setProgress] = useState({
    percent: 0,
    complete: 0,
    total: 0,
  });

  useEffect(() => {
    const complete = allLesson.filter((item) => item.status === "completed").length;
    const total = allLesson.length;
    const percent = (complete / total) * 100;
    setProgress({
      percent: percent,
      complete: complete,
      total: total,
    });
  }, [allLesson]);

  return (
    <header className="course-test-header">
      <div className="course-test-header-block">
        <div className="course-test-header-navigation">
          <p className="course-test-header__descr">
            {courseTitle ? courseTitle : <Skeleton variant="text" width={200} height={24} />}
          </p>
        </div>
        <div className="course-test-header-info">
          {allLesson.length ? (
            <>
              <div className="course-test-prograss">
                <i
                  className={"course-test-prograss-bar"}
                  style={{ width: progress.percent + "%" }}
                ></i>
              </div>
              <span className={"course-test-prograss-detail"}>
                {Math.trunc(progress.percent)}% пройдено / {progress.complete}{" "}
                {numWord(progress.complete, ["задание", "задания", "заданий"])} из {progress.total}
              </span>
            </>
          ) : (
            <>
              <Skeleton variant="text" height={18} />
              <Skeleton variant="text" height={18} />
            </>
          )}
        </div>
        {smMin && <UserWidgetShared />}
        {xsMax && (
          <div onClick={toggleMobileMenuCallback}>
            {" "}
            {isOpen ? <CourseHamburgerCloseIcon /> : <CourseHamburgerIcon />}{" "}
          </div>
        )}
      </div>
    </header>
  );
};

export default CourseSharedHeader;
