import { SadCatPic } from "../../../../components/Svg/Pics";
import { CourseTestImg } from "../../../../components/Svg/Pics";
import Container from "../../../../components/Container/Container";
import { TextMain } from "../../../../components/Typography/Texts";
import { H1 } from "../../../../components/Typography/Titles";
import { useEffect } from "react";
import { showAsideNav, showBottomNav } from "../../redux/courseSlice";
import { useDispatch } from "react-redux";

const CourseError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
  }, [dispatch]);

  return (
    <main className="course-test-main course-available-main">
      <Container>
        <div className="course-test-block">
          <div className="course-available-content course-test-content">
            <div className="course-test-content-img">
              <SadCatPic />
            </div>
            <H1 className="course-available__title">Ошибка</H1>
            <TextMain className="course-available__descr">
              К сожалению, доступ к курсу ограничен. Пожалуйста, обратитесь, к ответственному за
              обучение по курсу для получения доступа.
            </TextMain>
            <TextMain className="course-available__descr"></TextMain>
          </div>
          <div className="course-test-img course-available-img">
            <CourseTestImg />
          </div>
        </div>
      </Container>
    </main>
  );
};

export default CourseError;
