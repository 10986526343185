import { WebAppRoutes } from "app/routes";
import { codeSend, getPasswordPolicy, registerVerify, setTokens } from "features/auth/authSlice";
import { UseAuthFormFieldNames } from "./data.types";
import { TextMessages } from "app/messages";
import { setAuthError } from "./setAuthError";

export const registrationCodeSubmit = async (codeStr: string, dep: any) => {
  const { nav, dispatch, setError, resetField, authOIDC, showTimerTimeout } = dep;

  dispatch(getPasswordPolicy());

  dispatch(
    registerVerify({
      code: codeStr,
      email: authOIDC.email,
    })
  ).then((result: any) => {
    if (result?.payload.status === 200) {
      nav(WebAppRoutes.REGISTRATION_SECOND_STEP, { is_allowed: true });
    } else if (result?.payload.status === 500) {
      setError(UseAuthFormFieldNames.code, {
        type: "manual",
        message: TextMessages.UNKNOWN_ERROR,
      });
      resetField(UseAuthFormFieldNames.code, {
        keepError: true,
      });
    }

    if (result?.payload.data.code === "UNEXPECTED_ERROR") {
      setError(UseAuthFormFieldNames.code, {
        type: "manual",
        message: TextMessages.UNKNOWN_ERROR,
      });
      resetField(UseAuthFormFieldNames.code, {
        keepError: true,
      });
    }

    if (result?.payload.data.code === "CODE_EXPIRE") {
      setError(UseAuthFormFieldNames.code, {
        type: "manual",
        message: TextMessages.ERROR_CODE_CODE_EXPIRE,
      });
      resetField(UseAuthFormFieldNames.code, {
        keepError: true,
      });
      dispatch(
        codeSend({
          email: authOIDC.email,
        })
      ).then(() => showTimerTimeout());
    }

    if (result?.payload.data.code === "BAD_CODE") {
      setError(UseAuthFormFieldNames.code, {
        type: "manual",
        message: TextMessages.ERROR_CODE_ENTER_BAD,
      });
      resetField(UseAuthFormFieldNames.code, {
        keepError: true,
      });
    }

    setAuthError(result, nav);

    const errorDetails = result?.payload?.data?.error?.details?.[0];
    if (result?.payload.data.code === "USER_EXISTS" && errorDetails?.["Authorization"]) {
      dispatch(
        setTokens({
          email: authOIDC?.email,
          accessToken: errorDetails?.["Access-Token"],
          idToken: errorDetails?.["Authorization"],
          refreshToken: errorDetails?.["Refresh"],
        })
      );
      nav(WebAppRoutes.HOME);
    }
  });
};
