import { CSSTransition, SwitchTransition } from "react-transition-group";

export const WithSwitchAnimation = ({
  children,
  keyword,
  animationName = "fade",
  timeout = 300,
}) => {
  return (
    <SwitchTransition>
      <CSSTransition
        key={keyword}
        timeout={timeout}
        classNames={animationName}
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

export const WithAnimation = ({
  children,
  inCondition = true,
  animationName = "fade",
  timeout = 300,
}) => {
  return (
    <CSSTransition
      classNames={animationName}
      timeout={timeout}
      in={inCondition}
      mountOnEnter
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};

export const mapTitleStringToNodeArray = (title) => (
  <div>
    {title.split(" ").map((word, index) => (
      <span key={index}>{word}</span>
    ))}
  </div>
);
