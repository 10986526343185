import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { СompetenciesTypeData } from "components/CompetenciesCard/competancies.types";
import { EndPoints } from "../../../../app/endpoints";
import api from "../../../../services/api/api";
import { IBadgesRequest, IBadgesResponse } from "../../../Achievements/redux/interfaces";
import { IProfileLevel, IRatingLevelsResponse } from "../../../Rating/redux/interfaces";
import {
  Ctitzenship,
  KnownError,
  ProfileEducationDoc,
  ProfileInitalState,
  Workplace,
} from "./interface";

const initialState: ProfileInitalState = {
  isEdit: false,
  load: false,
  isLoadingPutProfile: false,
  isLoading: false,
  avatar: {
    type: "preset",
    src: "12",
  },
  details: {
    firstname: "",
    middlename: "",
    lastname: "",
    birthdate: "",
    gender: "",
    email: "",
    mobile_phone: "",
    workplace_id: "",
    citizenship_id: "",
    residence_address: "",
    snils: "",
    assignment: "",
    policy_agreed: false,
    points: 0,
    change: 0,
  },
  requests: { personalAgreement: null },
  filesLoad: false,
  files: [],
  filesErrors: [],
  referencebook: {
    citizenship: [],
    workplace: [],
    gender: [
      {
        id: "male",
        name: "Мужской",
      },
      {
        id: "female",
        name: "Женский",
      },
    ],
  },
  achievesData: [],
  coursesHistory: { results: [], start_ts: 0 },
  coursesHistoryLoading: true,
  achievementData: {} as IBadgesResponse,
  competenceData: [],
  myLevel: {} as IProfileLevel,
  isLoadingMyLevels: true,
};

const getReferencebookCtitzenship = createAsyncThunk<
  Ctitzenship[],
  void,
  { rejectValue: KnownError }
>("profile/getReferencebookCtitzenship", async (arg, { rejectWithValue }) => {
  try {
    const response = await api.get<Ctitzenship[]>(EndPoints.REFERENCE_CITIZENSHIP);
    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      status: error.response.status,
      code: error.response.data.code,
      details: error.response.data.details,
    });
  }
});

const getReferencebookWorkplace = createAsyncThunk(
  "profile/getReferencebookWorkplace",
  async ({ only_visible }: { only_visible?: boolean }, { rejectWithValue }) => {
    try {
      const response = await api.get<Workplace[]>(EndPoints.REFERENCE_WORKPLACE, {
        params: { only_visible: only_visible ? 1 : 0 },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getProfileFiles = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileFiles",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await api.get(EndPoints.PROFILE_FILES + "education_docs");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const putProfileFile = createAsyncThunk<
  any,
  { formData: any; fileName: string },
  { rejectValue: KnownError }
>("profile/putProfileFile", async ({ formData, fileName }, { rejectWithValue }) => {
  try {
    const response = await api.post<ProfileEducationDoc[]>(
      EndPoints.PROFILE_FILE + "education_docs",
      formData,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      fileName: fileName,
      status: error.response.status,
      code: error.response.data,
      details: error.response.data.error.details,
    });
  }
});

const deleteProfileFile = createAsyncThunk<any, number, { rejectValue: KnownError }>(
  "profile/deleteProfileFile",
  async (arg: number, { rejectWithValue }) => {
    try {
      const response = await api.delete(EndPoints.PROFILE_FILES + arg);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getProfileFields = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileFields",
  async (arg, { rejectWithValue, dispatch }) => {
    dispatch(getReferencebookCtitzenship());
    dispatch(getReferencebookWorkplace({}));
    dispatch(getProfileFiles());
    try {
      const response = await api.get(EndPoints.PROFILE_FIELDS);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getInitProfileFields = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getInitProfileFields",
  async (arg, { rejectWithValue, dispatch }) => {
    dispatch(getReferencebookCtitzenship());
    dispatch(getReferencebookWorkplace({}));
    dispatch(getProfileAvatar());
    dispatch(getProfileFiles());
    try {
      const response = await api.get(EndPoints.PROFILE_FIELDS);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const putProfileFields = createAsyncThunk<any, FormData, { rejectValue: KnownError }>(
  "profile/putProfileField",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(EndPoints.PROFILE_FIELDS, payload, {
        headers: {
          "Frdo-Sert-Mode": "false",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      return response;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.error.details,
      });
    }
  }
);

const putProfileAgreement = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/putProfileAgreement",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(EndPoints.PROFILE_ACCEPT_AGREEMENT);
      return response;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.error.details,
      });
    }
  }
);

const getProfileAvatar = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileAvatar",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await api.get("profile/image");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        message: error.response.data.message,
        code: error.response.data.code,
        status: error.response.status,
        details: error.response.data,
      });
    }
  }
);

const putRevokePersonalAgreement = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/putRevokePersonalAgreement",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const response = await api.put(EndPoints.PROFILE_REVOKE_PERSONAL_AGREEMENT, undefined, {
        headers: {
          // @ts-ignore
          "Access-Token": state?.auth?.oidc?.accessToken,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const getProfileCoursesHistory = createAsyncThunk("profile/courses-history", async () => {
  try {
    const response = await api.get("profile/courses-history");
    return response.data;
  } catch (error: any) {
    // return rejectWithValue({
    //   code: error.response.data.code,
    //   status: error.response.status,
    //   details: error.response.data,
    // });
  }
});

const getBadges = createAsyncThunk("profile/getBadges", async (params: IBadgesRequest) => {
  const response = await api.get(EndPoints.BADGES, { params });
  return response.data;
});

const getProfileCompetence = createAsyncThunk("profile/getCompetence", async () => {
  const response = await api.get(EndPoints.PROFILE_COMPETENCES);
  return response.data;
});

const getRatingLevels = createAsyncThunk("profile/getRatingLevels", async (_, thunkAPI) => {
  const response = await api.get(EndPoints.RATING_LEVELS);
  return response.data;
});

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    runPersonalAgreement(state) {
      state.requests = { personalAgreement: "new" };
    },
    clearFilesError(state) {
      state.filesErrors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitProfileFields.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getInitProfileFields.fulfilled, (state, action) => {
        state.details = Object.assign({}, action.payload.data);
        if (!state.details.birthdate) {
          state.details.birthdate = "";
        }
        state.load = true;
        state.isLoading = false;
      })
      .addCase(getInitProfileFields.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProfileFields.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProfileFields.fulfilled, (state, action) => {
        state.details = Object.assign({}, action.payload.data);
        if (!state.details.birthdate) {
          state.details.birthdate = "";
        }
        state.requests = action.payload.data.requests;
        state.load = true;
        state.isLoading = false;
      })
      .addCase(getProfileFields.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReferencebookCtitzenship.fulfilled, (state, action) => {
        state.referencebook.citizenship = action.payload;
        if (!state.referencebook.citizenship.length) {
          state.referencebook.citizenship = [];
        }
      })
      .addCase(getReferencebookCtitzenship.rejected, (state, action) => {
        state.referencebook.citizenship = [];
      })
      .addCase(getReferencebookWorkplace.fulfilled, (state, action) => {
        state.referencebook.workplace = action.payload ?? [];
        if (!state.referencebook.workplace.length) {
          state.referencebook.workplace = [];
        }
      })
      .addCase(getReferencebookWorkplace.rejected, (state, action) => {
        state.referencebook.workplace = [];
      })
      .addCase(putProfileFields.pending, (state) => {
        state.isLoadingPutProfile = true;
      })
      .addCase(putProfileFields.fulfilled, (state) => {
        state.isLoadingPutProfile = false;
      })
      .addCase(putProfileFields.rejected, (state, { payload }) => {
        state.isLoadingPutProfile = false;
        if (payload?.status === 400 && Array.isArray(payload?.details)) {
          state.filesErrors = [];
          payload.details.forEach((item) => {
            state.filesErrors.push({
              name: item.file,
              error: item.errors?.[0],
            });
          });
        }
      })
      .addCase(getProfileFiles.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.files = payload.data;
        }
        state.filesLoad = true;
      })
      .addCase(getProfileFiles.rejected, (state, action) => {})

      .addCase(putProfileFile.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.files.push(payload?.data);
        }
      })
      .addCase(putProfileFile.rejected, (state, { payload }) => {
        state.filesErrors = [];
        if (payload?.status === 400) {
          state.filesErrors.push({
            name: payload?.fileName ? payload?.fileName : "",
            error: payload?.details?.error_codes?.[0],
          });
        }
      })
      .addCase(deleteProfileFile.fulfilled, (state, action) => {
        const currentState = current(state.files);
        if (action?.payload?.data?.id) {
          state.files = currentState.filter((item) => {
            return item.id !== parseInt(action?.payload?.data?.id);
          });
        }
      })
      .addCase(deleteProfileFile.rejected, (state, action) => {})
      .addCase(getProfileAvatar.fulfilled, (state, action) => {
        if (!action.payload.data?.contentType) {
          state.avatar.type = "preset";
          state.avatar.src = action.payload.data?.name ? action.payload.data?.name : "12";
        } else {
          state.avatar.type = "image";
          state.avatar.src = action.payload.data?.src;
        }
      })
      .addCase(getProfileAvatar.rejected, (state, action) => {
        state.avatar.type = "preset";
        state.avatar.src = "12";
      })
      .addCase(getProfileCoursesHistory.pending, (state) => {
        state.coursesHistoryLoading = true;
      })
      .addCase(getProfileCoursesHistory.fulfilled, (state, action) => {
        if (action?.payload?.data) {
          state.coursesHistory = action.payload.data ?? { results: [], start_ts: 0 };
        }
        state.coursesHistoryLoading = false;
      })
      .addCase(getProfileCoursesHistory.rejected, (state) => {
        state.coursesHistoryLoading = false;
      })
      .addCase(getBadges.fulfilled, (state, action: PayloadAction<IBadgesResponse>) => {
        state.achievementData = action.payload || {};
      })
      .addCase(
        getProfileCompetence.fulfilled,
        (state, action: PayloadAction<СompetenciesTypeData>) => {
          state.competenceData = action.payload;
        }
      )
      .addCase(getRatingLevels.pending, (state) => {
        state.isLoadingMyLevels = true;
      })
      .addCase(getRatingLevels.fulfilled, (state, action: PayloadAction<IRatingLevelsResponse>) => {
        state.myLevel = action.payload?.data || {};
        state.isLoadingMyLevels = false;
      })
      .addCase(getRatingLevels.rejected, (state) => {
        state.isLoadingMyLevels = false;
      });
  },
});

export const { runPersonalAgreement, clearFilesError } = profileSlice.actions;
export {
  deleteProfileFile,
  getBadges,
  getInitProfileFields,
  getProfileAvatar,
  getProfileCompetence,
  getProfileCoursesHistory,
  getProfileFields,
  getRatingLevels,
  getReferencebookWorkplace,
  putProfileAgreement,
  putProfileFields,
  putProfileFile,
  putRevokePersonalAgreement,
};
export default profileSlice.reducer;
