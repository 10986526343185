import { useState, forwardRef, useCallback, useEffect } from "react";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField as MuiTextField } from "@mui/material";
import { SettingsChunk } from "../Buttons/InfoChunks";
import { validateRule } from "../../app/utils";
import { selectRegPasswordRules } from "../../features/auth/selectors";
import { useSelector } from "react-redux";

const passwordStrongRules = {
  length: {
    id: "length",
    title: "8 символов или более",
    isValide: false,
  },
  digits: {
    id: "digits",
    title: "цифры",
    isValide: false,
  },
  specialChars: {
    id: "specialChars",
    title: "знаки !@#$%^&*",
    isValide: false,
  },
  upperCase: {
    id: "upperCase",
    title: "заглавные буквы",
    isValide: false,
  },
};

const PasswordField = forwardRef(
  (
    {
      value,
      className,
      helperText = null,
      placeholder = null,
      error = false,
      name,
      type = "password",
      index,
      InputProps,
      strength,
      onChange,
      isSubmitted,
      ...rest
    },
    ref
  ) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isAutofilled, setIsAutofilled] = useState(false);

    const passwordRules = useSelector(selectRegPasswordRules);

    const checkPassStrength = (password, rules) => {
      const passRule = rules;
      if (rules.length) {
        for (const key in passRule) {
          passRule[key].isValide = validateRule(password, passRule[key].id, false, passwordRules);
        }
      }
    };

    const handleClickShowPassword = () => {
      setIsShowPassword((prevState) => !prevState);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const renderStrengthBar = (rules) => {
      if (!rules) return null;
      const tags = [];
      for (const key in rules) {
        tags.push(rules[key]);
      }

      return (
        <div className="settings-form-block-chunk">
          <ul className="settings-chunk-list chunk-list-authorization">
            {tags.map((item, index) => (
              <li className="settings-chunk-list__item" key={index}>
                <SettingsChunk
                  value={item.title}
                  classes={
                    item.isValide && value
                      ? "settings-chunk--active"
                      : isSubmitted
                      ? "settings-chunk--error"
                      : ""
                  }
                  isValid={item.isValide && value}
                  isSubmitted={isSubmitted}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    };

    const onChangeFromFormValidate = onChange;

    const setType = (type, show) => {
      if (type === "password" && !show) {
        return "password";
      }
      return "text";
    };

    const getInputProps = () => {
      return {
        ...InputProps,
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <div>
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseDown={handleMouseDownPassword}
                  onClick={handleClickShowPassword}
                >
                  {isShowPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </div>
            </InputAdornment>
          ) : (
            InputProps?.endAdornment ?? null
          ),
      };
    };

    const handleAutofill = useCallback((event) => {
      if (event.animationName === "mui-auto-fill") {
        setIsAutofilled(true);
      }
    }, []);

    useEffect(() => {
      setIsAutofilled(false);
    }, [value]);

    return (
      <>
        <MuiTextField
          {...rest}
          value={value}
          type={setType(name, isShowPassword)}
          name={name}
          ref={ref}
          className={`field textfield ${window.chrome ? "chrome" : ""} ${className ?? ""}`}
          data-empty={!value && !isAutofilled}
          error={!!error}
          helperText={error ? error?.message : helperText}
          placeholder={placeholder}
          onChange={(event) => {
            checkPassStrength(event.target.value, passwordStrongRules);
            if (typeof onChangeFromFormValidate === "function") {
              onChangeFromFormValidate.call(this, event);
            }
          }}
          InputProps={getInputProps()}
          onAnimationStart={handleAutofill}
        />
        {strength ? renderStrengthBar(passwordStrongRules) : null}
      </>
    );
  }
);

export default PasswordField;
