import { RootState } from "../../../app/store";

export const selectCorsesListisLoaded = (state: RootState) => state.courses.courses.isLoaded;
export const selectCorsesListCategories = (state: RootState) => state.courses.categories.entities;
export const selectCoursesListInputVal = (state: RootState) => state.courses.search.input;
export const selectCoursesListInputValPopup = (state: RootState) => state.courses.search.inputPopup;
export const selectCoursesListCategory = (state: RootState) => state.courses.search.categories;
export const selectCoursesListFormats = (state: RootState) => state.courses.search.format;
export const selectCoursesSearch = (state: RootState) => state.courses.search;
export const selectCoursesListIsClerable = (state: RootState) => state.courses.search.isClerable;
export const selectMissGetCourse = (state: RootState) => state.courses.search.missGetCourses;
export const selectDurations = (state: RootState) => state.courses.durations;
export const selectFormats = (state: RootState) => state.courses.formats;
export const selectStatuses = (state: RootState) => state.courses.statuses;
export const selectPaging = (state: RootState) => state.courses.paging;
