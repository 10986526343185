import { format, fromUnixTime, parseISO } from "date-fns";

export const dateISOToFormat = (dt: string, withHours = false): string => {
  if (!dt) return "";
  let template = "dd.MM.yyyy";
  if (withHours) template += " HH:mm";
  return format(parseISO(dt), template);
};

export const dateJSToFormat = (date: Date): string => {
  if (!date) return "";
  return format(date, "dd.MM.yyyy");
};

export const dateTsToFormat = (date_ts: number, withHours = false): string => {
  if (!date_ts) return "";
  let template = "dd.MM.yyyy";

  if (withHours) template += " HH:mm";

  return format(fromUnixTime(date_ts), template);
};
