import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import {
  selectProfileDetails,
  selectProfileFiles,
} from "../../../Profile/redux/profile-slice/selectors/profile.selectors";
import { clearCourseData, coursesSelectors, getCourseInfo } from "../../redux/courseSlice";
import CourseLoadSkeleton from "../CourseLoadSkeleton";

import { EntityId } from "@reduxjs/toolkit/src/entities/models";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import CourseError from "../Task/CourseError";

const CourseContentWrapShared = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { course_id, lesson_id } = useParams();
  const currentCourse = useAppSelector((state) =>
    coursesSelectors.selectById(state, course_id as EntityId)
  );
  const [isLoadCourseData, setIsLoadCourseData] = useState<boolean>(false);
  const [isCourseLoadError, setIsCourseLoadError] = useState<boolean>(false);
  const profile = useAppSelector(selectProfileDetails);
  const educationDocs = useAppSelector(selectProfileFiles);
  const courseDispatchRef = useRef(null);
  const [loadedCourseId, setLoadedCourseId] = useState<string>("");

  useEffect(() => {
    return () => {
      dispatch(clearCourseData());
      if (courseDispatchRef.current) {
        // @ts-ignore
        courseDispatchRef.current.abort();
      }
      setIsLoadCourseData(false);
    };
  }, [dispatch]);

  useEffect(() => {
    if (course_id && (loadedCourseId !== course_id || !currentCourse) && !isLoadCourseData) {
      setLoadedCourseId(course_id);
      setIsLoadCourseData(true);
      // @ts-ignore
      courseDispatchRef.current = dispatch(getCourseInfo(course_id));
      // @ts-ignore
      courseDispatchRef.current.then(({ payload }) => {
        if (payload?.status === 404) {
          navigate(`${WebAppRoutes.AUTH}`, { replace: true });
          return;
        }

        // @ts-ignore
        if (payload?.status === 403) {
          navigate("/403", { replace: true });
          return;
        }

        if (payload) {
          const course = payload?.course;
          const courseContent = payload?.courseContent?.length > 0 && payload?.courseContent;
          const contentLessons =
            courseContent && courseContent[0].lessons?.length > 0 && courseContent[0].lessons;
          const multicourseContent =
            payload?.multicourseContent?.length > 0 && payload?.multicourseContent;

          if (!course?.enrolled_ts) {
            setIsCourseLoadError(true);
          }

          if (!lesson_id && !pathname.includes("report") && !pathname.includes("result")) {
            const redirectUrl = !course?.enrolled_ts
              ? `${WebAppRoutes.COURSE_PREVIEW}/${course_id}`
              : course.format === CourseFormat.multicourse && multicourseContent
              ? `${WebAppRoutes.COURSE}/${course_id}${WebAppRoutes.CONTENT}/${multicourseContent[0].id}`
              : `${WebAppRoutes.COURSE}/${course_id}${WebAppRoutes.LESSON}/${contentLessons[0]?.id}`;

            navigate(redirectUrl, { replace: true });
          }
        }
        setIsLoadCourseData(false);
      });
    }
  }, [
    dispatch,
    navigate,
    currentCourse,
    isLoadCourseData,
    course_id,
    educationDocs,
    profile,
    lesson_id,
    pathname,
    loadedCourseId,
  ]);

  if (isCourseLoadError) {
    return <CourseError />;
  }
  return !isLoadCourseData ? <Outlet /> : <CourseLoadSkeleton />;
};
export default CourseContentWrapShared;
