import { useEventListener, useSetState, useSize } from "ahooks";
import { Portal } from "components/Portal";
import { useRef, useEffect, MutableRefObject, CSSProperties, useCallback } from "react";
import { usePositionDropdown } from "./hooks";
import { PositionDropdownType } from "./types";

interface DropdownProps {
  children: string | JSX.Element;
  elementRef: MutableRefObject<HTMLElement | null>;
  className?: string;
  position?: PositionDropdownType;
  onCloseDropDown?: () => void;
}

export const Dropdown = ({
  children = "",
  elementRef,
  className = "",
  position = "bottom-left",
  onCloseDropDown = () => {},
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [stylesDropdown, setStylesDropdown] = useSetState<CSSProperties>({
    position: "absolute",
    display: "none",
    zIndex: 3,
  });
  const getPositionDropdown = usePositionDropdown(elementRef);

  const size = useSize(elementRef);

  useEventListener(
    "click",
    (event: Event) => {
      if (
        !dropdownRef.current?.contains(event.target as Node) &&
        !elementRef.current?.contains(event.target as Node)
      ) {
        onCloseDropDown();
      }
    },
    { capture: true }
  );

  const getStylesForDropdown = useCallback(() => {
    const { width } = size ?? { width: 0 };
    return getPositionDropdown({
      position,
      elementWidth: width,
    });
  }, [getPositionDropdown, position, size]);

  const changeDropdownStyles = useCallback(() => {
    const dropdownPositionParams = getStylesForDropdown();
    const stylesDropDown = { ...dropdownPositionParams, display: "block" };
    const { width } = elementRef.current?.getBoundingClientRect() ?? { width: 0 };
    setStylesDropdown({ ...stylesDropDown, maxWidth: width });
  }, [elementRef, getStylesForDropdown, setStylesDropdown]);

  useEventListener("resize", () => changeDropdownStyles());

  useEffect(() => changeDropdownStyles(), [changeDropdownStyles]);

  return (
    <Portal renderingElement={elementRef.current}>
      <div ref={dropdownRef} style={stylesDropdown} className={className}>
        {children}
      </div>
    </Portal>
  );
};
