import { TextMessages } from "app/messages";
import { AuthTypes, UseAuthForm } from "../authRoutes.congig";
import { AuthData } from "./data.types";
import { WebAppRoutes } from "app/routes";
import { putProfileAgreement } from "pages/Profile/redux/profile-slice/profile.slice";

export const authAgreement = (dep: any): AuthData => {
  const { nav, dispatch } = dep;

  return {
    type: AuthTypes.AUTH_AGREEMENT,
    titles: {
      main: TextMessages.CONFIRM,
    },
    toggles: {
      top: {
        isReg: true,
        title: TextMessages.ACCOUNT_TO_CREATE,
        action: () => {
          nav(WebAppRoutes.REGISTRATION, { is_allowed: true });
        },
      },
    },
    form: {
      info: TextMessages.SERTIFICAT_HELP,
      submit: async (formData: UseAuthForm) => {
        if (formData.policy_agreed) {
          const result = dispatch(putProfileAgreement());
          result.then((result: any) => {
            if (result?.payload?.status === 200) {
              nav(WebAppRoutes.HOME);
            }
          });
        }
      },
      actions: {
        buttons: {
          primary: {
            title: TextMessages.CONTINUE_BUTTON,
            action: () => {},
          },
        },
      },
    },
  };
};
