import { useEffect, useState } from "react";
import CourseLoadSkeleton from "../CourseLoadSkeleton";

import { getTask, getTaskResult, setTaskIdle } from "./redux/taskSlice";
import TaskStart from "./TaskStart";
import TaskQuestions from "./TaskQuestions";
import { selectTaskStatus } from "./redux/taskSelectors";
import { CourseLessonTask } from "../../redux/interfaces/course.interface";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

const TaskIndex = ({ lesson }: { lesson: CourseLessonTask }) => {
  const taskStatus = useAppSelector(selectTaskStatus);
  const dispatch = useAppDispatch();
  const [isLoadTaskData, setIsLoadTaskData] = useState(false);

  useEffect(() => {
    dispatch(getTask(lesson?.id)).then(() => {
      dispatch(getTaskResult(lesson.id));
      setIsLoadTaskData(true);
    });
    return () => {
      dispatch(setTaskIdle());
      setIsLoadTaskData(false);
    };
  }, [dispatch, lesson?.id, setIsLoadTaskData]);

  const renderTask = (lesson: CourseLessonTask, taskStatus: string) => {
    switch (taskStatus) {
      case "idle":
        return <TaskStart lesson={lesson} />;
      case "started":
        return <TaskQuestions lesson={lesson} />;
      case "complete":
        return <TaskStart lesson={lesson} />;
      default:
        return <CourseLoadSkeleton />;
    }
  };

  return <>{isLoadTaskData ? renderTask(lesson, taskStatus) : <CourseLoadSkeleton />}</>;
};

export default TaskIndex;
