import { IShopProductFull } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";

export interface OrdersSlice {
  orders: IOrder[];
  currentProduct?: IShopProductFull;
  productDetailsLoading: boolean;
  ordersIsLoading: boolean;
}

export interface IOrder {
  id: string;
  number: string;
  product_id: string;

  name: string;
  created: string;
  price: number;
  customer: string;
  status: OrderStatus;
  picture_id: string;
}

export enum OrderStatus {
  New = "New",
  Confirmed = "Confirmed",
  Cancelled = "Cancelled",
  Done = "Done",
}

export interface IOrdersRequest {
  number?: string;
  productId?: string[];
  customer?: string;
  customerId?: string;
  orderMinPrice?: number;
  orderMaxPrice?: number;
  orderEarlyDate?: string;
  orderLaterDate?: string;
  status?: OrderStatus;
}
