import { H3 } from "../../../../../../../../components/Typography/Titles";
import { allAnswersSelector } from "../../../../redux/Quze/courseQuizeSelectors";
import { useSelector } from "react-redux";
import { QuestResultAlert } from "./QuestResultAlert";
import { IQuizeQuestionProp } from "../../../../redux/Quze/interface/quiz.interface";
import { QuestDescription } from "../QuestDescription/QuestDescription";

export const QuestTextResult = ({
  title,
  description,
  questionsId,
  right,
  wrong,
}: IQuizeQuestionProp) => {
  const allAnswers = useSelector(allAnswersSelector);

  const findedAnwer: any = allAnswers.find((answer: any) => answer.id === questionsId);

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />
      <div className="course-questions-block-wrapper">
        <ul className="course-questions-block-list">
          <li className="course-questions-block-list__item course-questions-block-list__item--text">
            <span className={"course-questions-bubble__item course-questions-bubble__item--text"}>
              {findedAnwer ? findedAnwer.answer : null}
            </span>
          </li>
        </ul>
      </div>
      <QuestResultAlert wrong={wrong} right={right} />
    </div>
  );
};
