import { App, AppState } from "@capacitor/app";
import { useAsyncEffect } from "ahooks";
import { EndPoints } from "app/endpoints";
import { differenceInSeconds, isSameDay } from "date-fns";
import { selectAuthOIDC } from "features/auth/selectors";
import { useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import api from "services/api/api";

export const AppTimeTracker = () => {
  const authOIDC = useAppSelector(selectAuthOIDC);
  const [totalTimeSpentInSeconds, setTotalTimeSpentInSeconds] = useState(0);

  useAsyncEffect(async () => {
    if (authOIDC?.idToken) {
      const lastTotalTimeSpentInSeconds = localStorage.getItem("lastTotalTimeSpentInSeconds");
      if (lastTotalTimeSpentInSeconds) {
        setTotalTimeSpentInSeconds(+lastTotalTimeSpentInSeconds);
      }
      const currentState = await App.getState();

      if (currentState.isActive) {
        const currentTime = new Date().getTime();
        localStorage.setItem("appStartTime", currentTime.toString());
      }

      const handleAppStateChange = (state: AppState) => {
        if (state.isActive) {
          const currentTime = new Date().getTime();
          localStorage.setItem("appStartTime", currentTime.toString());
        } else {
          const startTime = localStorage.getItem("appStartTime");
          const endTime = new Date().getTime();
          if (startTime) {
            const timeSpentInSeconds = differenceInSeconds(endTime, parseInt(startTime, 10));

            if (isSameDay(new Date(), new Date(parseInt(startTime, 10)))) {
              setTotalTimeSpentInSeconds((prevTotalTime) => {
                localStorage.setItem(
                  "lastTotalTimeSpentInSeconds",
                  JSON.stringify(prevTotalTime + timeSpentInSeconds)
                );
                return prevTotalTime + timeSpentInSeconds;
              });
            } else {
              localStorage.setItem(
                "lastTotalTimeSpentInSeconds",
                JSON.stringify(timeSpentInSeconds)
              );
              setTotalTimeSpentInSeconds(timeSpentInSeconds);
            }
          }
        }
      };

      App.addListener("appStateChange", handleAppStateChange);

      return;
    }
  }, [authOIDC?.idToken]);

  useEffect(() => {
    if (!authOIDC?.idToken) {
      App.removeAllListeners();
    }
  }, [authOIDC?.idToken]);

  useAsyncEffect(async () => {
    if (authOIDC?.idToken) {
      await api.post(EndPoints.USER_SAVE_ACTIVE_TIME, {
        date_ts: new Date().getTime(),
        studyingtime: totalTimeSpentInSeconds,
      });
    }
  }, [totalTimeSpentInSeconds]);
};
