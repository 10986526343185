import { WebAppRoutes } from "app/routes";

export interface UseAuthForm {
  code?: string;
  mail?: string;
  login?: string;
  name?: string;
  secondname?: string;
  department?: { label: string; value: number };
  position?: string;
  password?: string;
  policy_agreed?: boolean;
}

interface AuthRouteConfig {
  path: WebAppRoutes;
  contextInitial: AuthTypes;
}

export enum AuthTypes {
  // Авторизация
  AUTH = "sign-in",
  AUTH_EXIST = "auth-exist",

  // Восстановление пароля
  PASSWORD_RECOVERY = "password-recovery",

  // Регистрация
  REGISTRATION = "registration",
  REGISTRATION_CODE_CHECK = "registration-code-check",
  REGISTRATION_CODE_CHECK_ERROR = "registration-code-check-error",
  REGISTRATION_EMAIL_BLOCKED = "registration-email-blocked",
  REGISTRATION_SECOND_STEP = "second-step",
  REGISTERED = "registered",
  AUTH_AGREEMENT = "auth-agreement",
  // Вход по временному коду
  ACCESS_RECOVERY = "access-recovery",
  ACCESS_RECOVERY_CODE_CHECK = "access-recovery-code-check",
  // Домен забанен
  BANNED_DOMEN = "banned-domen",

  ACCOUNT_EXISTS = "account-exists",
}

export const authRoutesConfig: AuthRouteConfig[] = [
  {
    path: WebAppRoutes.AUTH,
    contextInitial: AuthTypes.AUTH,
  },
  {
    path: WebAppRoutes.AUTH_EXIST,
    contextInitial: AuthTypes.AUTH_EXIST,
  },
  {
    path: WebAppRoutes.PASSWORD_RECOVERY,
    contextInitial: AuthTypes.PASSWORD_RECOVERY,
  },
  {
    path: WebAppRoutes.REGISTRATION,
    contextInitial: AuthTypes.REGISTRATION,
  },
  {
    path: WebAppRoutes.REGISTRATION_CODE_CHECK,
    contextInitial: AuthTypes.REGISTRATION_CODE_CHECK,
  },
  {
    path: WebAppRoutes.REGISTERED,
    contextInitial: AuthTypes.REGISTERED,
  },

  // // Вход по временному коду
  // {
  //   path: WebAppRoutes.ACCESS_RECOVERY,
  //   contextInitial: AuthTypes.ACCESS_RECOVERY,
  // },
  // {
  //   path: WebAppRoutes.ACCESS_RECOVERY_CODE_CHECK,
  //   contextInitial: AuthTypes.ACCESS_RECOVERY_CODE_CHECK,
  // },

  {
    path: WebAppRoutes.REGISTRATION_CODE_CHECK_ERROR,
    contextInitial: AuthTypes.REGISTRATION_CODE_CHECK_ERROR,
  },
  {
    path: WebAppRoutes.REGISTRATION_EMAIL_BLOCKED,
    contextInitial: AuthTypes.REGISTRATION_EMAIL_BLOCKED,
  },
  {
    path: WebAppRoutes.BANNED_DOMEN,
    contextInitial: AuthTypes.BANNED_DOMEN,
  },
  {
    path: WebAppRoutes.REGISTRATION_SECOND_STEP,
    contextInitial: AuthTypes.REGISTRATION_SECOND_STEP,
  },
  {
    path: WebAppRoutes.AUTH_AGREEMENT,
    contextInitial: AuthTypes.AUTH_AGREEMENT,
  },
  {
    path: WebAppRoutes.ACCOUNT_EXISTS,
    contextInitial: AuthTypes.ACCOUNT_EXISTS,
  },
];
