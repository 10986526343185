import { generateUid } from "app/utils";
import { TestResultDownloadIcon } from "../../../../../components/Svg/Icons";
import { TextMain } from "../../../../../components/Typography/Texts";
import { Capacitor } from "@capacitor/core";

const TaskFileList = ({ files }: { files: File[] }) => {
  const showPreviewFile = (fileName: string, filePath: string, contentType: string) => () => {
    (window as any).PreviewAnyFile.previewPath(
      //@ts-ignore
      (win) => console.log("open status", win),
      //@ts-ignore
      (error) => console.error("open failed", error),
      filePath,
      {
        name: fileName,
        mimeType: contentType,
      }
    );
  };

  return (
    <div className="task-infoblock">
      <div className="task-docblock">
        {files.map((file) => (
          <div className="task-docblock-item" key={generateUid()}>
            <div className="task-docblock-item__pic">
              <TestResultDownloadIcon />
            </div>
            <TextMain className="task-docblock-item__link">
              {Capacitor.isNativePlatform() && (
                <button
                  className={"preview-file-button"}
                  onClick={showPreviewFile(
                    /*@ts-ignore*/
                    file?.name || "",
                    /*@ts-ignore*/
                    file?.src || "",
                    /*@ts-ignore*/
                    file?.contentType || ""
                  )}
                >
                  {file?.name}
                </button>
              )}
              {!Capacitor.isNativePlatform() && (
                <a
                  /*@ts-ignore*/
                  href={file?.src}
                  className={"text-button"}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  {file?.name}
                </a>
              )}
            </TextMain>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskFileList;
