import { numWord } from "app/utils";
import { RulesCard } from "components/RulesCard";
import { format } from "date-fns";
import { CourseFormat, CourseSummary } from "pages/Course/redux/interfaces/course.interface";
import styles from "./RulesList.module.scss";

export interface Rule {
  type: string;
  title: string;
  description: string;
}

interface RulesListProps {
  summary: CourseSummary;
  courseFormat?: CourseFormat;
}

export const RulesList = ({ summary, courseFormat }: RulesListProps): JSX.Element => {
  const {
    time_limit,
    attempts,
    questions,
    passing_score,
    finish_ts,
    camera,
    microphone,
    commenting,
    record,
  } = summary;

  const timeLimit = time_limit && Math.floor(time_limit / 60);
  const finishTs = format(new Date((finish_ts || 0) * 1000), "dd.MM.yyy");

  const questionsWords = numWord(questions, ["вопрос", "вопроса", "вопросов"]);
  const attemptsWords = numWord(attempts, ["попытка", "попытки", "попыток"]);
  const timeLimitWords = numWord(timeLimit, ["минута", "минуты", "минут"]);
  const zoomDescription = `Для участия в вебинаре вам понадобятся ${microphone ? "микрофон" : ""}${
    microphone && camera ? " и " : ""
  }${camera ? "камера" : ""}.`;

  const content = {
    time_limit: {
      type: "time_limit",
      title: "Ограниченное время",
      description: `На тестирование отведено только ${timeLimit} ${timeLimitWords}. Но у вас будет ${attempts} ${attemptsWords}, из которых учитывается лучший результат.`,
    },
    questions: {
      type: "questions",
      title: "Сколько вопросов",
      description:
        courseFormat === CourseFormat.quiz
          ? `В тестировании будет ${questions} ${questionsWords}. ${
              passing_score
                ? "Для успешного завершения вам нужно правильно ответить на " +
                  passing_score +
                  " вопроса."
                : ""
            }`
          : `В анкете будет ${questions} ${questionsWords}. Вы сможете отвечать на них в свободном порядке. Если какой-то вопрос вызовет сложности, то вы можете его пропустить.`,
    },
    finish_ts: {
      type: "finish_ts",
      title: "Есть дедлайн",
      description:
        courseFormat === CourseFormat.quiz
          ? `Участвовать в тестировании можно до ${finishTs} включительно. Не набравшие проходной балл будут автоматически отчислены.`
          : `Выполнить задание можно до ${finishTs} включительно. Не набравшие проходной балл будут автоматически отчислены.`,
    },
    zoom: {
      type: "zoom",
      title: "Оборудование",
      description: zoomDescription,
    },
    record: {
      type: "record",
      title: "Запись вебинара",
      description: "Будет доступна только зарегистрированным участникам.",
    },
    commenting: {
      type: "commenting",
      title: "Обратная связь",
      description:
        "После выполнения задания вы получите обратную связь от преподавателя с разбором ошибок и рекомендациями.",
    },
  };

  return (
    <>
      {(!!timeLimit && !!attempts) ||
      !!questions ||
      !!finish_ts ||
      camera ||
      microphone ||
      record ||
      commenting ? (
        <div className={styles["list"]}>
          {!!timeLimit && !!attempts && <RulesCard rule={content.time_limit} />}
          {!!questions && <RulesCard rule={content.questions} />}
          {!!finish_ts && <RulesCard rule={content.finish_ts} />}
          {(camera || microphone) && <RulesCard rule={content.zoom} />}
          {record && <RulesCard rule={content.record} />}
          {commenting && <RulesCard rule={content.commenting} />}
        </div>
      ) : null}
    </>
  );
};
