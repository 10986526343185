import { useSelector } from "react-redux";
import { selectProfileAvatar } from "../redux/profile-slice/selectors/profile.selectors";
import { Avatar } from "../../../components/Avatar";

const ProfileAvatar = () => {
  const profileAvatar = useSelector(selectProfileAvatar);
  return <Avatar src={profileAvatar.src} type={profileAvatar.type} />;
};

export default ProfileAvatar;
