import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../../../../services/api/api";
import { EndPoints } from "../../../../../../app/endpoints";

const initialState = {
  isLoading: false,
  questionnaire: {},
  questionnaire_state: "idle",
};

const getCourseQuestionnaireContent = createAsyncThunk(
  "course/getCourseQuestionnaireContent",
  async (lesson_id: number, thunkAPI) => {
    const response = await api.get(EndPoints.COURSE_LESSONS(lesson_id));
    return response.data;
  }
);

const postCourseQuestionnaireAnswers = createAsyncThunk<
  any,
  { questionnaireId: number; answers: any[] }
>("course/postCourseQuestionnaireAnswers", async ({ questionnaireId, answers }, thunkAPI) => {
  const response = await api.post(EndPoints.COURSE_QUESTIONNARE_ANSWER(questionnaireId), answers);
  return response.data;
});

const courseQuestionarySlice = createSlice({
  name: "courseQuestionary",
  initialState,
  reducers: {
    // @ts-ignore
    resetValidateForQuestions(state) {
      // @ts-ignore
      const questionsWithResetValidation = state.questionnaire.questions.map((question) => ({
        ...question,
        valid: true,
      }));
      return { ...state, questionnaire: { questions: questionsWithResetValidation } };
    },
    setQuestionaryIdle(state) {
      state.questionnaire_state = "idle";
    },
    setQuestionaryStarted(state) {
      state.questionnaire_state = "started";
    },
    setQuestionaryComplite(state) {
      state.questionnaire_state = "complete";
    },
    setQuestionnaireQuestions(state, { payload }) {
      //@ts-ignore
      state.questionnaire.questions = payload.questions;
    },
    setQuestionnaireQuestion(state, { payload }) {
      //@ts-ignore
      state.questionnaire.questions = state.questionnaire.questions.map((question) => {
        if (question.id === payload.question.id) {
          return payload.question;
        }
        return question;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseQuestionnaireContent.pending, (state, action) => {
        state.isLoading = true;
        state.questionnaire_state = "idle";
      })
      .addCase(getCourseQuestionnaireContent.fulfilled, (state, action) => {
        const questions: any[] = action?.payload?.data?.questions || [];
        if (questions.length > 0) {
          const questionsWithValidFlag = questions.map((question) => ({
            ...question,
            valid: true,
          }));
          state.questionnaire = { ...action?.payload?.data, questions: questionsWithValidFlag };
        } else {
          state.questionnaire = action?.payload?.data ?? {};
        }
        if (action?.payload?.data?.status === "completed") {
          state.questionnaire_state = "complete";
        } else {
          state.questionnaire_state = "idle";
        }
      })
      .addCase(getCourseQuestionnaireContent.rejected, (state, action) => {
        state.isLoading = true;
        state.questionnaire_state = "idle";
      })
      .addCase(postCourseQuestionnaireAnswers.pending, (state, action) => {})
      .addCase(postCourseQuestionnaireAnswers.fulfilled, (state, action) => {
        state.questionnaire_state = "complete";
      })
      .addCase(postCourseQuestionnaireAnswers.rejected, (state, action) => {});
  },
});

export const {
  setQuestionaryIdle,
  setQuestionaryStarted,
  setQuestionaryComplite,
  setQuestionnaireQuestions,
  setQuestionnaireQuestion,
  resetValidateForQuestions,
} = courseQuestionarySlice.actions;

export { getCourseQuestionnaireContent, postCourseQuestionnaireAnswers };
export default courseQuestionarySlice.reducer;
