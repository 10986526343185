import logo from "../../../assets/img/rostechnadzor-full-logo.png";
import styles from "./RostechnadzorFullLogo.module.scss";

export const RostechnadzorFullLogo = () => (
  <div className={styles.container}>
    <div>
      <img src={logo} alt="rostechnadzor-logo" className={styles.logo} />
    </div>
    <div>
      <p className={styles.text}>Ростехнадзор</p>
    </div>
  </div>
);
