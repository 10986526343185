import { motion } from "framer-motion";
import styles from "../Accordion.module.scss";
import { AccordionRowProps } from "../Accordion.types";

export const AccordionRow = ({ value }: AccordionRowProps): JSX.Element => {
  return (
    <div>
      <motion.header initial={false} className={styles["header"]}>
        <div className={styles["title"]}>{value.title}</div>
        {value.children}
      </motion.header>
    </div>
  );
};
