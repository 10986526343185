import { SVGProps } from "react";

export const TrainingRostechnadzorIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={638}
    height={520}
    fill="none"
    viewBox="0 0 638 520"
    {...props}
  >
    <rect
      width={424.305}
      height={424.305}
      x={94.818}
      y={-15}
      fill="#ECF2F7"
      rx={60}
      transform="rotate(15 94.818 -15)"
    />
    <path
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M478.063 451.909c-77.318 32.801-113.633 55.059-135.892 63.259-22.258 8.201-44.516 0-77.317-14.057-32.802-14.058-161.98-94.248-188.22-110.281-21.088-12.887-20.999-29.07 0-41.002 51.542-29.287 149.249-57.799 155.418-66.612 8.201-11.715 16.4-206.18 32.802-210.867 16.402-4.686 258.898 93.719 270.612 113.634 11.715 19.915-19.914 221.41-38.658 227.267-2.422.757-4.569 1.142-6.461 1.237 4.017 12.394 4.274 30.22-12.284 37.422Z"
    />
    <path
      fill="#fff"
      d="M245.332 308.816c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.908c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M245.332 308.816c-16.401-2.343-55.058 16.1-69.118 24.609-23.429 14.18 41.002 52.718 60.917 64.432 43.345 25.497 85.518 55.051 114.805 51.546 13.958-1.671 72.626-33.973 63.254-44.517-9.372-10.543-153.458-93.727-169.858-96.07ZM219.553 452.908c14.058 7.029 49.202-11.543 36.316-21.078-14.65-10.839-44.516-30.458-55.059-33.973-10.543-3.514-41.663 11.861-32.801 22.259 8.987 10.543 37.486 25.763 51.544 32.792ZM467.908 433.002c-15.23 10.543-123.006 59.745-135.892 63.26-12.886 3.514-28.115 0-49.202-8.201"
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M444.478 384.971c-23.429-8.2-176.249-93.123-190.307-98.98 0-4.686 18.165-186.96 25.193-186.96 7.029 0 230.717 90.303 231.889 103.19 1.171 12.886-19.915 130.034-26.944 154.635s-16.401 36.316-39.831 28.115Z"
    />
    <ellipse
      cx={318.87}
      cy={158.786}
      fill="#ECF2F7"
      rx={18.806}
      ry={19.573}
      transform="rotate(25.659 318.87 158.786)"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M310.243 149.653c-.431 7.096 1.604 13.534 6.184 12.311 4.58-1.222 18.347-8.104 19.203-9.087"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M329.405 146.425c-9.922-8.74-25.156-10.439-29.908 4.086-4.752 14.525 16.064 31.771 30.553 14.91"
    />
    <ellipse
      cx={310.427}
      cy={210.214}
      fill="#ECF2F7"
      rx={18.806}
      ry={19.573}
      transform="rotate(25.659 310.427 210.214)"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M301.8 201.081c-.431 7.096 1.604 13.533 6.184 12.311 4.58-1.222 18.346-8.104 19.202-9.087"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M320.962 197.853c-9.922-8.74-25.156-10.439-29.908 4.086-4.753 14.525 16.064 31.77 30.552 14.91"
    />
    <ellipse
      cx={301.427}
      cy={262.786}
      fill="#ECF2F7"
      rx={18.806}
      ry={19.573}
      transform="rotate(25.659 301.427 262.786)"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M292.799 253.653c-.431 7.096 1.604 13.534 6.184 12.311 4.58-1.222 18.347-8.104 19.203-9.087"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M311.961 250.425c-9.921-8.74-25.155-10.439-29.908 4.086-4.752 14.525 16.064 31.771 30.553 14.91"
    />
    <path
      fill="#fff"
      d="M381 157.999c-1.5-9.5-7.5-30 12-40.5s27.5-.5 31.5 1.5c0-7.5 0-21.5 14.5-25.5s26-.5 30 7c1.5-5 8-13.5 21-14s25.5 17.5 23.5 31c9 3 33 8.5 24 44.5l.067.018c7.489 1.997 16.933 4.515 16.933 18.482 0 4.501-2.499 12-2.499 12 12.5 0 27.999-1.499 32.999 24.001 2.809 14.33-9 21.5-21 24.999 4 2 19.5 15.501 16.5 23.501-5.139 13.702-13.573 15.143-19.871 15.123 19.292 1.09 22.115 9.354 25.371 22.377 3.5 14-12 57.5-68.5 25-6.001 5.001-18 24.5-48.5 31-16.686-36.947-39.526-62.795-58.5-96.001-12-21-33.63-63.999-36.5-69-5.696-9.764-14.782-35.5 7-35.5Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M458 227.5c29 7.5 60.5 21.5 65.897 59.79 8.683 61.601-60.899 76.21-82.397 76.21"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M387.15 225c-46.651-27.5-15.502-70.738 0-66.471 15.502 4.268-12.547 14.339-8.341-18.983 3.83-30.342 37.801-32.587 46.448-20.872-3.038-8.474-.516-22.77 17.828-26.772 18.344-4.002 32.735 10.519 25.262 10.915-6.884.364 6.761-17.375 20.884-16.315 14.124 1.06 28.426 16.876 24.228 32.126 47.335 5.678 19.403 62.907 16.422 46.098-1.107-6.244 36.364-2.717 20.531 29.129 28.602-15.603 57.102 40.192 11.158 47.764 26.801 7.378 24.418 39.018-.555 40.008-25.148.997 14.145-16.471 23.597 16.961 9.452 33.433-28.614 58.912-63.114 29.412"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M459.912 167.652c5.322 3.055 7.051 9.31 5.174 16.129-2.116-3.102-5.081-6.326-12.076-10.66M435.934 144.42c5.612 3.872 6.93 8.569 5.476 13.849-1.635-1.796-5.529-4.794-10.544-6.174M426.189 183.075c1.162 8.026 7.904 7.17 11.862 5.336-1.577 2.794-4.702 7.232-9.201 5.993-4.499-1.238-3.974-7.453-2.661-11.329ZM449.132 160.975c-4.311 5.814-8.77 9.222-13.326 9.627-4.322.383-3.421 9.024 5.057 7.162"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M445.14 126.822c-8.696 6.938-38.554 33.217-33.732 67.062 4.821 33.846 54.094 17.154 60.931 7.725 3.048 5.364 3.236 17.009 23.392 17.751 17.308.637 24.831-15.22 15.864-24.758-5.59-5.946-16.67-8.266-23.393-6.44-1.314-.362-5.467-5.991-6.306-13.047-1.415-11.912 14.487-39.765 21.596-32.229 6.129 6.497-8.429 13.187-23.846 12.664-10.696-.363-24.324-6.813-34.506-28.728Zm0 0c-.905-1.85-1.663-5.263-.939-7.891"
    />
    <ellipse
      cx={3.544}
      cy={3.446}
      fill="#0F1623"
      rx={3.544}
      ry={3.446}
      transform="scale(-1 1) rotate(-41.428 -1.686 699.02)"
    />
    <ellipse
      cx={3.544}
      cy={3.129}
      fill="#0F1623"
      rx={3.544}
      ry={3.129}
      transform="scale(-1 1) rotate(-41.428 -20.131 656.426)"
    />
    <path
      fill="#fff"
      d="M100.5 264c-9.5-5.5-12.5-43-17-57.5-1.395-5.5 25.449-9.5 35.499-9.5 18.5 0 30.001 1.5 31.501 6s-6 61-14.5 61-26 5.5-35.5 0Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M137.002 208.406c-11.885 5.415-40.65 6.617-53.002-5.494 22.076-11.887 53.847-5.079 62.729-3.958 8.882 1.121 4.896 21.27 0 42.028-5.551 23.535-7.772 28.018-27.201 28.018-19.429 0-21.766-6.215-28.311-28.018C85.665 222.489 84 216.003 84 216.003"
    />
    <path
      fill="#fff"
      d="M196.604 187.074c-31.556 4.979-51.696-12.832-59.585-16.816-22.011-11.115-12.966 29.569-12.966 33.742h-13.502c1.74-7.485 1.74-29.769 1.74-35.35-7.396 1.494-24.431 1.315-40.325-5.085C56.16 157.2 39.42 138.176 37 128.813c23.877-12.056 57.906 21.448 79.235 30.874 2.466-5.478-13.44-17.311-27.74-37.728-14.298-20.416-8.254-58.876-5.295-56.885 2.958 1.992 14.299 4.98 25.146 36.352 2.414 6.982 3.998 12.484 4.991 16.759 1.491-5.95 4.37-13.541 8.815-22.238 7.889-15.437 25.146-30.874 26.625-28.882 2.022 28.882-12.235 48.629-19.229 58.228-5.137 7.05-7.966 26.257 0 16.467 19.229-23.633 42.867-32.866 74.452-32.866-7.396 17.552-29.091 35.356-50.292 44.319-6.18 2.612-11.103 3.871-14.806 4.368 20.975 5.463 85.36 25.129 57.702 29.493Z"
    />
    <path
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M110.551 204c1.74-7.485 1.74-29.769 1.74-35.35-7.396 1.494-24.431 1.315-40.325-5.085C56.16 157.2 39.42 138.176 37 128.813c23.877-12.056 57.906 21.448 79.235 30.874 2.466-5.478-13.44-17.311-27.74-37.728-14.298-20.416-8.254-58.876-5.295-56.885 2.958 1.992 14.299 4.98 25.146 36.352 10.847 31.371 4.931 32.865 3.945 27.387-.987-5.477 1.972-17.429 9.861-32.866 7.889-15.437 25.146-30.874 26.625-28.882 2.022 28.882-12.235 48.629-19.229 58.228-5.137 7.05-7.966 26.257 0 16.467 19.229-23.633 42.867-32.866 74.452-32.866-7.396 17.552-29.091 35.356-50.292 44.319-21.202 8.963-27.612 1.992-20.709 2.988 6.903.996 95.161 25.894 63.605 30.873-31.556 4.979-51.696-12.832-59.585-16.816-22.011-11.115-12.966 29.569-12.966 33.742M91.673 258c-17.85 2.209-25.104 13.808-7.81 20.99 11.714 4.864 45.184 12.704 68.057.552 12.436-6.607 19.528-18.78-7.252-21.542M338 280c35 18.833 106.2 57 111 59"
    />
    <circle
      cx={391.5}
      cy={307.5}
      r={27.5}
      fill="#9CC7FF"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
    <path
      fill="#fff"
      stroke="#0F1623"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M391.358 292.981c-4.979 1.265-21.014 24.684-9.873 25.913 6.076.67 23.185 2.98 24.913-2.083 1.717-5.031-10.06-25.096-15.04-23.83Z"
    />
  </svg>
);
