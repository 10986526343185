import { TextField } from "components/TextField/TextField";
import { NUMBER_MASK_OPTS } from "masks";
import styles from "../RewardsSearch.module.scss";

interface PriceSearchProps {
  title: string;
  selectedPrice: string | undefined;
  onChange: (value: string) => void;
}

export const PriceSearch = ({ title, selectedPrice, onChange }: PriceSearchProps) => {
  return (
    <div className={styles["primary-control"]}>
      <TextField
        label={title}
        mask={NUMBER_MASK_OPTS}
        value={selectedPrice}
        onChange={onChange}
        showCleaner={!!selectedPrice}
      />
    </div>
  );
};
