import { Menu } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  LogoutSvgIcon,
  ScheduleHistoryIcon,
  SettingsSvgIcon,
  ShopSvgIcon,
  UserSvgIcon,
} from "../../../../components/Svg/Icons";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";

import { mediaQuerys } from "../../../../app/utils";
import { clear } from "../../../../features/auth/authSlice";

import { BalanceRow } from "components/BalanceRow";
import { CircleIconButton } from "components/Buttons/CircleIconButton";
import { mapTitleStringToNodeArray } from "components/utils/utils";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getProfileCoins } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import {
  selectProfileCoins,
  selectShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { WebAppRoutes } from "../../../../app/routes";
import { Skeleton } from "../../../../components/Skeleton";
import { KeyboardArrowDown, KeyboardArrowUp } from "../../../../components/Svg/Icons";
import { selectProfileName } from "../../../../pages/Profile/redux/profile-slice/selectors/profile.selectors";
import UserAvatar from "../../../components/UserAvatar";
import classNames from "classnames";

const UserWidget = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userName = useSelector(selectProfileName);
  const shopSettings = useAppSelector(selectShopSettings);
  const coins = useAppSelector(selectProfileCoins);
  const smMin = useMediaQuery(`(min-width:${mediaQuerys.smMin}px)`);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);
  const $widgetRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);
  const lgMin = useMediaQuery(`(min-width:${mediaQuerys.lgMin}px)`);

  const handleClick = () => {
    setAnchorEl($widgetRef.current);
    setActive(!active);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(clear());
    setAnchorEl(null);
    navigate(WebAppRoutes.HOME, { replace: true });
  };

  useEffect(() => {
    dispatch(getProfileCoins());
  }, [dispatch]);

  return (
    <>
      <div className="profile-widget" ref={$widgetRef}>
        <div
          className={classNames("profile-widget__name", {
            "profile-widget__name_rostechname": process.env.REACT_APP_THEME === "rostechnadzor",
          })}
        >
          {lgMin && (
            <>
              {userName ? (
                mapTitleStringToNodeArray(userName)
              ) : (
                <>
                  <Skeleton variant="text" width={"150px"} height={"20px"} />
                </>
              )}
            </>
          )}
          {smMin && !!shopSettings?.enable && (
            <>
              {coins ? (
                <BalanceRow type="profile" coins={coins.shop_coins} />
              ) : (
                <>
                  <Skeleton variant="text" width={"100px"} height={"25px"} />
                </>
              )}
            </>
          )}
        </div>
        <div className="profile-widget__avatar">
          <UserAvatar />
        </div>

        <CircleIconButton
          className="profile-widget__toggler"
          color="bordered"
          size="medium"
          onClick={handleClick}
          data-e2e={"user-menu-toogle"}
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </CircleIconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className={"profile-widget-menu"}
      >
        <Link
          to="/profile"
          className={"profile-widget-menu-item"}
          onClick={handleClose}
          data-e2e={"user-menu-profile"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <UserSvgIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>Профиль</span>
        </Link>
        {!!shopSettings?.enable && (
          <Link
            to="/orders"
            className={"profile-widget-menu-item"}
            onClick={handleClose}
            data-e2e={"user-menu-orders"}
          >
            <span className={"profile-widget-menu-item__icon"}>
              <ShopSvgIcon fontSize="small" />
            </span>
            <span className={"profile-widget-menu-item__text"}>Мои заказы</span>
          </Link>
        )}
        <Link
          to="/history"
          className={"profile-widget-menu-item"}
          onClick={handleClose}
          data-e2e={"user-menu-history"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <ScheduleHistoryIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>История</span>
        </Link>
        <Link
          to={WebAppRoutes.SETTINGS}
          className={"profile-widget-menu-item"}
          onClick={handleClose}
          data-e2e={"user-menu-setting"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <SettingsSvgIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>Настройки</span>
        </Link>
        <Link
          to={""}
          className={"profile-widget-menu-item"}
          onClick={handleLogout}
          data-e2e={"user-menu-exit"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <LogoutSvgIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>Выход</span>
        </Link>
      </Menu>
    </>
  );
};

UserWidget.propTypes = {};

export default UserWidget;
