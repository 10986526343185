import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import FieldSet, { FieldSetItem } from "../../../../components/Forms/FieldSet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TextField from "../../../../components/Forms/TextField";
import PasswordField from "../../../../components/Forms/PasswordField";
import {
  AuthDataFiled,
  AuthDataForm,
  UseAuthFormFieldNames,
} from "pages/Authorization/data/data.types";
import { Select } from "components/Select";
import { IconButton, InputAdornment } from "@mui/material";
import { WithAnimation } from "components/utils/utils";
import { CloseIcon } from "components/Icons";
import styles from "./AuthorizationFields.module.scss";

export interface AuthorizationFieldsProps {
  form?: AuthDataForm;
  control: any;
  isSubmitted: boolean;
}

export const AuthorizationFields = ({ form, control, isSubmitted }: AuthorizationFieldsProps) => {
  const profileRefBook = useSelector((state: any) => state.profile.referencebook);

  const fields = form?.fields;

  return (
    <TransitionGroup component={null}>
      <FieldSet
        className={classNames({
          [styles["fieldset-flex"]]: fields?.find(
            (field: AuthDataFiled) => field.name === UseAuthFormFieldNames.secondname
          ),
        })}
      >
        {!!fields?.length &&
          fields.length > 0 &&
          fields.map((fieldData: AuthDataFiled, index: number) => (
            <FieldSetItem key={index}>
              <CSSTransition timeout={200} classNames="fade">
                <div>
                  {control && (
                    <Controller
                      name={fieldData.name || ""}
                      control={control}
                      rules={fieldData.rules as any}
                      render={({ field: { onChange, ...otherFields }, fieldState: { error } }) =>
                        fieldData.type === "password" ? (
                          <PasswordField
                            key={fieldData.name}
                            {...fieldData}
                            onChange={(event: any) => {
                              if (typeof fieldData.onChange === "function") {
                                fieldData.onChange.call(this, event);
                              }
                              onChange.call(this, event);
                            }}
                            {...otherFields}
                            //@ts-ignore
                            strength={!!fieldData.strength}
                            error={error}
                            index={index}
                            errorMessage={error}
                            isSubmitted={isSubmitted}
                          />
                        ) : fieldData.name === UseAuthFormFieldNames.department ? (
                          (() => {
                            const workplaces = profileRefBook?.["workplace"] ?? [];
                            const options = workplaces.map(({ id, name }: any) => ({
                              label: name,
                              value: id,
                            }));

                            return (
                              <Select
                                showCleaner={true}
                                label={fieldData.label}
                                onChange={(event: any) => {
                                  if (typeof fieldData.onChange === "function") {
                                    fieldData.onChange.call(this, event);
                                  }
                                  onChange.call(this, event);
                                }}
                                options={options}
                                errorMessage={error}
                                dropdownClassName={styles["workplace-dropdown"]}
                                showUpButton={false}
                                {...otherFields}
                              />
                            );
                          })()
                        ) : (
                          <TextField
                            key={fieldData.name}
                            {...fieldData}
                            onChange={(event: any) => {
                              if (typeof fieldData.onChange === "function") {
                                fieldData.onChange.call(this, event);
                              }
                              onChange.call(this, event);
                            }}
                            {...otherFields}
                            //@ts-ignore
                            error={error}
                            index={index}
                            className={styles["text-field"]}
                            InputProps={{
                              endAdornment: (
                                <WithAnimation inCondition={!!otherFields.value}>
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        if (typeof fieldData.onChange === "function") {
                                          fieldData.onChange.call(this, "");
                                        }
                                        onChange.call(this, "");
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                </WithAnimation>
                              ),
                            }}
                          />
                        )
                      }
                    />
                  )}
                  {!control && (
                    <TextField
                      key={fieldData.name}
                      {...fieldData}
                      //@ts-ignore
                      index={index}
                    />
                  )}
                </div>
              </CSSTransition>
            </FieldSetItem>
          ))}
      </FieldSet>
    </TransitionGroup>
  );
};
