import classNames from "classnames";
import { Button } from "components/Buttons";
import { useAppSelector } from "hooks/redux";
import { selectProfileCoinsDetails } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import styles from "./BuyButton.module.scss";

interface BuyButtonProps {
  itemPrice?: number;
  itemClub?: string;
  buttonClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const BuyButton = ({ itemPrice, itemClub, onClick, buttonClassName }: BuyButtonProps) => {
  const coins = useAppSelector(selectProfileCoinsDetails);

  const currentCoins = itemClub
    ? coins.club_points.find((x) => x.club_id === itemClub)?.total_count || 0
    : coins.shop_coins;

  return currentCoins >= (itemPrice || 0) ? (
    <Button
      color="primary"
      type="button"
      size="small"
      className={classNames(styles["button"], buttonClassName)}
      onClick={onClick}
    >
      Купить
    </Button>
  ) : (
    <p className={styles["need"]}>
      Нужно накопить <br></br>еще {itemPrice! - currentCoins}
    </p>
  );
};
