import classNames from "classnames";
import { CapybaraBrokes } from "components/Svg/Pics";
import styles from "./TechWorks.module.scss";

export const TechWorks = () => {
  return (
    <article className={classNames("error-page", styles["page"])}>
      <div className="error-page__pic">
        <CapybaraBrokes />
      </div>
      <h1 className="error-page__title">Технические работы</h1>
      <div className="error-page__wrap">
        <p className={classNames(styles["page-text"], "error-page__text")}>
          С 27 февраля по 12 марта включительно Корпоративный онлайн-университет MyAcademy будет
          недоступен для обучения. Мы обновляем платформу, чтобы обучение стало еще удобнее и
          приятнее.
        </p>
      </div>
    </article>
  );
};
