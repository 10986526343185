import { ScoreType } from "../../types";
import {
  CourseGradeBookIcon,
  CourseGradePageIcon,
  CourseGradeQuestionnaireIcon,
  CourseGradeQuizIcon,
  CourseGradeTaskIcon,
  CourseGradeTrainingIcon,
  CourseGradeWebinarIcon,
} from "./components";

export const CourseGradeIcon = ({ scoreType }: { scoreType: ScoreType }) => {
  switch (scoreType) {
    case "page":
      return <CourseGradePageIcon />;
    case "task":
      return <CourseGradeTaskIcon />;
    case "book":
      return <CourseGradeBookIcon />;
    case "webinar":
      return <CourseGradeWebinarIcon />;
    case "quiz":
      return <CourseGradeQuizIcon />;
    case "questionnaire":
      return <CourseGradeQuestionnaireIcon />;
    case "scorm":
      return <CourseGradeTrainingIcon />;
    default:
      <></>;
  }

  return <></>;
};
