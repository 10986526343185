import { Button } from "components/Buttons";
import { WithAnimation } from "components/utils/utils";
import { AuthDataForm } from "pages/Authorization/data/data.types";

interface FormButtonProps {
  showTimer: boolean;
  form?: AuthDataForm;
  showTimerTimeout: () => void;
}

export const TimerButton = ({
  showTimer,
  form,
  showTimerTimeout,
}: FormButtonProps): JSX.Element => (
  <WithAnimation inCondition={showTimer === false && !!form?.support}>
    <Button
      size="small"
      color="accent"
      onClick={(e) => {
        showTimerTimeout();
        const action = form?.support?.buttons?.secondary?.action;
        if (typeof action === "function") {
          form?.support?.buttons?.secondary?.action(e);
        }
      }}
      style={{ marginTop: "40px" }}
    >
      {form?.support?.buttons?.secondary?.title ?? ""}
    </Button>
  </WithAnimation>
);
