import { Dispatch, SetStateAction } from "react";
import { Select } from "../../../../../components/Select";
import { Option } from "../../../../../components/Select/types";
import { KeywordType } from "pages/Course/redux/interfaces/course.interface";
import styles from "../CoursesSearch.module.scss";

interface KeywordsSearchProps {
  keywords: KeywordType[];
  selectedKeywords: Option[] | undefined;
  setSelectedKeywords: Dispatch<SetStateAction<Option[] | undefined>>;
}

export const KeywordsSearch = ({
  keywords,
  selectedKeywords,
  setSelectedKeywords,
}: KeywordsSearchProps) => {
  const handleSetSelectedCompetencies = (selectedOptions: Option[]) =>
    setSelectedKeywords(selectedOptions);

  const isSelectedAll = selectedKeywords?.length === keywords.length;

  return (
    <div className={styles["secondary-control"]}>
      <Select
        label="Метки"
        placeholder="Метки"
        options={keywords.map((obj) => ({
          label: obj.name,
          value: obj.id?.toString(),
          parent_id:
            keywords.some((keyword) => keyword.id === obj.parent_id) && obj.parent_id?.toString()
              ? obj.parent_id?.toString()
              : undefined,
        }))}
        onChange={(selectedOptions) => handleSetSelectedCompetencies(selectedOptions as Option[])}
        value={selectedKeywords}
        showCleaner={!isSelectedAll && !!selectedKeywords}
        isMulti
        isSorting
        isSearchable
      />
    </div>
  );
};
