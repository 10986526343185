import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const RobotAvatar = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();
  return process.env.REACT_APP_THEME === "myAcademy" ? (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width="234" height="234" rx="117" fill="#FF8B00" />
        <path
          d="M0.568375 328.789C-13.0175 313.564 -21.4407 293.444 -21.4407 271.421C-21.4407 230.638 7.08959 196.38 45.13 187.68V161.035C-7.31143 170.551 -47.2539 216.228 -47.2539 271.421C-47.2539 301.329 -35.2983 328.518 -16.2781 348.637C-10.8438 341.84 -4.86597 335.315 0.568375 328.789Z"
          fill="#FFC422"
        />
        <path
          d="M-46.439 285.016C-46.7107 283.385 -46.7107 281.482 -46.9824 279.851C-38.5592 278.219 -29.8642 276.86 -21.441 275.772C-21.441 277.404 -21.1693 279.307 -20.8975 280.938C-29.3208 281.754 -38.0157 283.113 -46.439 285.016ZM-45.0804 249.671L-19.8107 254.837C-19.539 253.206 -18.9955 251.574 -18.7238 249.671L-43.9935 244.505C-44.2652 246.137 -44.8087 247.768 -45.0804 249.671ZM-9.21369 187.409C-10.5723 188.497 -11.6591 189.856 -13.0177 190.944L3.82875 210.791C4.91562 209.432 6.27421 208.344 7.6328 207.257L-9.21369 187.409ZM-14.1046 306.224C-14.9198 304.592 -15.4632 302.961 -16.0066 301.33C-23.343 305.952 -30.9511 310.03 -38.8309 314.108C-38.2875 315.74 -37.4723 317.371 -36.6572 318.73C-29.0491 314.924 -21.441 310.574 -14.1046 306.224ZM17.9581 169.736C16.3278 170.552 14.6974 171.096 13.3389 171.911L22.849 196.109C24.4793 195.294 25.8379 194.478 27.4682 193.662L17.9581 169.736ZM-33.3965 217.588L-11.9309 232.27C-11.1157 230.639 -10.3006 229.28 -9.48541 227.648L-30.9511 212.966C-31.7662 214.598 -32.5814 215.957 -33.3965 217.588Z"
          fill="#191229"
        />
        <path
          d="M233.159 328.789C246.745 313.564 255.168 293.444 255.168 271.421C255.168 230.638 226.91 196.652 188.869 187.68V161.035C241.311 170.279 281.253 216.228 281.253 271.149C281.253 301.057 269.298 328.246 250.277 348.365C244.571 341.84 238.865 335.315 233.159 328.789Z"
          fill="#FFC422"
        />
        <path
          d="M254.625 280.938C254.897 279.306 254.897 277.403 255.169 275.772C263.864 276.859 272.287 278.219 280.71 279.85C280.71 281.481 280.438 283.385 280.167 285.016C271.743 283.113 263.32 281.753 254.625 280.938ZM277.721 244.505L252.451 249.671C252.995 251.302 253.267 252.933 253.538 254.837L278.808 249.671C278.536 247.767 278.265 246.136 277.721 244.505ZM226.367 206.984C227.725 208.072 228.812 209.431 230.171 210.519L247.017 190.671C245.659 189.584 244.572 188.224 243.213 187.137L226.367 206.984ZM270.657 318.73C271.472 317.099 272.015 315.467 272.83 314.108C264.95 310.301 257.342 305.951 250.006 301.329C249.463 302.96 248.647 304.592 248.104 306.223C255.169 310.573 262.777 314.924 270.657 318.73ZM206.531 193.934C208.162 194.75 209.52 195.565 211.15 196.381L220.661 172.183C219.03 171.367 217.672 170.552 216.041 170.008L206.531 193.934ZM264.679 212.966L243.213 227.648C244.028 229.007 244.843 230.639 245.659 232.27L267.124 217.588C266.581 215.957 265.766 214.597 264.679 212.966Z"
          fill="#191229"
        />
        <path
          d="M198.922 223.299L199.194 149.346H34.8047V223.299L45.1299 233.359V285.017H188.597V233.359L198.922 223.299Z"
          fill="#FFC422"
        />
        <path
          d="M78.8242 149.344L91.5949 142.547L78.8242 135.75H155.177L142.134 142.547L155.177 149.344H78.8242Z"
          fill="#191229"
        />
        <path d="M188.053 83.0039H174.467V116.99H188.053V83.0039Z" fill="#191229" />
        <path d="M196.476 87.627H183.977V112.369H196.476V87.627Z" fill="#191229" />
        <path d="M188.324 87.6258V59.3496H191.313V94.9668L188.324 87.6258Z" fill="#191229" />
        <path
          d="M189.955 63.7004C192.356 63.7004 194.302 61.7527 194.302 59.3502C194.302 56.9476 192.356 55 189.955 55C187.554 55 185.607 56.9476 185.607 59.3502C185.607 61.7527 187.554 63.7004 189.955 63.7004Z"
          fill="#191229"
        />
        <path d="M59.2597 83.0039H45.6738V116.99H59.2597V83.0039Z" fill="#191229" />
        <path d="M49.749 87.627H37.25V112.369H49.749V87.627Z" fill="#191229" />
        <path d="M45.401 87.6258V59.3496H42.4121V94.9668L45.401 87.6258Z" fill="#191229" />
        <path
          d="M44.0428 63.7004C46.4438 63.7004 48.3903 61.7527 48.3903 59.3502C48.3903 56.9476 46.4438 55 44.0428 55C41.6417 55 39.6953 56.9476 39.6953 59.3502C39.6953 61.7527 41.6417 63.7004 44.0428 63.7004Z"
          fill="#191229"
        />
        <path
          d="M91.322 115.086C98.6752 115.086 104.636 109.122 104.636 101.764C104.636 94.4061 98.6752 88.4414 91.322 88.4414C83.9688 88.4414 78.0078 94.4061 78.0078 101.764C78.0078 109.122 83.9688 115.086 91.322 115.086Z"
          fill="#FFC422"
        />
        <path
          d="M141.861 115.086C149.214 115.086 155.175 109.122 155.175 101.764C155.175 94.4061 149.214 88.4414 141.861 88.4414C134.508 88.4414 128.547 94.4061 128.547 101.764C128.547 109.122 134.508 115.086 141.861 115.086Z"
          fill="#FFC422"
        />
        <path d="M175.01 67.5059H58.7148V135.478H175.01V67.5059Z" fill="#FFC422" />
        <path
          d="M91.0505 112.912C98.4037 112.912 104.365 106.948 104.365 99.59C104.365 92.2322 98.4037 86.2676 91.0505 86.2676C83.6973 86.2676 77.7363 92.2322 77.7363 99.59C77.7363 106.948 83.6973 112.912 91.0505 112.912Z"
          fill="white"
        />
        <path
          d="M96.2582 99.5906C96.2582 100.112 96.4341 100.585 96.7782 100.93C97.1223 101.274 97.5955 101.45 98.1168 101.45C98.6381 101.45 99.1113 101.274 99.4554 100.93C99.7995 100.585 99.9754 100.112 99.9754 99.5906C99.9754 94.6927 95.9478 90.6621 91.0522 90.6621C86.1565 90.6621 82.1289 94.6927 82.1289 99.5906C82.1289 100.112 82.3048 100.585 82.6489 100.93C82.993 101.274 83.4662 101.45 83.9875 101.45C84.5088 101.45 84.982 101.274 85.3261 100.93C85.6702 100.585 85.8461 100.112 85.8461 99.5906C85.8461 96.6191 88.3241 94.381 91.0522 94.381C94.021 94.381 96.2582 96.8601 96.2582 99.5906Z"
          fill="#191229"
          stroke="#191229"
        />
        <path
          d="M110 114.5C116 121 120 121 126 114.5"
          stroke="#191229"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M141.59 112.912C148.943 112.912 154.904 106.948 154.904 99.59C154.904 92.2322 148.943 86.2676 141.59 86.2676C134.236 86.2676 128.275 92.2322 128.275 99.59C128.275 106.948 134.236 112.912 141.59 112.912Z"
          fill="white"
        />
        <path
          d="M146.797 99.5906C146.797 100.112 146.973 100.585 147.317 100.93C147.661 101.274 148.135 101.45 148.656 101.45C149.162 101.45 149.693 101.284 150.072 100.952C150.457 100.616 150.676 100.104 150.514 99.5157C150.473 94.6517 146.462 90.6621 141.591 90.6621C136.696 90.6621 132.668 94.6927 132.668 99.5906C132.668 100.112 132.844 100.585 133.188 100.93C133.532 101.274 134.005 101.45 134.527 101.45C135.048 101.45 135.521 101.274 135.865 100.93C136.209 100.585 136.385 100.112 136.385 99.5906C136.385 96.6191 138.863 94.381 141.591 94.381C144.56 94.381 146.797 96.8601 146.797 99.5906Z"
          fill="#191229"
          stroke="#191229"
        />
        <path d="M168.761 181.428H64.9648V211.335H168.761V181.428Z" fill="white" />
        <path
          d="M87.517 241.515C92.169 241.515 95.9402 237.742 95.9402 233.087C95.9402 228.432 92.169 224.658 87.517 224.658C82.865 224.658 79.0938 228.432 79.0938 233.087C79.0938 237.742 82.865 241.515 87.517 241.515Z"
          fill="#00AEFF"
        />
        <path
          d="M115.503 241.515C120.155 241.515 123.927 237.742 123.927 233.087C123.927 228.432 120.155 224.658 115.503 224.658C110.851 224.658 107.08 228.432 107.08 233.087C107.08 237.742 110.851 241.515 115.503 241.515Z"
          fill="#00AEFF"
        />
        <path
          d="M143.492 241.515C148.144 241.515 151.915 237.742 151.915 233.087C151.915 228.432 148.144 224.658 143.492 224.658C138.84 224.658 135.068 228.432 135.068 233.087C135.068 237.742 138.84 241.515 143.492 241.515Z"
          fill="#00AEFF"
        />
        <path d="M199.195 149.072H218.759L199.195 190.671V149.072Z" fill="#191229" />
        <path d="M34.8058 149.072H15.2422L34.8058 190.671V149.072Z" fill="#191229" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <rect width={234} height={234} fill="#C861F9" rx={117} />
        <path
          fill="#FFC422"
          d="M.568 328.789c-13.585-15.225-22.009-35.345-22.009-57.368 0-40.783 28.53-75.041 66.571-83.741v-26.645c-52.441 9.516-92.384 55.193-92.384 110.386 0 29.908 11.956 57.097 30.976 77.216 5.434-6.797 11.412-13.322 16.846-19.848Z"
        />
        <path
          fill="#191229"
          d="M-46.439 285.016c-.272-1.631-.272-3.534-.543-5.165 8.423-1.632 17.118-2.991 25.541-4.079 0 1.632.272 3.535.543 5.166-8.423.816-17.118 2.175-25.541 4.078Zm1.359-35.345 25.27 5.166c.271-1.631.814-3.263 1.086-5.166l-25.27-5.166c-.271 1.632-.815 3.263-1.086 5.166Zm35.866-62.262c-1.358 1.088-2.445 2.447-3.804 3.535L3.83 210.791c1.087-1.359 2.445-2.447 3.804-3.534l-16.847-19.848Zm-4.89 118.815c-.816-1.632-1.36-3.263-1.903-4.894-7.336 4.622-14.944 8.7-22.824 12.778.544 1.632 1.359 3.263 2.174 4.622 7.608-3.806 15.216-8.156 22.552-12.506Zm32.062-136.488c-1.63.816-3.26 1.36-4.62 2.175l9.511 24.198c1.63-.815 2.989-1.631 4.62-2.447l-9.51-23.926Zm-51.355 47.852 21.466 14.682c.815-1.631 1.63-2.99 2.446-4.622l-21.466-14.682c-.815 1.632-1.63 2.991-2.446 4.622Z"
        />
        <path
          fill="#FFC422"
          d="M233.159 328.789c13.586-15.225 22.009-35.345 22.009-57.368 0-40.783-28.258-74.769-66.299-83.741v-26.645c52.442 9.244 92.384 55.193 92.384 110.114 0 29.908-11.955 57.097-30.976 77.216l-17.118-19.576Z"
        />
        <path
          fill="#191229"
          d="M254.625 280.938c.272-1.632.272-3.535.544-5.166a384.511 384.511 0 0 1 25.541 4.078c0 1.631-.272 3.535-.543 5.166a199.836 199.836 0 0 0-25.542-4.078Zm23.096-36.433-25.27 5.166c.544 1.631.816 3.262 1.087 5.166l25.27-5.166c-.272-1.904-.543-3.535-1.087-5.166Zm-51.354-37.521c1.358 1.088 2.445 2.447 3.804 3.535l16.846-19.848c-1.358-1.087-2.445-2.447-3.804-3.534l-16.846 19.847Zm44.29 111.746c.815-1.631 1.358-3.263 2.173-4.622-7.88-3.807-15.488-8.157-22.824-12.779-.543 1.631-1.359 3.263-1.902 4.894 7.065 4.35 14.673 8.701 22.553 12.507Zm-64.126-124.796c1.631.816 2.989 1.631 4.619 2.447l9.511-24.198c-1.631-.816-2.989-1.631-4.62-2.175l-9.51 23.926Zm58.148 19.032-21.466 14.682c.815 1.359 1.63 2.991 2.446 4.622l21.465-14.682c-.543-1.631-1.358-2.991-2.445-4.622Z"
        />
        <path
          fill="#FFC422"
          d="m198.922 223.299.272-73.953H34.804v73.953l10.326 10.06v51.658h143.467v-51.658l10.325-10.06Z"
        />
        <path
          fill="#191229"
          d="m78.824 149.344 12.77-6.797-12.77-6.797h76.353l-13.043 6.797 13.043 6.797H78.824ZM188.053 83.004h-13.586v33.986h13.586V83.004Z"
        />
        <path fill="#191229" d="M196.476 87.627h-12.499v24.742h12.499V87.627Z" />
        <path fill="#191229" d="M188.324 87.626V59.35h2.989v35.617l-2.989-7.341Z" />
        <path
          fill="#191229"
          d="M189.955 63.7a4.348 4.348 0 0 0 4.347-4.35 4.348 4.348 0 1 0-8.695 0 4.35 4.35 0 0 0 4.348 4.35ZM59.26 83.004H45.674v33.986H59.26V83.004Z"
        />
        <path fill="#191229" d="M49.749 87.627H37.25v24.742h12.499V87.627Z" />
        <path fill="#191229" d="M45.401 87.626V59.35h-2.989v35.617l2.989-7.341Z" />
        <path
          fill="#191229"
          d="M44.043 63.7a4.349 4.349 0 0 0 4.347-4.35A4.349 4.349 0 0 0 44.043 55a4.349 4.349 0 0 0-4.348 4.35 4.349 4.349 0 0 0 4.348 4.35Z"
        />
        <path
          fill="#FFC422"
          d="M91.322 115.086c7.353 0 13.314-5.964 13.314-13.322 0-7.358-5.96-13.323-13.314-13.323-7.353 0-13.314 5.965-13.314 13.323 0 7.358 5.96 13.322 13.314 13.322ZM141.861 115.086c7.353 0 13.314-5.964 13.314-13.322 0-7.358-5.961-13.323-13.314-13.323s-13.314 5.965-13.314 13.323c0 7.358 5.961 13.322 13.314 13.322Z"
        />
        <path fill="#FFC422" d="M175.01 67.506H58.715v67.972H175.01V67.506Z" />
        <path
          fill="#fff"
          d="M91.05 112.912c7.354 0 13.315-5.964 13.315-13.322 0-7.358-5.961-13.322-13.314-13.322-7.354 0-13.315 5.964-13.315 13.322 0 7.358 5.961 13.322 13.314 13.322Z"
        />
        <path
          fill="#191229"
          stroke="#191229"
          d="M96.258 99.59c0 .522.176.995.52 1.34.344.344.817.52 1.339.52.521 0 .994-.176 1.338-.52.344-.345.52-.818.52-1.34 0-4.897-4.027-8.928-8.923-8.928s-8.923 4.03-8.923 8.929c0 .521.176.994.52 1.339.344.344.817.52 1.338.52.522 0 .995-.176 1.34-.52.343-.345.52-.818.52-1.34 0-2.97 2.477-5.209 5.205-5.209 2.969 0 5.206 2.48 5.206 5.21Z"
        />
        <path
          stroke="#191229"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M110 114.5c6 6.5 10 6.5 16 0"
        />
        <path
          fill="#fff"
          d="M141.59 112.912c7.353 0 13.314-5.964 13.314-13.322 0-7.358-5.961-13.322-13.314-13.322-7.354 0-13.315 5.964-13.315 13.322 0 7.358 5.961 13.322 13.315 13.322Z"
        />
        <path
          fill="#191229"
          stroke="#191229"
          d="M146.797 99.59c0 .522.176.995.52 1.34.344.344.818.52 1.339.52.506 0 1.037-.166 1.416-.498.385-.336.604-.848.442-1.436-.041-4.864-4.052-8.854-8.923-8.854-4.895 0-8.923 4.03-8.923 8.929 0 .521.176.994.52 1.339.344.344.817.52 1.339.52.521 0 .994-.176 1.338-.52.344-.345.52-.818.52-1.34 0-2.97 2.478-5.209 5.206-5.209 2.969 0 5.206 2.48 5.206 5.21Z"
        />
        <path fill="#fff" d="M168.761 181.428H64.965v29.907H168.76v-29.907Z" />
        <path
          fill="#FFC422"
          d="M87.517 241.515c4.652 0 8.423-3.773 8.423-8.428 0-4.655-3.771-8.429-8.423-8.429s-8.423 3.774-8.423 8.429 3.771 8.428 8.423 8.428ZM115.503 241.515c4.652 0 8.424-3.773 8.424-8.428 0-4.655-3.772-8.429-8.424-8.429-4.652 0-8.423 3.774-8.423 8.429s3.771 8.428 8.423 8.428ZM143.492 241.515c4.652 0 8.423-3.773 8.423-8.428 0-4.655-3.771-8.429-8.423-8.429s-8.424 3.774-8.424 8.429 3.772 8.428 8.424 8.428Z"
        />
        <path
          fill="#191229"
          d="M199.195 149.072h19.564l-19.564 41.599v-41.599ZM34.806 149.072H15.242l19.564 41.599v-41.599Z"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
