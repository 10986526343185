import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../services/api/api";
import { EndPoints } from "../../../app/endpoints";
import { MobileState } from "./mobile.types";

const initialState: MobileState = {
  settings: {
    ios_link: "",
    android_link: "",
    mobile_version: "",
  },
  timeLastUpdate: null,
};

const getMobileSettings = createAsyncThunk(
  "mobile/getMobileSettingsing",
  async (_arg, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.MOBILE_SETTINGS);
      return {
        status: response.status,
        settings: response.data?.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
      });
    }
  }
);

const mobileSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMobileSettings.fulfilled, (state, action) => {
      state.settings = action.payload.settings;
    });
  },
});

export { getMobileSettings };

export default mobileSlice.reducer;
