import { mediaQuerys } from "app/utils";
import { addDays, differenceInDays, format, fromUnixTime, isAfter, isSameDay } from "date-fns";
import { ru } from "date-fns/locale";
import { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { getMyCoursesCount } from "../../pages/My/redux/myCoursesSlice";
import { selectMyCoursesCount } from "../../pages/My/redux/selectors";
import CalendarContent from "../Calendar/CalendarContent";
import CalendarDateItem from "../Calendar/CalendarDateItem";
import CalendarWrapper from "../Calendar/CalendarWrapper";
import { CalendarPic } from "../Svg/Pics";
import { TextMain } from "../Typography/Texts";
import StudyCalendarEvents from "./StudyCalendarEvents";
import StudyCalendarSkeleton from "./StudyCalendarSkeleton";

const MAX_DATES_COUNT = 29;

const StudyCalendar = () => {
  const dispatch = useDispatch();

  const count = useSelector(selectMyCoursesCount);
  const [data, setData] = useState(null); // null - ответ от api еще не пришел; [] или [{}, {}, ...] - ответ пришел

  const xsSmXxsMax = useMediaQuery(
    `(min-width:${mediaQuerys.xsSm}px) and (max-width:${mediaQuerys.xxsMax}px)`
  );

  useEffect(() => {
    dispatch(getMyCoursesCount());
  }, [dispatch]);

  useEffect(() => {
    if (!count) {
      return;
    }
    const result = [];
    const copyCount = [...count];
    const nearFutureEvent = copyCount
      .sort((a, b) => (isAfter(fromUnixTime(a.date), fromUnixTime(b.date)) ? 1 : -1))
      .find((event) => differenceInDays(fromUnixTime(event.date), Date.now()) >= 0);

    const minDate = nearFutureEvent ? fromUnixTime(nearFutureEvent.date) : Date.now();

    for (let i = 0; i < MAX_DATES_COUNT; i++) {
      const dateForLoopIteration = addDays(minDate, i);
      const findedEvent = copyCount.find((event) =>
        isSameDay(fromUnixTime(event.date), dateForLoopIteration)
      );

      if (findedEvent) {
        const eventsForCalendar = findedEvent.events
          .map((event) => {
            if (
              event.type === "course" &&
              isSameDay(fromUnixTime(event.event_start_ts), dateForLoopIteration)
            ) {
              return {
                ...event,
                title: `Начало обучения по курсу`,
              };
            }
            if (
              event.type === "course" &&
              isSameDay(fromUnixTime(event.event_end_ts), dateForLoopIteration)
            ) {
              return {
                ...event,
                title: `Завершение курса`,
              };
            }

            if (
              event.type === "quiz" &&
              isSameDay(fromUnixTime(event.event_end_ts), dateForLoopIteration)
            ) {
              return {
                ...event,
                name: `"${event.name}" в курсе "${event.course.name}"`,
                title: "Дедлайн по тесту",
              };
            }
            if (event.type === "meeting" && event?.event_start_ts) {
              return {
                ...event,
                name: `В ${format(
                  fromUnixTime(event.event_start_ts),
                  "HH:mm"
                )} запланировано проведение вебинара "${event.name}" в курсе "${
                  event.course.name
                }"`,
                title: "Запланирован вебинар",
              };
            }
            return undefined;
          })
          .filter((value) => !!value)
          .sort((a, b) => {
            if (isSameDay(fromUnixTime(a.event_start_ts), fromUnixTime(b.event_start_ts))) {
              return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
            }
            return isAfter(fromUnixTime(a.event_start_ts), fromUnixTime(b.event_start_ts)) ? 1 : -1;
          });

        result.push({
          events: eventsForCalendar,
          selectedDay: i === 0,
          disabledDay: false,
          dayOfMonth: format(dateForLoopIteration, "dd"),
          day: format(dateForLoopIteration, "EEEEEE", { locale: ru }),
          monthAndYear: format(dateForLoopIteration, "MMM ’yy", { locale: ru }).replace(".", ""),
          date: dateForLoopIteration,
        });
        continue;
      }

      result.push({
        dayOfMonth: format(dateForLoopIteration, "dd"),
        selectedDay: i === 0,
        disabledDay: i !== 0,
        day: format(dateForLoopIteration, "EEEEEE", { locale: ru }),
        monthAndYear: format(dateForLoopIteration, "MMM ’yy", { locale: ru }).replace(".", ""),
        date: dateForLoopIteration,
      });
    }
    setData(result);
  }, [count]);

  if (!data) {
    return <StudyCalendarSkeleton />;
  }

  const current = xsSmXxsMax
    ? data?.find((item) => item?.events?.length > 0)
    : data?.find((item) => item.selectedDay);

  return (
    <CalendarWrapper>
      <CalendarContent>
        {current?.events?.length ? (
          <StudyCalendarEvents events={current.events} />
        ) : (
          <div className="calendar-timetable">
            <CalendarPic className="calendar__pic" />
            <TextMain className="calendar-timetable__text">
              Ваше расписание свободно. Это прекрасный шанс начать изучать что-то новое в этот день.{" "}
            </TextMain>
          </div>
        )}
        <ScrollContainer style={{ display: xsSmXxsMax ? "none" : "flex" }}>
          {(data || []).map((item, index) => (
            <CalendarDateItem
              key={item.dayOfMonth}
              dayNumber={item.dayOfMonth}
              dayWord={item.day}
              date={item.monthAndYear}
              selectedDay={item.selectedDay}
              disabledDay={item.disabledDay}
              onClick={() => {
                if (!item.disabledDay)
                  setData(
                    (data || []).map((item, indexInner) => ({
                      ...item,
                      selectedDay: index === indexInner,
                    }))
                  );
              }}
            />
          ))}
        </ScrollContainer>
      </CalendarContent>
    </CalendarWrapper>
  );
};

export default StudyCalendar;
