import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import { lessonsSelectors } from "../../redux/courseSlice";
import { selectLessonsLoaded } from "../../redux/courseSelectors";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import { useAppSelector } from "../../../../hooks/redux";

const CourseLessons = ({ isMulticourse = false }: { isMulticourse?: boolean }) => {
  const navigate = useNavigate();
  const { course_id, lesson_id } = useParams();

  const lessonsIds = useAppSelector((state) => lessonsSelectors.selectIds(state));
  const lessonsLoaded = useAppSelector(selectLessonsLoaded);

  useEffect(() => {
    if (lessonsLoaded && lesson_id === undefined) {
      if (lessonsIds[0]) {
        navigate(
          isMulticourse
            ? WebAppRoutes.COURSE + "/" + course_id + WebAppRoutes.CONTENT + "/" + lessonsIds[0]
            : WebAppRoutes.COURSE + "/" + course_id + WebAppRoutes.LESSON + "/" + lessonsIds[0],
          { replace: true }
        );
      }
    }
  }, [lesson_id, course_id, lessonsIds, navigate, lessonsLoaded, isMulticourse]);
  return <CourseLoadSkeleton />;
};

export default CourseLessons;
