import { SVGProps } from "react";

export const StarBigIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <path
        d="M31.9999 5.33398L40.2399 22.0273L58.6666 24.7207L45.3333 37.7073L48.4799 56.054L31.9999 47.3873L15.5199 56.054L18.6666 37.7073L5.33325 24.7207L23.7599 22.0273L31.9999 5.33398Z"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
