import Alert from "components/Alert/Alert";
import { IQuizQuestion } from "../../../redux/Quze/interface";
import { QuestCalculate, QuestCorrelate, QuestRadio, QuestText } from "../components";

export const getQuizControl = ({
  quest,
  questIndex,
  lessonId,
  handleChange = () => {},
}: {
  quest: IQuizQuestion;
  questIndex: number;
  lessonId: string;
  handleChange?: (params: { id: string | number; answer: unknown }) => void;
}) => {
  const questTitle = quest.title ? quest.title : "Вопрос " + questIndex;
  const questText = Array.isArray(quest.text) ? quest.text[0] : quest.text;
  switch (quest.question_type) {
    case "text":
      return (
        <QuestText
          key={"quest_" + quest.id}
          title={questTitle}
          description={questText}
          questionsId={quest.id}
          lesson_id={lessonId}
          handleChange={handleChange}
        />
      );
    case "bool":
      return (
        <QuestRadio
          key={"quest_" + quest.id}
          multy={false}
          title={questTitle}
          questionsId={quest.id}
          questions={quest.options}
          description={questText}
          lesson_id={lessonId}
          handleChange={handleChange}
          withHint={false}
        />
      );
    case "select":
      return (
        <QuestRadio
          key={"quest_" + quest.id}
          multy={quest?.config?.multiselect ? quest?.config?.multiselect : false}
          questionsId={quest.id}
          title={questTitle}
          questions={quest.options}
          description={questText}
          lesson_id={lessonId}
          handleChange={handleChange}
        />
      );
    case "correlate":
      return (
        <QuestCorrelate
          key={"quest_" + quest.id}
          multy={false}
          questionsId={quest.id}
          title={questTitle}
          questions={quest.options}
          description={questText}
          lesson_id={lessonId}
          handleChange={handleChange}
        />
      );
    case "numeric":
      return (
        <QuestCalculate
          key={"quest_" + quest.id}
          title={questTitle}
          questionsId={quest.id}
          description={questText}
          handleChange={handleChange}
        />
      );
    default:
      return <Alert>Тип вопроса не поддерживается</Alert>;
  }
};
