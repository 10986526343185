import { useEffect, useState } from "react";
import Container from "../../../../components/Container/Container";
import TaskFooter from "./components/TaskFooter";
import {
  hideAsideNav,
  hideBottomNav,
  setCourseTaskEnd,
  setCourseLessonFinish,
  getCourseLesson,
} from "../../redux/courseSlice";
import { H1, Subtitle } from "../../../../components/Typography/Titles";
import TextField from "../../../../components/Forms/TextField";
import { selectTaskDetail, selectTaskFileTypes, selectTaskIsLoading } from "./redux/taskSelectors";
import TaskFileList from "./components/TaskFileList";

import { useController, useForm } from "react-hook-form";
import TaskFileUpload from "./components/TaskFileUpload";
import { getTask, getTaskResult, setTaskFileAnswer, setTaskIdle } from "./redux/taskSlice";
import { LessonDescription } from "../LessonDescription";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { CourseLessonTask } from "../../redux/interfaces/course.interface";
import { Checkbox } from "components/Checkbox";
import { generateUid } from "app/utils";

const TaskQuestions = ({ lesson }: { lesson: CourseLessonTask }) => {
  const dispatch = useAppDispatch();
  const taskDetail = useAppSelector(selectTaskDetail);
  const taskIsLoading = useAppSelector(selectTaskIsLoading);
  const taskFileTypes = useAppSelector(selectTaskFileTypes);
  const [doneBtnDisable, setDoneBtnDisable] = useState(true);
  const [taskAttechetFiles, setTaskAttechetFiles] = useState<File[]>([]);

  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const {
    field: { onChange, value },
  } = useController({ name: "confirmAnswer", control });

  const handleFilesChange = (files: File[]) => {
    setTaskAttechetFiles(files);
    handleChange();
  };
  const handleDone = () => {
    const formValues = getValues();
    const formData = new FormData();

    if ("textAnswer" in formValues) {
      // https://jira.lanit.ru/browse/SDOLAN-2292
      // dispatch(setTaskTextAnswer({ id: taskDetail?.id, answer: formValues?.textAnswer }));
      if (formValues?.textAnswer) {
        formData.append("answer", formValues?.textAnswer);
      }
    }
    if (taskAttechetFiles.length) {
      taskAttechetFiles.forEach((item) => {
        formData.append("files[]", item, item.name);
      });
    }

    dispatch(setTaskFileAnswer({ id: taskDetail?.id, formData: formData })).then((response) => {
      if (!response.payload?.status) {
        dispatch(setCourseLessonFinish(taskDetail?.id)).then((res) => {
          !res.payload?.status && dispatch(setCourseTaskEnd(taskDetail?.id));
          dispatch(getCourseLesson(lesson?.id));
          dispatch(getTask(lesson?.id)).then(() => {
            dispatch(getTaskResult(lesson.id));
            dispatch(setTaskIdle());
          });
        });
      }
    });
  };

  const handleChange = () => {
    const formValues = getValues();
    const isValidTextAnswer = validateTextAnswer(formValues.textAnswer);

    switch (taskDetail?.task_type) {
      case "all": {
        const taskAnswerValid = taskDetail?.confirmation
          ? taskAttechetFiles.length > 0 &&
            formValues.textAnswer &&
            isValidTextAnswer &&
            formValues.confirmAnswer
          : taskAttechetFiles.length > 0 && formValues.textAnswer && isValidTextAnswer;
        setDoneBtnDisable(!taskAnswerValid);
        return;
      }

      case "text": {
        const taskAnswerValid = taskDetail?.confirmation
          ? formValues.textAnswer.length > 0 && isValidTextAnswer && formValues.confirmAnswer
          : formValues.textAnswer.length > 0 && isValidTextAnswer;
        setDoneBtnDisable(!taskAnswerValid);
        return;
      }

      case "file": {
        const taskAnswerValid = taskDetail?.confirmation
          ? taskAttechetFiles.length > 0 && formValues.confirmAnswer
          : taskAttechetFiles.length > 0;
        setDoneBtnDisable(!taskAnswerValid);
        return;
      }

      default: {
        return;
      }
    }
  };
  const questionTitle = (taskType?: string) => {
    switch (taskType) {
      case "all":
        return "Напишите свой ответ и прикрепите файл";
      case "text":
        return "Напишите свой ответ";
      case "file":
        return "Прикрепите файл со своим ответом";
      default:
        return "";
    }
  };

  useEffect(() => {
    dispatch(hideBottomNav());
    dispatch(hideAsideNav());
  }, [dispatch]);

  const validateTextAnswer = (value: string): boolean => {
    if (!taskDetail?.character_count || !value) return true;

    const wordCount = value.replace(/ +/g, " ").trim().split(" ").length;
    return wordCount <= taskDetail?.character_count;
  };

  if (taskIsLoading) {
    return (
      <>
        <Container>
          <CourseLoadSkeleton />
        </Container>
      </>
    );
  }

  const checkboxId = generateUid();

  return (
    <>
      <Container>
        <div className="task-block">
          <div className="task-heading">
            <H1 className="task-heading__title">{taskDetail?.title}</H1>
            <LessonDescription stringHTML={taskDetail?.description} />
          </div>
          {taskDetail?.files.length ? <TaskFileList files={taskDetail?.files} /> : null}
          <div className="task-infoblock">
            <form onChange={handleChange}>
              <Subtitle className="task-infoblock__subtitle">
                {questionTitle(taskDetail?.task_type)}
              </Subtitle>
              {taskDetail?.task_type === "all" || taskDetail?.task_type === "text" ? (
                <div className="task-textfield-wrap">
                  <TextField
                    /*// @ts-ignore */
                    placeholder="Ваш ответ"
                    className="task__textfield"
                    multiline
                    minRows={2}
                    error={errors?.textAnswer}
                    {...register("textAnswer", {
                      validate: (value) =>
                        validateTextAnswer(value) || `Лимит слов: ${taskDetail?.character_count}`,
                    })}
                  />
                </div>
              ) : null}
              {taskDetail?.task_type === "all" || taskDetail?.task_type === "file" ? (
                <TaskFileUpload
                  fileCount={taskDetail?.files_count}
                  fileType={taskFileTypes}
                  fileSize={taskDetail?.file_size}
                  onChangeFiles={handleFilesChange}
                />
              ) : null}
              {taskDetail.confirmation > 0 && (
                <div className="task-сheckbox-container">
                  <div className="task-сheckbox">
                    <Checkbox value={value} onChange={onChange} id={checkboxId} />
                  </div>
                  <label className="task-сheckbox__label" htmlFor={checkboxId}>
                    Подтверждаю, что загружаемый файл является моей собственной работой за
                    исключением случаев, где допускается использование работ других лиц
                  </label>
                </div>
              )}
            </form>
          </div>
        </div>
      </Container>
      <TaskFooter onDone={handleDone} disabled={doneBtnDisable} />
    </>
  );
};

export default TaskQuestions;
