import { selectFormats } from "pages/Courses/redux/coursesListSliceSelectors";
import { Dispatch, SetStateAction } from "react";
import { Select } from "../../../../../components/Select";
import { Option } from "../../../../../components/Select/types";
import { useAppSelector } from "../../../../../hooks/redux";
import styles from "../CoursesSearch.module.scss";

interface CompetenceSearchProps {
  selectedFormats: Option[] | undefined;
  setSelectedFormats: Dispatch<SetStateAction<Option[] | undefined>>;
}

export const FormatSearch = ({ selectedFormats, setSelectedFormats }: CompetenceSearchProps) => {
  const formats = useAppSelector(selectFormats);

  const handleSetSelectedFormats = (selectedOptions: Option[]) =>
    setSelectedFormats(selectedOptions);

  return (
    <div className={styles["secondary-control"]}>
      <Select
        isMulti={true}
        showCleaner={true}
        label="Формат"
        placeholder="Формат"
        options={formats.map(({ label, value }) => ({
          label,
          value,
        }))}
        onChange={(selectedOptions) => handleSetSelectedFormats(selectedOptions as Option[])}
        value={selectedFormats}
      />
    </div>
  );
};
