import { useModal } from "components/ModalsContainer";
import { ShrinkIcon } from "components/Svg/Icons";
import styles from "./ImageModal.module.scss";

type ImageModalProps = {
  imageSrc?: string;
};

export const ImageModal = ({ imageSrc }: ImageModalProps) => {
  const { closeModal } = useModal();
  return (
    <div className={styles["image-modal"]}>
      <img src={imageSrc} alt="fullscreen" />
      <ShrinkIcon className={styles["image-modal-shrink"]} onClick={closeModal} />
    </div>
  );
};
