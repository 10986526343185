import { ModalContainerProps } from "components/ModalsContainer";
import { ModalsContainerContext } from "components/ModalsContainer/context";
import { Fragment, useContext } from "react";
import { createPortal } from "react-dom";
import { CustomModal } from "../CustomModal";
import { Modal } from "../Modal";
import { Overlay } from "../Overlay";

export const ModalsRenderContainer = ({ modals = [] }: { modals: ModalContainerProps[] }) => {
  const dispatch = useContext(ModalsContainerContext);

  const modalsContainer = document.getElementById("modals")!;
  const overlayContainer = document.getElementById("overlay")!;
  const lastedOpenedModal = modals[modals.length - 1];

  const handleClose = () =>
    dispatch({
      type: "CLOSE_MODAL",
      payload: {},
    });

  return (
    <>
      {modals.map((modal) => (
        <Fragment key={modal.id}>
          {createPortal(
            !modal.custom ? (
              <Modal
                clickOutsideClose={modal.clickOutsideClose}
                onClose={handleClose}
                isActiveModal={modal.id === lastedOpenedModal?.id}
                closeClassName={modal.closeClassName}
              >
                {modal.content}
              </Modal>
            ) : (
              <CustomModal
                onClose={handleClose}
                isActiveModal={modal.id === lastedOpenedModal?.id}
                clickOutsideClose={modal.clickOutsideClose}
              >
                {modal.content}
              </CustomModal>
            ),
            modalsContainer,
            modal.id
          )}
        </Fragment>
      ))}
      {modals.length > 0 && createPortal(<Overlay />, overlayContainer)}
    </>
  );
};
