import styles from "./skeleton.module.scss";
import classNames from "classnames";

export const Skeleton = ({
  width = "100%",
  height = "24px",
  variant = "rounded",
  className,
  sx = {},
}: {
  width?: string | number;
  height?: string | number;
  variant?: "text" | "circular" | "rounded";
  className?: string;
  sx?: object;
}) => {
  const skeletonVariant: { [index: string]: any } = {
    text: "_text",
    circular: "_circular",
    rounded: "_rounded",
  };
  const customStyle = {
    width: typeof width !== "number" ? `${width}` : `${width}px`,
    height: typeof height !== "number" ? `${height}` : `${height}px`,
    ...sx,
  };
  return (
    <span
      className={classNames(styles["skeleton"], styles[skeletonVariant[variant]], className)}
      style={customStyle}
    ></span>
  );
};
