import { SVGProps } from "react";

export const TrainingIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="300"
    height="185"
    viewBox="0 0 300 185"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.08"
      d="M98.458 175.114C85.854 172.145 68.5234 172.407 52.1967 175.126C35.87 177.846 20.0167 167.342 19.0525 147.81C18.0884 128.279 30.7115 123.214 30.7115 107.446C30.7115 91.6791 19.0529 78.7496 27.2452 68.6586C35.4376 58.5676 41.7608 60.3193 52.6213 53.7672C63.4818 47.2151 61.2761 35.3518 81.7578 26.0874C102.239 16.8229 116.419 35.3518 139.104 31.2555C161.789 27.1591 164.314 17.573 180.699 20.571C197.085 23.5689 194.879 40.5932 214.256 35.3518C233.634 30.1105 249.391 37.7551 257.928 48.9172C266.465 60.0793 256.954 72.1274 251.912 86.3179C246.871 100.508 252.546 101.876 267.197 115.383C281.847 128.889 277.475 150.901 270.818 162.316C264.161 173.731 252.165 177.821 221.001 175.114C189.837 172.407 111.062 178.084 98.458 175.114Z"
      fill="rgb(var(--primary-color))"
    />
    <path
      d="M20.757 60.8144C20.757 58.1555 22.9179 56 25.5835 56H148.658C151.323 56 153.484 58.1555 153.484 60.8144V142.178C153.484 144.837 151.323 146.992 148.658 146.992H25.5835C22.9179 146.992 20.757 144.837 20.757 142.178V60.8144Z"
      fill="rgb(var(--white-color))"
    />
    <path
      d="M14 144.104C14 141.445 16.1609 139.289 18.8264 139.289H155.174C157.839 139.289 160 141.445 160 144.104V147.714C160 150.373 157.839 152.529 155.174 152.529H18.8264C16.1609 152.529 14 150.373 14 147.714V144.104Z"
      fill="rgb(var(--white-color))"
    />
    <g opacity="0.25">
      <path
        d="M20.757 60.8144C20.757 58.1555 22.9179 56 25.5835 56H148.658C151.323 56 153.484 58.1555 153.484 60.8144V142.178C153.484 144.837 151.323 146.992 148.658 146.992H25.5835C22.9179 146.992 20.757 144.837 20.757 142.178V60.8144Z"
        fill="rgb(var(--primary-color))"
      />
      <path
        d="M14 144.104C14 141.445 16.1609 139.289 18.8264 139.289H155.174C157.839 139.289 160 141.445 160 144.104V147.714C160 150.373 157.839 152.529 155.174 152.529H18.8264C16.1609 152.529 14 150.373 14 147.714V144.104Z"
        fill="rgb(var(--primary-color))"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M20.757 60.8144C20.757 58.1555 22.9179 56 25.5835 56H148.658C151.323 56 153.484 58.1555 153.484 60.8144V142.178C153.484 144.837 151.323 146.992 148.658 146.992H25.5835C22.9179 146.992 20.757 144.837 20.757 142.178V60.8144Z"
        fill="rgb(var(--dark-color))"
      />
      <path
        d="M14 144.104C14 141.445 16.1609 139.289 18.8264 139.289H155.174C157.839 139.289 160 141.445 160 144.104V147.714C160 150.373 157.839 152.529 155.174 152.529H18.8264C16.1609 152.529 14 150.373 14 147.714V144.104Z"
        fill="rgb(var(--dark-color))"
      />
    </g>
    <rect
      x="27.5142"
      y="61.7773"
      width="119.455"
      height="74.1419"
      rx="4.82644"
      fill="rgb(var(--white-color))"
    />
    <rect
      opacity="0.08"
      x="27.5142"
      y="61.7773"
      width="119.455"
      height="74.1419"
      rx="4.82644"
      fill="rgb(var(--primary-color))"
    />
    <path
      d="M135.818 44H38.1815C35.8721 44 34 45.8737 34 48.185V121.212C34 123.524 35.8721 125.397 38.1815 125.397H135.818C138.128 125.397 140 123.524 140 121.212V48.185C140 45.8737 138.128 44 135.818 44Z"
      fill="rgb(var(--white-color))"
    />
    <path
      opacity="0.08"
      d="M135.818 44H38.1815C35.8721 44 34 45.8737 34 48.185V121.212C34 123.524 35.8721 125.397 38.1815 125.397H135.818C138.128 125.397 140 123.524 140 121.212V48.185C140 45.8737 138.128 44 135.818 44Z"
      fill="rgb(var(--primary-color))"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.818 47.539H38.1815C37.8294 47.539 37.539 47.8254 37.539 48.185V121.212C37.539 121.572 37.8294 121.858 38.1815 121.858H135.818C136.17 121.858 136.461 121.572 136.461 121.212V48.185C136.461 47.8254 136.17 47.539 135.818 47.539ZM38.1815 44H135.818C138.128 44 140 45.8737 140 48.185V121.212C140 123.524 138.128 125.397 135.818 125.397H38.1815C35.8721 125.397 34 123.524 34 121.212V48.185C34 45.8737 35.8721 44 38.1815 44Z"
      fill="rgb(var(--white-color))"
    />
    <path
      d="M34 48.1859C34 45.8741 35.8735 44 38.1798 44H135.82C138.128 44 140 45.8743 140 48.1859V53.2069H34V48.1859Z"
      fill="rgb(var(--white-color))"
    />
    <g opacity="0.25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.818 47.539H38.1815C37.8294 47.539 37.539 47.8254 37.539 48.185V121.212C37.539 121.572 37.8294 121.858 38.1815 121.858H135.818C136.17 121.858 136.461 121.572 136.461 121.212V48.185C136.461 47.8254 136.17 47.539 135.818 47.539ZM38.1815 44H135.818C138.128 44 140 45.8737 140 48.185V121.212C140 123.524 138.128 125.397 135.818 125.397H38.1815C35.8721 125.397 34 123.524 34 121.212V48.185C34 45.8737 35.8721 44 38.1815 44Z"
        fill="rgb(var(--primary-color))"
      />
      <path
        d="M34 48.1859C34 45.8741 35.8735 44 38.1798 44H135.82C138.128 44 140 45.8743 140 48.1859V53.2069H34V48.1859Z"
        fill="rgb(var(--primary-color))"
      />
    </g>
    <g opacity="0.1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.818 47.539H38.1815C37.8294 47.539 37.539 47.8254 37.539 48.185V121.212C37.539 121.572 37.8294 121.858 38.1815 121.858H135.818C136.17 121.858 136.461 121.572 136.461 121.212V48.185C136.461 47.8254 136.17 47.539 135.818 47.539ZM38.1815 44H135.818C138.128 44 140 45.8737 140 48.185V121.212C140 123.524 138.128 125.397 135.818 125.397H38.1815C35.8721 125.397 34 123.524 34 121.212V48.185C34 45.8737 35.8721 44 38.1815 44Z"
        fill="rgb(var(--dark-color))"
      />
      <path
        d="M34 48.1859C34 45.8741 35.8735 44 38.1798 44H135.82C138.128 44 140 45.8743 140 48.1859V53.2069H34V48.1859Z"
        fill="rgb(var(--dark-color))"
      />
    </g>
    <path
      d="M40.1678 50.6954C41.2648 50.6954 42.154 49.8054 42.154 48.7076C42.154 47.6097 41.2648 46.7197 40.1678 46.7197C39.0709 46.7197 38.1816 47.6097 38.1816 48.7076C38.1816 49.8054 39.0709 50.6954 40.1678 50.6954Z"
      fill="rgb(var(--white-color))"
    />
    <path
      d="M46.6489 50.6954C47.7459 50.6954 48.6351 49.8054 48.6351 48.7076C48.6351 47.6097 47.7459 46.7197 46.6489 46.7197C45.552 46.7197 44.6627 47.6097 44.6627 48.7076C44.6627 49.8054 45.552 50.6954 46.6489 50.6954Z"
      fill="rgb(var(--white-color))"
    />
    <path
      d="M53.3393 50.6954C54.4363 50.6954 55.3255 49.8054 55.3255 48.7076C55.3255 47.6097 54.4363 46.7197 53.3393 46.7197C52.2424 46.7197 51.3532 47.6097 51.3532 48.7076C51.3532 49.8054 52.2424 50.6954 53.3393 50.6954Z"
      fill="rgb(var(--white-color))"
    />
    <g opacity="0.08">
      <path
        d="M40.1678 50.6954C41.2648 50.6954 42.154 49.8054 42.154 48.7076C42.154 47.6097 41.2648 46.7197 40.1678 46.7197C39.0709 46.7197 38.1816 47.6097 38.1816 48.7076C38.1816 49.8054 39.0709 50.6954 40.1678 50.6954Z"
        fill="rgb(var(--primary-color))"
      />
      <path
        d="M46.6489 50.6954C47.7459 50.6954 48.6351 49.8054 48.6351 48.7076C48.6351 47.6097 47.7459 46.7197 46.6489 46.7197C45.552 46.7197 44.6627 47.6097 44.6627 48.7076C44.6627 49.8054 45.552 50.6954 46.6489 50.6954Z"
        fill="rgb(var(--primary-color))"
      />
      <path
        d="M53.3393 50.6954C54.4363 50.6954 55.3255 49.8054 55.3255 48.7076C55.3255 47.6097 54.4363 46.7197 53.3393 46.7197C52.2424 46.7197 51.3532 47.6097 51.3532 48.7076C51.3532 49.8054 52.2424 50.6954 53.3393 50.6954Z"
        fill="rgb(var(--primary-color))"
      />
    </g>
    <rect x="168" y="69" width="104" height="70" rx="7" fill="rgb(var(--white-color))" />
    <rect
      opacity="0.25"
      x="168"
      y="69"
      width="104"
      height="70"
      rx="7"
      fill="rgb(var(--primary-color))"
    />
    <rect
      opacity="0.1"
      x="168"
      y="69"
      width="104"
      height="70"
      rx="7"
      fill="rgb(var(--dark-color))"
    />
    <rect x="172" y="73" width="96" height="62" rx="4" fill="rgb(var(--white-color))" />
    <rect
      opacity="0.08"
      x="172"
      y="73"
      width="96"
      height="62"
      rx="4"
      fill="rgb(var(--primary-color))"
    />
    <path
      d="M258.818 57L181.181 57C178.872 57 177 58.8737 177 61.185L177 124.212C177 126.524 178.872 128.397 181.181 128.397L258.818 128.397C261.128 128.397 263 126.524 263 124.212L263 61.185C263 58.8737 261.128 57 258.818 57Z"
      fill="rgb(var(--white-color))"
    />
    <path
      opacity="0.08"
      d="M258.818 57L181.181 57C178.872 57 177 58.8737 177 61.185L177 124.212C177 126.524 178.872 128.397 181.181 128.397L258.818 128.397C261.128 128.397 263 126.524 263 124.212L263 61.185C263 58.8737 261.128 57 258.818 57Z"
      fill="rgb(var(--primary-color))"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M258.818 60.539L181.181 60.539C180.829 60.539 180.539 60.8254 180.539 61.185L180.539 124.212C180.539 124.572 180.829 124.858 181.181 124.858L258.818 124.858C259.17 124.858 259.461 124.572 259.461 124.212L259.461 61.185C259.461 60.8254 259.17 60.539 258.818 60.539ZM181.181 57L258.818 57C261.128 57 263 58.8737 263 61.185L263 124.212C263 126.524 261.128 128.397 258.818 128.397L181.181 128.397C178.872 128.397 177 126.524 177 124.212L177 61.185C177 58.8737 178.872 57 181.181 57Z"
      fill="rgb(var(--white-color))"
    />
    <path
      d="M177 61.185C177 58.8732 178.874 57 181.18 57L258.82 57C261.128 57 263 58.8733 263 61.185L263 66.2069L177 66.2069L177 61.185Z"
      fill="rgb(var(--white-color))"
    />
    <g opacity="0.25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.818 60.539L181.181 60.539C180.829 60.539 180.539 60.8254 180.539 61.185L180.539 124.212C180.539 124.572 180.829 124.858 181.181 124.858L258.818 124.858C259.17 124.858 259.461 124.572 259.461 124.212L259.461 61.185C259.461 60.8254 259.17 60.539 258.818 60.539ZM181.181 57L258.818 57C261.128 57 263 58.8737 263 61.185L263 124.212C263 126.524 261.128 128.397 258.818 128.397L181.181 128.397C178.872 128.397 177 126.524 177 124.212L177 61.185C177 58.8737 178.872 57 181.181 57Z"
        fill="rgb(var(--primary-color))"
      />
      <path
        d="M177 61.185C177 58.8732 178.874 57 181.18 57L258.82 57C261.128 57 263 58.8733 263 61.185L263 66.2069L177 66.2069L177 61.185Z"
        fill="rgb(var(--primary-color))"
      />
    </g>
    <g opacity="0.1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.818 60.539L181.181 60.539C180.829 60.539 180.539 60.8254 180.539 61.185L180.539 124.212C180.539 124.572 180.829 124.858 181.181 124.858L258.818 124.858C259.17 124.858 259.461 124.572 259.461 124.212L259.461 61.185C259.461 60.8254 259.17 60.539 258.818 60.539ZM181.181 57L258.818 57C261.128 57 263 58.8737 263 61.185L263 124.212C263 126.524 261.128 128.397 258.818 128.397L181.181 128.397C178.872 128.397 177 126.524 177 124.212L177 61.185C177 58.8737 178.872 57 181.181 57Z"
        fill="rgb(var(--dark-color))"
      />
      <path
        d="M177 61.185C177 58.8732 178.874 57 181.18 57L258.82 57C261.128 57 263 58.8733 263 61.185L263 66.2069L177 66.2069L177 61.185Z"
        fill="rgb(var(--dark-color))"
      />
    </g>
    <path
      d="M183.168 63.6954C184.265 63.6954 185.154 62.8054 185.154 61.7076C185.154 60.6097 184.265 59.7197 183.168 59.7197C182.071 59.7197 181.182 60.6097 181.182 61.7076C181.182 62.8054 182.071 63.6954 183.168 63.6954Z"
      fill="rgb(var(--white-color))"
    />
    <path
      d="M189.649 63.6954C190.746 63.6954 191.635 62.8054 191.635 61.7076C191.635 60.6097 190.746 59.7197 189.649 59.7197C188.552 59.7197 187.663 60.6097 187.663 61.7076C187.663 62.8054 188.552 63.6954 189.649 63.6954Z"
      fill="rgb(var(--white-color))"
    />
    <path
      d="M196.339 63.6954C197.436 63.6954 198.326 62.8054 198.326 61.7076C198.326 60.6097 197.436 59.7197 196.339 59.7197C195.242 59.7197 194.353 60.6097 194.353 61.7076C194.353 62.8054 195.242 63.6954 196.339 63.6954Z"
      fill="rgb(var(--white-color))"
    />
    <g opacity="0.08">
      <path
        d="M183.168 63.6954C184.265 63.6954 185.154 62.8054 185.154 61.7076C185.154 60.6097 184.265 59.7197 183.168 59.7197C182.071 59.7197 181.182 60.6097 181.182 61.7076C181.182 62.8054 182.071 63.6954 183.168 63.6954Z"
        fill="rgb(var(--primary-color))"
      />
      <path
        d="M189.649 63.6954C190.746 63.6954 191.635 62.8054 191.635 61.7076C191.635 60.6097 190.746 59.7197 189.649 59.7197C188.552 59.7197 187.663 60.6097 187.663 61.7076C187.663 62.8054 188.552 63.6954 189.649 63.6954Z"
        fill="rgb(var(--primary-color))"
      />
      <path
        d="M196.339 63.6954C197.436 63.6954 198.326 62.8054 198.326 61.7076C198.326 60.6097 197.436 59.7197 196.339 59.7197C195.242 59.7197 194.353 60.6097 194.353 61.7076C194.353 62.8054 195.242 63.6954 196.339 63.6954Z"
        fill="rgb(var(--primary-color))"
      />
    </g>
  </svg>
);
