import { Tooltip } from "components/Tooltip";
import { CourseCoinAmount } from "../CourseCoinAmount";
import { CourseCoinsData } from "../../pages/Course/redux/interfaces/course.interface";
import styles from "./CourseCoinList.module.scss";

interface CourseCoinListProps {
  coins: CourseCoinsData;
  isMulticourse: boolean;
}

export const CourseCoinList = ({ coins, isMulticourse }: CourseCoinListProps) => (
  <div className={styles["coin-list"]}>
    <Tooltip content="За прохождение данного курса" className={styles["tooltip"]}>
      <CourseCoinAmount coin={coins?.course} hasText={false} />
    </Tooltip>

    {!isMulticourse && !!coins?.lessons?.amount && (
      <>
        {coins?.course?.amount && <span className={styles["plus"]}>+</span>}

        <Tooltip
          content="Сумма баллов за прохождение всех уроков курса"
          className={styles["tooltip"]}
        >
          <CourseCoinAmount coin={coins?.lessons} hasText={false} />
        </Tooltip>
      </>
    )}

    {isMulticourse && !!coins?.linked_courses?.amount && (
      <>
        {coins?.course?.amount && <span className={styles["plus"]}>+</span>}

        <Tooltip
          content="Сумма баллов за прохождение всех курсов траектории"
          className={styles["tooltip"]}
        >
          <CourseCoinAmount coin={coins?.linked_courses} hasText={false} />
        </Tooltip>
      </>
    )}
  </div>
);
