import { IQuizQuestion } from "../../../redux/Quze/interface";

export const getQuestionsByPosition = ({
  questions,
  position,
  currentOpenPage,
}: {
  questions: IQuizQuestion[];
  position: number;
  currentOpenPage: number;
}) => {
  if (position > 0) {
    const startSlice = (currentOpenPage - 1) * position;
    const endSlice = startSlice + position;
    return questions.slice(startSlice, endSlice);
  }

  return questions;
};
