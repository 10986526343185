import { Accordion } from "components/Accordion";
import { CourseCoinAmount } from "components/CourseCoinAmount";
import { H3 } from "components/Typography/Titles";
import { CourseContent } from "pages/Course/redux/interfaces/course.interface";
import { generateUid } from "../../../../../../app/utils";
import styles from "./CourseStudyProgrammRow.module.scss";

interface StudyProgrammRowProps {
  item: CourseContent;
}

export const CourseStudyProgrammRow = ({ item }: StudyProgrammRowProps): JSX.Element => {
  const accordionValue = (content: CourseContent) =>
    content?.lessons.map((item) => {
      return {
        title: item.title,
        value: item.description || "",
        children: (
          <>
            <CourseCoinAmount coin={item.coins} type="base" className={styles["coin"]} />
          </>
        ),
      };
    });

  return (
    <>
      <div className={styles["container"]} key={generateUid()}>
        <div className={styles["info"]}>
          <H3 className={styles["title"]}>{item?.title}</H3>
          <div className={styles["description"]}>{item?.description}</div>
        </div>
        <Accordion value={accordionValue(item)} className={styles["accordion"]} />
      </div>
    </>
  );
};
