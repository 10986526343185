import { Badge } from "components/Badge";
import { CommentsIcon, StarIcon } from "components/Icons";
import styles from "./CourseCardBadge.module.scss";
import classNames from "classnames";

const config = {
  rating: <StarIcon />,
  comments: <CommentsIcon />,
};

interface CourseCardBadgeProps {
  type: "comments" | "rating";
  text: string | number;
  className?: string;
}
export const CourseCardBadge = ({ type, text, className }: CourseCardBadgeProps): JSX.Element => (
  <Badge color="accent" className={classNames(styles["badge"], className)}>
    {config[type]}
    {text}
  </Badge>
);
