import { ProfileTransaction } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import { RightArrowShortIcon } from "components/Icons";
import classNames from "classnames";
import { format } from "date-fns";
import { BalanceRow } from "components/BalanceRow";
import styles from "./OperationHistoryCard.module.scss";

interface OperationHistoryCardProps {
  transaction: ProfileTransaction;
}

export const OperationHistoryCard = ({ transaction }: OperationHistoryCardProps): JSX.Element => (
  <div className={styles["container"]}>
    <div className={styles["left-content"]}>
      <RightArrowShortIcon
        className={classNames(styles["arrow"], { [styles["arrow-left"]]: transaction.coins < 0 })}
      />
      <div className={styles["date"]}>
        {format(new Date(transaction.transaction_ts), "dd.MM.yyyy")}
      </div>
      <div className={styles["time"]}>{format(new Date(transaction.transaction_ts), "HH:mm")}</div>
    </div>
    <div className={styles["reason"]}>{transaction.reason_additional}</div>
    <BalanceRow
      coins={transaction.coins}
      className={classNames(styles["balance"], { [styles["negative"]]: transaction.coins < 0 })}
    />
  </div>
);
