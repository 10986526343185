import { CloseIcon } from "../../../../../components/Icons";
import { TextField } from "../../../../../components/TextField";
import styles from "../RewardsSearch.module.scss";

interface GeneralSearchProps {
  searchInputValue: string;
  onChange?: (value: string) => void;
}

export const GeneralSearch = ({ searchInputValue, onChange }: GeneralSearchProps) => {
  return (
    <div className={styles["main-control"]}>
      <TextField
        maxLength={200}
        showCleaner={true}
        value={searchInputValue}
        onChange={onChange}
        label="Поиск"
        endAbortment={<>{searchInputValue && <CloseIcon onClick={() => onChange?.("")} />}</>}
      />
    </div>
  );
};
