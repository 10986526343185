import { createSlice } from "@reduxjs/toolkit";
import {
  enableNotificationAction,
  removeLessonAction,
  resetEnableNotificationAction,
} from "./actions";
import { meetingInitialState } from "./meeting-initial-state";
import { getMeetingThunkAction, postMeetingViewed } from "./thunk-actions";

const courseMeetingSlice = createSlice({
  name: "courseMeetingSlice",
  initialState: meetingInitialState,
  reducers: {
    enableNotification: enableNotificationAction,
    removeLesson: removeLessonAction,
    resetEnableNotification: resetEnableNotificationAction,
  },
  extraReducers(builder) {
    builder.addCase(getMeetingThunkAction.pending, (state) => {
      state.isLoadingLesson = true;
    });

    builder.addCase(getMeetingThunkAction.fulfilled, (state, action) => {
      state.isLoadingLesson = false;
      state.lesson = action.payload;
    });
    builder.addCase(postMeetingViewed.fulfilled, (state, action) => {
      if (!state.lesson) return;
      state.lesson.status = "completed";
    });
  },
});

const courseMeetingReducer = courseMeetingSlice.reducer;

export const { enableNotification, removeLesson, resetEnableNotification } =
  courseMeetingSlice.actions;

export { courseMeetingReducer };
