import { useAppSelector } from "hooks/redux";
import { selectContent } from "pages/Course/redux/courseSelectors";
import { H3 } from "components/Typography/Titles";
import { generateUid } from "app/utils";
import { CourseStudyProgrammRow } from "./components/CourseStudyProgrammRow";
import styles from "./StudyProgramm.module.scss";

export const StudyProgramm = (): JSX.Element => {
  const content = useAppSelector(selectContent);

  return (
    <>
      {content && (
        <div className={styles["container"]}>
          <H3 className={styles["title"]}>Программа обучения</H3>
          {content?.map((item) => (
            <CourseStudyProgrammRow item={item} key={generateUid()} />
          ))}
        </div>
      )}
    </>
  );
};
