import { TogglesType } from "pages/Authorization/data/data.types";
import styles from "../../AuthorizationForm.module.scss";

interface TogglesProps {
  toggles?: TogglesType;
  registrationIsAllowed: boolean;
}

export const Toggles = ({ toggles, registrationIsAllowed }: TogglesProps): JSX.Element => (
  <>
    {toggles && (
      <div className={styles["add"]}>
        <div
          onClick={() => {
            toggles?.top?.action();
          }}
        >
          {!(toggles.top.isReg && !registrationIsAllowed) && (
            <span>{toggles?.top?.title ?? ""}</span>
          )}
        </div>
      </div>
    )}
  </>
);
