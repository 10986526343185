import { WebAppRoutes } from "app/routes";
import { mediaQuerys } from "app/utils";
import Container from "components/Container/Container";
import PaperBox from "components/Paper/PaperBox";
import { useMediaQuery } from "hooks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getProfileCoins,
  getProfileTransactions,
  getShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import {
  selectProfileCoins,
  selectShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./OperationHistory.module.scss";
import { OperationHistoryBalanceList } from "./components/OperationHistoryBalanceList";
import { OperationHistoryHeader } from "./components/OperationHistoryHeader";
import { OperationHistoryList } from "./components/OperationHistoryList";

export const OperationHistory = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const lgMin = useMediaQuery(`(min-width:${mediaQuerys.lgMin}px)`);

  const shopSettings = useAppSelector(selectShopSettings);
  const coins = useAppSelector(selectProfileCoins);

  useEffect(() => {
    dispatch(getShopSettings()).then((res) => {
      // обработка ошибок
      if (res.payload.status === 200) {
        if (res.payload?.settings?.data?.shop?.enable) {
          dispatch(getProfileCoins());
          dispatch(getProfileTransactions()).then(
            (res) => res.payload.status !== 200 && navigate(`/${res.payload.status}`)
          );
        } else {
          navigate(WebAppRoutes.HOME);
        }
      } else {
        navigate(`/${res.payload.status}`);
      }
    });
  }, [dispatch, navigate]);

  const getTransactions = (limit: number, offset: number) => {
    dispatch(getProfileTransactions({ offset, limit })).then(
      (res) => res.payload.status !== 200 && navigate(`/${res.payload.status}`)
    );
  };

  return (
    <Container className={styles["history-container"]}>
      <div className={styles["wrapper"]}>
        <PaperBox className={styles["container"]}>
          <OperationHistoryHeader />
          <OperationHistoryList getTransactions={getTransactions} />
        </PaperBox>
        {lgMin && shopSettings && coins && (
          <OperationHistoryBalanceList shopSettings={shopSettings} coins={coins} />
        )}
      </div>
    </Container>
  );
};
