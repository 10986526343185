import { Link, useNavigate } from "react-router-dom";
import { selectDrawerOpened } from "../../../../features/mainMenu/selectors";
import { changeDrawerOpened } from "../../../../features/mainMenu/slice";

import { Capacitor } from "@capacitor/core";
import { getProfileAvatar } from "pages/Profile/redux/profile-slice/profile.slice";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { WebAppRoutes } from "../../../../app/routes";
import { CustomDrawer } from "../../../../components/CustomDrawer/CustomDrawer";
import { clear } from "../../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { selectProfileName } from "../../../../pages/Profile/redux/profile-slice/selectors/profile.selectors";
import UserAvatar from "../../../components/UserAvatar";
import { mobile_version } from "app/constants";

const MainMenuDrawer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userName = useAppSelector(selectProfileName);
  const drawerOpened = useAppSelector(selectDrawerOpened);
  const shopSettings = useAppSelector(selectShopSettings);
  const closeDrawer = () => {
    dispatch(changeDrawerOpened(false));
  };
  const handleLogout = () => {
    dispatch(clear());
    dispatch(changeDrawerOpened(false));
    navigate(WebAppRoutes.HOME, { replace: true });
  };

  useEffect(() => {
    dispatch(getProfileAvatar());
  }, [dispatch]);

  return (
    <CustomDrawer open={drawerOpened} className="drawer" onClose={closeDrawer}>
      <div className="drawer-profile">
        <div className="drawer-profile__img">
          <UserAvatar />
        </div>
        <p className="drawer-profile__name">{userName + " "}</p>
      </div>

      <ul className="drawer-list drawer-list-top">
        <li className="drawer-list__item">
          <Link to="/" onClick={closeDrawer} data-e2e={"mob-user-menu-education"}>
            Мое обучение
          </Link>
        </li>
        <li className="drawer-list__item" data-e2e={"mob-user-menu-catalog"}>
          <Link to="/courses" onClick={closeDrawer}>
            Каталог
          </Link>
        </li>

        {!Capacitor.isNativePlatform() && !!shopSettings?.enable && (
          <li className="drawer-list__item" data-e2e={"mob-user-menu-rewards-shop"}>
            <Link to="/rewards-shop" onClick={closeDrawer}>
              Магазин наград
            </Link>
          </li>
        )}
      </ul>
      <ul className="drawer-list drawer-list-bottom">
        <li className="drawer-list__item">
          <Link to="/profile" onClick={closeDrawer} data-e2e={"mob-user-menu-profile"}>
            Профиль
          </Link>
        </li>
        {shopSettings?.enable && !Capacitor.isNativePlatform() && (
          <li className="drawer-list__item">
            <Link to="/orders" onClick={closeDrawer} data-e2e={"mob-user-menu-profile"}>
              Мои заказы
            </Link>
          </li>
        )}
        <li className="drawer-list__item">
          <Link to="/history" onClick={closeDrawer} data-e2e={"mob-user-menu-history"}>
            История
          </Link>
        </li>
        <li className="drawer-list__item">
          <Link to={WebAppRoutes.SETTINGS} onClick={closeDrawer} data-e2e={"mob-user-menu-setting"}>
            Настройки
          </Link>
        </li>
        <li className="drawer-list__item">
          <Link to="/" onClick={handleLogout} data-e2e={"mob-user-menu-exit"}>
            Выход
          </Link>
        </li>
      </ul>
      {mobile_version && Capacitor.isNativePlatform() && (
        <p className="drawer__mobile-version">{mobile_version}</p>
      )}
    </CustomDrawer>
  );
};

export default MainMenuDrawer;
