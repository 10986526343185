import { useAppDispatch, useAppSelector } from "hooks/redux";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { setQuizeQuestionAnswer } from "../../redux/Quze/courseQuizeQuestionsSlice";
import { selectCourseQuizeDetail } from "../../redux/Quze/courseQuizeSelectors";
import { IQuizQuestion } from "../../redux/Quze/interface";
import { getQuizControl } from "./functions";

interface QuestionProps {
  lessonId: string;
  quest: IQuizQuestion;
  questIndex: number;
  onChange: (id: string | number, flush: () => unknown) => void;
}

export const Question = ({ lessonId, quest, questIndex, onChange }: QuestionProps) => {
  const dispatch = useAppDispatch();

  const quizeDetail = useAppSelector(selectCourseQuizeDetail);

  const handleChange = useMemo(
    () =>
      _.debounce(({ id, answer }: { id: string | number; answer: any }) => {
        const option = {
          quizeId: quizeDetail.id as string | number,
          questionId: id as string | number,
          attemptId: quizeDetail?.attempt_id ?? 1,
          answer: "" as any,
        };
        if (!!answer && answer?.constructor === Object) {
          if (Object.keys(answer).length) {
            const result = [];
            for (const key in answer) {
              if (key) {
                result.push([key, answer[key]]);
              }
            }
            option.answer = result;
          }
        }
        if (typeof answer === "string") {
          option.answer = answer;
        }
        if (Array.isArray(answer)) {
          option.answer = answer;
        }

        return dispatch(setQuizeQuestionAnswer(option));
      }, 1000),
    [dispatch, quizeDetail]
  );

  useEffect(() => {
    onChange(quest.id, handleChange.flush);
  }, [handleChange, onChange, quest.id]);

  return <>{getQuizControl({ quest, questIndex, lessonId, handleChange })}</>;
};
