import { NavLink } from "react-router-dom";
import styles from "./RowNavMenu.module.scss";
import classNames from "classnames";
import { generateUid } from "app/utils";

interface RowNavMenuProps {
  className?: string;
  routes?: { label: string; path: string }[];
}

export const RowNavMenu = ({ className = "", routes = [] }: RowNavMenuProps) => (
  <div className={classNames(styles["container"], className)}>
    {routes.length > 0 &&
      routes.map((route) => (
        <NavLink
          key={generateUid()}
          to={route.path}
          className={({ isActive }) =>
            classNames(styles["nav-link"], { [styles["nav-link-active"]]: isActive })
          }
        >
          {route.label}
        </NavLink>
      ))}
  </div>
);
